import api from '@/api/api';
import { TId } from '@/features/common';
import { parseAPIData, serialiseAPIData } from '@/utils/parsers';

import { IRecommendedCandidateEmail, IRecommendedCandidates } from '../types';

export async function fetchRecommendedCandidates(params: {
  jobOpeningId: TId;
}): Promise<IRecommendedCandidates> {
  const response = await api.get(
    `/openings/${params.jobOpeningId}/recommendations`
  );

  return parseAPIData(response.data);
}

export async function sendInvitationRecommendedCandidate(params: {
  jobOpeningId: TId;
  emailData: {
    candidateId: String;
    pipelineStageId: String;
    email: IRecommendedCandidateEmail;
  };
}): Promise<{ candidateId: TId }> {
  const response = await api.post(
    `/openings/${params.jobOpeningId}/recommendations`,
    serialiseAPIData(params.emailData)
  );

  return parseAPIData(response.data);
}
