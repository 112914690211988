import { TFeedbackScreenContactField } from '@/features/test';

export const MANDATORY_SYSTEM_CONTACT_FIELDS: TFeedbackScreenContactField[] = [
  {
    key: 'firstName',
    label: 'First name',
    mandatory: true,
    enabled: true,
    placeholder: null,
  },
  {
    key: 'lastName',
    label: 'Last name',
    mandatory: true,
    enabled: true,
    placeholder: null,
  },
  {
    key: 'email',
    label: 'Email',
    mandatory: true,
    enabled: true,
    placeholder: null,
  },
];

export const SYSTEM_CONTACT_FIELDS: TFeedbackScreenContactField[] = [
  {
    key: 'linkedInUrl',
    label: 'LinkedIn profile link',
    mandatory: false,
    enabled: true,
    placeholder: 'https://linkedin.com/in/alex',
  },
  {
    key: 'phoneNumber',
    label: 'Phone number',
    mandatory: false,
    enabled: true,
    placeholder: null,
  },
  {
    key: 'city',
    label: 'City',
    mandatory: false,
    enabled: true,
    placeholder: null,
  },
  {
    key: 'country',
    label: 'Country',
    mandatory: false,
    enabled: true,
    placeholder: null,
  },
];

export const SCORE_CHART_DISTRIBUTION: {
  range: [number, number];
  value: number;
}[] = [
  { range: [0, 10], value: 20 },
  { range: [10, 20], value: 10 },
  { range: [20, 30], value: 10 },
  { range: [30, 40], value: 30 },
  { range: [40, 50], value: 90 },
  { range: [50, 60], value: 20 },
  { range: [60, 70], value: 60 },
  { range: [70, 80], value: 70 },
  { range: [80, 90], value: 20 },
  { range: [90, 100], value: 1 },
];

export const SCORE_CHART_SKILLS_DISTRIBUTION: {
  skill: string;
  score: number;
}[] = [
  { skill: 'JavaScript', score: 100 },
  { skill: 'HTML & CSS', score: 25 },
  { skill: 'React', score: 60 },
  { skill: 'General coding', score: 0 },
  { skill: 'Communication', score: 0 },
];
