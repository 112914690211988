import * as React from 'react';
import styled from '@emotion/styled';
import { brands } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Icon } from '@/features/common';

interface LinkedInButtonProps {
  onClick: () => void;
  disabled?: boolean;
  variant?: 'login' | 'signup';
}

export function LinkedInButton({ variant, ...props }: LinkedInButtonProps) {
  return (
    <Button {...props} type="button">
      <Icon icon={brands('linkedin')} size="2xl" />
      <span>{variant === 'signup' ? 'Sign up' : 'Log in'} with LinkedIn</span>
    </Button>
  );
}

const Button = styled.button`
  width: auto;
  height: 39px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  padding: 0 1.33em;
  font-size: 12px;
  font-family: inherit;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.linkedin};
  -webkit-appearance: none;
  border: none;
  cursor: pointer;
  transition: box-shadow 0.2s;

  & span {
    margin-left: 6px;
  }

  &:hover {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.purple[50]};
    cursor: default;
  }

  &:disabled:hover {
    box-shadow: none;
  }
`;
