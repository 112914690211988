import React, { useEffect } from 'react';
import * as amplitude from '@amplitude/analytics-browser';

import { useWorkspaceId } from '@/hooks/router';

import {
  ConfirmCancelStepModal,
  ConfirmPauseModal,
  InitialCancelStepModal,
} from './ui';
import { FinalCancelStepModal } from './ui/final-cancel-step-modal';

export enum ECancelSubscribeStep {
  Initial = 'initial',
  ConfirmCancel = 'confirmCancel',
  ConfirmPause = 'confirmPause',
  FinalCancel = 'finalCancel',
}

export interface ICancelModalStepProps {
  open: boolean;
  setCurrentStep: React.Dispatch<
    React.SetStateAction<ECancelSubscribeStep | undefined>
  >;
}

interface ICancelModalProps {
  currentStep: ECancelSubscribeStep | undefined;
  setCurrentStep: React.Dispatch<
    React.SetStateAction<ECancelSubscribeStep | undefined>
  >;
}

export const CancelSubscribeModal = ({
  currentStep,
  setCurrentStep,
}: ICancelModalProps) => {
  const workspaceId = useWorkspaceId();
  useEffect(() => {
    amplitude?.logEvent(
      `billing/cancel subscription modal step ${currentStep}`,
      {
        'workspace id': workspaceId,
      }
    );
  }, [currentStep, workspaceId]);

  return currentStep ? (
    <>
      <InitialCancelStepModal
        open={currentStep === ECancelSubscribeStep.Initial}
        setCurrentStep={setCurrentStep}
      />

      <ConfirmPauseModal
        open={currentStep === ECancelSubscribeStep.ConfirmPause}
        setCurrentStep={setCurrentStep}
      />
      <ConfirmCancelStepModal
        open={currentStep === ECancelSubscribeStep.ConfirmCancel}
        setCurrentStep={setCurrentStep}
      />
      <FinalCancelStepModal
        open={currentStep === ECancelSubscribeStep.FinalCancel}
        setCurrentStep={setCurrentStep}
      />
    </>
  ) : null;
};
