import qs from 'qs';

export const linkedInLogin = (terms?: string) => {
  // window.location is used here as react-router history cannot get or replace
  // the host part of the URL
  const { protocol, host } = window.location;
  const callbackUrl = `${protocol}//${host}/login-callback`;
  const params: { 'redirect-url': string; 'terms-version'?: string } = {
    'redirect-url': callbackUrl,
  };
  if (terms) {
    params['terms-version'] = terms;
  }
  window.location.assign(
    `${import.meta.env.VITE_API_URL}/api/v1/auth/linkedin/user?${qs.stringify(
      params
    )}`
  );
};
