import React from 'react';
import { Redirect } from 'react-router-dom';
import { useMedia } from 'react-use';

import { AdminPage } from '@/features/admin-app/components/admin-page';

function HomePage() {
  const isMobile = useMedia('(max-width: 768px)');
  return isMobile ? (
    <AdminPage showNavigationOnMobile></AdminPage>
  ) : (
    <Redirect to="/admin" />
  );
}

export default HomePage;
