import React from 'react';
import styled from '@emotion/styled';

import { AddTag, TagItem, TagRecommended } from './ui';

interface TagsProps {
  children: React.ReactNode;
}

export function Tags(props: TagsProps) {
  return (
    <>
      <TagsWrapper>{props.children}</TagsWrapper>
    </>
  );
}

Tags.Add = AddTag;
Tags.Recommended = TagRecommended;
Tags.Item = TagItem;

//region Styles
const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
`;
// endregion
