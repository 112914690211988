import { useHistory, useParams } from 'react-router';

import { TId } from '@/features/common';

export function useTestOnly() {
  const history = useHistory();

  const { categoryId } = useParams<{
    categoryId?: TId;
  }>();

  const isTestOnlyPage =
    !!categoryId && history.location.pathname.includes('test-library');

  return isTestOnlyPage;
}
