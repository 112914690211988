import React from 'react';

export function LoadErrorIllustration() {
  return (
    <svg width="156" height="96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M115.692.4h-75.44c-5.412 0-9.8 4.387-9.8 9.8v63.04c0 5.412 4.388 9.8 9.8 9.8h75.44c5.412 0 9.8-4.388 9.8-9.8V10.2c0-5.413-4.388-9.8-9.8-9.8Z"
        fill="#7E7284"
      />
      <path d="M118.425 8.103h-80.91v67.25h80.91V8.103Z" fill="#2C1338" />
      <path
        d="M77.971 6.274a1.73 1.73 0 1 0 0-3.46 1.73 1.73 0 0 0 0 3.46Z"
        fill="#54465E"
      />
      <path
        d="M77.953 50.035c6.473 0 11.72-5.247 11.72-11.72 0-6.473-5.247-11.72-11.72-11.72-6.472 0-11.72 5.247-11.72 11.72 0 6.473 5.248 11.72 11.72 11.72Z"
        fill="#FF897A"
      />
      <path
        d="m47.514 57.071 4.28 22a4.73 4.73 0 0 0 6.515 3.446 4.73 4.73 0 0 0 2.855-4.616l-.76-16.07-12.89-4.76ZM35.647 59.998l4.9 19.46a4.47 4.47 0 0 0 7.716 1.838 4.481 4.481 0 0 0 1.054-3.558l-2.62-18.62-11.05.88ZM33.384 60.903l4.25 16a4.74 4.74 0 0 1-4.58 6 4.75 4.75 0 0 1-4.49-3.2l-7.19-20.94 10.94-1.27 1.07 3.41ZM19.18 58.493l5.34 14.61a4.187 4.187 0 0 1-2.87 5.48 4.19 4.19 0 0 1-4.83-2.23l-8.61-17.86h10.97Z"
        fill="#E6B26E"
      />
      <path
        d="M22.18 38.002a39.828 39.828 0 0 1 17.61 4.06l25.81 12.73a6.46 6.46 0 0 1 2.86 8.79 6.45 6.45 0 0 1-8.16 3l-9.52-3.9a11.609 11.609 0 0 0-6.14-.75l-4.54.67c-4.007.59-8.076.62-12.09.09a44 44 0 0 0-11.46 0l-3 .39a11.54 11.54 0 0 1-12.71-8.88 11.54 11.54 0 0 1 6.63-13.2 36.668 36.668 0 0 1 14.71-3Z"
        fill="#FFDE91"
      />
      <path
        d="M139.69 82.86H16.29a3.26 3.26 0 0 0-2.3 5.53l5.58 5.74a4.822 4.822 0 0 0 3.42 1.47h110a4.826 4.826 0 0 0 3.46-1.47l5.58-5.74a3.261 3.261 0 0 0-2.34-5.53Z"
        fill="#2C1338"
      />
      <path
        d="m108.469 57.071-4.28 22a4.733 4.733 0 0 1-9.37-1.17l.76-16.07 12.89-4.76ZM120.329 59.998l-4.9 19.46a4.47 4.47 0 0 1-7.715 1.838 4.478 4.478 0 0 1-1.055-3.558l2.62-18.62 11.05.88ZM122.599 60.903l-4.25 16a4.745 4.745 0 0 0 .802 4.136 4.733 4.733 0 0 0 3.778 1.864 4.752 4.752 0 0 0 4.49-3.2l7.19-20.94-10.94-1.27-1.07 3.41ZM136.801 58.493l-5.34 14.61a4.182 4.182 0 0 0 .227 3.373 4.192 4.192 0 0 0 2.643 2.107 4.189 4.189 0 0 0 4.83-2.23l8.61-17.86h-10.97Z"
        fill="#E6B26E"
      />
      <path
        d="M133.798 38.002a39.83 39.83 0 0 0-17.61 4.11l-25.81 12.68a6.46 6.46 0 0 0-2.86 8.79 6.45 6.45 0 0 0 8.16 3l9.52-3.9c1.941-.801 4.063-1.06 6.14-.75l4.54.67c4.007.59 8.076.62 12.09.09a44 44 0 0 1 11.46 0l3 .39a11.543 11.543 0 0 0 12.73-8.86 11.538 11.538 0 0 0-6.65-13.22 36.668 36.668 0 0 0-14.71-3Z"
        fill="#FFDE91"
      />
    </svg>
  );
}
