import React, { useState } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import {
  DSButton,
  DSColumn,
  DSField,
  DSInput,
  DSModal,
  DSRow,
  DSSelect,
} from '@hundred5/design-system';
import { useFormikContext } from 'formik';
import { useMedia } from 'react-use';

import { useJobOpeningByIdQuery } from '@/features/job-opening';
import { useCreatePipelineCategoryMutation } from '@/features/pipeline';
import { IAddTestToPipelineFormValues } from '@/features/test-library';

import addNewStageIllustration from '../assets/add-new-stage-illustration.svg';

import {
  ModalBody,
  ModalFooter,
  ModalFooterButtons,
  ModalHeader,
} from './modal';

export const AddNewStageModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const [stageName, setStageName] = useState('');
  const isMobile = useMedia('(max-width: 768px)');
  const createPipelineCategoryMutation = useCreatePipelineCategoryMutation();
  const form = useFormikContext<IAddTestToPipelineFormValues>();
  const jobOpeningQuery = useJobOpeningByIdQuery(
    { jobOpeningId: form.values.jobOpeningId! },
    { enabled: !!form.values.jobOpeningId }
  );
  if (form.values.jobOpeningId === undefined) {
    return null;
  }

  const handleSave = async () => {
    const createdStage = await createPipelineCategoryMutation.mutateAsync({
      jobOpeningId: form.values.jobOpeningId!,
      name: stageName,
      orderWeight: 1,
    });
    form.setFieldValue('pipelineStageId', createdStage.id);
    amplitude?.logEvent('test library/new stage created', {
      jobOpeningId: form.values.jobOpeningId,
    });
    onClose();
  };

  return (
    <DSModal
      open={open}
      contentStyle={{ width: isMobile ? '100%' : '644px' }}
      layer={2}
    >
      <ModalHeader>Add new stage</ModalHeader>
      <DSModal.CloseButton fixed small onClick={() => onClose()} />

      <DSModal.Separator />
      <ModalBody>
        <DSRow gap="12px" smWrap>
          <DSColumn size={12} sm={24}>
            <DSField for="jobOpening" required label="job">
              <DSSelect
                items={[
                  {
                    id: form.values.jobOpeningId!,
                    label: jobOpeningQuery.data?.name ?? '',
                  },
                ]}
                selectedItemId={form.values.jobOpeningId}
                onChange={() => {}}
                disabled
              />
            </DSField>
          </DSColumn>
          <DSColumn size={12} sm={24}>
            <DSField for="stage" required label="pipeline stage">
              <DSInput
                placeholder="Name the stage"
                onChange={(event) => setStageName(event.target.value)}
              />
            </DSField>
          </DSColumn>
        </DSRow>
      </ModalBody>
      {!isMobile && <img alt="" src={addNewStageIllustration} />}
      <ModalFooter>
        <ModalFooterButtons>
          <DSButton variant="secondary" onClick={onClose}>
            Cancel
          </DSButton>
          <DSButton
            disabled={!!!stageName || createPipelineCategoryMutation.isLoading}
            onClick={handleSave}
          >
            Save
          </DSButton>
        </ModalFooterButtons>
      </ModalFooter>
    </DSModal>
  );
};
