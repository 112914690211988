import React from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Icon } from '@/features/common';
import { TQuestionSource, TQuestionType } from '@/features/questions';

interface QuestionIconProps {
  /**
   * Question source ('library' or 'custom')
   */
  questionSource?: TQuestionSource;
  /**
   * Question type (used if question is from Toggl's library)
   */
  questionType?: TQuestionType;
  /**
   * Is question static or randomized
   * (used if question is from Toggl's library and is of auto evaluable type)
   */
  isRandom?: boolean;
}

export function QuestionIcon({
  questionSource,
  questionType,
  isRandom,
}: QuestionIconProps) {
  return (
    <IconContainer
      backgroundColor={questionSource === 'custom' ? 'lavender' : 'orange'}
    >
      {getQuestionIcon({ questionSource, questionType, isRandom: isRandom })}
    </IconContainer>
  );
}

const AUTO_EVAL_QUESTION_TYPES = [
  'single-choice',
  'multiple-choice',
  'numeric-input',
  'picture-choice',
  'placeholder-close-ended',
];

const getQuestionIcon = ({
  questionSource,
  questionType,
  isRandom,
}: QuestionIconProps) => {
  const commonProps = {
    fontSize: 10,
    color: 'purple-100',
  };

  if (questionSource === 'custom') {
    return <Icon icon={regular('book-bookmark')} {...commonProps} />;
  }

  if (!!questionType && AUTO_EVAL_QUESTION_TYPES.includes(questionType)) {
    return isRandom ? (
      <Icon icon={regular('shuffle')} {...commonProps} />
    ) : (
      <Icon icon={regular('bolt-auto')} {...commonProps} />
    );
  }

  switch (questionType) {
    case 'free-text':
      return <Icon icon={regular('text')} {...commonProps} />;
    case 'code-input':
      return <Icon icon={regular('code')} {...commonProps} />;
    case 'video-question':
      return <Icon icon={regular('video')} {...commonProps} />;

    default:
      return null;
  }
};

const IconContainer = styled.div<{ backgroundColor: string }>`
  width: 26px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: ${({ backgroundColor, theme }) =>
    theme.colors[backgroundColor][20]};

  &:empty {
    display: none;
  }
`;
