import styled from '@emotion/styled';

export const CancelModalFooterButtons = styled.div`
  display: flex;
  justify-content: end;
  gap: 12px;
`;

export const CancelModalHeader = styled.div`
  font-weight: 700;
  font-size: 16px;
  color: ${(props) => props.theme.typography.colorPrimary};
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
`;

export const CancelModalContentContainer = styled.div`
  padding-top: 16px;
`;
