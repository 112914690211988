import { TId } from '@/features/common';
import { useMeQuery } from '@/features/me';
import { IWorkspace } from '@/features/workspace';
import { useWorkspaceIdOrNull } from '@/hooks/router';

export const useWorkspace = (params?: {
  workspaceId?: TId;
}): IWorkspace | undefined => {
  const workspaceId = useWorkspaceIdOrNull() || params?.workspaceId;
  const { data: me } = useMeQuery();

  return me?.workspaces?.find((workspace) => workspace.id === workspaceId);
};
