import { TId } from '@/features/common';
import { IDashboardJobOpening } from '@/features/dashboard';
import {
  IJobOpening,
  isJobOpeningPaused,
  useJobOpeningByIdQuery,
} from '@/features/job-opening';

type UseJobOpeningStatusProps =
  | { id: TId | null | undefined; data?: never }
  | { data: IJobOpening | IDashboardJobOpening | undefined; id?: never };

/**
 * A hook that takes either job opening id (TId) or job opening data (IJobOpening | IDashboardJobOpening)
 * and returns the job opening status:
 * - `isClosed`: job opening closedAt date is set
 * - `isPaused`: job opening pausedAt date is set and is not in the future
 */
export const useJobOpeningStatus = (props: UseJobOpeningStatusProps) => {
  const { id, data } = props;

  const jobOpeningQuery = useJobOpeningByIdQuery({
    jobOpeningId: id ?? undefined,
  });

  const jobOpening = data ?? jobOpeningQuery.data;

  return {
    isPaused: isJobOpeningPaused(jobOpening?.pausedAt),
    isClosed: !!jobOpening?.closedAt,
  };
};
