import React from 'react';

export function CogFilled() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.4984 5.20625C15.5984 5.47813 15.514 5.78125 15.2984 5.975L13.9453 7.20625C13.9796 7.46563 13.9984 7.73125 13.9984 8C13.9984 8.26875 13.9796 8.53438 13.9453 8.79375L15.2984 10.025C15.514 10.2188 15.5984 10.5219 15.4984 10.7937C15.3609 11.1656 15.1953 11.5219 15.0046 11.8656L14.8578 12.1187C14.6515 12.4625 14.4203 12.7875 14.1671 13.0938C13.9828 13.3188 13.6765 13.3937 13.4015 13.3062L11.6609 12.7531C11.2421 13.075 10.7796 13.3438 10.2859 13.5469L9.89527 15.3313C9.83277 15.6156 9.61402 15.8406 9.32652 15.8875C8.89527 15.9594 8.45152 15.9969 7.9984 15.9969C7.54527 15.9969 7.10152 15.9594 6.67027 15.8875C6.38277 15.8406 6.16402 15.6156 6.10152 15.3313L5.7109 13.5469C5.21715 13.3438 4.75465 13.075 4.3359 12.7531L2.5984 13.3094C2.3234 13.3969 2.01715 13.3188 1.83277 13.0969C1.57965 12.7906 1.3484 12.4656 1.14215 12.1219L0.995272 11.8687C0.804647 11.525 0.639022 11.1687 0.501522 10.7969C0.401522 10.525 0.485897 10.2219 0.701522 10.0281L2.05465 8.79688C2.02027 8.53438 2.00152 8.26875 2.00152 8C2.00152 7.73125 2.02027 7.46563 2.05465 7.20625L0.701522 5.975C0.485897 5.78125 0.401522 5.47813 0.501522 5.20625C0.639022 4.83438 0.804647 4.47813 0.995272 4.13438L1.14215 3.88125C1.3484 3.5375 1.57965 3.2125 1.83277 2.90625C2.01715 2.68125 2.3234 2.60625 2.5984 2.69375L4.33902 3.24688C4.75777 2.925 5.22027 2.65625 5.71402 2.45312L6.10465 0.66875C6.16715 0.384375 6.3859 0.159375 6.6734 0.1125C7.10465 0.0375 7.5484 0 8.00152 0C8.45465 0 8.8984 0.0375 9.32965 0.109375C9.61715 0.15625 9.8359 0.38125 9.8984 0.665625L10.289 2.45C10.7828 2.65313 11.2453 2.92188 11.664 3.24375L13.4046 2.69062C13.6796 2.60312 13.9859 2.68125 14.1703 2.90313C14.4234 3.20938 14.6546 3.53437 14.8609 3.87812L15.0078 4.13125C15.1984 4.475 15.364 4.83125 15.5015 5.20312L15.4984 5.20625ZM8.00152 10.5C9.38277 10.5 10.5015 9.38125 10.5015 8C10.5015 6.61875 9.38277 5.5 8.00152 5.5C6.62027 5.5 5.50152 6.61875 5.50152 8C5.50152 9.38125 6.62027 10.5 8.00152 10.5Z"
        fill="#ABA0AF"
      />
    </svg>
  );
}
