import React from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Icon, SemanticButton } from '@/features/common';

export interface QuestionNavigationItemProps {
  number: number;
  icon?: React.ReactNode;
  title?: string;
  points?: number;
  isSelected?: boolean;
  isDisabled?: boolean;
  onClick?: () => void;
}

export function QuestionNavigationItem({
  number,
  icon,
  title,
  points,
  isSelected,
  isDisabled,
  onClick,
}: QuestionNavigationItemProps) {
  return (
    <NavigationItem
      type="button"
      selected={isSelected}
      disabled={isDisabled}
      onClick={() => {
        !isDisabled && onClick?.();
      }}
    >
      <Icon icon={regular('grip-dots-vertical')} color="purple-60" />

      <Number>{number}</Number>

      <span>{icon}</span>

      <Title>{title}</Title>

      <Points>{points && `${points}pt`}</Points>
    </NavigationItem>
  );
}

const NavigationItem = styled(SemanticButton)<{
  selected?: boolean;
}>`
  width: 100%;
  height: 36px;
  padding: 0 8px;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 12px 20px 26px 1fr auto;
  place-items: center;
  gap: 4px;
  border-radius: 8px;
  background: ${({ selected, theme }) =>
    selected ? theme.colors.purple[5] : theme.colors.white};
  box-shadow: ${({ selected, theme }) =>
    selected ? 'none' : theme.shadow.card};
  border: 1px solid
    ${({ selected, theme }) =>
      selected ? theme.colors.purple[40] : 'transparent'};
  transition:
    background 0.2s,
    border 0.2s;

  &:disabled {
    opacity: 0.4;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.purple[5]};
    cursor: pointer;
  }

  &:hover:disabled {
    background: ${({ theme }) => theme.colors.white};
    cursor: default;
  }
`;

const Number = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.typography.colorPrimary};
`;

const Title = styled.span`
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-self: flex-start;
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.typography.colorPrimary};
`;

const Points = styled.span`
  justify-self: flex-start;
  font-size: 10px;
  font-weight: 500;
  color: ${({ theme }) => theme.typography.colorPrimary};
`;
