import api from '@/api/api';
import { checkClientError } from '@/errors';
import { Id } from '@/types/misc';
import { parseAPIData } from '@/utils/parsers';

import { WorkspaceStats } from '../types';

export async function fetchWorkspaceStats(
  workspaceId: Id
): Promise<WorkspaceStats> {
  try {
    const response = await api.get(`/workspaces/${workspaceId}/stats`);

    return parseAPIData(response.data, {
      replacements: [
        //TODO: temporary replacements for weird api casing, to be fixed in API and removed
        ['ID', 'id'],
        ['WorkspaceID', 'workspaceId'],
      ],
    });
  } catch (error) {
    if (checkClientError(error, 'Forbidden')) {
      window.location.href = '/forbidden';
      throw error;
    } else {
      throw error;
    }
  }
}

export async function fetchWorkspaceCandidatesStats(
  workspaceId: Id
): Promise<{ total: number }> {
  const response = await api.get(`/workspaces/${workspaceId}/candidates/total`);

  return parseAPIData(response.data);
}

export async function markStatsAsSeen({ workspaceId }: { workspaceId: Id }) {
  await api.post(`/workspaces/${workspaceId}/stats/mark-as-seen`);
}
