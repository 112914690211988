import { useSearchParams } from '@/features/filters';

import { useInboxSearch } from '../providers';
import { IInboxSearchParams } from '../types';

export function useInboxSearchParams() {
  const { defaultParams, storageKey } = useInboxSearch();

  const {
    searchParams,
    hasSearchParams,
    addSearchParams,
    removeSearchParam,
    clearSearchParams,
  } = useSearchParams<IInboxSearchParams>(
    { ...defaultParams.pagination },
    storageKey
  );
  return {
    searchParams,
    hasSearchParams,
    addSearchParams,
    removeSearchParam,
    clearSearchParams,
  };
}
