import React from 'react';
import { TogglThemeProvider } from '@hundred5/design-system';
import { Redirect, Route, Switch } from 'react-router-dom';

import { AdminApp } from '@/features/admin-app';
import { Callback } from '@/features/authentication';
import { CandidateAppRedirect } from '@/features/candidate';
import { CandidateRedirect } from '@/features/candidate/components/candidate-redirect';
import { Forbidden, Tooltip } from '@/features/common';
import { GoogleReCaptcha } from '@/features/google-recaptcha';
import { Intercom } from '@/features/intercom';
import { Notifications } from '@/features/notifications';
import { AnonymousSplit } from '@/features/splits';
import { getSharedAuthEnabled } from '@/features/toggl-accounts';
import { useHubspotTracking } from '@/features/tracking';

import {
  useQueryClientWithAutoErrorHandling,
  useSentry,
  useSmartlook,
} from '../hooks';

import * as pages from './pages';
import { RedirectRouter } from './redirect-router';
import { StartDemo } from './start-demo';
import { StartSupport } from './start-support';

export function App() {
  useSmartlook();
  useSentry();
  useQueryClientWithAutoErrorHandling();
  useHubspotTracking();

  const sharedAuthEnabled = getSharedAuthEnabled();

  return (
    <TogglThemeProvider>
      <Notifications />
      <AnonymousSplit>
        <React.Suspense fallback={null}>
          <Switch>
            <Redirect from="/" to="/admin" exact />

            <Redirect from="/admin/login" to="/admin" />
            <Route path="/admin/signup" component={pages.SignUpPage} />

            {!sharedAuthEnabled && (
              <Route
                path="/admin/forgotten-password/reset"
                component={pages.ResetPasswordPage}
              />
            )}
            {!sharedAuthEnabled && (
              <Route
                path="/admin/forgotten-password"
                component={pages.ForgottenPasswordPage}
              />
            )}

            {!sharedAuthEnabled && (
              <Route path="/login-callback" component={Callback} />
            )}

            <Route path="/admin" component={AdminApp} />

            <Route path="/demo" component={StartDemo} />
            <Route path="/support-auth" component={StartSupport} />
            <Route
              path="/redirect/candidate/:workspaceId/:jobOpeningId/:candidateId/:tab(results|notes|history|application)/:tabItemId/:noteId?"
              component={CandidateRedirect}
            />
            <Route
              path="/redirect/candidate/:workspaceId/:jobOpeningId/:candidateId/:testId?"
              component={CandidateRedirect}
            />
            <Route path="/redirect" component={RedirectRouter} />
            <Route
              path="/c/:slug/:hashedTestId?"
              component={CandidateAppRedirect}
            />
            <Route path="/forbidden" component={Forbidden} />
            <Route path="/:slug" component={CandidateAppRedirect} />
          </Switch>
        </React.Suspense>
      </AnonymousSplit>
      <Tooltip />
      <Intercom />
      <GoogleReCaptcha />
    </TogglThemeProvider>
  );
}
