import { TQuestionType } from '@/features/questions';
import { ISkill, TQuestionTypeOptions } from '@/features/skills';
import { TTestType } from '@/features/test';

export function skillHasQuestionsForTestType(
  skill: ISkill,
  testType: TTestType
): boolean {
  if (testType === 'video') {
    return !!skill.questions.video['videoQuestion'];
  }

  const questionForTestType: TQuestionTypeOptions = skill.questions[testType];

  // whether we have hard or standard question for any type
  return Object.values(questionForTestType).some(
    (questionType) => questionType.hard || questionType.standard
  );
}

export function getQuestionTypesForSkill(
  skill: ISkill,
  testType: TTestType
): TQuestionType[] {
  const questionOptions = skill.questions[testType];

  return Object.keys(questionOptions).filter(
    (questionType) =>
      questionOptions[questionType].standard ||
      questionOptions[questionType].hard
  ) as TQuestionType[];
}
