import React, { createContext, useContext, useReducer } from 'react';

type AuthenticationContextType = { authenticated: boolean | null };

type AuthenticationActionType =
  | { type: 'AUTH_INITIALIZED'; payload: { authenticated: boolean } }
  | { type: 'LOGGED_IN' }
  | { type: 'LOGGED_OUT' };

export const AuthenticationContext = createContext<AuthenticationContextType>({
  authenticated: null,
});

export const AuthenticationDispatchContext = createContext<
  React.Dispatch<AuthenticationActionType>
>(null!);

export function useAuthentication() {
  const authenticationContext = useContext(AuthenticationContext);

  if (!AuthenticationContext) {
    throw new Error(
      'useAuthentication needs to be used within <AuthenticationProvider>'
    );
  }

  return authenticationContext;
}

export function useAuthenticationDispatch() {
  const authenticationDispatchContext = useContext(
    AuthenticationDispatchContext
  );

  if (!authenticationDispatchContext) {
    throw new Error(
      'useAuthenticationDispatch needs to be used within <AuthenticationProvider>'
    );
  }

  return authenticationDispatchContext;
}

const initialState = {
  authenticated: null,
};

function authenticationReducer(
  state: AuthenticationContextType,
  action: AuthenticationActionType
) {
  switch (action.type) {
    case 'AUTH_INITIALIZED':
      return { authenticated: action.payload.authenticated };
    case 'LOGGED_IN':
      return { authenticated: true };
    case 'LOGGED_OUT':
      return { authenticated: false };
    default:
      return state;
  }
}

export function AuthenticationProvider({ children }) {
  const [state, dispatch] = useReducer(authenticationReducer, initialState);

  return (
    <AuthenticationContext.Provider value={state}>
      <AuthenticationDispatchContext.Provider value={dispatch}>
        {children}
      </AuthenticationDispatchContext.Provider>
    </AuthenticationContext.Provider>
  );
}
