import {
  ICandidateSearchBaseProps,
  ICandidateSearchParams,
  TCandidateSearchStoreParams,
} from '../types';

export function getInitialParams(
  defaultParams: ICandidateSearchBaseProps,
  storeParams: TCandidateSearchStoreParams | null
): ICandidateSearchParams {
  return {
    ...defaultParams.pagination,
    ...defaultParams.sort,
    ...storeParams,
  };
}
