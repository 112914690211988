import React, { createContext, useContext } from 'react';

import { useDefaultSearchParams } from '@/features/filters';
import {
  IInboxSearchBaseProps,
  TInboxSearchStoreParams,
} from '@/features/inbox';
import { useStoreData } from '@/features/storage';

interface InboxSearchContextProps {
  defaultParams: IInboxSearchBaseProps;
  storageKey: string;
}

const InboxSearchContext = createContext<InboxSearchContextProps>({
  defaultParams: {
    pagination: {
      page: '1',
      itemsPerPage: '10',
    },
  },
  storageKey: 'inbox-search-params',
});

//region Provider
interface InboxSearchProviderProps extends IInboxSearchBaseProps {
  storageKey: string;
  children: React.ReactNode;
}

export function InboxSearchProvider({
  children,
  storageKey,
  ...defaultParams
}: InboxSearchProviderProps) {
  // Load the initial search params
  const [storeParams] = useStoreData<TInboxSearchStoreParams>(storageKey);
  useDefaultSearchParams({ ...defaultParams.pagination }, storeParams);

  return (
    <InboxSearchContext.Provider
      value={{
        defaultParams,
        storageKey,
      }}
    >
      {children}
    </InboxSearchContext.Provider>
  );
}

//endregion

//region Hooks
export function useInboxSearch() {
  const context = useContext(InboxSearchContext);
  if (context === undefined) {
    throw new Error('useInboxSearch must be used within a InboxSearchProvider');
  }
  return context;
}

//endregion
