import api from '@/api/api';
import { ICandidateHistoryResult } from '@/features/candidate';
import { parseAPIData } from '@/utils/parsers';

export async function fetchCandidateHistory(params: {
  candidateId: string;
  limit: number;
  offset: number;
}): Promise<ICandidateHistoryResult> {
  const response = await api.get(
    `/test-takers/${params.candidateId}/changelog`,
    {
      params: {
        limit: params.limit,
        offset: params.offset,
      },
    }
  );

  return {
    items:
      parseAPIData(response.data.data, {
        replacements: [['test_taker_id', 'candidate_id']],
      }) ?? [],
    total: response.data.total,
  };
}
