import React, { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { DSButton } from '@hundred5/design-system';
import { isFuture } from 'date-fns';
import { format } from 'date-fns/format';

import { CancelSubscribeModal, ECancelSubscribeStep } from '@/features/billing';
import { useMeQuery } from '@/features/me';
import { useWorkspaceIdOrNull } from '@/hooks/router';

import { paused, ResumeModal } from './ui';

export function PausedSubscriptionOverlay() {
  const workspaceId = useWorkspaceIdOrNull();

  const [resumeModalState, setResumeModalState] = useState<boolean>(false);
  const [cancelSubscribeStep, setCancelSubscribeStep] = useState<
    ECancelSubscribeStep | undefined
  >();

  const meQuery = useMeQuery();

  const { subscriptionPausedUntil, subscriptionPausedFrom } = useMemo(() => {
    if (!meQuery.isSuccess || workspaceId === null) {
      return {
        subscriptionPausedUntil: null,
        subscriptionPausedFrom: null,
      };
    }

    return {
      subscriptionPausedUntil: meQuery.data.workspaces?.find(
        (workspace) => workspace.id === workspaceId
      )?.subscriptionPausedUntil,
      subscriptionPausedFrom: meQuery.data.workspaces?.find(
        (workspace) => workspace.id === workspaceId
      )?.subscriptionPausedFrom,
    };
  }, [workspaceId, meQuery.isSuccess, meQuery.data?.workspaces]);

  const subscriptionPausedInFuture =
    subscriptionPausedFrom && isFuture(subscriptionPausedFrom);
  const isPaused =
    subscriptionPausedUntil &&
    !subscriptionPausedInFuture &&
    isFuture(subscriptionPausedUntil);

  useEffect(() => {
    if (isPaused) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    return () => document.body.classList.remove('no-scroll');
  }, [isPaused]);

  if (!isPaused || workspaceId === null) return null;

  return (
    <>
      <PausedSubscriptionOverlayContainer>
        <Content>
          <MessageContainer>
            <Title>Your subscription is paused.</Title>
            <Message className="pause-subscription-overlay__description">
              Your subscription is currently paused and will automatically
              restart on{' '}
              <b>
                {subscriptionPausedUntil &&
                  format(subscriptionPausedUntil, 'MMM d, yyyy')}
              </b>
              . You can resume your subscription now to get immediate access to
              your account.
            </Message>
            <ActionButtons>
              <DSButton
                variant="secondary"
                onClick={() =>
                  setCancelSubscribeStep(ECancelSubscribeStep.ConfirmCancel)
                }
              >
                Cancel subscription
              </DSButton>
              <DSButton
                variant="primary-purple"
                onClick={() => setResumeModalState(true)}
              >
                Resume subscription
              </DSButton>
            </ActionButtons>
          </MessageContainer>
        </Content>
      </PausedSubscriptionOverlayContainer>
      <ResumeModal
        resumeModalState={resumeModalState}
        setResumeModalState={setResumeModalState}
      />
      <CancelSubscribeModal
        currentStep={cancelSubscribeStep}
        setCurrentStep={setCancelSubscribeStep}
      />
    </>
  );
}

const PausedSubscriptionOverlayContainer = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100svh;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const Content = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.colors.purple[10]};

  display: flex;
  align-items: center;
  gap: 80px;
  margin: 24px;
  border-radius: 8px;
  padding: 16px 24px;
  overflow: hidden;

  @media screen and (min-width: 768px) {
    background-image: url(${paused});
    background-position: center right;
    background-repeat: no-repeat;
    background-size: clamp(200px, 35%, 300px) auto;
  }
`;

const MessageContainer = styled.div`
  @media screen and (min-width: 768px) {
    max-width: 65%;
  }
`;

const Title = styled.h1`
  font-family: 'GT Haptik', sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  margin: 0;
`;

const Message = styled.p`
  margin-top: 4px;
  font-size: 14px;
  line-height: 21px;
`;

const ActionButtons = styled.div`
  display: flex;
  flex-flow: wrap;
  gap: 12px;
  margin-top: 12px;
`;
