import { useCallback, useState } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import { addMonths } from 'date-fns';
import { format } from 'date-fns/format';

import {
  PauseDuration,
  usePauseSubscriptionMutation,
  useSubscriptionQuery,
} from '@/features/billing';
import { useNotifications } from '@/features/notifications';
import { useWorkspaceId } from '@/hooks/router';
import { pauseDurationMonthsValue } from '@/utils/workspace';

export const usePauseSubscriptionForm = () => {
  const workspaceId = useWorkspaceId();
  const { addNotification } = useNotifications();
  const [pauseDuration, setPauseDuration] =
    useState<PauseDuration>('pause-1-month');

  const { mutateAsync: pauseSubscription, isLoading: isPausingSubscription } =
    usePauseSubscriptionMutation();

  const onSubmitCallback = useCallback(
    () =>
      pauseSubscription(
        { pauseDuration },
        {
          onSuccess: () => {
            amplitude?.logEvent(`billing/subscription paused`, {
              'workspace id': workspaceId,
              'pause duration': pauseDuration,
            });
            addNotification({ type: 'subscription_paused' });
          },
        }
      ),
    [workspaceId, pauseDuration, addNotification, pauseSubscription]
  );

  return {
    pauseDuration,
    setPauseDuration,
    onSubmitCallback,
    isPausingSubscription,
  };
};

export const usePauseSubscriptionModal = () => {
  const {
    pauseDuration,
    setPauseDuration,
    onSubmitCallback,
    isPausingSubscription,
  } = usePauseSubscriptionForm();
  const { data: billingAccount } = useSubscriptionQuery();

  const currentPlan = billingAccount && billingAccount.subscription.currentPlan;
  const pauseStartDate =
    billingAccount?.subscription.currentPeriodEndsAt &&
    format(billingAccount.subscription.currentPeriodEndsAt, 'MMM d, yyyy');
  const resumeDate =
    billingAccount?.subscription.currentPeriodEndsAt &&
    format(
      addMonths(
        billingAccount.subscription.currentPeriodEndsAt,
        pauseDurationMonthsValue[pauseDuration]
      ),
      'MMM d, yyyy'
    );

  return {
    pauseStartDate,
    currentPlan,
    resumeDate,
    onSubmitCallback,
    isPausingSubscription,
    pauseDuration,
    setPauseDuration,
  };
};
