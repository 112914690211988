import { useCallback } from 'react';

import { setAuthToken } from '@/api/api';
import { authenticate } from '@/api/authentication';
import { storeAuthToken } from '@/authentication';
import { LogInCredentials } from '@/types/authentication';

import { useAuthenticationDispatch } from '../providers/authentication.context';

type LoginHook = (credentials: LogInCredentials) => void;

export function useLogin(): LoginHook {
  const dispatch = useAuthenticationDispatch();

  return useCallback(
    async (credentials: LogInCredentials) => {
      const token = await authenticate(credentials);

      storeAuthToken(token);
      setAuthToken(token);

      dispatch({ type: 'LOGGED_IN' });
    },
    [dispatch]
  );
}
