import React from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSSelect } from '@hundred5/design-system';

import { Icon } from '@/features/common';
import { IInterview } from '@/features/interview';

interface ISchedulingLinkSelectProps {
  interview: IInterview;
  onInterviewSelect: (value: string) => void;
}

export const SchedulingLinkSelect = ({
  interview,
  onInterviewSelect,
}: ISchedulingLinkSelectProps) => {
  const interviewLinks = [
    {
      id: interview.schedulingLink!,
      label: (
        <>
          {interview.name}
          <PlaceholderLink>{interview.schedulingLink}</PlaceholderLink>
        </>
      ),
    },
  ];

  return (
    <StyledDSSelect
      Icon={<Icon icon={regular('link-simple')} color="purple-60" />}
      placeholder="Insert scheduling link"
      alwaysShowPlaceholder
      items={interviewLinks}
      onChange={(item) => {
        if (item) {
          onInterviewSelect(item.id);
        }
      }}
      small
    />
  );
};

const StyledDSSelect = styled(DSSelect)`
  .dropdown {
    width: 270px;
    left: 0;
  }
`;

const PlaceholderLink = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.purple[60]};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
