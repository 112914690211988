import { useEffect } from 'react';
import * as amplitude from '@amplitude/analytics-browser';

import { useTestPage } from '@/features/test';

export function useTestPageAnalytics(pageName: string) {
  const { workspace, jobOpening, test } = useTestPage();

  useEffect(() => {
    amplitude?.logEvent(`page view/open ${pageName}`, {
      'workspace id': workspace.id,
      'job opening id': jobOpening.id,
      'test id': test.id,
    });
  }, [workspace, jobOpening, test, pageName]);
}
