/**
 * The test duration time unit used in the API for tests (minutes)
 */
export const TestTimeUnit = {
  unit: 'minute',
  inSeconds: 60,
};

/**
 * The test duration time unit used in the API for homeworks (hours)
 */
export const HomeworkTimeUnit = {
  unit: 'hour',
  inSeconds: 3600,
};
