import React from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Icon } from '@/features/common';

import { ISkill } from '../../types';

export interface SkillProps {
  skill?: ISkill;
  selected: boolean;
  onClick: (skill?: ISkill) => void;
  inverted?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
  htmlTag?: 'li' | 'div';
}

export function Skill({
  skill,
  selected,
  onClick,
  inverted,
  disabled,
  children,
  htmlTag = 'li',
  ...props
}: SkillProps) {
  return (
    <Wrapper as={htmlTag} selected={selected} inverted={inverted} {...props}>
      <button type="button" onClick={() => onClick(skill)} disabled={disabled}>
        <div>{skill?.name.replace('VQ', '').trim() ?? children}</div>
        <div>
          <Icon icon={regular('plus')} />
        </div>
      </button>
    </Wrapper>
  );
}

const Wrapper = styled.li<{
  selected: boolean;
  inverted?: boolean;
  disabled?: boolean;
}>`
  button {
    background: ${({ theme, inverted, selected }) =>
      selected && !inverted ? theme.colors.purple[100] : 'transparent'};
    border-color: ${({ theme, selected, inverted }) =>
      selected && !inverted
        ? theme.colors.purple[100]
        : theme.colors.purple[40]};
    border-radius: 8px;
    border-style: solid;
    border-width: 1px;
    color: ${({ theme, selected, inverted }) =>
      selected && !inverted ? theme.colors.white : theme.colors.purple[100]};
    cursor: pointer;
    display: flex;
    margin-right: 8px;
    margin-bottom: 8px;
    padding: 0;
    height: 28px;
    line-height: 100%;

    &:disabled {
      pointer-events: none;
      opacity: 0.4;
    }

    &:hover {
      background: ${({ theme, inverted, selected }) =>
        selected && !inverted
          ? theme.colors.purple[90]
          : theme.colors.purple[5]};
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      padding: 8px;
    }

    div:first-of-type {
      border-right-width: 1px;
      border-right-style: solid;
      border-right-color: ${({ theme, selected, inverted }) =>
        selected && !inverted ? theme.colors.white : theme.colors.purple[40]};
    }

    svg {
      transform: rotate(${({ selected }) => (selected ? '45deg' : '0deg')});
      transition: transform 100ms ease-in-out;
    }

    svg path {
      fill: ${({ selected, inverted, theme }) =>
        selected && !inverted ? theme.colors.white : theme.colors.purple[60]};
    }
  }
`;
