import { useEffect } from 'react';

export const useDocumentTitle = (title?: string) => {
  useEffect(() => {
    if (title != null) {
      document.title = `${title} - Toggl Hire`;
    } else {
      document.title = 'Toggl Hire';
    }
  }, [title]);
};
