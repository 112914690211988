import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSButton } from '@hundred5/design-system';

import { Icon, ScrollableContainer, TId } from '@/features/common';
import {
  IPlaceholderQuestion,
  IQuestion,
  QuestionNavigationItem,
  QuestionNavigationList,
} from '@/features/questions';

import { TestInfo } from './ui';

interface TestEditorQuestionsProps {
  testId: TId;
  questions: IQuestion[];
  testTimeLimit: number;
  /**
   * Does user have rights to add and reorder questions
   */
  isEditable?: boolean;
  /**
   * Disables the whole question list and adding new questions,
   * used while adding a new question is in progress
   */
  isDisabled?: boolean;
  maxQuestionCount?: number;
  selectedQuestionId?: TId;
  onQuestionSelect: (question: IQuestion) => void;
  onAddQuestion: () => void;
  /**
   * Placeholder question for new questions, displayed as a last item in questions list and always selected
   */
  placeholderQuestion?: IPlaceholderQuestion;
}

export function TestEditorQuestions({
  testId,
  questions,
  testTimeLimit,
  isEditable,
  isDisabled,
  maxQuestionCount,
  selectedQuestionId,
  onQuestionSelect,
  onAddQuestion,
  placeholderQuestion,
}: TestEditorQuestionsProps) {
  const canAddMoreQuestions =
    !maxQuestionCount || questions.length < maxQuestionCount;

  const pointsTotal = useMemo(() => {
    return (
      questions.reduce((total, question) => total + question.points, 0) ?? 0
    );
  }, [questions]);

  return (
    <TestEditorQuestionsContainer>
      <TestInfo
        questionCount={questions.length}
        pointsTotal={pointsTotal}
        timeLimit={testTimeLimit}
      />
      {isEditable && (
        <AddQuestionButton
          variant="secondary"
          onClick={onAddQuestion}
          disabled={!canAddMoreQuestions || isDisabled}
          data-rh={
            !canAddMoreQuestions
              ? `You have reached the ${maxQuestionCount}-question limit! To add another question, delete or regenerate an existing one.`
              : undefined
          }
          data-rh-at="right"
        >
          <Icon icon={regular('plus')} />
          Add new question
        </AddQuestionButton>
      )}
      <Questions>
        <QuestionNavigationList
          testId={testId}
          questions={questions}
          selectedQuestionId={selectedQuestionId}
          onQuestionSelect={onQuestionSelect}
          isDisabled={isDisabled}
          isOrderingDisabled={!isEditable}
        />
        {placeholderQuestion && (
          <QuestionNavigationItem
            number={questions.length + 1}
            icon={placeholderQuestion.icon}
            title={placeholderQuestion.title}
            points={placeholderQuestion.points}
            isSelected
          />
        )}
      </Questions>
    </TestEditorQuestionsContainer>
  );
}

const TestEditorQuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow: hidden;
`;

const AddQuestionButton = styled(DSButton)`
  width: 100%;
`;

const Questions = styled(ScrollableContainer)`
  display: grid;
  gap: 8px;
`;
