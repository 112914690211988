import { useMemo } from 'react';
import { orderBy } from 'lodash';

import {
  IDashboardJobOpening,
  useDashboardJobOpeningsQuery,
} from '@/features/dashboard';
import { usePreferencesQuery } from '@/features/me';
import { useWorkspaceId } from '@/hooks/router';

export const useBookmarkedJobOpenings = ({
  enabled,
  filter,
}: {
  enabled?: boolean;
  filter: 'open' | 'closed';
}) => {
  const workspaceId = useWorkspaceId();
  const preferencesQuery = usePreferencesQuery();
  const dashboardJobOpeningsQuery = useDashboardJobOpeningsQuery(
    {
      workspaceId,
      filter,
    },
    { enabled: !!workspaceId && enabled }
  );
  const jobOpenings = useMemo(() => {
    if (dashboardJobOpeningsQuery.isSuccess) {
      const bookmarkedJobOpeningsIds =
        preferencesQuery.data?.bookmarkedJobOpenings || [];

      const bookmarkedOpenings = bookmarkedJobOpeningsIds
        .map((id) =>
          dashboardJobOpeningsQuery.data.openings?.find(
            (opening) => opening.id === id
          )
        )
        .filter(Boolean) as IDashboardJobOpening[];

      const sortedBookmarkedOpenings = orderBy(
        bookmarkedOpenings,
        (opening) => opening.createdAt.getTime(),
        'desc'
      );

      const regularOpenings = orderBy(
        dashboardJobOpeningsQuery.data.openings?.filter(
          (opening) => !bookmarkedJobOpeningsIds.includes(opening.id)
        ) ?? [],
        (opening) => opening.createdAt.getTime(),
        'desc'
      );

      return [...sortedBookmarkedOpenings, ...regularOpenings];
    }

    return [];
  }, [
    dashboardJobOpeningsQuery.isSuccess,
    preferencesQuery.data?.bookmarkedJobOpenings,
    dashboardJobOpeningsQuery.data?.openings,
  ]);

  return {
    jobOpenings,
    isLoading:
      preferencesQuery.isLoading || dashboardJobOpeningsQuery.isLoading,
  };
};
