import React, { useMemo } from 'react';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { useMedia } from 'react-use';

import { Icon } from '@/features/common';
import { useInboxTotalUnreadQuery } from '@/features/inbox';
import { useWorkspaceId } from '@/hooks/router';

function getIcon(label: string, active: boolean) {
  switch (label) {
    case 'My inbox':
      return active ? solid('inbox') : regular('inbox');
    case 'All jobs':
      return active ? solid('briefcase') : regular('briefcase');
    case 'All candidates':
      return active ? solid('users') : regular('users');
    case 'Test Library':
      return active ? solid('book-open') : regular('book-open');
    default:
      return active ? solid('briefcase') : regular('briefcase');
  }
}

export function Shortcuts() {
  const workspaceId = useWorkspaceId();
  const isMobile = useMedia('(max-width: 768px)');
  const { data: inboxTotalUnread } = useInboxTotalUnreadQuery();
  const history = useHistory();

  const shortcuts = useMemo(() => {
    const items = [
      {
        label: 'My inbox',
        url: `/admin/${workspaceId}/inbox`,
        desktopOnly: true,
        badge: inboxTotalUnread,
        active: history.location.pathname.includes('inbox'),
      },
      {
        label: 'All jobs',
        url: `/admin/${workspaceId}/openings`,
        active: !!history.location.pathname.match(/openings$/),
      },
      {
        label: 'All candidates',
        url: `/admin/${workspaceId}/candidates?page=1&items_per_page=10&sort_order=date&sort_direction=desc`,
        desktopOnly: true,
        active: history.location.pathname.includes('candidates'),
      },
      {
        label: 'Test Library',
        url: `/admin/${workspaceId}/test-library`,
        active: history.location.pathname.includes('test-library'),
      },
    ];
    return items.filter((item) => (isMobile ? !item.desktopOnly : true));
  }, [isMobile, workspaceId, history.location.pathname, inboxTotalUnread]);

  return (
    <Container>
      <List>
        {shortcuts.map(({ label, url, badge, active }) => {
          return (
            <li key={label}>
              <ShortcutLink to={url} active={active}>
                <>
                  <Icon icon={getIcon(label, active)} fixedWidth />
                  <ShortcutName>{label}</ShortcutName>
                  {badge !== undefined && <Badge>{badge}</Badge>}
                </>
              </ShortcutLink>
            </li>
          );
        })}
      </List>
    </Container>
  );
}

const Container = styled.section`
  margin-top: 8px;
`;

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    display: flex;
  }
`;

const ShortcutLink = styled(Link, {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== 'active',
})<{ active: boolean }>`
  align-items: center;
  background: ${({ active, theme }) =>
    active ? theme.colors.orange[110] : 'transparent'};
  color: ${({ theme }) => theme.colors.peach[70]};
  display: flex;
  flex-grow: 1;
  height: 28px;
  justify-content: space-between;
  padding: 0 16px;

  &:hover {
    background: ${({ active, theme }) =>
      active ? theme.colors.orange[110] : theme.colors.purple[90]};
  }

  svg path {
    fill: ${({ theme }) => theme.colors.peach[70]};
  }
`;

const ShortcutName = styled.span`
  display: inline-block;
  flex: 1;
  overflow: hidden;
  padding: 0 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Badge = styled.span`
  align-self: center;
  background: ${({ theme }) => theme.colors.purple[90]};
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.peach[70]};
  font-size: 10px;
  font-weight: 700;
  line-height: 15px;
  padding: 0 8px;
`;
