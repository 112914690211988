import { useMutation } from '@tanstack/react-query';

import { bulkReject } from '@/features/bulk-actions/api/bulk-reject';
import { TId } from '@/features/common';

//region Mutations
interface BulkRejectParams {
  candidateIds: TId[];
  subject?: string;
  message?: string;
  sendAt?: Date;
}

export const useBulkReject = () => {
  return useMutation({
    mutationFn: (params: BulkRejectParams) => bulkReject(params),
  });
};
//endregion
