import styled from '@emotion/styled';

export const Alert = styled.div<{ type: 'error' | 'warning' }>`
  background: ${({ type, theme }) =>
    type === 'error' ? theme.colors.red[10] : theme.colors.yellow[30]};
  border-radius: 8px;
  color: ${({ theme, type }) =>
    type === 'warning'
      ? theme.typography.colorPrimary
      : theme.typography.colorError};
  padding: 4px 12px;
`;
