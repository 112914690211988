import React from 'react';
import styled from '@emotion/styled';
import { DSModal } from '@hundred5/design-system';

import {
  EmailResultModalContextProvider,
  useEmailModalContext,
} from '@/features/email';
import {
  EmailBanner,
  useEmailBanner,
  useEnableGmailMutation,
} from '@/features/me';

import { SendEmailForm } from './email-form';
import {
  useEmail,
  useEmails,
  useEmailTemplatesType,
  useHeader,
} from './email-modal.hooks';

export const EmailModal = () => {
  const { isOpen, close, emailModal } = useEmailModalContext();
  const { showBanner } = useEmailBanner();
  const header = useHeader();
  const email = useEmail();
  const emails = useEmails();
  const emailTemplatesType = useEmailTemplatesType();
  const testId =
    emailModal && 'meta' in emailModal && 'testId' in emailModal.meta
      ? emailModal.meta.testId
      : undefined;
  const interviewId =
    emailModal && 'meta' in emailModal && 'interviewId' in emailModal.meta
      ? emailModal.meta.interviewId
      : undefined;

  // We don't need to call the useEnableGmailCallbackMutation, since after auth the user will be redirected to the profile page
  const enableGmailMeMutation = useEnableGmailMutation({
    onSuccess: (result: string) => result && (window.location.href = result),
  });

  return (
    <DSModal
      shouldCloseOnOverlayClick={false}
      fluid
      open={isOpen}
      onClose={close}
      contentStyle={{ padding: 0, maxWidth: 644, width: '100%' }}
    >
      <Header>
        <div>{header}</div>
        <div>
          <DSModal.CloseButton small fixed onClick={close} />
        </div>
      </Header>

      <Content>
        {showBanner ? (
          <EmailBanner
            size="large"
            onClick={() => {
              enableGmailMeMutation.mutate();
              close();
            }}
          />
        ) : (
          <EmailResultModalContextProvider>
            <SendEmailForm
              emails={emails || (email ? [email!] : undefined)}
              onSubmit={close}
              testId={testId}
              interviewId={interviewId}
              emailTemplatesType={emailTemplatesType}
              initialSubject={emailModal?.props?.initialSubject}
              initialMessage={emailModal?.props?.initialMessage}
              actionButton={emailModal?.props?.actionButton}
            />
          </EmailResultModalContextProvider>
        )}
      </Content>
    </DSModal>
  );
};

const Header = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.purple[10]};
  padding: 36px 36px 16px 36px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => props.theme.colors.purple[100]};
`;

const Content = styled.div`
  padding: 16px 36px 36px 36px;
`;
