import * as React from 'react';
import { capitalize, upperFirst } from 'lodash';
import 'react-hint/css/index.css';

import { useBillingPlansConfig } from '@/features/billing';
import { Tooltip } from '@/features/common';
import { useWorkspaceQuery } from '@/features/workspace';
import {
  getNextPlanName,
  getPlanLimit,
  getPlanName,
  useGetPlanRequired,
} from '@/hooks/planLimits';
import { useWorkspaceId } from '@/hooks/router';

import { Content } from './ui';

export function UpsellTooltip() {
  const workspaceId = useWorkspaceId();
  const workspaceQuery = useWorkspaceQuery({ workspaceId });
  const { plans: planDescriptions } = useBillingPlansConfig();

  const customTestMinimalPlanCode =
    useGetPlanRequired('share_custom_test', 'annual') || 'premium-1m-v2-2023';
  const premiumTestMinimalPlanMonthlyCode =
    useGetPlanRequired('share_premium_template_test', 'annual') ||
    'premium-1m-v2-2023';

  const nextPlanName =
    (workspaceQuery.data?.subscriptionPlan &&
      getNextPlanName(getPlanName(workspaceQuery.data.subscriptionPlan))) ||
    'premium';

  const nextPlanCode =
    planDescriptions.find(
      (planDescription) => planDescription.plan === nextPlanName
    )?.planCode.annual || 'premium-1m-v2-2023';

  const planUnlockableCandidatesLimit =
    workspaceQuery.data?.subscriptionPlan &&
    getPlanLimit(
      workspaceQuery.data?.subscriptionPlan,
      'unlockable_candidates'
    );

  return (
    <>
      <Tooltip
        persist
        attribute="data-upsell-tooltip"
        delay={{ show: 0, hide: 500 }}
        onRenderContent={() => {
          return (
            <Content
              text="Upgrade to start inviting candidates to this premium test."
              requiredPlan={premiumTestMinimalPlanMonthlyCode}
            />
          );
        }}
      />
      <Tooltip
        persist
        attribute="data-upsell-tooltip-custom"
        delay={{ show: 0, hide: 500 }}
        onRenderContent={() => {
          return (
            <Content
              text={`Upgrade to ${upperFirst(
                getPlanName(customTestMinimalPlanCode)
              )} Plan to start inviting candidates to this custom test.`}
              requiredPlan={customTestMinimalPlanCode}
            />
          );
        }}
      />
      <Tooltip
        persist
        attribute="data-unlock-candidate-upsell-tooltip"
        delay={{ show: 0, hide: 500 }}
        onRenderContent={() => {
          return (
            <Content
              text={`You have unlocked all ${planUnlockableCandidatesLimit} candidates. Upgrading to ${capitalize(
                nextPlanName
              )} Plan will unlock more candidates.`}
              requiredPlan={nextPlanCode}
            />
          );
        }}
      />
    </>
  );
}
