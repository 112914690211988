import styled from '@emotion/styled';

export const AddNewButton = styled.button<{ disabled?: boolean }>`
  all: unset;
  width: 100%;
  border-radius: 8px;
  cursor: pointer;
  color: ${({ theme }) => theme.typography.colorPrimary};
  margin: -10px -12px;
  padding: 4px 12px;
  pointer-events: ${({ disabled }) => disabled && 'none'};
  opacity: ${({ disabled }) => disabled && 0.5};
  svg {
    padding-right: 8px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.purple[5]};
  }
`;
