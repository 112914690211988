import { isEmpty } from 'lodash';

import { IQuestionOption, TQuestionType } from '@/features/questions';
import {
  ITest,
  IValidationResult,
  TEditableQuestion,
  TTestType,
} from '@/features/test';

const MIN_NUMBER_OF_OPTIONS = 2;

const validateQuestion = (
  description: string | undefined,
  points: number | null
): IValidationResult[] => {
  const results: IValidationResult[] = [];

  if (!description) {
    results.push({
      type: 'error',
      field: 'description',
      message: 'Hey, what’s the question?',
    });
  }

  if (description && description.length > 5000) {
    results.push({
      type: 'error',
      field: 'description',
      message: 'Your question is too long. Keep it under 5000 characters.',
    });
  }

  if (points == null || points < 0) {
    results.push({
      type: 'warning',
      field: 'points',
      message: 'You haven’t set the points 🙃',
    });
  }

  return results;
};

const hasOptions = (options: IQuestionOption[]): boolean => {
  return options.length > 0;
};

const hasOptionDescription = (options: IQuestionOption[]): boolean => {
  return options.every((option) => !isEmpty(option.option));
};

const hasEnoughOptions = (options: IQuestionOption[]): boolean => {
  return options.length >= MIN_NUMBER_OF_OPTIONS;
};

const hasCorrectAnswer = (options: IQuestionOption[]): boolean => {
  return options.some((option) => option.isCorrect);
};

const validateChoiceAnswer = (
  options: IQuestionOption[],
  questionType: TQuestionType
): IValidationResult[] => {
  const results: IValidationResult[] = [];

  if (!hasOptions(options) || !hasCorrectAnswer(options)) {
    results.push({
      type: 'error',
      field: 'answer',
      message: `Add your options and mark the correct answer${
        questionType === 'multiple-choice' ? '(s).' : '.'
      }`,
    });
  }

  if (!hasOptionDescription(options)) {
    results.push({
      type: 'error',
      field: 'answer',
      message: 'Some of the choices are empty 😕',
    });
  }

  if (!hasEnoughOptions(options)) {
    results.push({
      type: 'error',
      field: 'answer',
      message: 'Hey, you should add more choices!',
    });
  }

  return results;
};

const validateNumericAnswer = (
  answer: number | undefined
): IValidationResult[] => {
  if (!answer) {
    return [
      {
        type: 'warning',
        field: 'answer',
        message: 'Enter the right answer 😉',
      },
    ];
  }

  return [];
};

const validateAnswer = (
  testType: TTestType,
  type: TQuestionType,
  options: IQuestionOption[] | undefined,
  answer: number | undefined
): IValidationResult[] => {
  if (testType === 'homework' || testType === 'video') return [];

  switch (type) {
    case 'single-choice':
    case 'multiple-choice':
    case 'picture-question':
      return validateChoiceAnswer(options ?? [], type);

    case 'numeric-input':
      return validateNumericAnswer(answer);

    default:
      return [];
  }
};

export const runValidation = (
  test: ITest,
  question: TEditableQuestion
): IValidationResult[] => [
  ...validateQuestion(question.question, question.points),
  ...validateAnswer(
    test.type,
    question.questionType,
    question.options,
    question.answer
  ),
];
