import React from 'react';
import styled from '@emotion/styled';
import { DSButton, DSModal } from '@hundred5/design-system';
import { Form, Formik } from 'formik';
import * as yup from 'yup';

import { FormikInputField, PromptIfDirty } from '@/features/common';
import {
  useCreateWorkspaceWebhookMutation,
  useUpdateWebhookMutation,
} from '@/features/workspace/queries';
import { IWebhook } from '@/features/workspace/types';
import { useWorkspaceId } from '@/hooks/router';

interface AddEditWebhookProps {
  variant: 'add' | 'edit';
  webhook: IWebhook | null;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onRemove: () => void;
}

export const AddEditWebhookModal = ({
  variant,
  webhook,
  open,
  onClose,
  onConfirm,
  onRemove,
}: AddEditWebhookProps) => {
  const workspaceId = useWorkspaceId();
  const { mutate: createWorkspaceWebhook, isLoading: creating } =
    useCreateWorkspaceWebhookMutation();

  const { mutate: updateWorkspaceWebhook, isLoading: updating } =
    useUpdateWebhookMutation();

  return (
    <DSModal
      fluid
      open={open}
      onClose={() => onClose()}
      contentStyle={{ width: '600px' }}
    >
      <Header>
        <HeaderTitle>Add Webhook</HeaderTitle>
        <DSModal.Separator />
      </Header>
      <DSModal.CloseButton fixed onClick={onClose} small />
      <Formik
        initialValues={{
          name: webhook?.name || '',
          url: webhook?.url || '',
          authUserName: webhook?.authUserName || '',
          authPassword: '',
        }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          const attributes = {
            name: values.name,
            url: values.url,
            auth_user_name: values.authUserName,
            auth_password: values.authPassword,
          };

          if (variant === 'add') {
            await createWorkspaceWebhook({
              workspaceId,
              attributes,
            });
          } else {
            if (webhook) {
              await updateWorkspaceWebhook({
                webhookId: webhook.id.toString(),
                attributes,
              });
            }
          }
          onConfirm();
          onClose();
        }}
      >
        {({ dirty }) => (
          <Form>
            <ContainerKey>
              <PromptIfDirty />
              <StyledNameField name="name" label="Name" required />
              <StyledURLField name="url" label="URL" required />
              <StyledUsernameField
                name="authUserName"
                label="HTTP Auth username"
                required
              />
              <StyledPasswordField
                type="password"
                name="authPassword"
                label="HTTP Auth password"
                required
              />
            </ContainerKey>
            <DSModal.Footer>
              {variant === 'edit' && (
                <Wrapper>
                  <DSButton
                    variant="destructive-secondary"
                    type="button"
                    disabled={updating || creating}
                    onClick={() => {
                      onClose();
                      onRemove();
                    }}
                  >
                    Remove Webhook
                  </DSButton>
                </Wrapper>
              )}
              <DSButton disabled={!dirty || updating || creating} type="submit">
                {updating || creating ? 'Saving...' : 'Save changes'}
              </DSButton>
            </DSModal.Footer>
          </Form>
        )}
      </Formik>
    </DSModal>
  );
};

const validationSchema = yup.object().shape({
  name: yup.string().required('Name cannot be empty'),
  url: yup
    .string()
    .url('URL must be a valid URL')
    .required('URL cannot be empty'),
  authUserName: yup.string().required('Username cannot be empty'),
  authPassword: yup.string().required('Password cannot be empty'),
});

const Header = styled.div`
  font-weight: 700;
  font-size: 16px;
  color: ${(props) => props.theme.typography.colorPrimary};
  margin-bottom: 16px;
  @media all and (max-width: 768px) {
    padding-top: 8px;
  }
`;

const HeaderTitle = styled.div`
  padding-bottom: 16px;
  line-height: 19px;
`;

const ContainerKey = styled.div`
  display: grid;
  grid-template-areas:
    'name name'
    'url url'
    'username password';
  grid-auto-columns: 1fr 1fr;
  gap: 12px;
  align-items: flex-start;
  padding-bottom: 32px;
`;

const StyledNameField = styled(FormikInputField)`
  grid-area: name;
`;

const StyledURLField = styled(FormikInputField)`
  grid-area: url;
`;

const StyledUsernameField = styled(FormikInputField)`
  grid-area: username;
`;

const StyledPasswordField = styled(FormikInputField)`
  grid-area: password;
`;

const Wrapper = styled.div`
  margin-right: auto;
`;
