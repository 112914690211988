import React from 'react';
import styled from '@emotion/styled';

interface TooltipProps {
  title?: string;
  content: string;
}

export function Tooltip({ title, content }: TooltipProps) {
  return (
    <Content>
      {title ? <Title>{title}</Title> : null}
      <Text>{content}</Text>
    </Content>
  );
}

const Content = styled.div`
  padding: 8px 16px;
  font-size: 12px;
  line-height: 18px;
  background-color: #fff;
  border-radius: 8px;
  color: #2c1138; // TODO colour out of design system
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const Text = styled.div`
  white-space: nowrap;
  overflow-wrap: break-word;
`;
