import api from '@/api/api';
import { TId } from '@/features/common';
import { WorkspaceUser } from '@/features/workspace';
import { Id } from '@/types/misc';
import { parseAPIData } from '@/utils/parsers';

import { Role } from './../../../types/role';

export async function fetchWorkspaceUsers(params: {
  workspaceId: TId;
}): Promise<WorkspaceUser[]> {
  const response = await api.get(`workspaces/${params.workspaceId}/users`);

  return parseAPIData(response.data.users);
}

export async function updateWorkspaceUser(params: {
  workspaceId: Id;
  userId: Id;
  role: Role;
}): Promise<void> {
  await api.put(`workspaces/${params.workspaceId}/users/${params.userId}`, {
    role: params.role,
  });
}

export async function deleteWorkspaceUser(params: {
  workspaceId: Id;
  userId: Id;
}): Promise<void> {
  await api.delete(`workspaces/${params.workspaceId}/users/${params.userId}`);
}
