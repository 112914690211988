import React from 'react';
import styled from '@emotion/styled';

export const BriefcaseBlankCirclePlusIcon = styled(
  BriefcaseBlankCirclePlusIconSvg
)`
  width: 14px;
  height: 14px;
  color: ${({ theme }) => theme.colors.purple[100]};
`;

function BriefcaseBlankCirclePlusIconSvg() {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.8 2.6H8.4C8.4 2.36594 8.4 2.13406 8.4 1.9C8.4 1.12562 7.77437 0.5 7 0.5C6.06594 0.5 5.13406 0.5 4.2 0.5C3.42781 0.5 2.8 1.12781 2.8 1.9C2.8 2.13406 2.8 2.36594 2.8 2.6H1.4C0.627813 2.6 0 3.22781 0 4C0 5.86812 0 7.73406 0 9.6C0 10.3744 0.627813 11 1.4 11C3.56125 11 5.72031 11 7.88156 11C7.62344 10.6872 7.41344 10.3328 7.2625 9.95C5.30906 9.95 3.35344 9.95 1.4 9.95C1.2075 9.95 1.05 9.7925 1.05 9.6C1.05 7.73187 1.05 5.86594 1.05 4C1.05 3.8075 1.2075 3.65 1.4 3.65C4.2 3.65 7 3.65 9.8 3.65C9.9925 3.65 10.15 3.8075 10.15 4C10.15 4.25375 10.15 4.50969 10.15 4.76344C10.3775 4.72187 10.6116 4.7 10.85 4.7C10.9681 4.7 11.0841 4.70437 11.2 4.71531C11.2 4.47687 11.2 4.23844 11.2 4C11.2 3.22781 10.5722 2.6 9.8 2.6ZM3.85 1.9C3.85 1.7075 4.0075 1.55 4.2 1.55C5.13406 1.55 6.06594 1.55 7 1.55C7.1925 1.55 7.35 1.7075 7.35 1.9C7.35 2.13406 7.35 2.36594 7.35 2.6C6.18406 2.6 5.01594 2.6 3.85 2.6C3.85 2.36594 3.85 2.13406 3.85 1.9ZM14 8.55C14 10.2891 12.5891 11.7 10.85 11.7C9.11094 11.7 7.7 10.2891 7.7 8.55C7.7 6.81094 9.11094 5.4 10.85 5.4C12.5891 5.4 14 6.81094 14 8.55ZM10.5 7.15V8.2C10.15 8.2 9.8 8.2 9.45 8.2C9.2575 8.2 9.1 8.3575 9.1 8.55C9.1 8.7425 9.2575 8.9 9.45 8.9C9.8 8.9 10.15 8.9 10.5 8.9V9.95C10.5 10.1425 10.6575 10.3 10.85 10.3C11.0425 10.3 11.2 10.1425 11.2 9.95C11.2 9.6 11.2 9.25 11.2 8.9C11.55 8.9 11.9 8.9 12.25 8.9C12.4425 8.9 12.6 8.7425 12.6 8.55C12.6 8.3575 12.4425 8.2 12.25 8.2C11.9 8.2 11.55 8.2 11.2 8.2C11.2 7.85 11.2 7.5 11.2 7.15C11.2 6.9575 11.0425 6.8 10.85 6.8C10.6575 6.8 10.5 6.9575 10.5 7.15Z"
        fill="#2C1338"
      />
    </svg>
  );
}
