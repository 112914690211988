import { useContext } from 'react';

import { AddTestToPipelineModalContext } from '../components';

export const useAddTestToPipelineModalContext = () => {
  const context = useContext(AddTestToPipelineModalContext);

  if (!context) {
    throw new Error(
      'useAddTestToPipelineModalContext must be used within a AddTestToPipelineModalContextProvider'
    );
  }
  const { isOpen, open, close, isCustomTest, onAddTest } = context;

  return { isOpen, open, close, isCustomTest, onAddTest };
};
