import { camelizeKeys } from 'humps';

import api from '@/api/api';
import { parseExistingTest, parseStats } from '@/api/tests';
import { TId } from '@/features/common';

export async function createVideoIntroduction({
  testId,
  video,
}: {
  testId: TId;
  video: Blob;
}) {
  const formData = new FormData();
  formData.append('upload', video);
  const response = await api.post(`/tests/${testId}/introduction`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  const data = camelizeKeys(response.data) as any;
  return { test: parseExistingTest(data), testStats: parseStats(data) };
}

export async function deleteVideoIntroduction({ testId }: { testId: TId }) {
  const response = await api.delete(`/tests/${testId}/introduction`);
  const data = camelizeKeys(response.data) as any;
  return { test: parseExistingTest(data), testStats: parseStats(data) };
}
