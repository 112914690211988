import api from '@/api/api';
import { serialiseAPIData } from '@/utils/parsers';

export async function requestSkillOrTemplate(params: {
  type: 'skill' | 'template';
  name: string;
  notes: string;
  workspace_id: number;
}) {
  await api.post('/tests/generator/custom/request', serialiseAPIData(params));
}
