import styled from '@emotion/styled';

export const Header = styled.h1`
  font-family: GT Haptik;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  margin-bottom: 16px;
  text-align: center;
`;
