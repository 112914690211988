import { decamelizeKeys } from 'humps';
import qs from 'qs';

import api from '@/api/api';
import { ICandidateSearchParams } from '@/features/candidate-search';
import { TId } from '@/features/common';
import { serializeSearchParams } from '@/features/workspace/api/workspace-candidates';
import { parseAPIData } from '@/utils/parsers';

import { IPipelineCandidate } from '../types';

export async function fetchPipelineCategoryCandidates(params: {
  jobOpeningId: TId;
  categoryId: TId;
  pageParam?: {
    limit?: number;
    offset?: number;
  };
  searchParams: ICandidateSearchParams;
}): Promise<{ candidates: IPipelineCandidate[]; total: number }> {
  // order here matters, limit and offset need to be at the end to not be overwritten
  const queryString = qs
    .stringify(
      {
        ...decamelizeKeys(serializeSearchParams(params.searchParams ?? {}), {
          separator: '-',
        }),
        limit: params?.pageParam?.limit || 50,
        offset: params?.pageParam?.offset || 0,
      },
      {
        encode: false,
        arrayFormat: 'comma',
      }
    )
    .replace('[]', ''); // FIXME: ugly, can it be done with custom encoder instead?

  const response = await api.get(
    `/openings/${params.jobOpeningId}/pipeline/${params.categoryId}/test-takers?${queryString}`
  );

  return (
    parseAPIData({
      ...response.data,
      candidates: response.data.candidates || [],
    }) || { candidates: [], total: 0 }
  );
}
