import { ITest } from '@/features/test';
import { Test } from '@/types/h5test';

/**
 * Type guard for determining if a test is coming from legacy redux store
 * and has some of the fields renamed or if it's the new interface that uses
 * fields as named in the API.
 */
export function isLegacyReduxTest(test: Test | ITest): test is Test {
  return (test as Test).smart !== undefined;
}
