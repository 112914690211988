import api from '@/api/api';
import { TId } from '@/features/common';
import { IInterview } from '@/features/interview';
import { parseAPIData, serialiseAPIData } from '@/utils/parsers';

export async function fetchInterview(params: {
  interviewId: TId;
}): Promise<IInterview> {
  const response = await api.get(`/interviews/${params.interviewId}`);
  return parseAPIData<IInterview>(response.data);
}

export async function createInterview(params: {
  jobOpeningId: TId;
  categoryId: TId;
  name: string;
}): Promise<IInterview> {
  const response = await api.post(
    `/openings/${params.jobOpeningId}/categories/${params.categoryId}/interview`,
    serialiseAPIData({
      name: params.name,
    })
  );

  return parseAPIData(response.data);
}

export async function updateInterview(params: {
  interview: IInterview;
}): Promise<IInterview> {
  const response = await api.put(
    `/interviews/${params.interview.id}`,
    serialiseAPIData(params.interview)
  );

  return parseAPIData<IInterview>(response.data);
}

export async function deleteInterview(params: {
  interviewId: TId;
}): Promise<void> {
  await api.delete(`/interviews/${params.interviewId}`);
}
