import { useEffect } from 'react';

import {
  useJobOpeningPageAnalytics,
  useJobOpeningQuery,
} from '@/features/job-opening';
import { useShareModal } from '@/features/share';
import { useHistory } from '@/hooks/router';

const JobOpeningSharePage = () => {
  const { data: jobOpening } = useJobOpeningQuery();

  const history = useHistory();
  const { openShareModal } = useShareModal();
  useJobOpeningPageAnalytics('sourcing');

  useEffect(() => {
    if (jobOpening) {
      history.push(
        `/admin/${jobOpening.workspaceId}/openings/${jobOpening.id}/pipeline`
      );
      openShareModal(jobOpening.id);
    }
  }, [jobOpening, history, openShareModal]);

  return null;
};

export default JobOpeningSharePage;
