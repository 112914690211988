const enabled = !!import.meta.env.VITE_HUBSPOT_ACCOUNT_ID;
const supportEmail = import.meta.env.VITE_SUPPORT_EMAIL;

const isRealUser = (email?: string): boolean => {
  return !!email && email !== supportEmail;
};

interface ContactProperties {
  email: string;
  firstname?: string;
}

const pushIdentify = (params: Partial<ContactProperties>) => {
  window._hsq.push(['identify', params]);
};

const pushSetPage = (path: string) => {
  window._hsq.push(['setPath', path]);
};

const pushPageView = () => {
  window._hsq.push(['trackPageView']);
};

export const identifyContact = (params: ContactProperties) => {
  if (!isRealUser(params.email)) return;

  if (enabled) {
    pushIdentify(params);
  }
};

export const trackPageView = (path: string, userEmail?: string) => {
  if (!isRealUser(userEmail)) return;

  if (enabled) {
    pushSetPage(path);
    pushPageView();
  }
};
