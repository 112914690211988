import React, { useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  DSButton,
  DSColumn,
  DSContentBlock,
  DSContextMenu,
  DSInput,
  DSRow,
} from '@hundred5/design-system';
import { format } from 'date-fns/format';
import { Field, Form, Formik } from 'formik';
import { mixed, number, object } from 'yup';

import {
  DayPicker,
  FormikCheckboxField,
  FormikInputField,
  FormikSelectField,
  Icon,
  PlanTag,
  pluralize,
  PromptIfDirty,
} from '@/features/common';
import { useClickOutside } from '@/features/common/hooks/use-click-outside';
import {
  isJobOpeningPaused,
  TJobOpeningReapplyAfterUnit,
  useDisableJobOpeningReapplyMutation,
  useEnableJobOpeningReapplyMutation,
  useJobOpeningQuery,
  usePauseJobOpeningMutation,
  useUnpauseJobOpeningMutation,
} from '@/features/job-opening';
import { useNotifications } from '@/features/notifications';
import { useJobOpeningPermission } from '@/features/permissions';
import { usePlanLimits } from '@/hooks/planLimits';
import { useJobOpeningId } from '@/hooks/router';
import { useUpsellModal } from '@/hooks/upsellModal';

interface ISettings {
  isPaused: boolean;
  pausedAt: Date;
  reapplyEnabled: boolean;
  reapplyAfter: number;
  reapplyAfterUnit: TJobOpeningReapplyAfterUnit;
}

const validationSchema = object().shape({
  pausedAt: mixed().when('isPaused', {
    is: (isPaused) => !!isPaused,
    then: (schema) => schema.required('Please select a date!'),
  }),
  reapplyAfter: number().min(1, 'Value should be greater than 0!'),
});

const DEFAULT_REAPPLY_AFTER: number = 12;
const DEFAULT_REAPPLY_AFTER_UNIT: TJobOpeningReapplyAfterUnit = 'months';

const reapplyAfterUnitValues = (value: number) =>
  ['day', 'month'].map((unit) => {
    const pluralizedUnit = pluralize(
      value,
      unit.charAt(0).toUpperCase() + unit.slice(1)
    );
    return {
      id: `${unit}s`, //make it always plural so the id is the value used by BE
      label: pluralizedUnit,
      labelText: pluralizedUnit,
    };
  });

export function JobOpeningSettingsCandidateApplications() {
  const canAccessFeature = usePlanLimits();
  const { openUpsellModal } = useUpsellModal();
  const { addNotification } = useNotifications();
  const jobOpeningId = useJobOpeningId();
  const canUpdate = useJobOpeningPermission()('job opening', 'update');
  const pauseJobOpeningMutation = usePauseJobOpeningMutation({
    jobOpeningId,
  });
  const unpauseJobOpeningMutation = useUnpauseJobOpeningMutation({
    jobOpeningId,
  });
  const enableJobOpeningReapplyMutation = useEnableJobOpeningReapplyMutation({
    jobOpeningId,
  });
  const disableJobOpeningReapplyMutation = useDisableJobOpeningReapplyMutation({
    jobOpeningId,
  });

  const { data: jobOpening } = useJobOpeningQuery();
  const isJobPaused = isJobOpeningPaused(jobOpening?.pausedAt);

  const [dayPickerOpen, setDayPickerOpen] = useState(false);
  const dayPickerContainerRef = useRef<null | HTMLDivElement>(null);
  useClickOutside(dayPickerContainerRef, () => {
    setDayPickerOpen(false);
  });

  const initialSettings: ISettings = useMemo(
    () => ({
      isPaused: !!jobOpening?.pausedAt,
      pausedAt: jobOpening?.pausedAt || new Date(),

      reapplyEnabled: !!jobOpening?.reapplyAfter,
      reapplyAfter: jobOpening?.reapplyAfter || DEFAULT_REAPPLY_AFTER,
      reapplyAfterUnit: jobOpening?.reapplyAfter
        ? jobOpening?.reapplyAfterUnit || DEFAULT_REAPPLY_AFTER_UNIT
        : DEFAULT_REAPPLY_AFTER_UNIT,
    }),
    [jobOpening]
  );

  const isValidPlan = () => {
    if (!canAccessFeature('pause_job_opening')) {
      openUpsellModal('premium_feature');
      return false;
    }
    return true;
  };

  const handleSubmit = async (values: ISettings) => {
    let promises: Promise<void>[] = [];

    // handle job pause
    if (
      (jobOpening?.pausedAt && !values.isPaused) ||
      (!jobOpening?.pausedAt && values.isPaused) ||
      (jobOpening?.pausedAt &&
        values.isPaused &&
        jobOpening?.pausedAt !== values.pausedAt)
    ) {
      if (values.isPaused) {
        promises.push(handlePause(values.pausedAt));
      } else {
        promises.push(handleUnpause());
      }
    }

    // handle reapply
    if (values.reapplyEnabled) {
      promises.push(
        handleEnableReapply(values.reapplyAfter, values.reapplyAfterUnit)
      );
    } else {
      promises.push(handleDisableReapply());
    }

    await Promise.all(promises);

    addNotification({ type: 'saved' });
  };

  const handlePause = (date: Date = new Date()) => {
    if (!isValidPlan()) return Promise.resolve();
    return pauseJobOpeningMutation.mutateAsync({
      jobOpeningId,
      pausedAt: date,
    });
  };

  const handleUnpause = () => {
    if (!isValidPlan()) return Promise.resolve();
    return unpauseJobOpeningMutation.mutateAsync({ jobOpeningId });
  };

  const handleEnableReapply = (
    reapplyAfter: number,
    reapplyAfterUnit: TJobOpeningReapplyAfterUnit
  ) => {
    if (!isValidPlan()) return Promise.resolve();
    return enableJobOpeningReapplyMutation.mutateAsync({
      jobOpeningId,
      reapplyAfter: reapplyAfter,
      reapplyAfterUnit: reapplyAfterUnit,
    });
  };
  const handleDisableReapply = () => {
    if (!isValidPlan()) return Promise.resolve();
    return disableJobOpeningReapplyMutation.mutateAsync({ jobOpeningId });
  };

  return (
    <Formik
      enableReinitialize
      onSubmit={handleSubmit}
      initialValues={initialSettings}
      validationSchema={validationSchema}
    >
      {({ values, setFieldValue, dirty }) => (
        <Form>
          <PromptIfDirty />
          <DSContentBlock>
            <DSContentBlock.Title>
              <CenteredRow gap="8px">
                Candidate applications
                <PlanTag />
              </CenteredRow>
            </DSContentBlock.Title>
            <DSContentBlock.Content>
              <DSColumn gap="12px">
                <CenteredRow gap="8px">
                  {isJobPaused ? (
                    <>
                      <DSButton
                        disabled={!canUpdate}
                        onClick={() => handleUnpause()}
                        Icon={<Icon icon={regular('play')} color="white" />}
                      >
                        Unpause job
                      </DSButton>
                      <Icon
                        icon={regular('info-circle')}
                        color="purple-60"
                        data-rh-at="right"
                        data-rh="Unpause the job. New candidates will be able to apply again."
                      />
                    </>
                  ) : (
                    <>
                      <DSButton
                        variant="secondary"
                        disabled={!canUpdate}
                        onClick={() => handlePause()}
                        Icon={<Icon icon={regular('pause')} />}
                      >
                        Pause job
                      </DSButton>
                      <Icon
                        icon={regular('info-circle')}
                        color="purple-60"
                        data-rh-at="right"
                        data-rh="New candidates won’t be able to apply. You can still invite existing candidates to the next stages."
                      />
                    </>
                  )}
                </CenteredRow>
                <CenteredRow gap="16px">
                  <FormikCheckboxField
                    name="isPaused"
                    label="Automatically pause new applications from"
                    disabled={!canUpdate || isJobPaused}
                    onChange={(e) => {
                      if (!isValidPlan()) return;
                      setFieldValue('isPaused', e.target.checked);
                    }}
                  />

                  <Field name="pausedAt">
                    {({ field, meta }) => (
                      <DayPickerContainer ref={dayPickerContainerRef}>
                        <DayPickerInput
                          readOnly
                          {...field}
                          disabled={!values.isPaused || isJobPaused}
                          error={!!meta.error}
                          value={format(field.value, 'MMMM dd, yyyy')}
                          onClick={() =>
                            values.isPaused && setDayPickerOpen(true)
                          }
                        />
                        <DSContextMenu
                          open={dayPickerOpen}
                          position={{
                            top: '40px',
                            right: '0',
                          }}
                        >
                          <DayPicker
                            onDayClick={(day, modifiers) => {
                              if (modifiers.disabled) {
                                return;
                              }
                              setFieldValue('pausedAt', day);
                              setDayPickerOpen(false);
                            }}
                            selectedDays={field.value}
                            initialMonth={field.value}
                            disabledDays={{ before: new Date() }}
                          />
                        </DSContextMenu>
                      </DayPickerContainer>
                    )}
                  </Field>
                </CenteredRow>
                <SectionLabel withTopMargin>Repeated application</SectionLabel>
                <CenteredRow gap="16px">
                  <FormikCheckboxField
                    name="reapplyEnabled"
                    label="Allow candidates to reapply again after"
                    disabled={!canUpdate || isJobPaused}
                    onChange={(e) => {
                      if (!isValidPlan()) return;
                      setFieldValue('reapplyEnabled', e.target.checked);
                    }}
                  />

                  <NumberInput
                    name="reapplyAfter"
                    label={null}
                    min={1}
                    type="number"
                    required
                    disabled={!values.reapplyEnabled || isJobPaused}
                    errorAbsolute={true}
                  />

                  <FormikSelectField
                    name="reapplyAfterUnit"
                    items={reapplyAfterUnitValues(values.reapplyAfter)}
                    required
                    disabled={!values.reapplyEnabled || isJobPaused}
                  />
                </CenteredRow>
              </DSColumn>
            </DSContentBlock.Content>
            {canUpdate && (
              <DSContentBlock.Footer>
                <DSButton type="submit" disabled={!dirty}>
                  Save changes
                </DSButton>
              </DSContentBlock.Footer>
            )}
          </DSContentBlock>
        </Form>
      )}
    </Formik>
  );
}

const CenteredRow = styled(DSRow)`
  align-items: center;
`;

const DayPickerContainer = styled.div`
  position: relative;
`;

const DayPickerInput = styled(DSInput)`
  cursor: pointer;
  width: 165px;
`;

const NumberInput = styled(FormikInputField)`
  width: 80px;
`;

const SectionLabel = styled.div<{ withTopMargin?: boolean }>`
  font-weight: 700;
  font-size: 1rem;
  color: ${(props) => props.theme.typography.colorPrimary};
  margin-top: ${(props) => props.withTopMargin && '32px'};
`;
