import React from 'react';

import {
  JobOpeningDescription,
  JobOpeningPageContent,
} from '@/features/job-opening';
import { useJobOpeningPageAnalytics } from '@/features/job-opening/utils/job-opening-page-analytics';

function JobOpeningDescriptionPage() {
  useJobOpeningPageAnalytics('job description');

  return (
    <JobOpeningPageContent title="Job description">
      <JobOpeningDescription />
    </JobOpeningPageContent>
  );
}

export default JobOpeningDescriptionPage;
