import { Location } from 'history';
import { camelizeKeys } from 'humps';
import qs from 'qs';

import { Query } from './redirect-router.types';

export function parseQuery(location: Location): Query {
  const search = location.search.slice(1);
  const params = qs.parse(search);
  return camelizeKeys(params) as any;
}
