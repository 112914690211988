import React from 'react';
import {
  DragOverlay,
  DropAnimation,
  defaultDropAnimationSideEffects,
} from '@dnd-kit/core';
import { createPortal } from 'react-dom';

const dropAnimationConfig: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        visibility: 'hidden',
      },
    },
  }),
};

interface SortableItemOverlayProps {
  children: React.ReactNode;
}

export function SortableItemOverlay({ children }: SortableItemOverlayProps) {
  return createPortal(
    <DragOverlay dropAnimation={dropAnimationConfig} zIndex={9999}>
      {children}
    </DragOverlay>,
    document.body
  );
}
