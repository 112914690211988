import React, { useEffect, useMemo, useState } from 'react';
import { DSButton, DSContentBlock } from '@hundred5/design-system';

import { ConfirmModal } from '@/features/common';
import { GmailIcon } from '@/features/integrations';
import {
  useDeleteGmailDataMutation,
  useDeleteGmailIntegrationMutation,
  useEnableGmailCallbackMutation,
  useEnableGmailMutation,
  useMeQuery,
} from '@/features/me';
import { getPlanName } from '@/hooks/planLimits';
import { useHistory, useLocation, useWorkspaceId } from '@/hooks/router';

import { useWorkspaceQuery } from '../../../workspace/queries';

import {
  Action,
  Container,
  Description,
  Details,
  EmailTooltip,
  Icon,
  LinkButton,
  Title,
} from './ui';

export const ProfileSettingsGmail = () => {
  return (
    <DSContentBlock>
      <DSContentBlock.Title>Email Integration</DSContentBlock.Title>
      <DSContentBlock.Content>
        <GmailElement />
      </DSContentBlock.Content>
    </DSContentBlock>
  );
};

const GmailElement = () => {
  const location = useLocation();
  const history = useHistory();
  const workspaceId = useWorkspaceId();
  const { data: workspace } = useWorkspaceQuery({ workspaceId: workspaceId });
  const { data: meQuery } = useMeQuery();
  const { mutateAsync: enableGmailCallbackMutation } =
    useEnableGmailCallbackMutation({
      onSuccess: () =>
        history.replace(`/admin/${workspaceId}/settings/profile`),
    });

  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const canActivateEmail = useMemo(() => workspace?.gmailEnabled, [workspace]);
  const isFreePlan = useMemo(
    () =>
      workspace ? getPlanName(workspace.subscriptionPlan) === 'free' : false,
    [workspace]
  );

  const enableGmailMutation = useEnableGmailMutation({
    onSuccess: (result: string) => result && (window.location.href = result),
  });
  const deleteGmailIntegrationMutation = useDeleteGmailIntegrationMutation({
    onSuccess: () => {
      setOpenConfirmModal(false);
    },
  });
  const deleteGmailDataMutation = useDeleteGmailDataMutation();

  useEffect(() => {
    if (location.search) {
      enableGmailCallbackMutation(location.search);
    }
  }, [location.search, enableGmailCallbackMutation]);

  return (
    <>
      <Container>
        <Icon>
          <GmailIcon />
        </Icon>

        <Title>Gmail</Title>

        <Description>
          Connect to Gmail to send and receive emails in Toggl Hire. View all
          communication with a candidate in one place.
        </Description>

        <Action>
          <DSButton
            variant="secondary"
            data-test-email-tooltip={
              !canActivateEmail && !isFreePlan
                ? true
                : isFreePlan && !canActivateEmail
                  ? 'You need to upgrade your plan to enable the Gmail integration'
                  : null
            }
            data-test-email-tooltip-at={
              !canActivateEmail || isFreePlan ? 'top' : null
            }
            disabled={!canActivateEmail || isFreePlan}
            onClick={() =>
              meQuery?.gmailStatus === 'enabled' && workspace?.gmailEnabled
                ? setOpenConfirmModal(true)
                : enableGmailMutation.mutate()
            }
          >
            {workspace?.gmailEnabled && meQuery?.gmailStatus === 'enabled'
              ? 'Remove'
              : 'Connect'}
          </DSButton>
        </Action>

        {meQuery?.hasGmailData && (
          <Details>
            <LinkButton
              type="button"
              disabled={deleteGmailDataMutation.isLoading}
              onClick={() => deleteGmailDataMutation.mutate()}
            >
              Delete Gmail user data
            </LinkButton>
          </Details>
        )}
      </Container>

      <ConfirmModal
        open={openConfirmModal}
        action="Remove integration"
        onConfirm={() => {
          deleteGmailIntegrationMutation.mutate();
        }}
        onClose={() => setOpenConfirmModal(false)}
        title="Disconnect Gmail integration"
        footerVariant="destructive-primary"
      >
        <p>Are you sure you want to remove access to your Gmail account?</p>
        <p>
          You will no longer be able to contact candidates using your email
          address.
        </p>
      </ConfirmModal>
      <EmailTooltip />
    </>
  );
};
