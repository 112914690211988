import React from 'react';
import { Location } from 'history';
import qs from 'qs';
import { Redirect } from 'react-router-dom';

import { parseQuery } from './redirect-router.utils';

interface RedirectRouterProps {
  location: Location;
}

export function RedirectRouter({ location }: RedirectRouterProps) {
  const query = parseQuery(location);

  switch (query.type) {
    case 'candidate': {
      const {
        workspace,
        jobOpening,
        candidate,
        tab,
        testId,
        noteId,
        interviewId,
      } = query;
      if (tab === 'results' && interviewId) {
        return (
          <Redirect
            to={`/redirect/candidate/${workspace}/${jobOpening}/${candidate}/results/${interviewId}/${noteId}`}
          />
        );
      }
      if (tab === 'results') {
        return (
          <Redirect
            to={`/redirect/candidate/${workspace}/${jobOpening}/${candidate}/results/${testId}/${noteId}`}
          />
        );
      }

      if (tab === 'notes') {
        return (
          <Redirect
            to={`/redirect/candidate/${workspace}/${jobOpening}/${candidate}/notes/${noteId}`}
          />
        );
      }

      return (
        <Redirect
          to={`/redirect/candidate/${workspace}/${jobOpening}/${candidate}`}
        />
      );
    }

    case 'invitation': {
      const { email, token } = query;
      const search = qs.stringify({ email, token });

      return <Redirect to={`/admin/invitation?${search}`} />;
    }

    case 'password-reset': {
      const { email, token } = query;
      const search = qs.stringify({ email, token });
      return <Redirect to={`/admin/forgotten-password/reset?${search}`} />;
    }

    case 'workspace-slack-notifications': {
      const { workspace } = query;
      return <Redirect to={`/admin/${workspace}/settings/integrations`} />;
    }

    case 'job-opening': {
      const { workspace, jobOpening } = query;
      return <Redirect to={`/admin/${workspace}/openings/${jobOpening}`} />;
    }

    case 'job-opening-share': {
      const { workspace, jobOpening } = query;
      return (
        <Redirect to={`/admin/${workspace}/openings/${jobOpening}/share`} />
      );
    }

    case 'job-opening-email-notifications': {
      const { workspace, jobOpening } = query;
      return (
        <Redirect
          to={`/admin/${workspace}/openings/${jobOpening}/job-settings`}
        />
      );
    }

    case 'workspace-integrations': {
      const { workspace } = query;
      return <Redirect to={`/admin/${workspace}/settings/integrations`} />;
    }

    case 'job-opening-integrations': {
      const { workspace, jobOpening } = query;
      return (
        <Redirect
          to={`/admin/${workspace}/openings/${jobOpening}/integrations`}
        />
      );
    }

    case 'email-verification': {
      const { token } = query;
      const search = qs.stringify({ token });
      return <Redirect to={`/admin/email-verification?${search}`} />;
    }

    case 'dashboard': {
      const { workspace } = query;
      return <Redirect to={`/admin/${workspace}/openings`} />;
    }

    default: {
      return <Redirect to="/admin" />;
    }
  }
}
