import styled from '@emotion/styled';

export const Label = styled.span`
  display: block;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 150%;
  text-transform: uppercase;
  margin-block-end: 4px;

  color: ${(props) => props.theme.colors.purple[100]};
`;
