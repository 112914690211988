import React, { createContext, useCallback, useState } from 'react';

import { BillingPlan, BillingPreview, Coupon } from '@/features/billing';

export const SubscribeModalContext = createContext<{
  isOpen: boolean;
  plan: BillingPlan | null;
  preview: BillingPreview | null;
  challengeToken: string | null;
  coupon: Coupon | null;
  open: (plan?: BillingPlan | null) => void;
  changeSubscriptionPlanPreview: (preview: BillingPreview | null) => void;
  challengeRequired: (token: string) => void;
  challengeFailed: () => void;
  onCouponActivated: (coupon: Coupon | null) => void;
  onCouponCanceled: () => void;
  close: () => void;
}>({
  isOpen: false,
  plan: null,
  challengeToken: null,
  preview: null,
  coupon: null,
  open: (plan) => {},
  changeSubscriptionPlanPreview: (preview) => {},
  challengeRequired: (token) => {},
  onCouponActivated: (coupon) => {},
  onCouponCanceled: () => {},
  challengeFailed: () => {},
  close: () => {},
});

export function SubscribeModalContextProvider({ children }) {
  const [shareModalData, setShareModalData] = useState<{
    isOpen: boolean;
    plan: BillingPlan | null;
  }>({
    isOpen: false,
    plan: null,
  });
  const [challengeToken, setChallengeToken] = useState<string | null>(null);
  const [preview, setPreview] = useState<BillingPreview | null>(null);
  const [coupon, setCoupon] = useState<Coupon | null>(null);

  const open = useCallback(
    (plan) => {
      setShareModalData({
        isOpen: true,
        plan,
      });
    },
    [setShareModalData]
  );

  const close = useCallback(() => {
    setShareModalData({
      isOpen: false,
      plan: null,
    });
  }, [setShareModalData]);

  const onCouponCanceled = useCallback(() => setCoupon(null), []);
  const challengeFailed = useCallback(() => setChallengeToken(null), []);

  return (
    <SubscribeModalContext.Provider
      value={{
        isOpen: shareModalData.isOpen,
        plan: shareModalData.plan,
        preview,
        challengeToken,
        coupon,
        onCouponActivated: setCoupon,
        onCouponCanceled,
        challengeRequired: setChallengeToken,
        challengeFailed,
        changeSubscriptionPlanPreview: setPreview,
        open,
        close,
      }}
    >
      {children}
    </SubscribeModalContext.Provider>
  );
}
