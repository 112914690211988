import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { TId } from '@/features/common';
import { Id } from '@/types/misc';

import { fetchTemplates, fetchTemplate, fetchTemplateTypes } from '../api';

export const TEMPLATES_QUERY_KEY = ['templates'];
export const TEMPLATE_QUERY_KEY = ['template'];
export const TEMPLATE_TYPES_QUERY_KEY = ['template-types'];

export const useTemplatesQuery = (
  params: { query?: string } = {},
  options?: UseQueryOptions
) => {
  return useQuery(
    [...TEMPLATES_QUERY_KEY, params.query],
    () => fetchTemplates(params),
    options as any
  );
};

export const useTemplateQuery = (
  params: { id: TId },
  options?: UseQueryOptions
) => {
  return useQuery(
    [...TEMPLATE_QUERY_KEY, params.id],
    () => fetchTemplate(params),
    options as any
  );
};

export const useTemplateTypesQuery = (
  params: { categoryId: Id },
  options?: UseQueryOptions
) => {
  return useQuery(
    [...TEMPLATE_TYPES_QUERY_KEY, params.categoryId],
    () => fetchTemplateTypes(params),
    options as any
  );
};
