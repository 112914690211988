import store from 'store';

const ACTIVE_DASHBOARD_TAB_KEY = `toggl_hire_active_dashboard_tab`;

export const getActiveDashboardTab = () =>
  store.get(ACTIVE_DASHBOARD_TAB_KEY) || 'openings';

export const setActiveDashboardTab = (
  tab: 'openings' | 'candidates' | 'inbox'
) => store.set(ACTIVE_DASHBOARD_TAB_KEY, tab);
