import React from 'react';
import styled from '@emotion/styled';

const AccountSettingsPage = () => {
  return (
    <>
      <Iframe
        src={`${
          import.meta.env.VITE_TOGGL_ACCOUNTS_URL
        }/settings?product=hire&amp;webapp=true&amp;theme=light`}
      ></Iframe>
    </>
  );
};

const Iframe = styled.iframe`
  width: 100%;
  min-height: 100vh;
  border: none;
`;

export default AccountSettingsPage;
