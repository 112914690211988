import { useDashboardJobOpeningsQuery } from '@/features/dashboard';
import { useWorkspaceStatsQuery } from '@/features/workspace';
import { usePlanLimits } from '@/hooks/planLimits';
import { useWorkspaceIdOrNull } from '@/hooks/router';

export const usePromotePremium = () => {
  const workspaceId = useWorkspaceIdOrNull();
  const canAccessFeature = usePlanLimits();

  const dashboardJobOpeningsQuery = useDashboardJobOpeningsQuery(
    {
      workspaceId: workspaceId!,
      filter: 'open',
    },
    { enabled: !!workspaceId }
  );
  const workspaceStatsQuery = useWorkspaceStatsQuery({
    workspaceId: workspaceId!,
  });
  const unlockableTestTakersRemaining =
    workspaceStatsQuery.data?.unlockableTestTakersRemaining;
  const openings = dashboardJobOpeningsQuery.data?.openings;

  return {
    promotePremium:
      openings &&
      openings.length > 0 &&
      (!canAccessFeature('hide_premium_banner') ||
        unlockableTestTakersRemaining === 0),
    workspaceId,
  };
};
