import * as React from 'react';
import styled from '@emotion/styled';
import ReactHintFactory from 'react-hint';
import 'react-hint/css/index.css';
import { useMedia } from 'react-use';

const ReactHint = ReactHintFactory(React);

interface TooltipProps {
  delay?: { show?: number; hide?: number } | number;
  attribute?: string;
  persist?: boolean;
  onRenderContent?: (target: any, content: string) => React.ReactNode;
}

export function Tooltip({
  delay = 100,
  attribute,
  persist,
  onRenderContent,
}: TooltipProps) {
  const isMobile = useMedia('(max-width: 768px)');

  if (isMobile) return null;

  return (
    <TooltipContainer>
      <ReactHint
        events
        autoPosition
        delay={delay}
        attribute={attribute}
        persist={persist}
        onRenderContent={
          onRenderContent
            ? onRenderContent
            : (target, content) => {
                return (
                  <Content>
                    {target.dataset.title ? (
                      <Title>{target.dataset.title}</Title>
                    ) : null}
                    <Text>{content}</Text>
                  </Content>
                );
              }
        }
      />
    </TooltipContainer>
  );
}

const TooltipContainer = styled.div`
  .react-hint {
    max-width: 325px;
    z-index: 100000;
  }

  .react-hint--top:after {
    border-top-color: ${(props) => props.theme.colors.white};
    filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.1));
  }

  .react-hint--left:after {
    border-left-color: ${(props) => props.theme.colors.white};
    filter: drop-shadow(2px 0 1px rgba(0, 0, 0, 0.1));
  }

  .react-hint--right:after {
    border-right-color: ${(props) => props.theme.colors.white};
    filter: drop-shadow(-2px 0 1px rgba(0, 0, 0, 0.1));
  }

  .react-hint--bottom:after {
    border-bottom-color: ${(props) => props.theme.colors.white};
    filter: drop-shadow(0px -2px 1px rgba(0, 0, 0, 0.1));
  }

  .react-hint--top {
    margin-top: -4px;
  }

  .react-hint--left {
    margin-left: -4px;
  }

  .react-hint--right {
    margin-left: 4px;
  }

  .react-hint--bottom {
    margin-top: 4px;
  }
`;

const Content = styled.div`
  padding: 10px;
  font-size: 12px;
  line-height: 1.4;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.purple[100]};
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const Text = styled.p`
  margin: 0;
  white-space: pre-wrap;
  overflow-wrap: break-word;
`;
