import React from 'react';
import { DSTestLandingPage } from '@hundred5/design-system';

import { useImageUpload } from '@/hooks/images';
import { ImagePurpose } from '@/types/image';

const LOGO_IMAGE_TYPE: ImagePurpose = 'workspace_logo';

interface LogoProps {
  url?: string;
  editable?: boolean;
  onUpdate: (url?: string) => void;
}

export function Logo({ url, editable, onUpdate }: LogoProps) {
  const { uploadImage, uploadingImage } = useImageUpload();

  return (
    <DSTestLandingPage.Logo
      url={url}
      editable={editable}
      loading={uploadingImage}
      onUpload={(file) => {
        uploadImage(file, LOGO_IMAGE_TYPE, false).then(([, url]) => {
          url && onUpdate(url);
          return url;
        });
      }}
      onDelete={() => {
        onUpdate('');
      }}
    />
  );
}
