import React from 'react';
import { DSField, DSInput } from '@hundred5/design-system';
import { useField } from 'formik';
import { uniqueId } from 'lodash';

type Props = {
  name: string;
  label?: React.ReactNode;
  required?: boolean;
  id?: string;
  type?: string;
  placeholder?: string;
  readonly?: boolean;
  disabled?: boolean;
  errorAbsolute?: boolean;
  className?: string;
} & React.ComponentProps<typeof DSInput>;

export function FormikInputField({
  name,
  label,
  required,
  id = uniqueId('formik-field-'),
  type,
  placeholder,
  readonly,
  disabled,
  errorAbsolute,
  className,
  ...props
}: Props) {
  const [field, meta] = useField<any>(name);
  const error = meta.touched && !!meta.error ? meta.error : null;

  return (
    <DSField
      for={id}
      label={label}
      required={required}
      error={error}
      errorAbsolute={errorAbsolute}
      className={className}
    >
      <DSInput
        id={id}
        type={type}
        placeholder={placeholder}
        error={!!error}
        {...props}
        {...field}
        readOnly={readonly}
        disabled={disabled}
      />
    </DSField>
  );
}
