import styled from '@emotion/styled';

export const Option = styled.div<{ selected?: boolean }>`
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  flex: 1;
  align-items: center;
  padding: 4px 12px;
  gap: 8px;
  position: relative;
  border-radius: 6px;

  opacity: ${({ selected }) => (!selected ? 1 : 0.5)};
  pointer-events: ${({ selected }) => (!selected ? 'all' : 'none')};

  &:hover {
    background: ${({ theme }) => theme.colors.purple[5]};
    cursor: pointer;
  }
`;
