import React from 'react';
import { DSSelect } from '@hundred5/design-system';

import { Label } from '@/features/common';
import { useDashboardJobOpeningsQuery } from '@/features/dashboard';
import { useWorkspaceId } from '@/hooks/router';

//region Main
interface JobOpeningSelectorProps {
  label?: string;
  onChange: (jobOpeningId: string) => void;
}

export const JobOpeningSelector = ({
  label = 'Job',
  onChange,
}: JobOpeningSelectorProps) => {
  const workspaceId = useWorkspaceId();
  const dashboardJobOpeningsQuery = useDashboardJobOpeningsQuery({
    workspaceId,
  });
  const jobOpenings = dashboardJobOpeningsQuery.data?.openings;

  if (!jobOpenings || dashboardJobOpeningsQuery.isLoading) {
    return null;
  }

  return (
    <div>
      <Label>{label.toUpperCase()}</Label>
      <DSSelect
        items={jobOpenings.map((jobOpening) => ({
          id: jobOpening.id,
          label: jobOpening.name,
          labelText: jobOpening.name,
        }))}
        placeholder="Select job"
        onChange={(selected) => {
          if (selected) {
            onChange(selected.id);
          }
        }}
      />
    </div>
  );
};
