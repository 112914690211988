import api from '@/api/api';
import { TId } from '@/features/common';
import { TChecklistItem } from '@/features/workspace';
import { parseAPIData, serialiseAPIData } from '@/utils/parsers';

export async function updateChecklist(params: {
  workspaceId: TId;
  checklistItem: TChecklistItem;
}): Promise<TChecklistItem[]> {
  const response = await api.post(
    `/workspaces/${params.workspaceId}/checklist`,
    serialiseAPIData({ checklistItem: params.checklistItem })
  );

  return parseAPIData(response.data);
}
