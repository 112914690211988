import React, { createContext, useCallback, useState } from 'react';

import { PreviewFeatureModalOptions, PreviewFeatureModalType } from '../types';

export const PreviewFeatureModalContext = createContext<{
  isOpen: boolean;
  open: (
    type: PreviewFeatureModalType,
    options?: PreviewFeatureModalOptions
  ) => void;
  close: () => void;
  type: PreviewFeatureModalType | null;
  options: PreviewFeatureModalOptions | null;
}>({
  isOpen: false,
  open: (type, options) => {},
  type: null,
  options: null,
  close: () => {},
});

export function PreviewFeatureModalContextProvider({ children }) {
  const [previewFeatureModalData, setPreviewFeatureModalData] = useState<{
    isOpen: boolean;
    type?: PreviewFeatureModalType | null;
    options?: PreviewFeatureModalOptions | null;
  }>({
    isOpen: false,
    type: null,
    options: null,
  });

  const open = useCallback(
    (typeData, optionsData) => {
      setPreviewFeatureModalData({
        isOpen: true,
        type: typeData,
        options: optionsData ?? null,
      });
    },
    [setPreviewFeatureModalData]
  );
  const close = useCallback(() => {
    setPreviewFeatureModalData({
      isOpen: false,
      type: null,
      options: null,
    });
  }, [setPreviewFeatureModalData]);

  return (
    <PreviewFeatureModalContext.Provider
      value={{
        isOpen: previewFeatureModalData.isOpen,
        type: previewFeatureModalData.type ?? null,
        options: previewFeatureModalData.options ?? null,
        open,
        close,
      }}
    >
      {children}
    </PreviewFeatureModalContext.Provider>
  );
}
