import React from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Icon } from '@/features/common';

export const VideoAnswerPreview = () => {
  return (
    <VideoWrapper>
      <VideoControls>
        <InputIcon>
          <Icon icon={regular('microphone')} color="white" fontSize="16px" />
          <Icon icon={regular('angle-up')} color="white" fontSize="12px" />
        </InputIcon>
        <InputIcon>
          <Icon icon={regular('camera-web')} color="white" fontSize="16px" />
          <Icon icon={regular('angle-up')} color="white" fontSize="12px" />
        </InputIcon>
        <RecordButton>
          <Icon icon={regular('record-vinyl')} color="white" fontSize="12px" />
        </RecordButton>
      </VideoControls>
    </VideoWrapper>
  );
};

const VideoWrapper = styled.div`
  margin: 16px auto 24px auto;
  display: flex;
  align-items: end;
  height: 290px;
  width: 500px;
  border-radius: 8px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.purple[10]};
  opacity: 0.4;
`;

const VideoControls = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.purple[70]};
  color: ${({ theme }) => theme.colors.white};
  font-size: 10px;
  text-align: center;
  width: 100%;
  padding: 12px;
  display: flex;
  gap: 16px;
`;

const InputIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const RecordButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  background: ${({ theme }) => theme.colors.orange[100]};
  border-radius: 8px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
