import { camelize, decamelize } from 'humps';

/**
 * Convert content inside brackets to camelCase.
 * eg. [job name] -> [jobName]
 */
export const convertVariablesToCamelCase = (value: string) => {
  return value.replace(/\[([^\]]+)\]/g, (_, content) => {
    return '[' + camelize(content) + ']';
  });
};

/**
 * Convert camelCase content inside brackets to space separated and lowercase.
 * eg. [jobName] -> [job name]
 */
export const convertVariablesFromCamelCase = (value: string) => {
  return value.replace(/\[([a-zA-Z]+)\]/g, (_, content) => {
    return '[' + decamelize(content, { separator: ' ' }) + ']';
  });
};
