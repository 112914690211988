import { camelizeKeys } from 'humps';

import { AuthenticationToken } from '../types/authentication';

import api from './api';

export async function createDemoWorkspace(): Promise<AuthenticationToken> {
  const response = await api.post('/demo-workspace');
  const data = camelizeKeys(response.data) as any;
  return data.accessToken;
}
