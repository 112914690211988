import * as React from 'react';
import Transition, {
  ENTERED,
  ENTERING,
  EXITED,
  EXITING,
} from 'react-transition-group/Transition';

const HEIGHT_TIMEOUT = 200;
const OPACITY_TIMEOUT = 200;
const TIMEOUT = HEIGHT_TIMEOUT + OPACITY_TIMEOUT;

const ENTER_TRANSITION = [
  `opacity ${OPACITY_TIMEOUT}ms ${HEIGHT_TIMEOUT}ms`,
  `height ${HEIGHT_TIMEOUT}ms`,
  `margin ${HEIGHT_TIMEOUT}ms`,
].join(', ');

const EXIT_TRANSITION = [
  `opacity ${OPACITY_TIMEOUT}ms`,
  `height ${HEIGHT_TIMEOUT}ms ${OPACITY_TIMEOUT}ms`,
  `margin ${HEIGHT_TIMEOUT}ms ${OPACITY_TIMEOUT}ms`,
].join(', ');

const STYLES = {
  [ENTERING]: { transition: ENTER_TRANSITION },
  [ENTERED]: { display: 'block' },
  [EXITING]: { transition: EXIT_TRANSITION },
  [EXITED]: { display: 'none' },
};

interface CollapseTransitionProps {
  children: React.ReactNode;
  in?: boolean;
}

function getElementContentHeight(element: HTMLElement): number | null {
  const child = element.firstElementChild;

  if (child && child instanceof HTMLElement) {
    return child.offsetHeight;
  } else {
    return null;
  }
}

function handleEnter(element: HTMLElement) {
  element.style.height = '0';
  element.style.margin = '0';
  element.style.opacity = '0';
}

function handleEntering(element: HTMLElement) {
  const height = getElementContentHeight(element);

  element.scrollTop;

  element.style.height = height != null ? `${height}px` : 'auto';
  element.style.margin = '';
  element.style.opacity = '';
}

function handleEntered(element: HTMLElement) {
  element.style.height = '';
}

function handleExit(element: HTMLElement) {
  const height = getElementContentHeight(element);
  element.style.height = height != null ? `${height}px` : 'auto';
}

function handleExiting(element: HTMLElement) {
  element.scrollTop;

  element.style.height = '0';
  element.style.margin = '0';
  element.style.opacity = '0';
}

export function CollapseTransition({
  children,
  ...props
}: CollapseTransitionProps) {
  return (
    <Transition
      {...props}
      onEnter={handleEnter}
      onEntering={handleEntering}
      onEntered={handleEntered}
      onExit={handleExit}
      onExiting={handleExiting}
      timeout={TIMEOUT}
    >
      {(state) => <div style={STYLES[state]}>{children}</div>}
    </Transition>
  );
}
