import React from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { NavLink } from 'react-router-dom';

import { Icon } from '@/features/common';
import { useWorkspaceIdOrNull } from '@/hooks/router';
import { getActiveDashboardTab } from '@/utils/dashboard';

interface BackProps {
  to?: string;
  tooltip?: string;
}

export function BackButton(props: BackProps) {
  const workspaceId = useWorkspaceIdOrNull();
  if (workspaceId == null) return null;

  return (
    <Link
      data-rh={props && props.tooltip ? props.tooltip : 'Go Back'}
      data-rh-at="right"
      to={
        props && props.to
          ? `/admin/${workspaceId}${props.to}`
          : `/admin/${workspaceId}/${getActiveDashboardTab()}`
      }
    >
      <Icon icon={regular('chevron-left')} color="purple-60" fontSize={16} />
    </Link>
  );
}

const Link = styled(NavLink)`
  height: 24px;
  width: 24px;
  margin-right: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: ${(props) => props.theme.colors.white};
  transition:
    background 0.1s,
    transform 0.1s;

  &:hover {
    background: ${(props) => props.theme.hover.onWhite};
  }

  &:active {
    transform: scale(0.96);
  }
`;
