import { useEffect } from 'react';

import { setAuthToken } from '@/api/api';
import { loadAuthToken } from '@/authentication';
import { getSharedAuthEnabled } from '@/features/toggl-accounts';

import { useAuthenticationDispatch } from './authentication.context';

export const AuthProvider = () => {
  const dispatch = useAuthenticationDispatch();
  const sharedAuthEnabled = getSharedAuthEnabled();

  useEffect(() => {
    if (!sharedAuthEnabled) {
      const token = loadAuthToken();
      if (token != null) {
        setAuthToken(token);
      }

      dispatch({
        type: 'AUTH_INITIALIZED',
        payload: { authenticated: token != null },
      });
    }
  }, [dispatch, sharedAuthEnabled]);

  return null;
};
