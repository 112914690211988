import styled from '@emotion/styled';

/**
 * Button without any styling besides slight background change on hover, to be used as a wrapper for things that are interactive but doesn't look like our buttons from design system.
 */
export const SemanticButton = styled.button`
  background-color: transparent;
  border-width: 0;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  padding: 0;
  cursor: pointer;
  border-radius: 8px;

  &:hover,
  &:active,
  &:focus {
    background-color: ${({ theme }) => theme.colors.purple[5]};
  }
`;
