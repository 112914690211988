import React from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Icon } from '@/features/common';

interface ChecklistItemProps {
  name: string;
  done: boolean;
  clickable: boolean;
  onClick?: () => void;
}

export function ChecklistItem({
  name,
  done,
  onClick,
  clickable = true,
}: ChecklistItemProps) {
  return (
    <ChecklistItemContainer done={done} onClick={onClick} clickable={clickable}>
      <CheckContainer checked={done}>
        {done && <Icon icon={regular('check')} color="white" />}
      </CheckContainer>
      <Name>{name}</Name>
    </ChecklistItemContainer>
  );
}

const ChecklistItemContainer = styled.div<{
  done: boolean;
  clickable: boolean;
}>`
  display: flex;
  align-content: center;
  margin-bottom: 12px;

  opacity: ${(props) => (props.done ? 0.5 : 1)};
  text-decoration-line: ${(props) => (props.done ? 'line-through' : 'none')};
  color: ${({ theme }) => theme.colors.peach[70]};
  cursor: ${(props) => (props.clickable ? 'pointer' : 'initial')};

  &:hover {
    color: ${(props) =>
      props.clickable
        ? props.theme.colors.orange[70]
        : props.theme.colors.peach[70]};
  }
`;

const CheckContainer = styled.div<{ checked: boolean }>`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  background: ${({ checked, theme }) =>
    checked ? theme.colors.orange[100] : theme.colors.white};
  border: 1px solid
    ${({ checked, theme }) =>
      checked ? theme.colors.orange[100] : theme.colors.purple[40]};
  box-sizing: border-box;
  border-radius: 4px;
  margin-right: 12px;
`;

const Name = styled.span`
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 100%;
`;
