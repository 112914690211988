import React from 'react';

interface GmailInIllustrationDisconnectedProps {
  width?: number;
  height?: number;
}

export function GmailIllustrationDisconnected({
  width,
  height,
}: GmailInIllustrationDisconnectedProps) {
  return (
    <svg
      width={width || 'auto'}
      height={height || 'auto'}
      viewBox="0 0 208 126"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M104 0.509277C46.8391 0.509277 0.50058 47.3847 0.50058 105.208H207.5C207.5 47.3847 161.162 0.509277 104 0.509277Z"
        fill="#95899B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M104 125.49C161.162 125.49 207.5 116.41 207.5 105.208C207.5 94.0069 161.162 84.9263 104 84.9263C46.8387 84.9263 0.500244 94.0069 0.500244 105.208C0.500244 116.41 46.8387 125.49 104 125.49Z"
        fill="#2C1338"
      />
      <rect
        width="80.9597"
        height="60.0634"
        rx="7.88943"
        transform="matrix(0.651918 -0.75829 0.750732 0.660608 55.1848 73.6167)"
        fill="white"
      />
      <g clipPath="url(#clip0_1355_12635)">
        <path
          d="M102.799 83.9524L106.598 79.5821L95.9848 70.3559L85.8752 72.5288L99.2982 84.1972C100.333 85.0967 101.9 84.9872 102.799 83.9524Z"
          fill="#ABA0AF"
        />
        <path
          d="M119.623 64.5988L123.422 60.2285C124.322 59.1937 124.212 57.627 123.178 56.7274L109.755 45.059L109.01 55.3726L119.623 64.5988Z"
          fill="#D5D0D7"
        />
        <path
          d="M100.894 48.3176L109.01 55.3729L109.755 45.0593L107.257 42.8884C104.943 40.8763 101.324 42.3696 101.102 45.4298L100.894 48.3176Z"
          fill="#EAE7EB"
        />
        <path
          d="M95.9848 70.3556L87.8686 63.3003L100 60.6929L100.894 48.3165L109.01 55.3718L108.116 67.7482L95.9848 70.3556Z"
          fill="#C0B8C3"
        />
        <path
          d="M83.3779 70.3573L85.8751 72.5281L95.9847 70.3553L87.8685 63.2999L85.0378 63.9084C82.0382 64.5535 81.0632 68.3451 83.3779 70.3573Z"
          fill="#95899B"
        />
      </g>
      <defs>
        <clipPath id="clip0_1355_12635">
          <rect
            width="36.3504"
            height="27.3396"
            fill="white"
            transform="translate(80.5661 67.8823) rotate(-49)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
