import { useCallback } from 'react';

import { setAuthToken } from '@/api/api';
import { storeAuthToken } from '@/authentication';
import { AuthenticationToken } from '@/types/authentication';

import { useAuthenticationDispatch } from '../providers/authentication.context';

type SaveAuthTokenHook = (token: AuthenticationToken) => void;

export function useSaveAuthToken(): SaveAuthTokenHook {
  const dispatch = useAuthenticationDispatch();

  return useCallback(
    (token: AuthenticationToken) => {
      storeAuthToken(token);
      setAuthToken(token);

      dispatch({ type: 'LOGGED_IN' });
    },
    [dispatch]
  );
}
