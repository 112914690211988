import { TId } from '@/features/common';

export type TTestType = (typeof TestType)[keyof typeof TestType];

export enum TestType {
  Quiz = 'quiz',
  Video = 'video',
  Homework = 'homework',
  Psychometric = 'psychometric',
}

export type TestExperienceLevel = 'senior' | 'mid-level' | '';

/**
 * Represents a single test of any type, as used in test editor.
 * This is not the same as candidate test (historical record of test taken).
 */
export interface ITest {
  /**
   * Actual time the test should take (time limit).
   * If time is set manually, this is equal to "durationInSeconds"
   */
  approximatedDurationInSeconds: number;
  autoCandidateReminderDays: number | null;
  autoNextStepTemplateId: number | null;
  autoNextStepThreshold: number | null;
  backgroundPictureUrl: string | null;
  /**
   * Background picture position from the center in percents
   */
  backgroundPosition: { x: number; y: number };
  createdAt: Date;
  /**
   * Time as set manually by user. If 0, time is set to "auto"
   */
  durationInSeconds: number;
  dynamicQuestionAmount: number | null;
  /**
   * Only available for skill tests with smart questions
   */
  dynamicSkills?: IDynamicSkill[];
  experienceLevel: TestExperienceLevel;
  hashedTestId: TId;
  id: TId;
  introductionVideoUrl: string | null;
  isFromTestGenerator: boolean;
  isPremiumTemplate: boolean | null;
  isQuestionRatingDisabled: boolean;
  isSmart: boolean;
  jobOpeningId: number;
  name: string;
  percentToPass: number;
  questionCount: number;
  screens: IScreens;
  /**
   * Not available in homeworks
   */
  staticSkills?: IStaticSkill[];
  type: TTestType;
  updatedAt: Date;
  workspaceId: number;
}

export interface IDynamicSkill {
  difficulty?: 'standard' | 'hard';
  dynamicQuestionAmount?: number;
  id: TId;
  weight: number;
}

export interface IStaticSkill {
  id: TId;
}

export interface IScreens {
  noThreshold?: TFeedbackScreen | null;
  notPassed?: TFeedbackScreen | null;
  passed?: TFeedbackScreen | null;
  welcome?: TFeedbackScreen | null;
}

export type TFeedbackScreen = {
  content: string;
  settings: TFeedbackScreenSettings;
};

export type TFeedbackScreenSettings = {
  firstName?: boolean;
  lastName?: boolean;
  city?: boolean;
  country?: boolean;
  phone?: boolean;
  linkedIn?: boolean;
  scoreChart?: boolean;
  userExperience?: boolean;
  redirectUrlEnabled?: boolean;
  redirectUrl?: string;
  manifesto?: boolean;
  userContactFields?: TFeedbackScreenContactField[];
  systemContactFields?: TFeedbackScreenContactField[];
};

export type TFeedbackScreenContactField = {
  label: string;
  key: string | null;
  mandatory: boolean;
  enabled: boolean;
  placeholder: string | null;
};

export enum TScreenType {
  Passed = 'passed',
  NotPassed = 'notPassed',
  NoThreshold = 'noThreshold',
}
