import { ICandidate } from '@/features/candidate';
import { Candidate } from '@/types/candidate';

export const getCandidateServiceName = (
  candidate: Candidate
): string | null => {
  const match = /^https?:\/\/([a-z.]+)/.exec(candidate.url);

  if (match) {
    const [, domain] = match;

    switch (domain) {
      case 'github.com':
        return 'GitHub';
      case 'linkedin.com':
      case 'www.linkedin.com':
        return 'LinkedIn';
      default:
        return null;
    }
  } else {
    return null;
  }
};

const getCandidateFullNameOrNull = (
  candidate: Candidate | ICandidate
): string | null => {
  if ('contact' in candidate) {
    if (
      !candidate.contact?.firstName?.trim() &&
      !candidate.contact?.lastName?.trim()
    )
      return (
        candidate.contact?.fullName?.trim() || candidate.name.trim() || null
      );

    return `${candidate.contact?.firstName?.trim()} ${candidate.contact?.lastName?.trim()}`.trim();
  }

  if (
    !candidate.contactInfo?.firstName?.trim() &&
    !candidate.contactInfo?.lastName?.trim()
  )
    return (
      candidate.contactInfo?.fullName?.trim() || candidate.name.trim() || null
    );

  return `${candidate.contactInfo?.firstName?.trim()} ${candidate.contactInfo?.lastName?.trim()}`.trim();
};

export const getCandidateName = (candidate: Candidate | ICandidate): string => {
  return getCandidateFullNameOrNull(candidate) || '(no name)';
};

export const getCandidateEmail = (
  candidate: Candidate | ICandidate | null
): string => {
  if (!candidate) {
    return '';
  }

  if ('contact' in candidate) {
    return candidate.contact
      ? candidate.contact.email?.trim() || candidate.email.trim() || ''
      : candidate.email?.trim() || '';
  }

  return candidate.contactInfo
    ? candidate.contactInfo.contactEmail?.trim() || candidate.email.trim() || ''
    : candidate.email?.trim() || '';
};
