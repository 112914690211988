import {
  ISplitTreatmentsChildProps,
  useSplitTreatments,
} from '@splitsoftware/splitio-react';

import { useWorkspaceQuery } from '@/features/workspace';
import { useWorkspaceIdOrNull } from '@/hooks/router';

export const useWorkspaceTreatments = (
  splits: string[]
): ISplitTreatmentsChildProps => {
  const workspaceId = useWorkspaceIdOrNull();
  const workspaceQuery = useWorkspaceQuery({
    workspaceId: workspaceId ?? undefined,
  });

  const attributes = !!workspaceQuery.data
    ? {
        subscription_plan: workspaceQuery.data.subscriptionPlan,
        created_at: workspaceQuery.data.createdAt.getTime(),
      }
    : undefined;

  return useSplitTreatments({ names: splits, attributes });
};
