import styled from '@emotion/styled';

export const ScrollableContainer = styled.div`
  overflow: auto;

  --scrollbar-foreground: ${({ theme }) => theme.colors.purple[20]};
  --scrollbar-background: transparent;

  scrollbar-color: var(--scrollbar-foreground) var(--scrollbar-background);
  scrollbar-gutter: auto;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--scrollbar-foreground);
    border-radius: 8px;
  }

  &::-webkit-scrollbar-track {
    background: var(--scrollbar-background);
  }
`;
