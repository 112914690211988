import { find } from 'lodash';

import { useMeQuery } from '@/features/me';
import {
  TPermissionsAction,
  TWorkspaceResource,
  permissions,
} from '@/features/permissions';
import { IWorkspace } from '@/features/workspace';
import { useWorkspaceIdOrNull } from '@/hooks/router';

export const useWorkspacePermission = (): ((
  resource: TWorkspaceResource,
  action: TPermissionsAction
) => boolean | null) => {
  const { data: me } = useMeQuery();
  const workspaceId = useWorkspaceIdOrNull();

  return (resource, action) => {
    if (!me || !workspaceId) return null;

    const member: IWorkspace = find(
      me.workspaces,
      (workspace: IWorkspace) => workspace.id === workspaceId
    )!;

    if (!member) return null;

    return permissions.can(member.role, resource, action);
  };
};
