import React from 'react';

interface GmailIllustrationProps {
  width?: string;
  height?: string;
}

export function GmailIllustration({ width, height }: GmailIllustrationProps) {
  return (
    <svg
      width={width || 'auto'}
      height={height || 'auto'}
      viewBox="0 0 212 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M106 0C47.458 0 0.000183105 48.0077 0.000183105 107.228H212C212 48.0077 164.542 0 106 0Z"
        fill="#FFC4BC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M106 128C164.542 128 212 118.7 212 107.228C212 95.756 164.542 86.4561 106 86.4561C47.458 86.4561 0.000183105 95.756 0.000183105 107.228C0.000183105 118.7 47.458 128 106 128Z"
        fill="#FF897A"
      />
      <rect
        width="82.9152"
        height="61.5142"
        rx="8.08"
        transform="matrix(0.651918 -0.75829 0.750732 0.660608 56.0055 74.874)"
        fill="white"
      />
      <g clipPath="url(#clip0_304_7221)">
        <path
          d="M104.77 85.4583L108.661 80.9825L97.791 71.5334L87.4372 73.7588L101.184 85.709C102.244 86.6303 103.849 86.5181 104.77 85.4583Z"
          fill="#4285F4"
        />
        <path
          d="M122.001 65.6372L125.891 61.1614C126.813 60.1016 126.7 58.497 125.641 57.5757L111.894 45.6255L111.131 56.1882L122.001 65.6372Z"
          fill="#34A853"
        />
        <path
          d="M102.819 48.9625L111.131 56.1882L111.893 45.6255L109.336 43.4022C106.965 41.3415 103.259 42.8708 103.032 46.0049L102.819 48.9625Z"
          fill="#FBBC04"
        />
        <path
          d="M97.7909 71.5338L89.4787 64.3081L101.903 61.6377L102.818 48.9624L111.131 56.1881L110.215 68.8634L97.7909 71.5338Z"
          fill="#EA4335"
        />
        <path
          d="M84.8796 71.5355L87.4372 73.7588L97.7909 71.5334L89.4787 64.3077L86.5796 64.9308C83.5076 65.5915 82.509 69.4748 84.8796 71.5355Z"
          fill="#C5221F"
        />
      </g>
      <defs>
        <clipPath id="clip0_304_7221">
          <rect
            width="37.2285"
            height="28"
            fill="white"
            transform="translate(82 69) rotate(-49)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
