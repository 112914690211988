import React from 'react';

import { Container } from './ui/container';
import { Copy } from './ui/copy';
import { Header } from './ui/header';
import { InlineButton } from './ui/inline-button';
import { LoadErrorIllustration } from './ui/load-error-illustration';

export function LoadErrorFallback() {
  return (
    <Container>
      <LoadErrorIllustration />
      <Header>New updates are live on Toggl Hire! </Header>
      <Copy>
        <InlineButton type="button" onClick={() => window.location.reload()}>
          Refresh
        </InlineButton>{' '}
        the page to see what’s new 🎉
      </Copy>
    </Container>
  );
}
