import React from 'react';
import styled from '@emotion/styled';
import { DSButton } from '@hundred5/design-system';

import { TGmailStatus } from '@/features/me';

import { GmailIllustration, GmailIllustrationDisconnected } from '.';

interface ContentSmallProps {
  status?: TGmailStatus;
  workspaceStatus?: boolean;
  onClick: () => void;
}

const data = {
  disconnected: {
    title: 'Token expired',
    icon: <GmailIllustrationDisconnected width={72} height={44} />,
    description:
      'We are unable to show any new email communication between you and the candidates.',
    buttonText: 'Reconnect',
  },
  default: {
    title: 'The Gmail integration is enabled for your workspace',
    icon: <GmailIllustration />,
    description:
      'Connect your email account to send and receive emails in Toggl Hire.',
    buttonText: 'Connect your account',
  },
};

export function ContentSmall({
  status,
  workspaceStatus,
  onClick,
}: ContentSmallProps) {
  const dataToShow =
    status === 'disconnected' ? data.disconnected : data.default;

  return (
    <Content>
      <Icon>{dataToShow.icon}</Icon>

      <Info>
        <h2>{dataToShow.title}</h2>
        <p>{dataToShow.description}</p>
        <Action>
          <DSButton variant="primary-purple" onClick={() => onClick()}>
            {workspaceStatus ? dataToShow.buttonText : 'Enable Gmail'}
          </DSButton>
        </Action>
      </Info>
    </Content>
  );
}

const Content = styled.div`
  display: grid;
  grid-template-areas: 'info icon';
  grid-template-columns: 6fr 1fr;
  gap: 24px;
  padding: 16px 36px 36px 36px;
  align-items: center;

  @container email-banner (width < 600px) {
    grid-template-areas: 'icon info' '. action';
    grid-template-columns: 72px auto;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 36px;
    width: calc(100% - 72px);
    height: 1px;
    background: ${({ theme }) => theme.colors.purple[10]};
  }
`;

const Icon = styled.div`
  grid-area: icon;
  display: flex;
`;

const Info = styled.section`
  grid-area: info;
  display: flex;
  flex-direction: column;
  gap: 4px;

  h2 {
    margin: 0;
    font-size: 14px;
    font-weight: 700;
    color: ${(props) => props.theme.typography.colorPrimary};
  }

  p {
    margin-top: 0;
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: 400;
    color: ${(props) => props.theme.typography.colorSecondary};
  }
`;

const Action = styled.section`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;
