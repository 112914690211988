import smartlookClient from 'smartlook-client';

if (import.meta.env.VITE_SMARTLOOK_PROJECT_KEY) {
  try {
    smartlookClient.init(import.meta.env.VITE_SMARTLOOK_PROJECT_KEY);
  } catch {
    //pass
  }
}

export const trackSmartlookEvent = (
  event: string,
  data?: { [key: string]: string | number | boolean }
) => {
  if (!smartlookClient.initialized()) return;
  smartlookClient.track(event, { ...data });
};
