import React from 'react';
import styled from '@emotion/styled';
import {
  Redirect,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from 'react-router-dom';

import { TId, useDocumentTitle } from '@/features/common';
import { InterviewPageHeader, InterviewSettings } from '@/features/interview';
import { useJobOpeningStatus } from '@/features/job-opening';
import { useHistory } from '@/hooks/router';

function InterviewPage() {
  useDocumentTitle('Interview');

  const { url } = useRouteMatch();
  const { workspace, opening, interviewId } = useParams<{
    workspace: TId;
    opening: TId;
    interviewId: TId;
  }>();

  const history = useHistory();
  const isJobOpeningClosed = useJobOpeningStatus({ id: opening }).isClosed;

  if (isJobOpeningClosed) {
    history.push(`/admin/${workspace}/openings/${opening}/pipeline`);
    return null;
  }
  return (
    <>
      <InterviewPageHeader interviewId={interviewId} />
      <PageContent>
        <Switch>
          <Route path={`${url}/settings`}>
            <InterviewSettings interviewId={interviewId} />
          </Route>

          <Redirect from={`${url}`} to={`${url}/settings`} exact />
        </Switch>
      </PageContent>
    </>
  );
}

const PageContent = styled.div`
  padding: 24px;
`;

export default InterviewPage;
