import * as React from 'react';
import ReactMarkdown, { Options } from 'react-markdown';

import { MarkdownImage, MarkdownLink } from './ui';

export function Markdown({ children, ...rest }: Options) {
  return (
    <ReactMarkdown
      {...rest}
      components={{
        a: MarkdownLink,
        img: MarkdownImage,
      }}
    >
      {children}
    </ReactMarkdown>
  );
}
