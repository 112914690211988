import React from 'react';
import styled from '@emotion/styled';
import { DSButton } from '@hundred5/design-system';

import { useLogout } from '@/features/toggl-accounts';
import { useHistory } from '@/hooks/router';

export function NoWorkspaceNavigation() {
  const history = useHistory();
  const logout = useLogout();

  return (
    <Container>
      <Info>You are no longer part of any active Toggl Hire workspace.</Info>
      <Actions>
        <DSButton onClick={() => history.push('/admin/new')}>
          Create a workspace
        </DSButton>
        <DSButton variant="secondary" onClick={() => logout()}>
          Log out
        </DSButton>
      </Actions>
    </Container>
  );
}

const Container = styled.div`
  display: grid;
  gap: 20px;
  background: ${(props) => props.theme.colors.purple[90]};
  border-radius: 8px;
  margin: 16px;
  padding: 12px;
  box-shadow: 0px 1px 40px 0px rgba(0, 0, 0, 0.25);

  button {
    width: 100%;
  }
`;

const Info = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.peach[70]};
`;

const Actions = styled.div`
  display: grid;
  gap: 12px;
`;
