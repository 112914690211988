import { TSkillType } from '../types';

export const TAB_LABEL_DICTIONARY = {
  Tech: 'Technical',
  'Customer support': 'Customer Success',
};

export const DEFAULT_CATEGORY_TAB = 'Tech';

const TAB_ORDER: TSkillType[] = [
  'Tech',
  'Sales',
  'Customer Support',
  'Marketing',
  'Product',
  'Human Resources',
  'Finance',
  'Language',
  'Soft',
];

export const orderTabs = (tabs: TSkillType[]): TSkillType[] => {
  return tabs.sort((a, b) => {
    const indexA = TAB_ORDER.indexOf(a);
    const indexB = TAB_ORDER.indexOf(b);

    // if not found in TAB_ORDER, move to the end
    if (indexA === -1) return 1;
    if (indexB === -1) return -1;

    return indexA - indexB;
  });
};
