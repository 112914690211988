import styled from '@emotion/styled';

export const Container = styled.div`
  display: grid;
  grid-template-areas:
    'icon title       action'
    'icon description action'
    '.    details     .';
  grid-template-columns: max-content auto max-content;
  column-gap: 12px;
`;
