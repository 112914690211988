import React from 'react';

import { IJobOpening } from '@/features/job-opening';
import { ITest } from '@/features/test';
import { IWorkspace } from '@/features/workspace';

interface TestPageContextProps {
  workspace: IWorkspace;
  jobOpening: IJobOpening;
  test: ITest;
}

export const TestPageContext = React.createContext<TestPageContextProps | null>(
  null
);

export function useTestPage(): TestPageContextProps {
  const context = React.useContext(TestPageContext);
  if (context === undefined) {
    throw new Error('useTestPage must be used within a TestPageProvider');
  }
  if (context === null) {
    throw new Error('TestPageContext should have a value');
  }

  return context;
}
