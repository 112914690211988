import * as React from 'react';

interface MarkdownLinkProps {
  href?: string;
  title?: string;
  children: React.ReactNode;
}

export function MarkdownLink({ href, title, children }: MarkdownLinkProps) {
  return (
    <a
      href={href}
      title={title || undefined}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
}
