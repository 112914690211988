import {
  ITogglAccountsMe,
  IUpdateTogglAccountsMe,
} from '@/features/toggl-accounts';
import { parseAPIData, serialiseAPIData } from '@/utils/parsers';

import togglAccountsApi from './toggl-accounts-api';

export async function fetchTogglAccountsMe(): Promise<ITogglAccountsMe> {
  const response = await togglAccountsApi.get(`/me`);
  return parseAPIData(response.data);
}

export async function closeAccount(): Promise<ITogglAccountsMe> {
  const response = await togglAccountsApi.post(`/me/close_account/hire`);
  return parseAPIData(response.data);
}

export async function updateTogglAccountsMe(
  params: IUpdateTogglAccountsMe
): Promise<ITogglAccountsMe> {
  const response = await togglAccountsApi.put(`/me`, serialiseAPIData(params));
  return parseAPIData(response.data);
}
