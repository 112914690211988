import React, { useMemo } from 'react';
import { DSField, DSComboboxMultiple } from '@hundred5/design-system';
import { useField } from 'formik';
import { uniqueId } from 'lodash';

type TItem = {
  id: string;
  label: string;
};

interface FormikComboboxMultipleFieldProps {
  name: string;
  items: TItem[];
  label?: React.ReactNode;
  required?: boolean;
  id?: string;
  maxSelectedItemCount?: number;
  placeholder?: string;
  disabledField?: boolean;
  disabledInput?: boolean;
  errorAbsolute?: boolean;
  onChange?: (selectedItemIds?: string[] | undefined) => void;
  onCreate?: (value: string) => void;
}

export function FormikComboboxMultipleField(
  props: FormikComboboxMultipleFieldProps
) {
  const [field, meta, form] = useField<any>(props.name);
  const error = meta.touched && !!meta.error ? meta.error : null;
  const id = useMemo(() => props.id || uniqueId('formik-field-'), [props.id]);

  return (
    <DSField
      for={id}
      label={props.label}
      required={props.required}
      error={error}
      errorAbsolute={props.errorAbsolute}
      disabled={props.disabledField}
    >
      <DSComboboxMultiple
        id={id}
        {...field}
        maxSelectedItemCount={props.maxSelectedItemCount}
        placeholder={props.placeholder}
        disabled={props.disabledInput}
        error={!!error}
        items={props.items}
        selectedItemIds={field.value}
        onChange={(selectedItemIds) => {
          if (props.onChange) {
            props.onChange(selectedItemIds);
          } else {
            form.setValue(selectedItemIds);
          }
        }}
        onCreate={props.onCreate}
      />
    </DSField>
  );
}
