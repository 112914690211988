const accountId = import.meta.env.VITE_HUBSPOT_ACCOUNT_ID;

if (accountId) {
  window._hsq = window._hsq || [];

  const script = document.createElement('script');
  script.src = `https://js.hs-scripts.com/${accountId}.js`;
  script.id = 'hs-script-loader';
  script.async = true;

  document.head.appendChild(script);
}
