import React from 'react';
import styled from '@emotion/styled';

import {
  SkillsTestSettingsAntiCheat,
  SkillsTestSettingsAuthentication,
  SkillsTestSettingsAutomations,
  SkillsTestSettingsGeneral,
  SkillsTestSettingsOther,
  useTestPageAnalytics,
} from '@/features/test';

export const SkillsTestSettings = () => {
  useTestPageAnalytics('skills test settings');

  return (
    <SkillsTestSettingsContainer>
      <SkillsTestSettingsGeneral />
      <SkillsTestSettingsAuthentication />
      <SkillsTestSettingsAutomations />
      <SkillsTestSettingsAntiCheat />
      <SkillsTestSettingsOther />
    </SkillsTestSettingsContainer>
  );
};

const SkillsTestSettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
