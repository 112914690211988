import React from 'react';
import styled from '@emotion/styled';
import { DSButton } from '@hundred5/design-system';
import { Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';

import { recordAcceptingTermsAndConditions } from '@/api/termsConsent';
import { createWorkspace } from '@/api/workspaces';
import { checkClientError } from '@/errors';
import { FormikInputField } from '@/features/common';
import { useApiError } from '@/hooks/api';
import { useHistory } from '@/hooks/router';
import { isBusinessEmail } from '@/utils/emails';

import { useSignup } from '../../hooks';

const initialValues = {
  email: '',
  password: '',
  companyName: '',
  acceptTerms: false,
};

type Values = typeof initialValues;

const schema = yup.object().shape({
  email: yup
    .string()
    .required('Business email cannot be empty')
    .email('Enter a valid email')
    .test('common', 'Please use your business address', (value) =>
      value != null ? isBusinessEmail(value) : false
    ),
  password: yup
    .string()
    .required('Password cannot be empty')
    .min(6, 'Password must have more than 6 characters'),
});

export function SignupForm() {
  const signUp = useSignup();
  const handleApiError = useApiError();
  const history = useHistory();

  const handleSubmit = async (values: Values, form: FormikHelpers<Values>) => {
    try {
      await signUp({
        email: values.email,
        password: values.password,
        createWorkspace: true,
      });
    } catch (error) {
      if (checkClientError(error, 'AlreadyRegistered')) {
        form.setFieldError('email', 'Email is already taken');
      } else {
        handleApiError(error);
      }

      form.setSubmitting(false);
      return;
    }

    try {
      await recordAcceptingTermsAndConditions('october-2021');
      await createWorkspace();
    } catch (error) {
      handleApiError(error);
    }

    history.replace('/admin');
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={schema}
    >
      {(form) => (
        <Form onSubmit={form.handleSubmit} data-recording-ignore="mask">
          <SignupFormField
            type="email"
            name="email"
            id="signup-form-email"
            label="Business email"
          />
          <SignupFormField
            type="password"
            name="password"
            id="signup-form-password"
            label="Password"
          />
          <Terms>
            <TermsLabel>
              By signing up, you agree to our{' '}
              <a
                href="https://toggl.com/hire/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms & Conditions
              </a>{' '}
              and to receiving marketing communication from Toggl Hire. You can
              opt out anytime.
            </TermsLabel>
          </Terms>
          <SubmitButton type="submit" disabled={form.isSubmitting}>
            Sign Up&nbsp;
            <span>• No credit card required</span>
          </SubmitButton>
        </Form>
      )}
    </Formik>
  );
}

const Form = styled.form`
  width: 100%;
`;

const SignupFormField = styled(FormikInputField)`
  &:not(:first-of-type) {
    margin-top: 12px;
  }

  @media screen and (max-height: 750px) and (min-width: 490px) {
    &:not(:first-of-type) {
      margin-top: 10px;
    }
  }
`;

const Terms = styled.div`
  margin-top: 22px;

  @media (min-width: 490px) {
    margin-top: 15px;
  }
`;
const TermsLabel = styled.div`
  font-size: 12px;
`;

const SubmitButton = styled(DSButton)`
  width: 100%;
  margin-top: 28px;

  & span {
    color: ${({ theme }) => theme.colors.orange[10]};
    font-weight: 500;
  }

  @media screen and (max-height: 750px) and (min-width: 490px) {
    margin-top: 17px;
  }
`;
