import { serialiseAPIData } from '@/utils/parsers';

import togglAccountsApi from './toggl-accounts-api';

export async function logout(params: {
  closeAllSessions?: boolean;
}): Promise<void> {
  await togglAccountsApi.delete(`/sessions`, {
    data: serialiseAPIData({
      closeAllSessions: !!params.closeAllSessions,
    }),
    skipLogoutOnUnauthorized: true,
  });
}
