import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { requestSkillOrTemplate } from '../api/request';

export const REQUEST_SKILL_OR_TEMPLATE_QUERY_KEY = [
  'request-skill-or-template',
];

export const useRequestSkillOrTemplateMutation = (
  options?: UseMutationOptions
) => {
  return useMutation(requestSkillOrTemplate, {
    mutationKey: REQUEST_SKILL_OR_TEMPLATE_QUERY_KEY,
    ...(options as any),
  });
};
