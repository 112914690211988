import styled from '@emotion/styled';
import { DSStyledContent } from '@hundred5/design-system';

import { Icon } from '@/features/common';

export const Wrapper = styled.div<{
  expanded: boolean;
  variant?: 'light' | 'dark';
}>`
  background: ${({ theme, variant = 'light' }) =>
    variant === 'light' ? theme.colors.white : theme.colors.purple[5]};
  border-top: 1px solid ${(props) => props.theme.colors.purple[10]};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: grid;
  overflow: hidden;
  grid-template-columns: 100%;
  grid-template-rows: min-content ${({ expanded }) =>
      expanded ? '1fr' : '0fr'};
  transition: grid-template-rows 0.2s;
`;

const Heading = styled.h4`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  margin: 0;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.purple[100]};
`;

const Arrow = styled(Icon, {
  shouldForwardProp: (prop) => prop !== 'up',
})<{ up: boolean }>`
  transform: rotate(${({ up }) => (up ? 180 : 0)}deg);
  transition: transform 100ms ease-in-out;
`;

const ContentWrapper = styled.div`
  min-height: 0;
`;

const Content = styled(DSStyledContent)`
  padding: 16px 24px 24px 24px;
`;

export const QuestionNotesContainer = {
  Wrapper,
  Heading,
  Arrow,
  ContentWrapper,
  Content,
};
