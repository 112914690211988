import React from 'react';
import { DSInput } from '@hundred5/design-system';
import TimeField from 'react-simple-timefield';

type Props = Partial<React.ComponentProps<typeof TimeField>>;

const TimePicker = (props: Props) => {
  return <TimeField input={<DSInput />} {...props} />;
};

export default TimePicker;
