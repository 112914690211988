import React, { useCallback } from 'react';
import { arrayMove } from '@dnd-kit/sortable';

import { SortableList, TId } from '@/features/common';
import {
  IQuestion,
  QuestionIcon,
  QuestionNavigationItem,
  useSortQuestionsMutation,
} from '@/features/questions';

interface QuestionNavigationListProps {
  testId: TId;
  questions: IQuestion[];
  selectedQuestionId?: TId;
  onQuestionSelect: (question: IQuestion) => void;
  /**
   * Disables the ability to select/reorder questions
   */
  isDisabled?: boolean;
  /**
   * Disables the ability to just reorder questions, no changes in UI
   */
  isOrderingDisabled?: boolean;
}

export function QuestionNavigationList({
  testId,
  questions,
  selectedQuestionId,
  onQuestionSelect,
  isDisabled,
  isOrderingDisabled,
}: QuestionNavigationListProps) {
  const sortQuestionsMutation = useSortQuestionsMutation({ testId });

  const handleOrderChanged = useCallback(
    (activeIndex: number, overIndex: number) => {
      // Update local list order
      const newSortedQuestions = arrayMove(questions, activeIndex, overIndex);

      sortQuestionsMutation.mutate(
        newSortedQuestions.map((question) => question.id)
      );
    },
    [questions, sortQuestionsMutation]
  );

  return (
    <SortableList
      items={questions}
      renderItem={(question, index) => (
        <QuestionNavigationItem
          number={index + 1}
          icon={
            <QuestionIcon
              questionSource={question.source}
              questionType={question.questionType}
              isRandom={question.isRandom}
            />
          }
          title={question.skill?.name ?? 'Custom'}
          points={question.points}
          isDisabled={isDisabled}
          isSelected={question.id === selectedQuestionId}
          onClick={() => onQuestionSelect(question)}
        />
      )}
      onOrderChanged={handleOrderChanged}
      isOrderingDisabled={isOrderingDisabled || isDisabled}
    />
  );
}
