import api from '@/api/api';
import { parseAPIData } from '@/utils/parsers';

import { ITemplateCategory } from '../types';

export async function fetchTemplateCategories(): Promise<ITemplateCategory[]> {
  const response = await api.get(`/tests/generator/categories`);

  return parseAPIData(response.data.categories ?? []);
}
