import { useCallback } from 'react';

import { TId } from '@/features/common';
import { DASHBOARD_JOB_OPENINGS_QUERY_KEY } from '@/features/dashboard';
import {
  PIPELINE_CATEGORIES_KEY,
  fetchPipelineCategories,
  useUpdatePipelineCategoryMutation,
} from '@/features/pipeline';
import { queryClient } from '@/utils/reactQuery';

import * as testsApi from '../api/tests';
import { Test, TestStats, TestType } from '../types/h5test';

import { useHistory, useWorkspaceId } from './router';

export const useCreateCustomSkillsTest = () => {
  const workspaceId = useWorkspaceId();
  const history = useHistory();
  const updatePipelineCategoryMutation = useUpdatePipelineCategoryMutation();

  return useCallback(
    async (
      jobOpeningId: TId,
      pipelineCategoryId: TId
    ): Promise<{ test: Test; testStats: TestStats }> => {
      const result = await testsApi.createTest(workspaceId, {
        name: 'Custom test: Skills Test',
        type: 'quiz' as TestType,
        passPercentage: 0,
        timeLimit: 0,
        jobOpeningId: jobOpeningId,
        pipelineStageId: pipelineCategoryId,
      });

      const pipelineCategories = await fetchPipelineCategories({
        jobOpeningId,
      });
      const pipelineCategory = pipelineCategories.find(
        (pipelineCategory) => pipelineCategory.id === pipelineCategoryId
      );

      if (result.test.id && pipelineCategory) {
        await updatePipelineCategoryMutation.mutateAsync({
          jobOpeningId,
          category: {
            ...pipelineCategory,
            testId: result.test.id,
          },
        });
        queryClient.invalidateQueries({
          queryKey: [...PIPELINE_CATEGORIES_KEY, jobOpeningId],
        });
      }

      queryClient.invalidateQueries({
        queryKey: [...DASHBOARD_JOB_OPENINGS_QUERY_KEY],
      });

      history.push(
        `/admin/${workspaceId}/openings/${jobOpeningId}/test/${result.test.id}/questions`
      );

      return result;
    },
    [history, workspaceId, updatePipelineCategoryMutation]
  );
};
