import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';

import { checkClientError } from '@/errors';
import { TId } from '@/features/common';
import { useNotifications } from '@/features/notifications';
import { useApiError } from '@/hooks/api';
import { useWorkspaceId } from '@/hooks/router';

import {
  deleteEmailTemplate,
  fetchEmailTemplates,
  fetchEmailTemplateVariables,
  saveEmailTemplate,
  sendEmailTemplatePreview,
  updateEmailTemplate,
} from '../api';
import { IEmailTemplate, TEmailTemplateForSave } from '../types';

const EMAIL_TEMPLATES_KEY = ['email-templates'];
export const useEmailTemplatesQuery = (options?: UseQueryOptions) => {
  const workspaceId = useWorkspaceId();

  return useQuery(
    [...EMAIL_TEMPLATES_KEY, workspaceId],
    () => fetchEmailTemplates(workspaceId),
    options as any
  );
};

export const useSaveEmailTemplateMutation = (options?: UseMutationOptions) => {
  const queryClient = useQueryClient();
  const workspaceId = useWorkspaceId();
  const { addNotification } = useNotifications();

  return useMutation(
    (emailTemplate: TEmailTemplateForSave) => saveEmailTemplate(emailTemplate),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([...EMAIL_TEMPLATES_KEY, workspaceId]);
        addNotification({ type: 'saved' });
      },
      ...(options as any),
    }
  );
};

export const useUpdateEmailTemplateMutation = (
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();
  const workspaceId = useWorkspaceId();
  const { addNotification } = useNotifications();

  return useMutation(
    (params: { emailTemplate: IEmailTemplate }) =>
      updateEmailTemplate(workspaceId, params.emailTemplate),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([...EMAIL_TEMPLATES_KEY, workspaceId]);
        addNotification({ type: 'saved' });
      },
      ...(options as any),
    }
  );
};

export const useDeleteEmailTemplateMutation = (
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();
  const workspaceId = useWorkspaceId();
  const { addNotification } = useNotifications();

  return useMutation(
    (emailTemplateId: TId) => deleteEmailTemplate(workspaceId, emailTemplateId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([...EMAIL_TEMPLATES_KEY, workspaceId]);
        addNotification({ type: 'saved' });
      },
      ...(options as any),
    }
  );
};

export const useEmailTemplateVariablesQuery = (options?: UseQueryOptions) => {
  return useQuery(
    ['email-template-variables'],
    () => fetchEmailTemplateVariables(),
    options as any
  );
};

export const useSendEmailTemplatePreviewMutation = (
  options?: UseQueryOptions
) => {
  const workspaceId = useWorkspaceId();
  const { addNotification } = useNotifications();
  const handleApiError = useApiError();

  return useMutation(
    (params: {
      emailTemplateId: TId;
      emailTemplateDomain: 'workspace' | 'system';
    }) =>
      sendEmailTemplatePreview(
        workspaceId,
        params.emailTemplateId,
        params.emailTemplateDomain
      ),
    {
      onError: (err) => {
        if (checkClientError(err, 'BadTemplate')) {
          addNotification({
            type: 'email_bad_template',
            meta: { error: err.message },
          });
        } else {
          handleApiError(err);
        }
      },
      ...(options as any),
    }
  );
};
