import React, { forwardRef, useImperativeHandle } from 'react';
import styled from '@emotion/styled';

import { useClickOutside } from '@/features/common/hooks/use-click-outside';

import { Content } from './content';
import { Option } from './option';
import { Trigger } from './trigger';

export interface PopoverProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  close: () => void;
}

interface PopoverHandle {
  close: PopoverProps['close'];
}

export const PopoverContext = React.createContext<PopoverProps>({
  isOpen: false,
  setIsOpen: () => {},
  close: () => {},
});

// `Object.assign` is used to enable type inference for `Popover.Trigger`, `Popover.Content` and `Popover.Option`
export const Popover = Object.assign(
  forwardRef<PopoverHandle, { children?: React.ReactNode }>(
    function Popover(props, ref) {
      const [isOpen, setIsOpen] = React.useState(false);
      const containerRef = React.useRef(null);
      const close = React.useCallback(() => setIsOpen(false), [setIsOpen]);

      useClickOutside(containerRef, close);

      useImperativeHandle(ref, () => {
        return {
          close,
        };
      }, [close]);

      return (
        <PopoverContext.Provider value={{ isOpen, setIsOpen, close }}>
          <PopoverWrapper ref={containerRef} {...props} />
        </PopoverContext.Provider>
      );
    }
  ),
  {
    Trigger,
    Content,
    Option,
  }
);

//region styles
const PopoverWrapper = styled.div`
  display: inline-block;
  position: relative;
`;
//endregion
