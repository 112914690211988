import React from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Icon } from '@/features/common';
import { maxQuestionReachedMessage } from '@/features/common/utils/max-questions-reached';

interface CounterProps {
  min?: number;
  max?: number;
  /**
   * The number (or string representation) to be used on the tooltip,
   * could be different from the max if the counter depends on other counters
   */
  maxInWords?: number | string;
  disabled?: boolean;
  onChange: (count: number) => void;
}

export function Counter({
  min = 1,
  max = 50,
  maxInWords = String(max),
  disabled = false,
  onChange,
}: CounterProps) {
  const [count, setCount] = React.useState<number>(1);
  const canAdd = count < max && !disabled;

  return (
    <Wrapper>
      <CounterButton
        disabled={count === min || disabled}
        onClick={() =>
          setCount((prev) => {
            onChange(prev - 1);
            return prev - 1;
          })
        }
      >
        <Icon icon={regular('minus')} />
      </CounterButton>
      <Value>{count}</Value>
      <CounterButton
        disabled={!canAdd}
        onClick={() =>
          setCount((prev) => {
            onChange(prev + 1);
            return prev + 1;
          })
        }
        data-rh={!canAdd ? maxQuestionReachedMessage(maxInWords) : null}
        data-rh-at="top"
      >
        <Icon icon={regular('plus')} />
      </CounterButton>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  border: 1px solid ${({ theme }) => theme.colors.purple[40]};
  border-radius: 8px;
  height: 28px;

  align-self: start;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
`;

const CounterButton = styled.button`
  background: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.purple[60]};
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  height: 100%;
  line-height: 100%;

  &:hover {
    background: ${({ theme, disabled }) =>
      disabled ? 'transparent' : theme.colors.purple[5]};
  }
`;

const Value = styled.span`
  text-align: center;
  padding: 0 4px;
`;
