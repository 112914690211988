import React from 'react';

export function Illustration() {
  return (
    <svg
      width="140"
      height="143"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M106.336 70.232c-3.552-11.03-13.707-7.518-16.64-1.524-2.932 5.994 1.622 14.146 6.268 20.454 5.71 7.753 20.863 20.727 21.94 21.747 1.076 1.02 2.931 3.09 4.9 4.903 1.968 1.813 8.252 4.378 12.325.873 8.093-6.963 2.623-17.758 1.638-19.55-.985-1.794-2.549-6.63-11.041-11.409-5.688-3.2-14.949-1.707-19.39-15.494Z"
        fill="#A85D42"
      />
      <path
        d="M100.122 70.072c-.187-1.883 2.724-3.593 4.004-4.174 1.496 1.93 1.785 3.188 2.986 5.78 1.041 2.25 1.406 3.563 1.805 4.277-.947.531-2.878 1.72-4.306 1.788-1.783.087-4.256-5.318-4.489-7.671Z"
        fill="#CD9460"
      />
      <mask
        id="a"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="118"
        height="119"
      >
        <path d="M.371.91h117.521v117.52H.372V.91Z" fill="#C4C4C4" />
      </mask>
      <g mask="url(#a)">
        <path
          d="m72.93 78.894 5.105-5.185 8.642 8.51-5.106 5.185-8.641-8.51Z"
          fill="#ABA0AF"
        />
        <path
          d="M77.613 14.33c17.654 17.592 17.704 46.166.11 63.82-17.592 17.654-46.165 17.704-63.82.11C-3.75 60.669-3.8 32.096 13.793 14.44c17.592-17.654 46.165-17.704 63.82-.11Z"
          fill="#564260"
        />
        <path
          d="M70.727 21.238c13.837 13.788 13.876 36.184.087 50.02-13.789 13.838-36.184 13.877-50.021.088-13.838-13.789-13.877-36.184-.088-50.021 13.79-13.837 36.185-13.876 50.022-.087Z"
          fill="#FFDE91"
        />
      </g>
      <path
        d="M82.923 82.924a8.538 8.538 0 0 1 12.073-.093l39.623 37.708a8.539 8.539 0 0 1-11.981 12.167L83.016 94.998a8.538 8.538 0 0 1-.093-12.074Z"
        fill="#564260"
      />
      <path
        d="M63.144 108.923a8.028 8.028 0 0 0 8.704 13.171 8.029 8.029 0 0 0 12.86 9.159l1.16-1.12a8.028 8.028 0 0 0 13.294 7.995l3.568-3.446a8.029 8.029 0 0 0 13.601 5.501l3.777-3.648a8.028 8.028 0 0 0-6.754-13.717 8.03 8.03 0 0 0-10.887-9.459 8.031 8.031 0 0 0-10.411-10.78 8.028 8.028 0 0 0-12.86-9.159l-16.052 15.503Z"
        fill="#CD9460"
      />
      <path
        d="M79.4 57.14c-5.916 18.616-25.804 28.912-44.42 22.996C16.361 74.22 6.066 54.33 11.982 35.715c5.916-18.617 25.804-28.913 44.421-22.997C75.021 18.635 85.317 38.523 79.4 57.14Z"
        fill="#FF9587"
      />
      <path
        d="m48.18 54.4-9.28-2.948 7.72-24.29 9.279 2.948-7.72 24.29Zm-5.421.983c3.275 1.04 4.95 4.277 3.91 7.553-1.041 3.275-4.278 4.95-7.553 3.91-3.275-1.041-4.95-4.278-3.91-7.553 1.04-3.275 4.278-4.951 7.553-3.91Z"
        fill="#412A4C"
      />
    </svg>
  );
}
