const PERCENT_TAG_RE = /%([a-zA-Z0-9]+)%/g;
const BRACKET_TAG_RE = /\\?\[([a-zA-Z0-9]+)\\?\]/g;

export function replaceTemplateTags(
  template: string,
  replacements: { [key: string]: string }
): string {
  const replacer = (match: string, tagName: string): string =>
    replacements[tagName] || match;

  return template
    .replace(PERCENT_TAG_RE, replacer)
    .replace(BRACKET_TAG_RE, replacer);
}
