import React from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Icon } from '@/features/common';

interface Props {
  canGoToPrev: boolean;
  canGoToNext: boolean;
  goPrev: () => void;
  goNext: () => void;
  iconSize?: '12px' | '16px';
}

export function CarouselNavigation({
  canGoToPrev,
  canGoToNext,
  goPrev,
  goNext,
  iconSize = '12px',
}: Props) {
  return (
    <Container>
      <IconWrapper onClick={goPrev} iconSize={iconSize} disabled={!canGoToPrev}>
        <Icon
          icon={regular('chevron-left')}
          fontSize={iconSize}
          color={canGoToPrev ? 'purple-100' : 'purple-20'}
        />
      </IconWrapper>
      <IconWrapper onClick={goNext} iconSize={iconSize} disabled={!canGoToNext}>
        <Icon
          icon={regular('chevron-right')}
          fontSize={iconSize}
          color={canGoToNext ? 'purple-100' : 'purple-20'}
        />
      </IconWrapper>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const IconWrapper = styled.div<{ iconSize: string; disabled: boolean }>`
  display: flex;
  cursor: pointer;
  border-radius: 8px;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  transition: background-color 200ms ease-in-out;

  svg {
    transform: scale(1);
    transition: transform 200ms ease-in-out;
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.purple[5]};

    svg {
      transform: ${(props) =>
        props.iconSize === '12px' ? 'scale(calc(2 / 3))' : 'scale(0.75)'};
    }
  }
`;
