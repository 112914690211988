import styled from '@emotion/styled';
import { DSInput } from '@hundred5/design-system';

export const Input = styled(DSInput)`
  width: 100%;

  @media all and (min-width: 769px) {
    width: 280px;
  }
`;
