import React from 'react';
import styled from '@emotion/styled';
import { DSField, DSSelect } from '@hundred5/design-system';
import { useField } from 'formik';

import { useCountryList } from '@/features/common';

import { getFieldId } from '../card-token-form.utils';

export const CountryField = ({
  name,
  label,
  required,
  hasRecurlyError,
}: {
  name: string;
  label: string;
  required?: boolean;
  hasRecurlyError: boolean;
}) => {
  const { countries } = useCountryList();
  const [field, meta, form] = useField(name);
  const errorMessage = hasRecurlyError
    ? 'Invalid input'
    : meta.touched && !!meta.error
      ? meta.error
      : null;
  return (
    <DSField
      for={getFieldId(name)}
      label={label}
      required={required}
      error={errorMessage}
      onBlur={() => form.setTouched(true)}
    >
      <SelectInput data-recurly={name} {...field} />
      <DSSelect
        id={getFieldId(name)}
        onChange={(item) => item && form.setValue(item.id)}
        placeholder="Select..."
        error={!!errorMessage}
        items={countries.map((country) => ({
          id: country.code,
          label: country.name,
          labelText: country.name,
        }))}
      />
    </DSField>
  );
};

const SelectInput = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: -1;
  pointer-events: none;
`;
