import React from 'react';
import styled from '@emotion/styled';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { Icon, useColor } from '@/features/common';

interface OptionIconProps {
  icon: IconProp;
  color: string;
}

export function OptionIcon({ icon, color }: OptionIconProps) {
  return (
    <IconContainer bgrColor={useColor(color, 'purple-60')}>
      <Icon icon={icon} color="purple-100" />
    </IconContainer>
  );
}

const IconContainer = styled.div<{ bgrColor: string }>`
  width: 44px;
  height: 44px;
  border-radius: 8px;
  background-color: ${({ bgrColor }) => bgrColor};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
`;
