import { useCallback, useMemo } from 'react';

import { BillingPlan, useBillingPlansConfig } from '@/features/billing';
import { isLegacyReduxTest } from '@/features/common';
import { IDashboardJobOpening } from '@/features/dashboard';
import { IJobOpening } from '@/features/job-opening';
import { useMeQuery } from '@/features/me';
import { usePipelineTests } from '@/features/pipeline';
import { ITest } from '@/features/test';
import {
  useWorkspaceQuery,
  useWorkspaceStatsQuery,
  useWorkspaceUsersQuery,
} from '@/features/workspace';
import { useWorkspaceInvitationsQuery } from '@/features/workspace/queries/workspace-invitations';
import { useWorkspaceId, useWorkspaceIdOrNull } from '@/hooks/router';
import { Test } from '@/types/h5test';

export type PlanFeature =
  | 'api_access'
  | 'create_workspace'
  | 'export_candidates'
  | 'candidate_attachments'
  | 'candidate_emails'
  | 'reject_candidates'
  | 'hard_difficulty'
  | 'premium_skills_and_templates'
  | 'ats_integrations'
  | 'slack_integration'
  | 'zapier_integration'
  | 'job_opening_analytics'
  | 'candidate_email_login'
  | 'disable_question_rating'
  | 'disable_contact_info_fields'
  | 'disable_candidate_feedback'
  | 'candidate_redirect_url'
  | 'disable_score_chart'
  | 'hide_premium_banner'
  | 'suspicious_activities'
  | 'answer_paste'
  | 'create_custom_test'
  | 'share_custom_test'
  | 'candidate_history'
  | 'bulk_actions'
  | 'add_candidate'
  | 'email_templates_management'
  | 'share_premium_template_test'
  | 'share_hard_test'
  | 'pause_job_opening'
  | 'purchase_credits'
  | 'pipeline_automation'
  | 'job_opening_user_rights'
  | 'candidate_answer_ai_features';

export type PlanLimitedFeature =
  | 'job_openings'
  | 'workspace_users'
  | 'questions_generation'
  | 'unlockable_candidates'
  | 'unlockable_video_intros'
  | 'tests_in_job_opening';

export type PlanLevel =
  | 'free'
  | 'free-2021'
  | 'free-exp-2023'
  | 'free-basicexperiment-oct22'
  | 'free-v2-2023'
  | 'standard'
  | 'premium-2021'
  | 'premium-exp-2023'
  | 'premium'
  | 'premium-v2-2023'
  | 'premium-60c-2023'
  | 'premium-45c-2024'
  | 'demo'
  | 'basic'
  | 'basic-exp-2023'
  | 'basic-basicexperiment-oct22'
  | 'basic-v2-2023'
  | 'basic-2024'
  | 'business'
  | 'business-exp-2023'
  | 'business-v2-2023'
  | 'unlimited';

export type PlanName =
  | 'free'
  | 'standard'
  | 'premium'
  | 'basic'
  | 'business'
  | 'unlimited'
  | 'demo';

export type NextPlanName = 'premium' | 'business' | 'enterprise';

export const premiumPlans: PlanLevel[] = [
  'premium',
  'premium-2021',
  'premium-exp-2023',
  'premium-v2-2023',
  'premium-60c-2023',
  'premium-45c-2024',
  'business',
  'business-exp-2023',
  'business-v2-2023',
  'unlimited',
  'demo',
];

export const paidPlans: PlanLevel[] = [
  'basic',
  'basic-basicexperiment-oct22',
  'basic-exp-2023',
  'basic-2024',
  'premium',
  'premium-2021',
  'premium-exp-2023',
  'premium-v2-2023',
  'premium-60c-2023',
  'premium-45c-2024',
  'business',
  'business-exp-2023',
  'business-v2-2023',
  'unlimited',
];

const requiredPlans: Record<PlanFeature, PlanLevel[]> = {
  api_access: premiumPlans,
  create_workspace: [
    'standard',
    'premium',
    'premium-exp-2023',
    'premium-v2-2023',
    'premium-60c-2023',
    'premium-45c-2024',
    'business',
    'business-exp-2023',
    'business-v2-2023',
    'unlimited',
    'demo',
  ],
  export_candidates: premiumPlans,
  candidate_attachments: premiumPlans,
  candidate_emails: premiumPlans,
  reject_candidates: [
    'free-2021',
    'free-exp-2023',
    'free-basicexperiment-oct22',
    'basic',
    'basic-exp-2023',
    'basic-basicexperiment-oct22',
    'basic-exp-2023',
    'standard',
    ...premiumPlans,
  ],
  hard_difficulty: [...premiumPlans],
  premium_skills_and_templates: [
    'free-2021',
    'free-exp-2023',
    'free-basicexperiment-oct22',
    'basic',
    'basic-exp-2023',
    'basic-basicexperiment-oct22',
    ...premiumPlans,
  ],
  ats_integrations: premiumPlans,
  slack_integration: premiumPlans,
  zapier_integration: premiumPlans,
  job_opening_analytics: [
    'free-2021',
    'free-exp-2023',
    'free-basicexperiment-oct22',
    'basic',
    'basic-exp-2023',
    'basic-basicexperiment-oct22',
    'standard',
    'basic-exp-2023',
    ...premiumPlans,
  ],
  candidate_email_login: premiumPlans,
  disable_question_rating: premiumPlans,
  disable_contact_info_fields: premiumPlans,
  disable_candidate_feedback: premiumPlans,
  candidate_redirect_url: [
    'business',
    'business-exp-2023',
    'business-v2-2023',
    'unlimited',
    'demo',
  ],
  disable_score_chart: premiumPlans,
  hide_premium_banner: premiumPlans,
  suspicious_activities: premiumPlans,
  answer_paste: premiumPlans,
  create_custom_test: [
    'free-2021',
    'free-exp-2023',
    'free-basicexperiment-oct22',
    'free-v2-2023',
    'basic',
    'basic-exp-2023',
    'basic-basicexperiment-oct22',
    'basic-v2-2023',
    'basic-2024',
    ...premiumPlans,
  ],
  share_custom_test: [
    'free-2021',
    'free-basicexperiment-oct22',
    'free-exp-2023',
    'free-v2-2023',
    'basic',
    'basic-v2-2023',
    'basic-2024',
    'basic-basicexperiment-oct22',
    'basic-exp-2023',
    ...premiumPlans,
  ],
  share_premium_template_test: [...premiumPlans],
  share_hard_test: [...premiumPlans],
  candidate_history: premiumPlans,
  bulk_actions: [
    'business-v2-2023',
    'business-exp-2023',
    'business',
    'unlimited',
    'demo',
  ],
  add_candidate: premiumPlans,
  email_templates_management: premiumPlans,
  pause_job_opening: premiumPlans,
  purchase_credits: [
    'basic',
    'basic-exp-2023',
    'basic-basicexperiment-oct22',
    'basic-v2-2023',
    'basic-2024',
    'premium',
    'premium-2021',
    'premium-exp-2023',
    'premium-v2-2023',
    'premium-60c-2023',
    'premium-45c-2024',
    'demo',
  ],
  pipeline_automation: premiumPlans,
  job_opening_user_rights: [
    'business-exp-2023',
    'business',
    'unlimited',
    'demo',
    'business-v2-2023',
  ],
  candidate_answer_ai_features: premiumPlans,
};

export const useGetPlanRequired = (
  feature: PlanFeature,
  planPeriod: 'monthly' | 'annual'
): BillingPlan | undefined => {
  const plansConfig = useBillingPlansConfig();
  return useMemo(
    () =>
      [
        ...new Set(
          requiredPlans[feature]
            .filter((planLevel) =>
              plansConfig.plans
                .map((plan) => plan.planLevel)
                .includes(planLevel)
            )
            .sort(
              (planLevelA, planLevelB) =>
                getPlanStrength(getPlanName(planLevelA)) -
                getPlanStrength(getPlanName(planLevelB))
            )
            .map(
              (planLevel) =>
                plansConfig.plans.find((plan) => plan.planLevel === planLevel)
                  ?.planCode[planPeriod]
            )
        ),
      ][0],
    [plansConfig, feature, planPeriod]
  );
};

export const planLevels: { [K in BillingPlan]: PlanLevel } = {
  free: 'free',
  'free-2021': 'free-2021',
  'free-exp-2023': 'free-exp-2023',
  'free-basicexperiment-oct22': 'free-basicexperiment-oct22',
  'free-v2-2023': 'free-v2-2023',

  'basic-1m-2021': 'basic',
  'basic-12m-2021': 'basic',
  'basic-12m-exp-2023': 'basic-exp-2023',
  'basic-12m-basicexperiment-oct22': 'basic-basicexperiment-oct22',
  'basic-12m-v2-2023': 'basic-v2-2023',
  'basic-12m-2024': 'basic-2024',

  'premium-1m-2021': 'premium-2021',
  'premium-12m-2021': 'premium-2021',
  'premium-1m-exp-2023': 'premium-exp-2023',
  'premium-12m-exp-2023': 'premium-exp-2023',
  'premium-1m-v2-2023': 'premium-v2-2023',
  'premium-12m-v2-2023': 'premium-v2-2023',
  'premium-12m-60c-2023': 'premium-60c-2023',
  'premium-12m-45c-2024': 'premium-45c-2024',

  'business-1m-2021': 'business',
  'business-12m-2021': 'business',
  'business-1m-exp-2023': 'business-exp-2023',
  'business-12m-exp-2023': 'business-exp-2023',
  'business-1m-v2-2023': 'business-v2-2023',
  'business-12m-v2-2023': 'business-v2-2023',

  'unlimited-1m-2021': 'unlimited',
  'unlimited-12m-2021': 'unlimited',

  'demo-workspace': 'demo',
};

const planLimits: Record<
  PlanLimitedFeature,
  Partial<Record<PlanLevel, number>>
> = {
  job_openings: {
    free: 3,
    'free-2021': 1,
    'free-basicexperiment-oct22': 1,
    standard: 3,
    basic: 3,
    'basic-basicexperiment-oct22': 3,
    'premium-2021': 10,
    business: 30,
  },
  workspace_users: {
    free: 5,
    'free-2021': 5,
    'free-basicexperiment-oct22': 5,
  },
  questions_generation: {
    free: 5,
    'free-2021': 5,
    'free-exp-2023': 5,
    'free-basicexperiment-oct22': 5,
    'free-v2-2023': 5,
    standard: 10,
    basic: 10,
    'basic-exp-2023': 10,
    'basic-basicexperiment-oct22': 10,
    'basic-v2-2023': 10,
    'basic-2024': 10,
    premium: 15,
    'premium-2021': 15,
    'premium-exp-2023': 15,
    'premium-60c-2023': 15,
    'premium-45c-2024': 15,

    'premium-v2-2023': 15,
    business: 20,
    'business-exp-2023': 20,
    'business-v2-2023': 20,
  },
  unlockable_candidates: {
    free: 5,
    'free-2021': 5,
    'free-exp-2023': 5,
    'free-basicexperiment-oct22': 5,
    'free-v2-2023': 5,
    basic: 20,
    'basic-exp-2023': 20,
    'basic-basicexperiment-oct22': 20,
    'basic-v2-2023': 20,
    'basic-2024': 20,
    'premium-exp-2023': 2000,
    'premium-60c-2023': 60,
    'premium-v2-2023': 300,
    'premium-45c-2024': 45,
  },
  unlockable_video_intros: {
    basic: 5,
    'basic-exp-2023': 5,
    'basic-basicexperiment-oct22': 5,
    'basic-v2-2023': 5,
    'basic-2024': 5,
  },
  tests_in_job_opening: {
    free: 5,
    'free-2021': 5,
    'free-basicexperiment-oct22': 5,
    basic: 5,
    'basic-basicexperiment-oct22': 5,
    premium: 5,
    'premium-2021': 5,
  },
};

export const getPlanLimit = (
  plan: BillingPlan,
  feature: PlanLimitedFeature
): number => {
  const planLevel = planLevels[plan];
  return planLimits[feature][planLevel] || Number.MAX_VALUE;
};

export const usePlanLimits = () => {
  const workspaceId = useWorkspaceIdOrNull();
  const workspaceQuery = useWorkspaceQuery({
    workspaceId: workspaceId ?? undefined,
  });

  return useCallback(
    (feature: PlanFeature): boolean | null => {
      if (!workspaceQuery.isSuccess) {
        return null;
      }

      return requiredPlans[feature].includes(
        planLevels[workspaceQuery.data?.subscriptionPlan!]
      );
    },
    [workspaceQuery.isSuccess, workspaceQuery.data?.subscriptionPlan]
  );
};

export const getPlanName = (plan: BillingPlan | PlanLevel): PlanName =>
  plan.split('-')[0] as PlanName;

export const getNextPlanName = (
  currentPlanName: PlanName
): NextPlanName | undefined => {
  switch (currentPlanName) {
    case 'free':
      return 'premium';
    case 'standard':
      return 'premium';
    case 'premium':
      return 'business';
    case 'basic':
      return 'premium';
    case 'business':
      return 'enterprise';
    case 'unlimited':
      return undefined;
    case 'demo':
      return undefined;
  }
};

export const getPlanStrength = (planName: PlanName): number => {
  switch (planName) {
    case 'free':
      return 0;
    case 'standard':
      return 1;
    case 'basic':
      return 1;
    case 'premium':
      return 2;
    case 'business':
      return 3;
    case 'unlimited':
      return 4;
    case 'demo':
      return 4;
  }
};

export const getPlanPeriod = (plan: BillingPlan): 'monthly' | 'annual' => {
  if (/1-month|1m/.test(plan)) {
    return 'monthly';
  } else {
    return 'annual';
  }
};

export const isPaidPlan = (plan: BillingPlan): boolean => {
  return paidPlans.includes(planLevels[plan]);
};

export const useIsPaidPlan = () => {
  const workspaceId = useWorkspaceId();
  const workspaceQuery = useWorkspaceQuery({ workspaceId });

  return useMemo((): boolean | null => {
    if (!workspaceQuery.isSuccess) {
      return null;
    }

    return isPaidPlan(workspaceQuery.data?.subscriptionPlan!);
  }, [workspaceQuery.isSuccess, workspaceQuery.data?.subscriptionPlan]);
};

export const useJobOpeningsLimit = (): boolean | null => {
  const workspaceId = useWorkspaceId();
  const meQuery = useMeQuery();
  const workspaceStatsQuery = useWorkspaceStatsQuery({ workspaceId });

  return useMemo(() => {
    if (!meQuery.isSuccess || !workspaceStatsQuery.isSuccess) {
      return null;
    }

    // cased to non-null, users are not able to see workspaces they are not a part of
    const workspace = meQuery.data.workspaces?.find(
      (workspace) => workspace.id === workspaceId
    )!;

    return (
      workspaceStatsQuery.data.activeJobOpenings <
      getPlanLimit(workspace?.subscriptionPlan, 'job_openings')
    );
  }, [workspaceId, meQuery, workspaceStatsQuery]);
};

export const useWorkspaceUsersAndInvitesLimit = (): boolean | null => {
  const workspaceId = useWorkspaceId();
  const workspaceQuery = useWorkspaceQuery({ workspaceId });
  const workspaceUsersQuery = useWorkspaceUsersQuery();
  const workspaceInvitationsQuery = useWorkspaceInvitationsQuery();

  return useMemo(() => {
    if (
      !workspaceQuery.isSuccess ||
      !workspaceUsersQuery.isSuccess ||
      !workspaceInvitationsQuery.isSuccess
    ) {
      return null;
    }
    const invitationsCount = workspaceInvitationsQuery.data?.length || 0;
    const usersAndInvitationsCount =
      Object.keys(workspaceUsersQuery.data).length + invitationsCount;

    return (
      usersAndInvitationsCount <
      getPlanLimit(workspaceQuery.data?.subscriptionPlan!, 'workspace_users')
    );
  }, [
    workspaceQuery.isSuccess,
    workspaceInvitationsQuery.isSuccess,
    workspaceUsersQuery.isSuccess,
    workspaceQuery.data,
    workspaceInvitationsQuery.data,
    workspaceUsersQuery.data,
  ]);
};

export const useCanShareTest = () => {
  const canAccessFeature = usePlanLimits();

  return useCallback(
    (test?: Test | ITest | null): boolean => {
      if (!test) {
        return false;
      }
      const hardTestCondition =
        test.experienceLevel === 'senior' &&
        !canAccessFeature('share_hard_test');
      const premiumTestCondition =
        test.isPremiumTemplate &&
        !canAccessFeature('share_premium_template_test');
      const customSkillsTestCondition =
        (isLegacyReduxTest(test)
          ? test.smart === false
          : test.isSmart === false) &&
        test.type === 'quiz' &&
        !canAccessFeature('share_custom_test');

      return !(
        hardTestCondition ||
        premiumTestCondition ||
        customSkillsTestCondition
      );
    },
    [canAccessFeature]
  );
};

export const useCanShareJobOpening = (jobOpening?: IJobOpening) => {
  const canShareTest = useCanShareTest();
  const { pipelineTests: tests } = usePipelineTests();

  return useMemo(() => {
    if (jobOpening) {
      const nonShareableTests = tests.filter(
        (test) => test && !canShareTest(test)
      );
      return nonShareableTests.length === 0;
    } else {
      return false;
    }
  }, [jobOpening, tests, canShareTest]);
};

export const useCanShareDashboardJobOpening = (
  dashboardJobOpening: IDashboardJobOpening | null
) => {
  const canAccessFeature = usePlanLimits();

  return useMemo(() => {
    if (dashboardJobOpening) {
      return !(
        (!dashboardJobOpening.isSmart &&
          !canAccessFeature('share_custom_test')) ||
        (dashboardJobOpening.isPremiumTemplate &&
          !canAccessFeature('share_premium_template_test'))
      );
    } else {
      return false;
    }
  }, [dashboardJobOpening, canAccessFeature]);
};
