import React from 'react';
import styled from '@emotion/styled';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Icon } from '@/features/common/components/icon';
import { TTestType } from '@/features/test';

interface TestTypeIconProps {
  testType?: TTestType;
  tooltip?: string;
  inverted?: boolean;
}

export function TestTypeIcon({
  testType,
  tooltip,
  inverted,
  ...rest
}: TestTypeIconProps) {
  return (
    <Container
      data-rh={tooltip}
      data-rh-at="right"
      inverted={inverted}
      {...rest}
    >
      {testType === 'quiz' && (
        <StyledIcon icon={solid('ruler')} inverted={inverted} />
      )}
      {testType === 'video' && (
        <StyledIcon icon={solid('video')} inverted={inverted} />
      )}
      {testType === 'homework' && (
        <StyledIcon icon={solid('file-lines')} inverted={inverted} />
      )}
      {!testType && (
        <StyledIcon icon={solid('briefcase-blank')} inverted={inverted} />
      )}
    </Container>
  );
}

const Container = styled.div<{ inverted: boolean | undefined }>`
  align-items: center;
  background-color: ${({ theme, inverted }) =>
    inverted ? 'transparent' : theme.colors.purple[100]};
  border-radius: 8px;
  display: flex;
  height: 16px;
  justify-content: center;
  flex-shrink: 0;
  padding: 2px 8px;
`;

const StyledIcon = styled(Icon, {
  shouldForwardProp: (prop) => !['inverted'].includes(prop),
})<{ inverted: boolean | undefined }>`
  color: ${({ theme, inverted }) =>
    inverted ? theme.colors.purple[100] : theme.colors.white};
  max-height: 10px;
  max-width: 10px;
`;
