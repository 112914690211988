import { MarkdownState, RichTextState } from '@togglhire/text-editor';

export type EditorState = MarkdownState | RichTextState;

export const createEditorState = (markdown: string): EditorState =>
  RichTextState.create(markdown);

export const getEditorMarkdown = (state: EditorState): string => {
  const regex = />@(.+?)</g;
  return state.markdown().replace(regex, '>**@$1**<');
};

export const parseTemplateVariables = (html: string): string => {
  const regex = /\[(.*?)\]/g;
  return html.replace(regex, '<span data-template-variable="$1">$1</span>');
};

export const serializeTemplateVariables = (html: string): string => {
  const regex = /<span.*?data-template-variable="(.*?)".*?<\/span>/g;
  return html.replace(regex, '[$1]');
};
