import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import {
  createWorkspaceInvitation,
  deleteWorkspaceInvitation,
  fetchWorkspaceInvitations,
  resendWorkspaceInvitation,
} from '@/features/workspace';
import { useWorkspaceId } from '@/hooks/router';

const WORKSPACE_INVITATIONS_KEY = ['workspace', 'invitations'];

export const useWorkspaceInvitationsQuery = () => {
  const workspaceId = useWorkspaceId();
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(WORKSPACE_INVITATIONS_KEY, {
    onSuccess: () =>
      queryClient.invalidateQueries([
        ...WORKSPACE_INVITATIONS_KEY,
        workspaceId,
      ]),
  });

  return useQuery([...WORKSPACE_INVITATIONS_KEY, workspaceId], () =>
    fetchWorkspaceInvitations({ workspaceId })
  );
};

export const useCreateWorkspaceInvitationMutation = (
  options?: UseMutationOptions
) => {
  return useMutation(createWorkspaceInvitation, {
    mutationKey: WORKSPACE_INVITATIONS_KEY,
    ...(options as any),
  });
};

export const useDeleteWorkspaceInvitationMutation = (
  options?: UseMutationOptions
) => {
  return useMutation(deleteWorkspaceInvitation, {
    mutationKey: WORKSPACE_INVITATIONS_KEY,
    ...(options as any),
  });
};

export const useResendWorkspaceInvitationMutation = (
  options?: UseMutationOptions
) => {
  return useMutation(resendWorkspaceInvitation, {
    mutationKey: WORKSPACE_INVITATIONS_KEY,
    ...(options as any),
  });
};
