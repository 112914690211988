import { useCallback } from 'react';

import { setAuthToken } from '@/api/api';
import { createDemoWorkspace } from '@/api/demo';
import { storeAuthToken } from '@/authentication';

import { useAuthenticationDispatch } from '../providers/authentication.context';

type DemoHook = () => void;

export function useDemo(): DemoHook {
  const dispatch = useAuthenticationDispatch();

  return useCallback(async () => {
    const token = await createDemoWorkspace();

    storeAuthToken(token);
    setAuthToken(token);

    dispatch({ type: 'LOGGED_IN' });
  }, [dispatch]);
}
