import React from 'react';

import { DemoBadge, NavigationMenu, PageHeader, TId } from '@/features/common';
import {
  InterviewIcon,
  InterviewPageContextMenu,
  useInterviewQuery,
} from '@/features/interview';
import {
  JobOpeningArchivedTag,
  JobOpeningPausedTag,
  useJobOpeningQuery,
} from '@/features/job-opening';

interface InterviewPageHeaderProps {
  interviewId: TId;
}

export function InterviewPageHeader({ interviewId }: InterviewPageHeaderProps) {
  const { data: jobOpening } = useJobOpeningQuery();
  const { data: interview } = useInterviewQuery({ interviewId });

  return (
    <PageHeader
      title={
        <>
          {jobOpening?.name}
          <JobOpeningPausedTag pausedAt={jobOpening?.pausedAt} />
          <JobOpeningArchivedTag
            closedAt={jobOpening?.closedAt}
            tooltipPosition="bottom"
          />
          {jobOpening?.isDemo && <DemoBadge />}
        </>
      }
      subtitle={
        <>
          <InterviewIcon />
          {interview?.name}
        </>
      }
      backButton={{
        to: `/openings/${jobOpening?.id}`,
        tooltip: 'Go back to pipeline',
      }}
      actions={<InterviewPageContextMenu interviewId={interviewId} />}
      sticky
    >
      <NavigationMenu>
        <NavigationMenu.Item
          label="Settings"
          to={`/admin/${jobOpening?.workspaceId}/openings/${jobOpening?.id}/interview/${interviewId}/settings`}
        />
      </NavigationMenu>
    </PageHeader>
  );
}
