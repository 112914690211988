import React from 'react';
import styled from '@emotion/styled';
import { DSButton, DSModal } from '@hundred5/design-system';

import { BillingPlan } from '@/features/billing';
import { useWorkspaceQuery } from '@/features/workspace';
import { getNextPlanName, getPlanLimit, getPlanName } from '@/hooks/planLimits';
import { useHistory, useWorkspaceIdOrNull } from '@/hooks/router';
import { useUpsellModal } from '@/hooks/upsellModal';
import { UpsellModalOptions, UpsellModalType } from '@/types/upsellModal';

import { PremiumUpsellIcon } from './premium-upsell-icon';

export function UpsellModal() {
  const { isOpen, type, options, close } = useUpsellModal();
  const history = useHistory();
  const workspaceId = useWorkspaceIdOrNull();
  const workspaceQuery = useWorkspaceQuery({
    workspaceId: workspaceId ?? undefined,
  });

  return (
    <DSModal
      fluid
      open={isOpen}
      onClose={() => close()}
      contentStyle={{ padding: 0 }}
      layer={2}
    >
      <Container>
        <DSModal.CloseButton onClick={() => close()} fixed small />
        <Icon />
        <Title>{getHeader(type)}</Title>
        <Description>
          {getText(type, options, workspaceQuery.data?.subscriptionPlan)}
        </Description>
        <DSButton
          onClick={() => {
            options?.onBeforeUpgrade?.();
            options?.onUpgrade?.() ||
              history.push(`/admin/${workspaceId}/settings/billing`);
            options?.onAfterUpgrade?.();
            close();
          }}
        >
          {getLabel(type)}
        </DSButton>
      </Container>
    </DSModal>
  );
}

const getHeader = (type: UpsellModalType | null): string => {
  switch (type) {
    case 'job_openings_limit_reached':
    case 'question_generation_limit':
    case 'tests_in_job_opening_limit_reached':
    case 'addable_candidates_limit_reached':
    case 'unlock_candidates_limit_reached':
    case 'workspace_users_limit':
      return 'Limit reached';
    case 'paid_feature':
      return 'You discovered a paid feature!';
    case 'premium_feature':
      return 'You discovered a premium feature!';
    case 'business_feature':
      return 'You discovered a business feature!';

    default:
      return '';
  }
};

const getText = (
  type: UpsellModalType | null,
  options: UpsellModalOptions | null,
  plan: BillingPlan | undefined
): string => {
  switch (type) {
    case 'job_openings_limit_reached':
      if (plan) {
        const planLowerCase = getPlanName(plan);
        const planName =
          planLowerCase.charAt(0).toUpperCase() + planLowerCase.slice(1);
        const nextPlanLowerCase = getNextPlanName(planLowerCase) || 'premium';
        const nextPlanName =
          nextPlanLowerCase.charAt(0).toUpperCase() +
          nextPlanLowerCase.slice(1);
        return `${planName} plan is limited to ${getPlanLimit(
          plan,
          'job_openings'
        )} active tests. Upgrading to a ${nextPlanName} plan or closing older tests will unlock more tests.`;
      }
      return 'You have reached the limit for active skills tests on your current plan. Upgrade to create more skills tests.';
    case 'paid_feature':
      return 'Upgrade to unlock it.';
    case 'premium_feature':
      return 'Create assessments tailored for mid and senior level positions with Advanced questions.';
    case 'business_feature':
      return 'Create assessments tailored for mid and senior level positions with Advanced questions';
    case 'question_generation_limit': {
      return `You can't add more ${options?.meta?.skillName} questions as you have reached the limit on current plan. You can upgrade to access more questions.`;
    }
    case 'tests_in_job_opening_limit_reached':
      if (plan) {
        const planLowerCase = getPlanName(plan);
        const planName =
          planLowerCase.charAt(0).toUpperCase() + planLowerCase.slice(1);
        const testsInJobOpeningLimit = getPlanLimit(
          plan,
          'tests_in_job_opening'
        );

        return (
          `${planName} plan is limited to ${testsInJobOpeningLimit} active test${
            testsInJobOpeningLimit === 1 ? '' : 's'
          } in one job opening. ` +
          `Upgrading to a Business plan will allow you to create more tests in one job opening.`
        );
      }
      return (
        'You have reached the limit for active same type tests in one job opening on your current plan.' +
        'Upgrading will allow you to create more same type tests in one job opening.'
      );
    case 'addable_candidates_limit_reached':
      return 'You don’t have any credits left to add another candidate. Upgrade or purchase more credits.';
    case 'unlock_candidates_limit_reached':
      if (plan) {
        const planLowerCase = getPlanName(plan);
        const planName =
          planLowerCase.charAt(0).toUpperCase() + planLowerCase.slice(1);

        return (
          `You have reached the limit for unlocked candidates on the ${planName} plan. ` +
          `Upgrade to unlock all candidates and create more tests ${
            planLowerCase === 'free'
              ? '.'
              : ' or buy additional candidate credits.'
          }`
        );
      }
      return (
        'You have reached the limit for unlocked candidates on your current plan. ' +
        'Upgrade to unlock all candidates and create more tests or buy additional candidate credits.'
      );
    case 'workspace_users_limit':
      return 'You have reached the limit for workspace users on your current plan. Upgrade to add more users.';
    default:
      return '';
  }
};

const getLabel = (type: UpsellModalType | null): string => {
  switch (type) {
    case 'addable_candidates_limit_reached':
      return 'Buy candidate credits ›';
    default:
      return 'Upgrade';
  }
};

const Container = styled.div`
  padding: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 572px;
`;

const Title = styled.div`
  font-family: GT Haptik;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  text-align: center;
  margin-bottom: 16px;
`;

const Description = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: center;
  margin-bottom: 32px;
  color: ${({ theme }) => theme.colors.purple[60]};
`;

const Icon = styled(PremiumUpsellIcon)`
  margin: 32px;
  height: 128px;
  width: 214px;
`;
