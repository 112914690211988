import api from '@/api/api';
import { Id } from '@/types/misc';
import { parseAPIData } from '@/utils/parsers';

import { IWebhookAttributes, IWebhooks } from '../types/workspace-webhooks';

export async function fetchWorkspaceWebhooks(params: {
  workspaceId: Id;
}): Promise<IWebhooks> {
  const response = await api.get(`workspaces/${params.workspaceId}/webhooks`);
  return parseAPIData(response.data);
}

export async function createWorkspaceWebhook(params: {
  workspaceId: Id;
  attributes: IWebhookAttributes;
}): Promise<void> {
  await api.post(
    `workspaces/${params.workspaceId}/webhooks`,
    params.attributes
  );
}

export async function updateWorkspaceWebhook(params: {
  webhookId: Id;
  attributes: IWebhookAttributes;
}): Promise<void> {
  await api.put(`webhooks/${params.webhookId}`, params.attributes);
}

export async function deleteWorkspaceWebhook(params: {
  webhookId: Id;
}): Promise<void> {
  await api.delete(`webhooks/${params.webhookId}`);
}
