import React, { useState } from 'react';
import { DSButton, DSModal } from '@hundred5/design-system';
import { capitalize } from 'lodash';

import { ConfirmModal } from '@/features/common';
import { getPlanName } from '@/hooks/planLimits';

import { ICancelModalStepProps, usePauseSubscriptionModal } from '..';

import {
  CancelModalContentContainer,
  CancelModalFooterButtons,
  CancelModalHeader,
  PauseSubscriptionForm,
} from '.';

export const ConfirmPauseModal = ({
  open,
  setCurrentStep,
}: ICancelModalStepProps) => {
  const {
    currentPlan,
    resumeDate,
    pauseStartDate,
    onSubmitCallback,
    isPausingSubscription,
    pauseDuration,
    setPauseDuration,
  } = usePauseSubscriptionModal();
  const [confirmModalState, setConfirmModalState] = useState<boolean>(false);

  return (
    <>
      <DSModal
        fluid
        open={open}
        contentStyle={{ width: '640px' }}
        onClose={() => setCurrentStep(undefined)}
      >
        <CancelModalHeader>
          You’re about to pause your subscription
          <DSModal.CloseButton
            fixed
            small
            onClick={() => setCurrentStep(undefined)}
          />
        </CancelModalHeader>
        <DSModal.Separator />
        <CancelModalContentContainer>
          <strong>Pause my subscription for:</strong>
          <PauseSubscriptionForm
            pauseDuration={pauseDuration}
            setPauseDuration={setPauseDuration}
          />
          Your subscription will be paused from {pauseStartDate} and will
          automatically resume on {resumeDate} on{' '}
          {currentPlan && capitalize(getPlanName(currentPlan))}.
          <DSModal.Footer>
            <CancelModalFooterButtons>
              <DSButton
                variant="secondary"
                type="button"
                onClick={() => setCurrentStep(undefined)}
              >
                Stay on current plan
              </DSButton>

              <DSButton
                variant="primary"
                type="button"
                disabled={isPausingSubscription}
                onClick={() => setConfirmModalState(true)}
              >
                Pause subscription
              </DSButton>
            </CancelModalFooterButtons>
          </DSModal.Footer>
        </CancelModalContentContainer>
      </DSModal>
      <ConfirmModal
        open={confirmModalState}
        onClose={() => setConfirmModalState(false)}
        onConfirm={() =>
          onSubmitCallback().then(() => {
            setCurrentStep(undefined);
          })
        }
        action="Confirm"
        confirmation="Pause"
      >
        <h3>Are you sure?</h3>
      </ConfirmModal>
    </>
  );
};
