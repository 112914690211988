import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';

import { TId } from '@/features/common';

import { fetchQuestions, sortQuestions } from '../api';

export const LEGACY_FETCH_QUESTIONS_QUERY_KEY = ['legacy-questions'];
export const FETCH_QUESTIONS_QUERY_KEY = ['questions'];

export const useQuestionsQuery = (
  params: {
    testId: TId;
  },
  options?: UseQueryOptions
) =>
  useQuery(
    [...FETCH_QUESTIONS_QUERY_KEY, params.testId],
    () => fetchQuestions(params),
    {
      enabled: !!params.testId,
      ...(options as any),
    }
  );

export const useSortQuestionsMutation = (params: { testId: TId }) => {
  const queryClient = useQueryClient();
  return useMutation(
    (questionIds: TId[]) =>
      sortQuestions({ testId: params.testId, questionIds }),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([
          ...FETCH_QUESTIONS_QUERY_KEY,
          params.testId,
        ]),
    }
  );
};
