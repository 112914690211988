import styled from '@emotion/styled';
import { togglTheme } from '@hundred5/design-system';

export const InlineButton = styled.button`
  background: none;
  border: none;
  color: ${togglTheme.colors.purple[100]};
  cursor: pointer;
  font-size: inherit;
  padding: 0;
  text-decoration: underline;
`;
