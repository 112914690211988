import { Mutation, Query } from '@tanstack/react-query';

import { useApiError } from '@/hooks/api';
import { queryClient } from '@/utils/reactQuery';

export function useQueryClientWithAutoErrorHandling() {
  const handleApiError = useApiError();

  const defaultQueryErrorHandler = (error: unknown, query: Query) => {
    if (!query.options?.meta?.skipDefaultErrorHandler) {
      handleApiError(error);
    }
  };

  const defaultMutationErrorHandler = (
    error: unknown,
    variables: unknown,
    context: unknown,
    mutation: Mutation
  ) => {
    if (!mutation.options?.meta?.skipDefaultErrorHandler) {
      handleApiError(error);
    }
  };

  queryClient.getQueryCache().config.onError = defaultQueryErrorHandler;
  queryClient.getMutationCache().config.onError = defaultMutationErrorHandler;
}
