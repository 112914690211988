import * as React from 'react';
import styled from '@emotion/styled';
import {
  DSColumn,
  DSRow,
  IColumnProps,
  IRowProps,
} from '@hundred5/design-system';

interface JobOpeningPageContentBlockProps extends IRowProps, IColumnProps {
  center?: boolean;
  title?: string | null;
  footer?: React.ReactNode;
  removeFooterBorder?: boolean;
  children: React.ReactNode;
  className?: string;
}

export const JobOpeningPageContentBlock = ({
  title,
  center,
  footer,
  removeFooterBorder,
  children,
  className,
  ...columnProps
}: JobOpeningPageContentBlockProps) => {
  return (
    <Wrapper>
      <BlockRow centered={center}>
        <BlockColumn {...columnProps}>
          <WhiteBox className={className}>
            {title ? <Title>{title}</Title> : null}

            <Content>{children}</Content>

            {footer ? (
              <Footer removeBorder={removeFooterBorder}>{footer}</Footer>
            ) : null}
          </WhiteBox>
        </BlockColumn>
      </BlockRow>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-bottom: 20px;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }
`;

const BlockRow = styled(DSRow)`
  @media all and (max-width: 992px) {
    flex-wrap: wrap-reverse;
  }
`;

const BlockColumn = styled(DSColumn)`
  position: relative;
  display: flex;

  @media all and (max-width: 992px) {
    min-width: 100%;
  }
`;

const WhiteBox = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  width: 100%;
`;

const Content = styled.div`
  padding: 36px;

  @media all and (max-width: 768px) {
    padding: 16px;
  }
`;

const Title = styled.h2`
  padding: 0 36px 12px;
  margin: 0;
  height: 60px;
  border-bottom: 1px solid ${(props) => props.theme.colors.purple[10]};
  display: flex;
  align-items: flex-end;
`;

const Footer = styled.div<{ removeBorder?: boolean }>`
  padding: 0 36px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: ${(props) =>
    props.removeBorder ? 'none' : `1px solid ${props.theme.colors.purple[10]}`};

  & button {
    margin-left: 12px;
  }

  @media all and (max-width: 768px) {
    padding: 16px;

    & button {
      flex: 1;
    }
  }
`;
