import React from 'react';
import styled from '@emotion/styled';
import { DSButton, DSModal } from '@hundred5/design-system';
import { useMedia } from 'react-use';

import { Icon } from '@/features/common';

import { useGenericPricingUpsellModalContext } from '../../hooks';

import { UPSELL_MODAL_COPIES } from './generic-pricing-upsell-modal.utils';

export const GenericPricingUpsellModal = () => {
  const { close, isOpen, type } = useGenericPricingUpsellModalContext();
  const isMobile = useMedia('(max-width: 768px)');

  // TODO: use data from /me when available, these are just mocks
  // type planToShow = 'trial' | 'starter' | 'premium';
  const p = 's';
  const plan = p === 's' ? 'trial' : p === 't' ? 'starter' : 'premium';

  return (
    <DSModal
      // TODO: leave only isOpen
      open={false || isOpen}
      onClose={close}
      contentStyle={{
        height: '464px',
        width: isMobile ? '100%' : '800px',
      }}
    >
      <CloseButtonContainer>
        <DSModal.CloseButton small onClick={close} />
      </CloseButtonContainer>
      <Container>
        <div>
          <ModalHeader>{UPSELL_MODAL_COPIES[type][plan].title}</ModalHeader>

          <ModalDescription>
            {UPSELL_MODAL_COPIES[type][plan].description}
          </ModalDescription>
          <ModalListTitle>
            {UPSELL_MODAL_COPIES[type][plan].listTitle}
          </ModalListTitle>

          <ModalListContainer>
            <>
              {UPSELL_MODAL_COPIES[type][plan].list.map((item) => (
                <ListItem key={item.text}>
                  {item.icon ? <FaIcon icon={item.icon} /> : item.customIcon}{' '}
                  {item.text}
                </ListItem>
              ))}
            </>
          </ModalListContainer>

          <ModalButtonContainer>
            {plan === 'trial' && (
              <ModalTrialButtonContainer>
                <DSButton variant="primary-purple">Start 14 day trial</DSButton>
                <NoCreditCard>No credit card required</NoCreditCard>
              </ModalTrialButtonContainer>
            )}

            {plan === 'premium' && (
              <DSButton variant="secondary">Book a demo</DSButton>
            )}
            {(plan === 'starter' || plan === 'premium') && (
              <DSButton variant="primary-purple">Upgrade</DSButton>
            )}
          </ModalButtonContainer>
        </div>
        {UPSELL_MODAL_COPIES[type].illustration()}
      </Container>
    </DSModal>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const CloseButtonContainer = styled.div`
  position: absolute;
  top: 6px;
  right: 6px;
`;

const ModalHeader = styled.div`
  color: ${({ theme }) => theme.colors.purple[100]};
  font-family: GT Haptik;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  text-align: left;
  margin-bottom: 8px;
`;

const ModalDescription = styled.div`
  color: ${({ theme }) => theme.colors.purple[60]};
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  max-width: 431px;
  margin-bottom: 16px;
`;

const ModalListTitle = styled.div`
  color: ${({ theme }) => theme.colors.purple[100]};
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  text-align: left;
`;

const ModalListContainer = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: ${({ theme }) => theme.colors.purple[100]};
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 12px;
  margin-bottom: 36px;
`;

const ModalButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const ModalTrialButtonContainer = styled.div`
  width: 159px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ListItem = styled.span`
  gap: 4px;
`;

const NoCreditCard = styled.span`
  color: ${({ theme }) => theme.colors.purple[60]};
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  margin-top: 8px;
`;

const FaIcon = styled(Icon)`
  width: 14px;
  height: 14px;
`;
