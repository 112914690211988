import React from 'react';
import styled from '@emotion/styled';
import { DSButton, DSModal } from '@hundred5/design-system';

import {
  useCloseAccountMutation,
  useLogout,
  useTogglAccountsMeQuery,
} from '@/features/toggl-accounts';

//region Main
export const CloseAccountModal = ({ open, onClose }) => {
  const { data: togglAccountsMeData } = useTogglAccountsMeQuery();
  const logout = useLogout();
  const closeAccountMutation = useCloseAccountMutation();
  const hasOnlyHireAccount =
    !togglAccountsMeData?.trackUser &&
    !togglAccountsMeData?.planUser &&
    !togglAccountsMeData?.workUser;

  const handleOnCloseAccount = () => {
    closeAccountMutation.mutate(undefined, {
      onSuccess: () => {
        logout();
      },
    });
  };

  return (
    <DSModal
      fluid
      open={open}
      onClose={() => onClose()}
      contentStyle={{ maxWidth: '640px' }}
    >
      <Header>
        <DSModal.CloseButton onClick={() => onClose()} fixed small />
        <Title>Close account</Title>
        <DSModal.Separator />
      </Header>
      <Content>
        <span>
          All data associated with this Toggl Hire account will be permanently
          deleted. <strong>This action cannot be reversed.</strong>
        </span>
        <span>
          {!hasOnlyHireAccount &&
            `Your accounts in other Toggl tools that are associated with this email will remain active. `}
          <ReadMoreLink href="https://support.hire.toggl.com/en/articles/8074188-how-can-i-delete-my-account">
            Read this article for more information.
          </ReadMoreLink>
        </span>
      </Content>
      <Footer>
        <DSButton variant="secondary" onClick={onClose}>
          Cancel
        </DSButton>
        <DSButton
          variant="destructive-primary"
          onClick={() => handleOnCloseAccount()}
        >
          Close Toggl Hire account
        </DSButton>
      </Footer>
    </DSModal>
  );
};
//endregion

export const Header = styled.header`
  margin-bottom: 16px;

  @media all and (max-width: 768px) {
    padding-top: 8px;
  }
`;

export const Content = styled.div`
  color: ${(props) => props.theme.typography.colorPrimary};
  font-size: 14px;
  padding-bottom: 36px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Title = styled.h3`
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  padding-bottom: 16px;
  color: ${(props) => props.theme.typography.colorPrimary};
`;

const ReadMoreLink = styled.a`
  text-decoration: underline;
  color: ${(props) => props.theme.typography.colorPrimary};
  cursor: pointer;
`;

const Footer = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
`;
