import { addDays } from 'date-fns/addDays';
import { decamelizeKeys } from 'humps';
import qs from 'qs';

import api from '@/api/api';
import { TId } from '@/features/common';
import { parseAPIData } from '@/utils/parsers';

import { TYPES } from '../.';
import { IInboxItem, IInboxSearchParams, TInboxItemType } from '../types';

interface IInboxItemsList {
  items: IInboxItem[];
  total: number;
}

const typesMap: Record<(typeof TYPES)[number], TInboxItemType[]> = {
  note: ['candidate_note_mention', 'answer_note_mention'],
  candidate: ['candidate_notification'],
};

const serializeSearchParams = (searchParams: IInboxSearchParams): any => {
  return {
    search: searchParams.q,
    limit: searchParams.itemsPerPage,
    offset: (
      parseInt(searchParams.page) * parseInt(searchParams.itemsPerPage) -
      parseInt(searchParams.itemsPerPage)
    ).toString(),
    types:
      searchParams.types &&
      searchParams.types.reduce(
        (acc: TInboxItemType[], cur) => acc.push(...typesMap[cur]) && acc,
        []
      ),
    fromDate:
      searchParams.date &&
      new Date(searchParams.date.split('--')[0]).toISOString(),
    toDate:
      searchParams.date &&
      addDays(new Date(searchParams.date.split('--')[1]), 1).toISOString(),
  };
};

export async function fetchInboxItems(
  workspaceId: TId,
  searchParams: IInboxSearchParams
): Promise<IInboxItemsList> {
  const queryString = qs
    .stringify(
      decamelizeKeys(serializeSearchParams(searchParams), { separator: '-' }),
      {
        encode: false,
        arrayFormat: 'comma',
      }
    )
    .replace('[]', '');

  const response = await api.get(`/inbox/${workspaceId}?${queryString}`);

  return parseAPIData(response.data);
}

export async function fetchInboxTotalUnread(workspaceId: TId): Promise<number> {
  const response = await api.get(`/inbox/${workspaceId}/unseen`);

  return parseAPIData(response.data);
}

export async function markInboxItemsSeen(params?: {
  itemsIds: TId[];
}): Promise<void> {
  const queryString = params
    ? qs
        .stringify(
          decamelizeKeys({ bulk: params.itemsIds }, { separator: '-' }),
          {
            encode: false,
            arrayFormat: 'comma',
          }
        )
        .replace('[]', '')
    : '';

  await api.post(`/inbox/mark-seen${queryString ? `?${queryString}` : ''}`);
}
