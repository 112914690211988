export function getSplitConfig(
  trafficType: 'anonymous' | 'workspace',
  key: string
): SplitIO.IBrowserSettings {
  return {
    core: {
      authorizationKey: import.meta.env.VITE_SPLIT_API_KEY || 'localhost',
      trafficType,
      key,
    },
    startup: {
      readyTimeout: 1,
    },
    storage: {
      type: 'LOCALSTORAGE',
    },
  };
}
