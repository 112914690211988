import { replaceTemplateTags } from '@/features/common';
import { TScreenType } from '@/features/test';

function formatScore(score: number) {
  return `${score.toFixed(0)}%`;
}

function getSevenDaysFromStarting(startedAt: number): string {
  const date = new Date(startedAt);
  date.setDate(date.getDate() + 7);
  return date.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
  });
}

export function replaceScreenTemplateTags(
  screenContent: string,
  screenType: TScreenType
) {
  const score = formatScore(screenType === TScreenType.NotPassed ? 25 : 85);
  const sevenDaysFromStarting = getSevenDaysFromStarting(Date.now());

  return replaceTemplateTags(screenContent, {
    score: score,
    CandidateScore: score,
    TestScore: score,
    '7DaysFromApplying': sevenDaysFromStarting,
  });
}
