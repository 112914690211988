import api from '@/api/api';
import { TId } from '@/features/common';
import { IWorkspace } from '@/features/workspace';
import { parseAPIData, serialiseAPIData } from '@/utils/parsers';

export async function createWorkspace(params: {
  name: string;
}): Promise<IWorkspace> {
  const response = await api.post(`workspaces`, serialiseAPIData(params));

  return parseAPIData(response.data);
}

export async function updateWorkspace(params: {
  workspaceId: TId;
  workspace: IWorkspace;
}): Promise<IWorkspace> {
  const response = await api.put(
    `workspaces/${params.workspaceId}`,
    serialiseAPIData(params.workspace)
  );

  return parseAPIData(response.data);
}

export async function deleteWorkspace(params: { workspaceId: TId }) {
  await api.delete(`workspaces/${params.workspaceId}`);
}
