import React from 'react';

interface ContactInfoProps {
  name: string;
  email: string;
}

export function ContactInfo({ name, email }: ContactInfoProps) {
  const emailLink = <a href={`mailto:${email}`}>{email}</a>;

  const guideLink = (
    <a
      href="https://support.hire.toggl.com/en/articles/4373944-candidate-support"
      rel="noreferrer noopener"
      target="_blank"
    >
      Test Guide and FAQ
    </a>
  );

  return (
    <>
      <strong>Questions?</strong> Contact {name} at {emailLink}, read our{' '}
      {guideLink}.
    </>
  );
}
