import React from 'react';

import { TestLibrary, TestLibraryHeader } from '@/features/test-library';

export function TestLibraryPage() {
  return (
    <>
      <TestLibraryHeader />
      <TestLibrary />
    </>
  );
}
