import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';

import {
  deleteGmailEmail,
  fetchCandidateEmails,
  sendGmailEmail,
  sendGmailEmailReply,
  syncGmailEmails,
} from '@/features/candidate';
import { TId } from '@/features/common';
import { sendBulkGmailEmail } from '@/features/email/api/emails';
import { useNotifications } from '@/features/notifications';

export const CANDIDATE_EMAILS_QUERY_KEY = ['candidate', 'emails'];

export const useCandidateEmailQuery = (
  params: {
    candidateId: TId;
    limit: number;
    offset: number;
  },
  options?: UseQueryOptions
) =>
  useQuery(
    [
      ...CANDIDATE_EMAILS_QUERY_KEY,
      params.candidateId,
      params.limit,
      params.offset,
    ],
    () => fetchCandidateEmails(params),
    {
      ...(options as any),
    }
  );

export const useSyncGmailEmailsMutation = (
  params: { candidateId: TId },
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();
  const { addNotification } = useNotifications();

  return useMutation(
    () => syncGmailEmails({ candidateId: params.candidateId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          ...CANDIDATE_EMAILS_QUERY_KEY,
          params.candidateId,
        ]);

        addNotification({ type: 'gmail_synced' });
      },
      ...(options as any),
    }
  );
};

export const useSendGmailEmailMutation = (
  params: { candidateId: TId },
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();

  return useMutation(sendGmailEmail, {
    onSuccess: () => {
      queryClient.invalidateQueries([
        ...CANDIDATE_EMAILS_QUERY_KEY,
        params.candidateId,
      ]);
    },
    ...(options as any),
  });
};

export const useSendBulkGmailEmailMutation = (options?: UseMutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation(sendBulkGmailEmail, {
    onSuccess: (_, variables) => {
      variables.candidateIds.forEach((candidateId) => {
        queryClient.invalidateQueries([
          ...CANDIDATE_EMAILS_QUERY_KEY,
          candidateId,
        ]);
      });
    },
    ...(options as any),
  });
};

export const useSendGmailEmailReplyMutation = (
  params: { candidateId: TId },
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();

  return useMutation(sendGmailEmailReply, {
    onSuccess: () => {
      queryClient.invalidateQueries([
        ...CANDIDATE_EMAILS_QUERY_KEY,
        params.candidateId,
      ]);
    },
    ...(options as any),
  });
};

export const useDeleteGmailEmailMutation = (
  params: { candidateId: TId },
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();
  const { addNotification } = useNotifications();

  return useMutation(deleteGmailEmail, {
    onSuccess: () => {
      queryClient.invalidateQueries([
        ...CANDIDATE_EMAILS_QUERY_KEY,
        params.candidateId,
      ]);

      addNotification({ type: 'gmail_email_deleted' });
    },
    ...(options as any),
  });
};
