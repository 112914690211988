import styled from '@emotion/styled';
import { DSModal } from '@hundred5/design-system';

export const Footer = styled(DSModal.Footer)`
  display: flex;
  gap: 8px;

  @media all and (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;
