import { useEffect } from 'react';

import { useMeQuery } from '@/features/me';
import { useLocation } from '@/hooks/router';

import { identifyContact, trackPageView } from '../utils/hubspot';

export function useHubspotTracking() {
  const location = useLocation();
  const meQuery = useMeQuery();

  useEffect(() => {
    if (meQuery.data?.email) {
      identifyContact({
        email: meQuery.data.email,
        firstname: meQuery.data.name,
      });
    }
  }, [meQuery.data?.email, meQuery.data?.name]);

  useEffect(() => {
    trackPageView(location.pathname, meQuery.data?.email);
  }, [location.pathname, meQuery.data?.email]);
}
