import React from 'react';
import styled from '@emotion/styled';

import { NavigationItem } from './ui';

interface NavigationMenuProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

export function NavigationMenu({ children, ...rest }: NavigationMenuProps) {
  return (
    <NavigationMenuContainer {...rest}>
      <ul>{children}</ul>
    </NavigationMenuContainer>
  );
}

const NavigationMenuContainer = styled.nav`
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    gap: 20px;
  }
`;

NavigationMenu.Item = NavigationItem;
