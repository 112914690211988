import React, { createContext, useCallback, useState } from 'react';

import { TGenericPricingUpsellTypes } from './generic-pricing-upsell-modal.types';

export const GenericPricingUpsellModalContext = createContext<{
  isOpen: boolean;
  open: (type: TGenericPricingUpsellTypes) => void;
  close: () => void;
  type: 'jobs' | 'generic' | 'unlimitedSkillsTests' | 'fullCustomization';
}>({
  isOpen: false,
  open: (type) => {},
  close: () => {},
  type: 'generic',
});

export function GenericPricingUpsellModalContextProvider({ children }) {
  const [genericPricingUpsellModalData, setGenericPricingUpsellModalData] =
    useState<{
      isOpen: boolean;
      type: 'jobs' | 'generic' | 'unlimitedSkillsTests' | 'fullCustomization';
    }>({
      isOpen: false,
      type: 'generic',
    });

  const open = useCallback(
    (typeData) => {
      setGenericPricingUpsellModalData({
        isOpen: true,
        type: typeData,
      });
    },
    [setGenericPricingUpsellModalData]
  );
  const close = useCallback(() => {
    setGenericPricingUpsellModalData({
      isOpen: false,
      type: 'generic',
    });
  }, [setGenericPricingUpsellModalData]);

  return (
    <GenericPricingUpsellModalContext.Provider
      value={{
        isOpen: genericPricingUpsellModalData.isOpen,
        type: genericPricingUpsellModalData.type,
        open,
        close,
      }}
    >
      {children}
    </GenericPricingUpsellModalContext.Provider>
  );
}
