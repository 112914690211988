import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { fetchSkills, IFetchSkillsParams } from '../api';

export const SKILLS_QUERY_KEY = ['skills'];

interface IUseSkillsQuery {
  requestParams?: IFetchSkillsParams;
  queryOptions?: UseQueryOptions;
}

export const useSkillsQuery = ({
  requestParams,
  queryOptions,
}: IUseSkillsQuery) =>
  useQuery(
    [...SKILLS_QUERY_KEY, JSON.stringify(requestParams)],
    () => fetchSkills(requestParams),
    {
      staleTime: 60 * 1000,
      ...(queryOptions as any),
    }
  );
