import React from 'react';
import styled from '@emotion/styled';
import {
  DSButton,
  DSProgressBar,
  DSTestResultPage,
} from '@hundred5/design-system';
import { ScoreChart } from '@togglhire/candidate-components';
import { useFormikContext } from 'formik';
import { useMedia } from 'react-use';

import { TScreenType } from '@/features/test';
import { usePlanLimits } from '@/hooks/planLimits';
import { useUpsellModal } from '@/hooks/upsellModal';

import {
  SCORE_CHART_DISTRIBUTION,
  SCORE_CHART_SKILLS_DISTRIBUTION,
} from '../result-page-settings-form.const';
import { TResultPageSettingsForm } from '../result-page-settings-form.types';

import { PoweredBy } from './powered-by';

interface ChartProps {
  screenType: TScreenType;
  editing: boolean;
}

export function Chart({ screenType, editing }: ChartProps) {
  const isDesktop = useMedia('(min-width: 768px)');
  const canAccessFeature = usePlanLimits();
  const { openUpsellModal } = useUpsellModal();
  const form = useFormikContext<TResultPageSettingsForm>();
  const isEnabled = form.values.scoreChart;

  return (
    <DSTestResultPage.Media
      embedded
      mobileBackgroundColor="white"
      header={
        editing && (
          <ActionButton
            variant={isEnabled ? 'tertiary' : 'secondary'}
            size="xsmall"
            onClick={() => {
              if (canAccessFeature('disable_score_chart')) {
                form.setFieldValue('scoreChart', !isEnabled);
              } else {
                openUpsellModal('premium_feature');
              }
            }}
          >
            {isEnabled ? 'Disable' : 'Enable'} score chart
          </ActionButton>
        )
      }
    >
      {(editing || isEnabled) && (
        <DataContainer disabled={!isEnabled}>
          <ScoreChart
            pinLabel="You're here"
            xAxisLabel="Score:"
            yAxisLabel="Candidates"
            data={SCORE_CHART_DISTRIBUTION}
            selected={screenType === TScreenType.NotPassed ? 2 : 8}
            animated
            color="purple"
            chartColor={isDesktop ? 'white' : 'orange'}
          />

          <ScoreCard>
            {SCORE_CHART_SKILLS_DISTRIBUTION.map(({ skill, score }) => (
              <DSProgressBar
                key={skill}
                label={`${skill} ${score}%`}
                value={score}
                backgroundColor={isDesktop ? 'purple-5' : 'white'}
              />
            ))}
          </ScoreCard>

          <PoweredBy displayOn="desktop" />
        </DataContainer>
      )}
    </DSTestResultPage.Media>
  );
}

const ActionButton = styled(DSButton)`
  margin: 12px 16px;

  @media (min-width: 768px) {
    margin: 24px 36px;
  }
`;

const DataContainer = styled.div<{ disabled: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
  place-self: center stretch;
  align-items: center;
  width: 100%;
  padding-top: 16px;

  @media (min-width: 768px) {
    gap: 24px;
  }
`;

const ScoreCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  background-color: ${(props) => props.theme.colors.purple[5]};
  border-radius: 8px;
  width: 100%;
  max-width: 820px;

  @media (min-width: 768px) {
    background-color: ${(props) => props.theme.colors.white};
    gap: 12px;
  }
`;
