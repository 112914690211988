import React, { useState } from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  DSButton,
  DSCheckbox,
  DSTestResultPage,
} from '@hundred5/design-system';
import { useFormikContext } from 'formik';
import { camelize } from 'humps';

import { FormikInputField, FormikSelectField, Icon } from '@/features/common';
import { usePlanLimits } from '@/hooks/planLimits';
import { useUpsellModal } from '@/hooks/upsellModal';

import { MANDATORY_SYSTEM_CONTACT_FIELDS } from '../result-page-settings-form.const';
import { TResultPageSettingsForm } from '../result-page-settings-form.types';

import { DetailsAddFieldModal, DetailsFieldMenu } from '.';

interface DetailsProps {
  editing: boolean;
}

export function Details({ editing }: DetailsProps) {
  const canAccessFeature = usePlanLimits();
  const { openUpsellModal } = useUpsellModal();
  const form = useFormikContext<TResultPageSettingsForm>();
  const [openAddFieldModal, setOpenAddFieldModal] = useState(false);

  const handleOpenAddFieldModal = () => {
    if (canAccessFeature('disable_contact_info_fields')) {
      setOpenAddFieldModal(true);
    } else {
      openUpsellModal('premium_feature');
    }
  };

  const handleAddField = (name: string) => {
    const newField = {
      label: name,
      enabled: true,
      key: camelize(name) + (form.values.userContactFields.length + 1),
      mandatory: false,
      placeholder: null,
    };
    form.setFieldValue('userContactFields', [
      ...form.values.userContactFields,
      newField,
    ]);
    setOpenAddFieldModal(false);
  };

  const handleUpdateField = (name: string, value: boolean) => {
    if (canAccessFeature('disable_contact_info_fields')) {
      form.setFieldValue(name, value);
    } else {
      openUpsellModal('premium_feature');
    }
  };

  const handleDeleteField = (name: string, index: number) => {
    const newValue = [...form.values[name]];
    newValue.splice(index, 1);
    form.setFieldValue(name, newValue);
  };

  return (
    <DSTestResultPage.DataSection title="Add your details">
      {editing ? (
        <>
          <div>
            {MANDATORY_SYSTEM_CONTACT_FIELDS.map((field) => (
              <DetailsRow key={field.key}>
                <DSCheckbox defaultChecked={field.enabled} disabled />
                {field.label}
                <DetailsFieldMenu
                  canEdit={false}
                  canDelete={false}
                  isMandatory={field.mandatory}
                />
              </DetailsRow>
            ))}

            {form.values.systemContactFields.map((field, index) => (
              <DetailsRow key={field.key}>
                <DSCheckbox
                  checked={field.enabled}
                  onChange={() => {
                    handleUpdateField(
                      `systemContactFields[${index}].enabled`,
                      !field.enabled
                    );
                  }}
                />
                {field.label}
                <DetailsFieldMenu
                  canEdit={true}
                  canDelete={false}
                  isMandatory={field.mandatory}
                  onEdit={(newValue) => {
                    handleUpdateField(
                      `systemContactFields[${index}].mandatory`,
                      newValue
                    );
                  }}
                />
              </DetailsRow>
            ))}

            {form.values.userContactFields.map((field, index) => (
              <DetailsRow key={field.key}>
                <DSCheckbox
                  checked={field.enabled}
                  onChange={() => {
                    handleUpdateField(
                      `userContactFields[${index}].enabled`,
                      !field.enabled
                    );
                  }}
                />
                {field.label}
                <DetailsFieldMenu
                  canEdit={true}
                  canDelete={true}
                  isMandatory={field.mandatory}
                  onEdit={(newValue) => {
                    handleUpdateField(
                      `userContactFields[${index}].mandatory`,
                      newValue
                    );
                  }}
                  onDelete={() => handleDeleteField(`userContactFields`, index)}
                />
              </DetailsRow>
            ))}
          </div>
          <DSButton
            variant="tertiary"
            size="xsmall"
            Icon={<Icon icon={regular('plus')} />}
            onClick={handleOpenAddFieldModal}
          >
            Add
          </DSButton>
          <DetailsAddFieldModal
            open={openAddFieldModal}
            onClose={() => setOpenAddFieldModal(false)}
            onSave={handleAddField}
          />
        </>
      ) : (
        [
          ...MANDATORY_SYSTEM_CONTACT_FIELDS,
          ...form.values.systemContactFields,
          ...form.values.userContactFields,
        ]
          .filter((field) => field.enabled)
          .map((field) => {
            return field.key !== 'country' ? (
              <FormikInputField
                key={field.key}
                name={field.label}
                label={field.label}
                required={field.mandatory}
                placeholder={field.placeholder ?? undefined}
                disabled
              />
            ) : (
              <FormikSelectField
                key={field.key}
                name={field.label}
                label={field.label}
                required={field.mandatory}
                placeholder={field.placeholder ?? undefined}
                items={[]}
                disabled
              />
            );
          })
      )}
    </DSTestResultPage.DataSection>
  );
}

const DetailsRow = styled.div`
  display: grid;
  grid-template-columns: 10% 60% 30%;
  padding: 12px 16px;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.purple[10]};
  transition: background-color 0.2s;

  &:hover {
    background-color: ${(props) => props.theme.hover.onWhite};
  }

  &:last-of-type {
    border-bottom: none;
  }
`;
