import React, { useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { IQuestion } from '@/features/questions';
import { useUpdateQuestion } from '@/features/questions/hooks';
import { CustomQuestionEditor, useTestPage } from '@/features/test';
import { useHistory } from '@/hooks/router';

export const TestEditorQuestionEdit = ({ question, index }) => {
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const { test } = useTestPage();

  const goBack = useCallback(() => {
    history.replace(url.replace(/\/edit$/, ''));
  }, [history, url]);

  const { updateQuestion } = useUpdateQuestion(test.id);

  const handleSave = useCallback(
    async ({ skill, ...updatedQuestion }: IQuestion) => {
      await updateQuestion(
        {
          ...updatedQuestion,
          skillId: skill?.id ?? '',
          source: 'custom',
        },
        (newQuestion: IQuestion) => {
          history.replace(
            path.replace('/:questionId(\\d+)/edit', `/${newQuestion.id}`)
          );
        }
      );
    },
    [history, path, updateQuestion]
  );

  return (
    <CustomQuestionEditor
      index={index}
      question={question}
      onCancel={goBack}
      onSave={handleSave}
    />
  );
};
