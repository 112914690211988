import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import { TId } from '@/features/common';

import { useCandidateQuery } from '../../queries';

interface CandidateRedirectParams {
  candidateId: TId;
  jobOpeningId: TId;
  workspaceId: TId;
  testId?: TId;
  tab?: 'test' | 'notes' | 'history' | 'application';
  tabItemId?: TId;
  noteId?: TId;
}

type CandidateRedirectProps = RouteComponentProps<CandidateRedirectParams>;

/**
 * This component doesn't render any UI, it's used to redirect user to the candidate card in correct pipeline stage.
 * It can be used whenever figuring out the categoryID is not possible.
 * It's also used in a public canonical URL of candidate, `/redirect/candidate/{workspaceID}/{jobOpeningID}/{candidateID}`, used by our customer and us internally.
 */
export function CandidateRedirect({
  match: {
    params: {
      candidateId,
      jobOpeningId,
      workspaceId,
      tab,
      tabItemId,
      noteId,
      testId,
    },
  },
}: CandidateRedirectProps) {
  const candidateQuery = useCandidateQuery({ candidateId });

  if (candidateQuery.isLoading || candidateQuery.isFetching) {
    return null;
  }

  if (candidateQuery.isError) {
    return (
      <Redirect
        to={`/admin/${workspaceId}/openings/${jobOpeningId}/pipeline`}
      />
    );
  }

  if (tab) {
    return (
      <Redirect
        to={`/admin/${workspaceId}/openings/${jobOpeningId}/pipeline/stage/${candidateQuery.data.categoryId}/candidate/${candidateId}/${tab}/${tabItemId}/${noteId}`}
      />
    );
  }

  if (testId) {
    return (
      <Redirect
        to={`/admin/${workspaceId}/openings/${jobOpeningId}/pipeline/stage/${candidateQuery.data.categoryId}/candidate/${candidateId}/results/${testId}`}
      />
    );
  }

  return (
    <Redirect
      to={`/admin/${workspaceId}/openings/${jobOpeningId}/pipeline/stage/${candidateQuery.data.categoryId}/candidate/${candidateId}/results`}
    />
  );
}
