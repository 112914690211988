import { isLegacyReduxTest } from '@/features/common';
import { ITest } from '@/features/test';
import { Test } from '@/types/h5test';

export const getShareTestUpsellTooltipAttributes = (
  test: Test | ITest,
  position = 'bottom'
) =>
  test.type === 'quiz' &&
  (isLegacyReduxTest(test) ? !test.smart : !test.isSmart)
    ? {
        'data-upsell-tooltip-custom': true,
        'data-upsell-tooltip-custom-at': position,
      }
    : {
        'data-upsell-tooltip': true,
        'data-upsell-tooltip-at': position,
      };

export const getShareJobUpsellTooltipAttributes = (position = 'bottom') => ({
  'data-upsell-tooltip': true,
  'data-upsell-tooltip-at': position,
});
