import styled from '@emotion/styled';
import { togglTheme } from '@hundred5/design-system';

export const Copy = styled.p`
  color: ${togglTheme.colors.purple[80]};
  margin-bottom: 24px;
  margin-top: 0;
  text-align: center;
  width: 480px;

  a {
    color: ${togglTheme.colors.purple[100]};
  }
`;
