import React from 'react';
import styled from '@emotion/styled';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Icon } from '@/features/common';

interface InterviewIconProps extends React.HTMLAttributes<HTMLDivElement> {
  inverted?: boolean;
}

export function InterviewIcon({ inverted, ...rest }: InterviewIconProps) {
  return (
    <Container
      data-rh="Interview"
      data-rh-at="right"
      inverted={inverted}
      {...rest}
    >
      <StyledIcon icon={solid('comment')} inverted={inverted} />
    </Container>
  );
}

const Container = styled.div<{ inverted?: boolean }>`
  align-items: center;
  background-color: ${({ theme, inverted }) =>
    inverted ? 'transparent' : theme.colors.purple[100]};
  border-radius: 8px;
  display: flex;
  height: 16px;
  justify-content: center;
  flex-shrink: 0;
  padding: 2px 8px;
`;

const StyledIcon = styled(Icon, {
  shouldForwardProp: (prop) => !['inverted'].includes(prop),
})<{ inverted: boolean | undefined }>`
  color: ${({ theme, inverted }) =>
    inverted ? theme.colors.purple[100] : theme.colors.white};
  max-height: 10px;
  max-width: 10px;
`;
