import { useContext } from 'react';
import { History, Location } from 'history';
import { __RouterContext, matchPath } from 'react-router';

import { Id } from '@/types/misc';

export const useLocation = (): Location => {
  const { location } = useContext(__RouterContext);
  return location;
};

export const useHistory = (): History => {
  const { history } = useContext(__RouterContext);
  return history;
};

export const useWorkspaceId = (): Id => {
  const location = useLocation();

  const match = matchPath<{ workspace: Id }>(location.pathname, {
    path: '/admin/:workspace(\\d+)',
  });

  if (match == null) {
    throw new Error('Missing workspace ID');
  }

  return match.params.workspace;
};

export const useJobOpeningId = (): Id => {
  const location = useLocation();
  let jobOpeningId: Id | undefined;
  const paths = [
    '/admin/:workspace(\\d+)/openings/:opening(\\d+)',
    '/admin/:workspace(\\d+)/test-library/:opening(\\d+)/:categoryId(\\d+)',
  ];

  for (const path of paths) {
    const match = matchPath<{ opening: Id }>(location.pathname, { path });

    if (match && match.params && match.params.opening) {
      jobOpeningId = match.params.opening;
      break;
    }
  }

  if (!jobOpeningId) {
    throw new Error('Missing job opening ID');
  }

  return jobOpeningId;
};

export const useWorkspaceIdOrNull = (): Id | null => {
  try {
    return useWorkspaceId();
  } catch (error) {
    return null;
  }
};

export const useJobOpeningIdOrNull = (): Id | null => {
  try {
    return useJobOpeningId();
  } catch (error) {
    return null;
  }
};

export const usePipelineRecommendedCandidateIdOrNull = (): Id | null => {
  const location = useLocation();

  const match = matchPath<{ candidateId: Id }>(location.pathname, {
    path: '/admin/:workspace(\\d+)/openings/:opening(\\d+)/pipeline/stage/recommended/candidate/:candidateId(\\d+)',
  });

  if (match == null) {
    return null;
  }

  return match.params.candidateId;
};
