import * as React from 'react';
import styled from '@emotion/styled';

interface LightboxProps {
  imageUrl: string;
  description?: string;
}

export function Lightbox({ imageUrl, description }: LightboxProps) {
  return (
    <LightboxContainer>
      <LightboxImage src={imageUrl} alt={description} />
    </LightboxContainer>
  );
}

const LightboxContainer = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
`;

const LightboxImage = styled.img`
  max-width: 100%;
  max-height: 80vh;
`;
