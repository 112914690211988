import React from 'react';
import { DSSelect } from '@hundred5/design-system';

import { Label } from '@/features/common';
import {
  IPipelineCategory,
  usePipelineCategoriesQuery,
} from '@/features/pipeline';

//region Main
interface PipelineStageSelectorProps {
  jobOpeningId: string;
  label?: string;
  onChange: (pipelineCategory: IPipelineCategory) => void;
}

export const PipelineStepSelector = ({
  jobOpeningId,
  label = 'Pipeline step',
  onChange,
}: PipelineStageSelectorProps) => {
  const pipelineCategoriesQuery = usePipelineCategoriesQuery(
    { jobOpeningId: jobOpeningId },
    { enabled: !!jobOpeningId }
  );

  const initializing = pipelineCategoriesQuery.isLoading;
  const pipelineCategories = pipelineCategoriesQuery.data || [];

  return (
    <div>
      <Label>{label.toUpperCase()}</Label>
      <DSSelect
        items={pipelineCategories.map((candidateCategory) => ({
          id: candidateCategory.id,
          label: candidateCategory.name,
          labelText: candidateCategory.name,
          disabled: !candidateCategory.testId,
        }))}
        disabled={!jobOpeningId || !pipelineCategories || initializing}
        placeholder="Select stage"
        onChange={(selected) => {
          if (selected) {
            onChange(
              pipelineCategories.find(
                (pipelineCategory) => pipelineCategory.id === selected.id
              )!
            );
          }
        }}
      />
    </div>
  );
};
//endregion
