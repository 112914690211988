import React from 'react';
import styled from '@emotion/styled';
import { DSColumn, DSField, DSGrid, DSRow } from '@hundred5/design-system';
import { useMedia } from 'react-use';

import { useJobOpeningByIdQuery } from '@/features/job-opening';

import { useShareModal } from '../hooks';

import JobOpeningSelector from './job-opening-selector';
import TestSelector from './test-selector';

//region Main
const Selectors = () => {
  const isMobile = useMedia('(max-width: 768px)');

  const { jobOpeningIdSelected } = useShareModal();
  const { data: jobOpening } = useJobOpeningByIdQuery(
    {
      jobOpeningId: jobOpeningIdSelected ?? '',
    },
    { enabled: !!jobOpeningIdSelected }
  );

  return (
    <Wrapper>
      <DSGrid gap="12px">
        <DSRow gap="16px" smWrap>
          <DSColumn size={12} sm={24}>
            {isMobile && !!jobOpeningIdSelected ? (
              <JobName>{jobOpening?.name}</JobName>
            ) : (
              <DSField
                for={'job-opening-selector'}
                label={'Job'}
                required={true}
              >
                <JobOpeningSelector />
              </DSField>
            )}
          </DSColumn>

          <DSColumn size={12} sm={24}>
            <DSField
              for={'pipeline-selector'}
              label={'Pipeline Stage'}
              required={true}
            >
              <TestSelector />
            </DSField>
          </DSColumn>
        </DSRow>
      </DSGrid>
    </Wrapper>
  );
};
//endregion

//region Components

//endregion

//region Styles
const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const JobName = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
`;

//endregion

export default Selectors;
