import api from '@/api/api';
import { Id } from '@/types/misc';

import { JobOpeningEmailNotifications } from '../types';

export async function fetchJobOpeningEmailNotifications(params: {
  jobOpeningId: Id;
}): Promise<JobOpeningEmailNotifications> {
  const response = await api.get(
    `/openings/${params.jobOpeningId}/notifications`
  );
  return response.data;
}

export async function updateJobOpeningEmailNotifications(params: {
  jobOpeningId: Id;
  settings: JobOpeningEmailNotifications;
}): Promise<JobOpeningEmailNotifications> {
  const response = await api.put(
    `/openings/${params.jobOpeningId}/notifications`,
    params.settings
  );
  return response.data;
}
