import React from 'react';
import styled from '@emotion/styled';

import {
  CustomTextEditor,
  EditorState,
} from '@/features/common/components/custom-text-editor';
import { QuestionNotesContainer } from '@/features/test/components/ui/question-notes-container';

interface QuestionNotesEditorProps {
  notes: EditorState;
  onChange: (state: EditorState) => void;
}

export function QuestionNotesEditor({
  notes,
  onChange,
}: QuestionNotesEditorProps) {
  return (
    <QuestionNotesContainer.Wrapper expanded={true}>
      <Heading>Evaluation notes (optional)</Heading>
      <QuestionNotesContainer.ContentWrapper>
        <QuestionNotesContainer.Content>
          <CustomTextEditor
            placeholder="Write your notes here..."
            state={notes}
            onChange={onChange}
            resizable={false}
          />
        </QuestionNotesContainer.Content>
      </QuestionNotesContainer.ContentWrapper>
    </QuestionNotesContainer.Wrapper>
  );
}

const Heading = styled(QuestionNotesContainer.Heading)`
  background: ${({ theme }) => theme.colors.purple[5]};
`;
