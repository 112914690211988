import qs from 'qs';

import api from '@/api/api';
import { Id } from '@/types/misc';
import { parseAPIData } from '@/utils/parsers';

import { IDashboardJobOpening, IDashboardJobOpenings } from '../types';

export async function fetchDashboardJobOpenings({
  workspaceId,
  filter,
}: {
  workspaceId: Id;
  filter?: 'open' | 'closed';
}): Promise<IDashboardJobOpenings> {
  const queryString = filter
    ? `?${qs.stringify({
        filter,
      })}`
    : '';

  const response = await api.get(
    `/workspaces/${workspaceId}/dashboard${queryString}`
  );
  const data = parseAPIData<IDashboardJobOpenings>(response.data, {
    replacements: [['job_opening_id', 'id']],
  });
  const openings = data.openings?.map((jobOpening) => ({
    ...jobOpening,
    totalCandidates: jobOpening.pipelineStats?.reduce(
      (acc, stat) => acc + stat.total,
      0
    ),
  })) as IDashboardJobOpening[];
  return { openings };
}
