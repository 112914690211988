import { IMe } from '../../me';

import { appId } from './app-id';

export const bootIntercom = (me: IMe) => {
  if (!appId) return;

  const createdAt =
    me.createdAt != null
      ? Math.floor(me.createdAt.getTime() / 1000)
      : undefined;

  window.Intercom('boot', {
    app_id: appId,
    user_id: me.id,
    email: me.email,
    name: me.name,
    created_at: createdAt,
    user_hash: me.intercomUserHash,
  });
};

export const showIntercom = () => {
  if (!appId) return;
  window.Intercom('show');
};

export const updateIntercom = () => {
  if (!appId) return;
  window.Intercom('update');
};

export const shutdownIntercom = () => {
  if (!appId) return;
  window.Intercom('shutdown');
};
