import React from 'react';
import styled from '@emotion/styled';

interface ISkillGroupProps {
  children: React.ReactNode;
  withHeader?: boolean;
}

export function SkillGroup({ children, withHeader }: ISkillGroupProps) {
  const Wrapper = withHeader ? SkillsWrapperWithHeaders : SkillsWrapper;
  return <Wrapper>{children}</Wrapper>;
}

const SkillsWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const SkillsWrapperWithHeaders = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  & h4 {
    margin-top: 0;
    margin-bottom: 8px;
  }

  & h4:not(:first-of-type) {
    margin-top: 16px;
  }
`;
