import React from 'react';
import { useParams } from 'react-router-dom';
import { useMedia } from 'react-use';

import { PageHeader, TId } from '@/features/common';

export function TestLibraryHeader() {
  const isMobile = useMedia('(max-width: 768px)');
  const { jobOpeningId } = useParams<{
    jobOpeningId?: TId;
  }>();

  const backToPath = !!jobOpeningId ? `/openings/${jobOpeningId}` : `/openings`;
  const backToTooltip = !!jobOpeningId
    ? `Go back to pipeline`
    : `Go back to dashboard`;

  const title = !!jobOpeningId ? 'Add a test' : 'Test Library';

  return (
    <PageHeader
      title={title}
      backButton={{ to: backToPath, tooltip: backToTooltip }}
      sticky={isMobile}
    />
  );
}
