import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { DSIconPlus } from '@hundred5/design-system';

interface Props {
  onClick: () => void;
  disabled?: boolean;
  tooltip?: string;
}

export function AddMoreOptions(props: Props) {
  return (
    <Container
      data-rh={props.tooltip || null}
      onClick={props.disabled ? undefined : props.onClick}
      disabled={props.disabled}
    >
      <IconButton type="button">
        <DSIconPlus variant="accent" size={`${8 / 14}rem`} />
      </IconButton>
      Add more options
    </Container>
  );
}

const Container = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  width: max-content;
  color: ${(props) => props.theme.typography.colorPrimary};

  &:hover button {
    background: ${(props) => props.theme.hover.onWhite};
  }

  &:active button {
    transform: scale(0.96);
  }

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.4;
      cursor: default;
      pointer-events: none;
    `}
`;

const IconButton = styled.button`
  padding: 0;
  width: ${16 / 14}rem;
  height: ${16 / 14}rem;
  border-radius: 4px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  -webkit-appearance: none;
  text-align: center;
  cursor: pointer;
  outline: none;
  border: none;
  background: ${(props) => props.theme.colors.white};
  transition:
    background 0.1s,
    transform 0.1s;
`;
