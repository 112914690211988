import { useMutation } from '@tanstack/react-query';

import { bulkInviteToNewJob } from '@/features/bulk-actions/api';
import { TId } from '@/features/common';

interface BulkInviteToNewJobParams {
  candidateIds: TId[];
  pipelineStageId: number;
  subject: string;
  message: string;
}

export const useBulkInviteToNewJob = () => {
  return useMutation({
    mutationFn: (params: BulkInviteToNewJobParams) =>
      bulkInviteToNewJob(
        params.candidateIds,
        params.pipelineStageId,
        params.subject,
        params.message
      ),
  });
};
