import { useEffect } from 'react';
import { Location } from 'history';
import qs from 'qs';

import { useSaveAuthToken } from '@/features/authentication/hooks/use-save-auth-token';
import { useHistory } from '@/hooks/router';
import { queryClient } from '@/utils/reactQuery';

interface Props {
  location: Location;
}

export function StartSupport(props: Props) {
  const logIn = useSaveAuthToken();
  const history = useHistory();

  const { token } = qs.parse(props.location.search.slice(1)) as {
    token: string;
  };

  useEffect(() => {
    queryClient.clear();
    logIn(token);
    history.replace('/admin');
  }, [history, logIn, token]);

  return null;
}
