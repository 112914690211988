import { orderBy, uniq } from 'lodash';

import { replaceTemplateTags } from '@/features/common';
import { IJobOpening } from '@/features/job-opening';
import { ISkill } from '@/features/skills';
import { ITest } from '@/features/test';
import { IWorkspace } from '@/features/workspace';

function formatTimeLimit(timeLimit: number): string {
  return Math.floor(timeLimit / 60).toFixed(0);
}

export function replaceScreenTemplateTags(
  workspace: IWorkspace,
  jobOpening: IJobOpening,
  test: ITest,
  screenContent: string,
  skills: ISkill[]
) {
  const position = jobOpening.name;
  const company = workspace.name;
  const testName = test.name;
  const timeLimit = formatTimeLimit(test.approximatedDurationInSeconds);
  const questionCount = test.questionCount.toString();

  const dynamicSkillsOrdered = test.dynamicSkills
    ? orderBy(test.dynamicSkills, (skill) => skill.weight, 'desc').map(
        (skill) => String(skill.id)
      )
    : [];
  const staticSkills = test.staticSkills
    ? test.staticSkills.map((skill) => String(skill.id))
    : [];

  const skillNames = uniq(
    dynamicSkillsOrdered
      .concat(staticSkills)
      .map((skillId) => skills.find((skill) => skill.id === skillId)?.name)
  ).join(', ');

  return replaceTemplateTags(screenContent, {
    name: position,
    duration: timeLimit,
    TestName: testName,
    TestTimeLimit: timeLimit,
    TimeLimit: timeLimit,
    Position: position,
    Company: company,
    Number: questionCount,
    Skills: skillNames || 'various topics',
  });
}
