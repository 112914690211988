import React from 'react';
import { DSColumn, DSField, DSInput, DSRow } from '@hundred5/design-system';
import { useFormikContext } from 'formik';

export interface ICandidateFieldsFormValues {
  firstName: string;
  lastName: string;
  phoneNumber: string;
}
export const CandidateFields = () => {
  const { values, setFieldValue } =
    useFormikContext<ICandidateFieldsFormValues>();

  return (
    <>
      <DSRow gap="16px" smWrap>
        <DSColumn size={12} sm={24}>
          <DSField for={'candidate-invite-first-name'} label={'First Name'}>
            <DSInput
              value={values.firstName}
              onChange={(e) => {
                setFieldValue('firstName', e.target.value);
              }}
            />
          </DSField>
        </DSColumn>

        <DSColumn size={12} sm={24}>
          <DSField for={'candidate-invite-last-name'} label={'Last Name'}>
            <DSInput
              value={values.lastName}
              onChange={(e) => {
                setFieldValue('lastName', e.target.value);
              }}
            />
          </DSField>
        </DSColumn>
      </DSRow>
      <DSRow gap="16px" smWrap>
        <DSColumn size={12} sm={24}>
          <DSField for={'candidate-invite-phone'} label={'Phone Number'}>
            <DSInput
              value={values.phoneNumber}
              onChange={(e) => {
                setFieldValue('phoneNumber', e.target.value);
              }}
            />
          </DSField>
        </DSColumn>
        <DSColumn size={12} />
      </DSRow>
    </>
  );
};
