import { decamelizeKeys } from 'humps';
import qs from 'qs';

import api from '@/api/api';
import { TTestType } from '@/features/test';
import { parseAPIData } from '@/utils/parsers';

import { ISkill } from '../types';

export interface IFetchSkillsParams {
  questionTypes?: string[];
  testType?: TTestType;
  includeHidden?: boolean;
}

export async function fetchSkills(
  params: IFetchSkillsParams | undefined = {}
): Promise<ISkill[]> {
  const queryString = qs.stringify(decamelizeKeys(params), {
    arrayFormat: 'comma',
    encode: false,
  });

  const response = await api.get(`/tests/generator/skills?${queryString}`);
  const parsed = parseAPIData(response.data.skills) as ISkill[];

  return parsed.filter((skill) => !!skill.type);
}
