import React from 'react';

export function QuestionMark() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0C3.58125 0 0 3.58125 0 8C0 12.4187 3.58125 16 8 16C12.4187 16 16 12.4187 16 8C16 3.58125 12.4187 0 8 0ZM8 14.5C4.41563 14.5 1.5 11.5841 1.5 8C1.5 4.41594 4.41563 1.5 8 1.5C11.5844 1.5 14.5 4.41594 14.5 8C14.5 11.5841 11.5844 14.5 8 14.5ZM8 10.5C7.4375 10.5 7 10.9375 7 11.5C7 12.0625 7.40938 12.5 8 12.5C8.53438 12.5 9 12.0625 9 11.5C9 10.9375 8.53438 10.5 8 10.5ZM9.03438 4H7.4375C6.21875 4 5.25 4.96875 5.25 6.1875C5.25 6.59375 5.59375 6.9375 6 6.9375C6.40625 6.9375 6.75 6.59375 6.75 6.1875C6.75 5.8125 7.03438 5.5 7.40938 5.5H9.00625C9.40938 5.5 9.75 5.8125 9.75 6.1875C9.75 6.4375 9.625 6.62813 9.40625 6.75313L7.625 7.84375C7.375 8 7.25 8.25 7.25 8.5V9C7.25 9.40625 7.59375 9.75 8 9.75C8.40625 9.75 8.75 9.40625 8.75 9V8.9375L10.1594 8.0625C10.8156 7.65625 11.2219 6.9375 11.2219 6.1875C11.25 4.96875 10.2813 4 9.03438 4Z"
        fill="#ABA0AF"
      />
    </svg>
  );
}
