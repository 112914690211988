import React from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  DSButton,
  DSColumn,
  DSContentBlock,
  DSField,
} from '@hundred5/design-system';
import { Form, Formik } from 'formik';
import { number, object, string } from 'yup';

import {
  DaysInput,
  FormikInputField,
  FormikRadioField,
  Icon,
  PercentageInput,
  PlanTag,
  PromptIfDirty,
} from '@/features/common';
import { EmailTemplatesSelector } from '@/features/email';
import { useNotifications } from '@/features/notifications';
import { useJobOpeningPermission } from '@/features/permissions';
import { useTestPage, useUpdateTestMutation } from '@/features/test';
import { usePlanLimits } from '@/hooks/planLimits';
import { useUpsellModal } from '@/hooks/upsellModal';

import { TestSettingsSection } from '../test-settings-section';

interface Settings {
  autoNextStepThreshold: 'auto' | 'manual';
  autoNextStepThresholdValue: number;
  autoNextStepTemplateId: number | null;
  percentToPass: 'auto' | 'manual';
  percentToPassValue: number;
  autoCandidateReminderDays: 'auto' | 'manual';
  autoCandidateReminderDaysValue: number;
}

const validationSchema = object().shape({
  autoNextStepThreshold: string()
    .oneOf(['auto', 'manual'])
    .required("This can't be blank!"),
  autoNextStepThresholdValue: number()
    .when('autoNextStepThreshold', {
      is: 'manual',
      then: (schema) => schema.required("This can't be blank!"),
      otherwise: (schema) => schema.nullable(),
    })
    .max(100, 'Percentage to invite automatically has to be at most 100%')
    .integer(),
  percentToPass: string()
    .oneOf(['auto', 'manual'])
    .required("This can't be blank!"),
  percentToPassValue: number()
    .when('percentToPass', {
      is: 'manual',
      then: (schema) => schema.required("This can't be blank!"),
      otherwise: (schema) => schema.nullable(),
    })
    .max(100, 'Percentage to pass has to be at most 100%')
    .integer(),
  autoCandidateReminderDays: string()
    .oneOf(['auto', 'manual'])
    .required("This can't be blank!"),
  autoCandidateReminderDaysValue: number()
    .when('autoCandidateReminderDays', {
      is: 'manual',
      then: (schema) => schema.required("This can't be blank!"),
      otherwise: (schema) => schema.nullable(),
    })
    .min(1, 'Auto reminder days must be greater than or equal to 1')
    .integer(),
});

const DEFAULT_PERCENT_TO_PASS: number = 60;
const DEFAULT_AUTO_NEXT_STEP_THRESHOLD: number = 80;
const DEFAULT_AUTO_REMINDER_DAYS: number = 5;

export function SkillsTestSettingsAutomations() {
  const canAccessFeature = usePlanLimits();
  const canUpdate = useJobOpeningPermission()('test', 'update');
  const { addNotification } = useNotifications();
  const { openUpsellModal } = useUpsellModal();
  const { workspace, test } = useTestPage();
  const updateTestMutation = useUpdateTestMutation();

  const initialSettings: Settings = {
    percentToPass: test.percentToPass ? 'manual' : 'auto',
    percentToPassValue: test.percentToPass
      ? +test.percentToPass.toFixed(0)
      : DEFAULT_PERCENT_TO_PASS,
    autoNextStepThreshold: test.autoNextStepThreshold ? 'manual' : 'auto',
    autoNextStepThresholdValue: test.autoNextStepThreshold
      ? +test.autoNextStepThreshold.toFixed(0)
      : DEFAULT_AUTO_NEXT_STEP_THRESHOLD,
    autoNextStepTemplateId: test.autoNextStepTemplateId,
    autoCandidateReminderDays: test.autoCandidateReminderDays
      ? 'manual'
      : 'auto',
    autoCandidateReminderDaysValue:
      test.autoCandidateReminderDays ?? DEFAULT_AUTO_REMINDER_DAYS,
  };

  const handleSubmit = async (values: Settings) => {
    const updatedTest = {
      jobOpeningId: test.jobOpeningId,
      autoNextStepThreshold:
        values.autoNextStepThreshold === 'auto'
          ? null
          : values.autoNextStepThresholdValue,
      autoNextStepTemplateId:
        values.autoNextStepThreshold === 'auto'
          ? null
          : values.autoNextStepTemplateId,
      percentToPass:
        values.percentToPass === 'auto' ? 0 : values.percentToPassValue,
      autoCandidateReminderDays:
        values.autoCandidateReminderDays === 'auto'
          ? null
          : values.autoCandidateReminderDaysValue,
    };

    await updateTestMutation.mutateAsync({
      testId: test.id,
      test: updatedTest,
    });

    addNotification({ type: 'saved' });
  };
  return (
    <Formik<Settings>
      enableReinitialize
      initialValues={initialSettings}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, dirty, isSubmitting, setFieldValue }) => (
        <Form>
          <PromptIfDirty />

          <DSContentBlock>
            <DSContentBlock.Title>Automations</DSContentBlock.Title>

            <DSContentBlock.Content>
              <DSColumn gap="30px">
                <TestSettingsSection>
                  <TestSettingsSection.Header>
                    Set automatic pass rate
                  </TestSettingsSection.Header>

                  <TestSettingsSection.Content>
                    <TestSettingsSection.OptionsWrapper>
                      <FormikRadioField
                        name="percentToPass"
                        value="auto"
                        label="Disabled"
                      />
                      <FormikRadioField
                        name="percentToPass"
                        value="manual"
                        label={
                          <TestSettingsSection.LabelWrapper>
                            <PercentageInput>
                              <NumberInputField
                                type="number"
                                name="percentToPassValue"
                                min={1}
                                max={100}
                                disabled={values.percentToPass === 'auto'}
                                errorAbsolute
                              />
                            </PercentageInput>
                            <Icon
                              icon={regular('circle-info')}
                              color="purple-60"
                              fontSize="12px"
                              data-rh="Setting up a pass threshold will filter out all candidates scoring below your minimum requirement."
                              data-rh-at="right"
                            />
                          </TestSettingsSection.LabelWrapper>
                        }
                        centered
                      />
                    </TestSettingsSection.OptionsWrapper>
                  </TestSettingsSection.Content>
                </TestSettingsSection>

                <TestSettingsSection>
                  <TestSettingsSection.Header>
                    Automatically invite candidates to next stage
                  </TestSettingsSection.Header>

                  <TestSettingsSection.Content>
                    <TestSettingsSection.OptionsWrapper>
                      <FormikRadioField
                        name="autoNextStepThreshold"
                        value="auto"
                        label="Disabled"
                      />
                      <FormikRadioField
                        name="autoNextStepThreshold"
                        value="manual"
                        onChange={() => {
                          if (!canAccessFeature('pipeline_automation')) {
                            openUpsellModal('premium_feature');
                            return;
                          }
                          amplitude?.logEvent(
                            'pipeline automation/enabled auto invite',
                            {
                              'workspace id': workspace.id,
                            }
                          );
                          setFieldValue('autoNextStepThreshold', 'manual');
                        }}
                        label={
                          <TestSettingsSection.LabelWrapper>
                            <PercentageInput>
                              <NumberInputField
                                type="number"
                                min={values.percentToPassValue}
                                max={100}
                                name="autoNextStepThresholdValue"
                                disabled={
                                  values.autoNextStepThreshold === 'auto'
                                }
                                errorAbsolute
                              />
                            </PercentageInput>
                            <Icon
                              icon={regular('circle-info')}
                              color="purple-60"
                              fontSize="12px"
                              data-rh="If another test is set up, all candidates above set percentage will be automatically invited. Otherwise, they'll be moved to the next step."
                              data-rh-at="right"
                            />
                            <StyledPlanTag />
                          </TestSettingsSection.LabelWrapper>
                        }
                        centered
                      />
                    </TestSettingsSection.OptionsWrapper>

                    <EmailTemplateField
                      label="Invitation template"
                      for="autoNextStepTemplateId"
                      disabled={values.autoNextStepThreshold === 'auto'}
                    >
                      <EmailTemplatesSelector
                        currentEmailTemplateId={
                          values.autoNextStepTemplateId
                            ? values.autoNextStepTemplateId.toString()
                            : ''
                        }
                        onChange={(value) =>
                          setFieldValue('autoNextStepTemplateId', value)
                        }
                        disabled={values.autoNextStepThreshold === 'auto'}
                        alwaysShowPlaceholder={false}
                        dropdownPosition={{ left: '0' }}
                        workspaceTemplatesOnly={true}
                        addSystemTemplatePlaceholder={true}
                      />
                    </EmailTemplateField>
                  </TestSettingsSection.Content>
                </TestSettingsSection>

                <TestSettingsSection>
                  <TestSettingsSection.Header>
                    Send test reminders to candidates after a set time
                  </TestSettingsSection.Header>

                  <TestSettingsSection.Content>
                    <TestSettingsSection.OptionsWrapper>
                      <FormikRadioField
                        name="autoCandidateReminderDays"
                        value="auto"
                        label="Disabled"
                      />
                      <FormikRadioField
                        name="autoCandidateReminderDays"
                        value="manual"
                        onChange={() => {
                          if (!canAccessFeature('pipeline_automation')) {
                            openUpsellModal('premium_feature');
                            return;
                          }
                          amplitude?.logEvent(
                            'pipeline automation/enabled candidate email reminder',
                            {
                              'workspace id': workspace.id,
                            }
                          );
                          setFieldValue('autoCandidateReminderDays', 'manual');
                        }}
                        label={
                          <TestSettingsSection.LabelWrapper>
                            <DaysInput
                              days={values.autoCandidateReminderDaysValue}
                            >
                              <NumberInputField
                                type="number"
                                min={1}
                                name="autoCandidateReminderDaysValue"
                                disabled={
                                  values.autoCandidateReminderDays === 'auto'
                                }
                                errorAbsolute
                              />
                            </DaysInput>
                            <StyledPlanTag />
                          </TestSettingsSection.LabelWrapper>
                        }
                        centered
                      />
                    </TestSettingsSection.OptionsWrapper>
                  </TestSettingsSection.Content>
                </TestSettingsSection>
              </DSColumn>
            </DSContentBlock.Content>

            <DSContentBlock.Footer>
              <DSButton
                type="submit"
                disabled={!canUpdate || !dirty || isSubmitting}
                data-rh={!canUpdate ? 'Only admins can save changes' : null}
              >
                {isSubmitting ? 'Saving...' : 'Save changes'}
              </DSButton>
            </DSContentBlock.Footer>
          </DSContentBlock>
        </Form>
      )}
    </Formik>
  );
}

const EmailTemplateField = styled(DSField)`
  max-width: 312px;

  & > div > div {
    // Target the dropdown
    max-width: 312px;
  }
`;

const NumberInputField = styled(FormikInputField)`
  width: 116px;
`;

const StyledPlanTag = styled(PlanTag)`
  margin: 0;
`;
