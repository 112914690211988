import { useCallback, useEffect, useState } from 'react';
import { useInterval } from 'react-use';

import { getSelectorFromTarget } from './getSelectorFromTarget';

interface Options {
  limit: number;
  interval: number;
}
//based on: https://help.piwik.pro/support/collecting-data/rage-clicks-error-clicks-dead-clicks-and-mouse-shakes/
export const useDetectRageClicks = (
  onRageClick: (target) => void,
  options: Options
) => {
  const [count, setCount] = useState<number>(0);
  const { interval, limit } = options;

  useInterval(() => {
    return setCount(1);
  }, interval);

  const listener = useCallback(
    (event) => {
      if (count === limit) {
        onRageClick(getSelectorFromTarget(event.target));
        setCount(1);
      }
      setCount((count) => count + 1);
    },
    [count, limit, onRageClick, setCount]
  );
  useEffect(() => {
    document.addEventListener('click', listener);
    return () => document.removeEventListener('click', listener);
  }, [listener]);
};
