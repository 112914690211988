import React from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSSelect } from '@hundred5/design-system';

import { Icon } from '@/features/common';

export const CodeInputAnswerPreview = () => {
  return (
    <CodeInputWrapper>
      <SyntaxContainer>
        <p>Syntax highlighting:</p>
        <DSSelect
          onChange={() => {}}
          items={[{ id: 'plain-text', label: 'Plain Text' }]}
          selectedItemId="plain-text"
          disabled
        />
        <Icon icon={regular('info-circle')} color="purple-60" fontSize="12px" />
      </SyntaxContainer>
      <CodeContainer>
        <LineNumber>1</LineNumber>
        <CodeTextBox />
      </CodeContainer>
    </CodeInputWrapper>
  );
};

const CodeInputWrapper = styled.div`
  display: grid;
  gap: 16px;
`;

const SyntaxContainer = styled.div`
  display: grid;
  grid-template-columns: max-content minmax(min-content, 292px) min-content;
  align-items: center;
  gap: 12px;
`;

const CodeContainer = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
  gap: 24px;
  opacity: 0.4;
`;
const LineNumber = styled.span`
  font-family: monospace;
`;
const CodeTextBox = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.purple[40]};
  height: 20px;
`;
