import styled from '@emotion/styled';

export const Header = styled.header`
  color: ${(props) => props.theme.typography.colorPrimary};
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 16px;

  @media all and (max-width: 768px) {
    padding-top: 8px;
  }
`;
