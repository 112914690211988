import React from 'react';

import { usePopover } from './usePopover';

export interface TriggerProps {
  disabled?: boolean;
  children: ((props) => JSX.Element | JSX.Element[] | null) | React.ReactNode;
}

export const Trigger = ({ disabled, children }: TriggerProps) => {
  const { isOpen, setIsOpen } = usePopover();

  function onClick() {
    setIsOpen((state) => !state);
  }

  return typeof children === 'function' ? (
    children({ isOpen, onClick })
  ) : (
    <div onClick={() => !disabled && setIsOpen((value) => !value)}>
      {children}
    </div>
  );
};
