import { useContext } from 'react';

import { EmailModalContext } from '@/features/email';

export const useEmailModalContext = () => {
  const context = useContext(EmailModalContext);

  if (!context) {
    throw new Error(
      'useEmailModalContext must be used within a EmailModalContextProvider'
    );
  }

  const { isOpen, emailModalData, open, close } = context;

  return { isOpen, emailModal: emailModalData, openEmailModal: open, close };
};
