import React from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import styled from '@emotion/styled';
import { DSButton } from '@hundred5/design-system';
import { capitalize } from 'lodash';

import {
  useWorkspaceQuery,
  useWorkspaceStatsQuery,
} from '@/features/workspace';
import { getPlanName, usePlanLimits } from '@/hooks/planLimits';
import { useHistory } from '@/hooks/router';

type Props = {
  workspaceId: string | undefined;
};

export const TopUpsellBanner = ({ workspaceId }: Props) => {
  const canAccessFeature = usePlanLimits();
  const history = useHistory();
  const workspaceQuery = useWorkspaceQuery({ workspaceId });
  const workspaceStatsQuery = useWorkspaceStatsQuery({
    workspaceId,
  });
  const workspaceStats = workspaceStatsQuery.isSuccess
    ? workspaceStatsQuery.data
    : undefined;

  const onUpgradeClick = () => {
    history.push(`/admin/${workspaceId}/settings/billing`);
    amplitude?.logEvent('upsell/top banner/upgrade', {
      'workspace id': workspaceId,
    });
  };

  if (!workspaceQuery.data) {
    return null;
  }

  return (
    <Container>
      {workspaceStats?.unlockableTestTakersRemaining === 0 ? (
        <>
          {`You’ve hit the limit for candidates on the ${capitalize(
            getPlanName(workspaceQuery.data.subscriptionPlan)
          )} plan.
        Upgrade to unlock more candidates and start hiring today!`}
          <DSButton variant="tertiary" size="xsmall" onClick={onUpgradeClick}>
            Upgrade
          </DSButton>
        </>
      ) : !canAccessFeature('hide_premium_banner') ? (
        <>
          {`You're currently using the ${capitalize(
            getPlanName(workspaceQuery.data.subscriptionPlan)
          )} plan.
            Upgrade to test more candidates and access anti-cheating features.`}
          <DSButton variant="tertiary" size="xsmall" onClick={onUpgradeClick}>
            Upgrade
          </DSButton>
        </>
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.orange[20]};
  font-size: 14px;
  text-align: center;
  gap: 12px;
`;
