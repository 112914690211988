import api from '@/api/api';
import {
  TWorkspaceInvitation,
  TWorkspaceInvitationAttributes,
} from '@/features/workspace';
import { Id } from '@/types/misc';
import { parseAPIData } from '@/utils/parsers';

export async function fetchWorkspaceInvitations(params: {
  workspaceId: Id;
}): Promise<TWorkspaceInvitation[]> {
  const response = await api.get(
    `workspaces/${params.workspaceId}/invitations`
  );
  return parseAPIData(response.data.invitations);
}

export async function createWorkspaceInvitation(params: {
  workspaceId: Id;
  attributes: TWorkspaceInvitationAttributes;
}): Promise<void> {
  await api.post(`workspaces/${params.workspaceId}/invitations`, {
    email: params.attributes.email.trim(),
    role: params.attributes.role.trim(),
  });
}

export async function deleteWorkspaceInvitation(params: {
  workspaceId: Id;
  invitationId: Id;
}): Promise<void> {
  await api.delete(
    `/workspaces/${params.workspaceId}/invitations/${params.invitationId}`
  );
}

export async function resendWorkspaceInvitation(params: {
  workspaceId: Id;
  invitationId: Id;
  attributes: TWorkspaceInvitationAttributes;
}): Promise<void> {
  await deleteWorkspaceInvitation({
    workspaceId: params.workspaceId,
    invitationId: params.invitationId,
  });
  await createWorkspaceInvitation({
    workspaceId: params.workspaceId,
    attributes: params.attributes,
  });
}
