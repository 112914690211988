import React from 'react';

import { TQuestionType } from '@/features/questions';
import { QuestionTypeGroupTitle } from '@/features/test/components/test-editor-question-preview/ui';

export const CUSTOM_QUESTION_TYPE_OPTIONS: {
  id: TQuestionType | string;
  label: React.ReactNode;
  disabled?: boolean;
}[] = [
  {
    id: 'auto-evaluated',
    label: <QuestionTypeGroupTitle>Auto evaluation</QuestionTypeGroupTitle>,
    disabled: true,
  },
  { id: 'single-choice', label: 'Single Choice' },
  { id: 'multiple-choice', label: 'Multiple Choice' },
  { id: 'picture-question', label: 'Picture Choice' },
  { id: 'numeric-input', label: 'Numeric Input' },
  {
    id: 'manually-evaluated',
    label: (
      <QuestionTypeGroupTitle>
        Requires manual evaluation
      </QuestionTypeGroupTitle>
    ),
    disabled: true,
  },
  { id: 'free-text', label: 'Free text' },
  { id: 'code-input', label: 'Coding' },
];
