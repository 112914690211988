import { sortBy } from 'lodash';

import { useSkillsQuery } from '@/features/skills';
import { TTestType } from '@/features/test';

/**
 * Return skills for the given test type, ordered by name
 * @param testType
 */
export const useSkills = (testType: TTestType, includeHidden?: boolean) => {
  const { data: skills } = useSkillsQuery({
    requestParams: { testType, includeHidden },
  });

  return { skills: sortBy(skills, 'name') };
};
