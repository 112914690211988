import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';

import {
  createVideoIntroduction,
  deleteVideoIntroduction,
  TEST_QUERY_KEY,
} from '@/features/test';
import { Test } from '@/types/h5test';

const useInvalidateTestQuery = () => {
  const queryClient = useQueryClient();
  return async (test: Test) => {
    await queryClient.invalidateQueries([...TEST_QUERY_KEY, test.id]);
  };
};

export const useCreateVideoIntroductionMutation = ({
  onSuccess,
  ...options
}: UseMutationOptions) => {
  const invalidateTestQuery = useInvalidateTestQuery();
  return useMutation(createVideoIntroduction, {
    onSuccess: async (data) => {
      await invalidateTestQuery(data.test);
    },
    ...(options as any),
  });
};

export const useDeleteVideoIntroductionMutation = ({
  onSuccess,
  ...options
}: UseMutationOptions) => {
  const invalidateTestQuery = useInvalidateTestQuery();
  return useMutation(deleteVideoIntroduction, {
    onSuccess: async (data) => {
      await invalidateTestQuery(data.test);
    },
    ...(options as any),
  });
};
