import api from '@/api/api';
import { serialiseAPIData } from '@/utils/parsers';

import { IFeedbackChurnSurvey } from '../types';

export async function postFeedbackChurnSurvey(
  params: IFeedbackChurnSurvey
): Promise<void> {
  await api.post(`/survey/churn`, serialiseAPIData(params));
}
