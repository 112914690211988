import styled from '@emotion/styled';

import { pluralize } from '@/features/common';

import { UnitInputWrapper } from './ui';

export const DaysInput = styled(UnitInputWrapper)<{
  days: number;
}>`
  & > div:first-of-type::after {
    content: ${(props) => `"${pluralize(+props.days, 'day')}"`};
  }
`;
