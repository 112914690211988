import { TTestType } from '@/features/test';

export function getTestTypeName(type: TTestType) {
  switch (type) {
    case 'quiz':
      return 'Skills test';
    case 'video':
      return 'Video intro';
    case 'homework':
      return 'Homework';
    default:
      return '';
  }
}
