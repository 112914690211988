import React from 'react';
import styled from '@emotion/styled';

import { Popover, PopoverProps } from '@/features/common';

import { FiltersButton } from '../buttons';

interface IProps {
  children: (props: PopoverProps) => React.ReactNode;
}

export const FiltersSelect = ({ children }: IProps) => {
  return (
    <Popover>
      <Popover.Trigger>
        {({ isOpen, onClick }) => (
          <FiltersButton onClick={onClick} isOpen={isOpen} />
        )}
      </Popover.Trigger>
      <Popover.Content>{children}</Popover.Content>
    </Popover>
  );
};

//region styles
export const Filter = styled.div`
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  flex: 1;
  align-items: center;
  padding: 4px 12px;
  gap: 8px;
  position: relative;
  border-radius: 6px;

  &:hover {
    background: ${({ theme }) => theme.colors.purple[5]};
    cursor: pointer;
  }
`;

FiltersSelect.Filter = Filter;
//endregion
