import React from 'react';
import styled from '@emotion/styled';

interface TestInfoProps {
  questionCount: number;
  pointsTotal: number;
  timeLimit: number;
}

export function TestInfo({
  questionCount,
  pointsTotal,
  timeLimit,
}: TestInfoProps) {
  const hours = Math.floor(timeLimit / 60 / 60);
  const minutes = (timeLimit / 60) % 60;
  const timeLimitString = `${hours !== 0 ? `${hours}h` : ''} ${
    minutes !== 0 ? `${minutes}min` : ''
  }`;

  return (
    <TestInfoContainer>
      <div>Total questions: {questionCount}</div>
      <div>Max test points: {pointsTotal}pt</div>
      <div>Time limit: {timeLimit !== 0 ? timeLimitString : 'Auto'}</div>
    </TestInfoContainer>
  );
}

const TestInfoContainer = styled.div`
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadow.card};
  padding: 12px 24px;
  display: grid;
  gap: 4px;
  div {
    font-size: 14px;
    font-weight: 500;
    color: ${({ theme }) => theme.typography.colorPrimary};
  }
`;
