import React from 'react';
import styled from '@emotion/styled';
import { DSButton } from '@hundred5/design-system';

import { TGmailStatus } from '@/features/me/types';
import { useHistory, useWorkspaceId } from '@/hooks/router';

import { GmailIllustration, GmailIllustrationDisconnected } from '.';

interface ContentLargeProps {
  onClick?: () => void;
  status?: TGmailStatus;
}

const data = {
  disconnected: {
    title: 'Token expired',
    icon: <GmailIllustrationDisconnected width={210} height={124} />,
    description: 'Please reconnect to your Gmail inbox to send emails.',
    buttonText: 'Reconnect',
  },
  default: {
    title: 'Connect your Gmail account',
    icon: <GmailIllustration />,
    description:
      'Connect your email account to send and receive emails in Toggl Hire.',
    buttonText: 'Connect to Gmail',
  },
};

export function ContentLarge({ onClick, status }: ContentLargeProps) {
  const history = useHistory();
  const workspaceId = useWorkspaceId();

  const dataToShow =
    status === 'disconnected' ? data.disconnected : data.default;

  return (
    <Content>
      <Icon>{dataToShow.icon}</Icon>

      <Info>
        <h2>{dataToShow.title}</h2>
        <p>{dataToShow.description}</p>
      </Info>

      <DSButton
        variant="primary-purple"
        onClick={() => {
          history.push(`/admin/${workspaceId}/settings/profile`);
          onClick && onClick();
        }}
      >
        {dataToShow.buttonText}
      </DSButton>
    </Content>
  );
}

const Content = styled.div`
  padding: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`;

const Icon = styled.div`
  display: flex;
  padding: 32px;
`;

const Info = styled.section`
  display: grid;
  gap: 16px;
  text-align: center;

  h2 {
    margin: 0;
    font-family: 'GT Haptik';
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    font-feature-settings:
      'ss04' on,
      'ss02' on;
    color: ${(props) => props.theme.typography.colorPrimary};
  }

  p {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: ${(props) => props.theme.typography.colorSecondary};
  }
`;
