import React, { useRef, useState } from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSButton, DSContextMenu } from '@hundred5/design-system';
import { addDays } from 'date-fns/addDays';
import { format } from 'date-fns/format';

import { Icon } from '@/features/common';

import { useClickOutside } from '../../hooks/use-click-outside';

import { DateAndTimePicker } from './ui/date-and-time-picker';

interface ScheduleMenuProps {
  onScheduleSend: (time: Date) => void;
  closeDateAndTimePickerModal?: boolean;
  disabled?: boolean;
}

export const ScheduleMenu = (props: ScheduleMenuProps) => {
  const [open, setOpen] = useState(false);
  const [dateAndTimePickerOpen, setDateAndTimePickerOpen] = useState(false);
  const refScheduleMenuOpen = useRef<null | HTMLDivElement>(null);

  useClickOutside(refScheduleMenuOpen, () => {
    setOpen(false);
  });

  const tomorrow = addDays(new Date(), 1);
  const nextWeek = addDays(new Date(), 7);

  return (
    <ScheduleMenuContainer ref={refScheduleMenuOpen}>
      <Button
        variant="primary"
        Icon={<Icon icon={regular('clock-three')} color="white" />}
        onClick={() => setOpen(true)}
        disabled={props.disabled}
      />
      <DSContextMenu open={open} position={{ bottom: '66px', right: '36px' }}>
        <DSContextMenu.Title label="Schedule email for later" />
        <DSContextMenu.Item
          label="Tomorrow"
          secondaryLabel={format(tomorrow, 'hh:mm aa')}
          onClick={() => {
            props.onScheduleSend(tomorrow);
            setOpen(false);
          }}
        />
        <DSContextMenu.Item
          label="Next week"
          secondaryLabel={format(nextWeek, "cccc 'at' hh:mm aa")}
          onClick={() => {
            props.onScheduleSend(nextWeek);
            setOpen(false);
          }}
        />
        <DSContextMenu.Delimiter />
        <DSContextMenu.Item
          label="Pick date & time"
          icon={<Icon icon={regular('calendar-day')} color="purple-60" />}
          onClick={() => {
            setDateAndTimePickerOpen(true);
            setOpen(false);
          }}
        />
      </DSContextMenu>
      <DateAndTimePicker
        open={dateAndTimePickerOpen}
        onClose={() => setDateAndTimePickerOpen(false)}
        onScheduleSend={(time) => {
          props.onScheduleSend(time);

          if (props.closeDateAndTimePickerModal) {
            setDateAndTimePickerOpen(false);
          }
        }}
      />
    </ScheduleMenuContainer>
  );
};

const ScheduleMenuContainer = styled.div``;

const Button = styled(DSButton)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;
