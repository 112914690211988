import React from 'react';
import styled from '@emotion/styled';
import { DSIconDelete } from '@hundred5/design-system';

export function DeleteIconButton(
  props: React.ButtonHTMLAttributes<HTMLButtonElement>
) {
  return (
    <IconButton {...props}>
      <DSIconDelete size="12px" variant="primary" />
    </IconButton>
  );
}

const IconButton = styled.button`
  padding: 0;
  width: ${24 / 14}rem;
  height: ${24 / 14}rem;
  border-radius: 8px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  -webkit-appearance: none;
  text-align: center;
  cursor: pointer;
  outline: none;
  border: 1px solid ${(props) => props.theme.colors.purple[40]};
  background: ${(props) => props.theme.colors.white};
  transition:
    border 0.1s,
    background 0.1s,
    transform 0.1s;

  &:hover {
    border: none;
    background: ${(props) => props.theme.hover.onError};
    & svg path {
      fill: ${(props) => props.theme.typography.colorError};
    }
  }

  &:active {
    transform: scale(0.96);
  }

  &:disabled {
    opacity: 0.4;
    cursor: default;
  }
`;
