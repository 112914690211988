import React from 'react';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';

import { useColor } from '@/features/common';

//region Main
interface IconProps extends FontAwesomeIconProps {}

export function Icon({ color = 'inherit', ...props }: IconProps) {
  return <FontAwesomeIcon color={useColor(color, 'purple-100')} {...props} />;
}

//endregion
