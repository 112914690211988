import React, { createContext, useState } from 'react';

export const AddTestToPipelineModalContext = createContext<{
  isOpen: boolean;
  open: (
    onAddTest: (jobOpeningId, pipelineCategoryId) => Promise<void>,
    isCustomTest?: boolean
  ) => void;
  close: () => void;
  onAddTest?: (jobOpeningId, pipelineCategoryId) => Promise<void>;
  isCustomTest?: boolean;
}>({
  isOpen: false,
  open: () => {},
  close: () => {},
  onAddTest: async ({}) => {},
});

export function AddTestToPipelineModalContextProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false);
  const [onAddTest, setOnAddTest] = useState<() => Promise<void>>();
  const [isCustomTest, setIsCustomTest] = useState(false);

  const open = (onAddTestCallback, isCustomTest) => {
    setIsOpen(true);
    setOnAddTest(() => onAddTestCallback);
    setIsCustomTest(!!isCustomTest);
  };
  const close = () => {
    setIsOpen(false);
  };

  return (
    <AddTestToPipelineModalContext.Provider
      value={{ isOpen, open, close, onAddTest, isCustomTest }}
    >
      {children}
    </AddTestToPipelineModalContext.Provider>
  );
}
