import React from 'react';
import styled from '@emotion/styled';
import { DSButton, DSModal } from '@hundred5/design-system';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';

import { FormikInputField } from '@/features/common';

interface AddCustomFieldModalProps {
  open: boolean;
  onClose: () => void;
  onSave: (name: string) => void;
}

const validationSchema = object().shape({
  name: string().required("This can't be blank!"),
});

export function AddCustomFieldModal({
  open,
  onClose,
  onSave,
}: AddCustomFieldModalProps) {
  return (
    <DSModal open={open} onClose={onClose} contentStyle={{ maxWidth: 442 }}>
      <DSModal.CloseButton small fixed onClick={onClose} />
      <Header>Add field</Header>
      <DSModal.Separator />
      <Formik<{ name: string }>
        initialValues={{
          name: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => onSave(values.name)}
      >
        {({ dirty }) => (
          <Form>
            <Content>
              <FormikInputField
                label="Name"
                name="name"
                placeholder="Field name"
                required
                errorAbsolute
              />
            </Content>
            <DSModal.Footer float="right">
              <DSButton type="submit" disabled={!dirty}>
                Add
              </DSButton>
            </DSModal.Footer>
          </Form>
        )}
      </Formik>
    </DSModal>
  );
}

const Header = styled.h2`
  color: ${(props) => props.theme.typography.colorPrimary};
  margin-top: 0;
  margin-bottom: 16px;
`;

const Content = styled.div`
  margin-top: 16px;
  margin-bottom: 24px;
`;
