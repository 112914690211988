import React, { useCallback, useMemo } from 'react';
import styled from '@emotion/styled';

import {
  ISortableItemBase,
  SortableList,
  SortableListItems,
} from '@/features/common';
import { IQuestionOption, TQuestionType } from '@/features/questions';

import { ChoiceOption } from './choice-option';
import { PictureChoiceOption } from './picture-choice-option';

interface ChoiceAnswerEditorProps {
  questionType: TQuestionType;
  options: IQuestionOption[];
  onChange: (value: IQuestionOption[]) => void;
}

const moveOption = (
  options: IQuestionOption[],
  activeIndex: number,
  overIndex: number
) => {
  const newOptions = [...options];
  const activeOption = newOptions[activeIndex];
  newOptions.splice(activeIndex, 1);
  newOptions.splice(overIndex, 0, activeOption);
  return newOptions;
};

interface ISortableOption extends IQuestionOption, ISortableItemBase {
  removable?: boolean;
}

const NEW_OPTION: ISortableOption = {
  id: 'new_option',
  option: '',
  isCorrect: false,
  sortable: false,
  removable: false,
};

const removePlaceholderOption = (options: ISortableOption[]) =>
  options.filter((option) => option.id !== NEW_OPTION.id);

export const ChoiceAnswerEditor = ({
  questionType,
  options,
  onChange,
}: ChoiceAnswerEditorProps) => {
  // Convert the options into the SortableList format
  // Also add a new option placeholder to the end of the list
  const listOptions: ISortableOption[] = useMemo(
    () => [
      ...options.map<ISortableOption>((option, index) => ({
        ...option,
        id: String(index),
        sortable: true,
        removable: true,
      })),
      NEW_OPTION,
    ],
    [options]
  );

  const updateOptions = useCallback(
    (updatedOptions) => {
      onChange(removePlaceholderOption(updatedOptions));
    },
    [onChange]
  );

  const handleOrderChanged = useCallback(
    (activeIndex: number, overIndex: number) => {
      updateOptions(moveOption(listOptions, activeIndex, overIndex));
    },
    [listOptions, updateOptions]
  );

  const handleSingleSelectionChange = useCallback(
    (selectedIndex: number) => {
      const newOptions = listOptions.map(
        (option: ISortableOption, index: number) => ({
          ...option,
          isCorrect: index === selectedIndex,
        })
      );
      updateOptions(newOptions);
    },
    [listOptions, updateOptions]
  );

  const handleMultipleSelectionChange = useCallback(
    (selectedIndex: number, checked: boolean) => {
      const newOptions = listOptions.map(
        (option: ISortableOption, index: number) => ({
          ...option,
          isCorrect: index === selectedIndex ? checked : option.isCorrect,
        })
      );
      updateOptions(newOptions);
    },
    [listOptions, updateOptions]
  );

  const handleOptionCreate = useCallback(
    (index: number, value?: string) => {
      // remove the placeholder option and add the new one
      const newOptions = [
        ...listOptions.filter((option) => option.id !== NEW_OPTION.id),
        {
          id: String(index),
          option: value ? value : '',
          isCorrect: false,
          sortable: true,
          removable: true,
        },
      ];

      updateOptions(newOptions);
    },
    [listOptions, updateOptions]
  );

  const handleOptionChange = useCallback(
    (index: number, value: string) => {
      const newOptions = listOptions.map(
        (option: ISortableOption, localIndex: number) => ({
          ...option,
          option: localIndex === index ? value : option.option,
          sortable: true,
          removable: true,
        })
      );

      updateOptions(newOptions);
    },
    [listOptions, updateOptions]
  );

  const handleRemoveOption = useCallback(
    (index: number) => {
      const newOptions = [...listOptions];
      newOptions.splice(index, 1);
      updateOptions(newOptions);
    },
    [listOptions, updateOptions]
  );

  return (
    <SortableList<ISortableOption>
      items={listOptions}
      Container={questionType === 'picture-question' ? PictureGrid : undefined}
      layout={questionType === 'picture-question' ? 'grid' : 'vertical'}
      renderItem={(option, index) => {
        switch (questionType) {
          case 'single-choice':
          case 'multiple-choice':
            return (
              <ChoiceOption
                key={option.id}
                id={option.id}
                sortable={option.sortable ?? false}
                removable={option.removable ?? false}
                questionType={questionType}
                value={option.option}
                isCorrect={option.isCorrect}
                onSingleSelectionChange={() =>
                  handleSingleSelectionChange(index)
                }
                onMultipleSelectionChange={(checked) =>
                  handleMultipleSelectionChange(index, checked)
                }
                onOptionCreate={() => handleOptionCreate(index)}
                onOptionChange={(value) => handleOptionChange(index, value)}
                onRemoveOption={() => handleRemoveOption(index)}
              />
            );
          case 'picture-question':
            return (
              <PictureChoiceOption
                key={option.option}
                id={option.id}
                sortable={option.sortable ?? false}
                value={option.option}
                isCorrect={option.isCorrect}
                onSelectionChange={() => handleSingleSelectionChange(index)}
                onOptionChange={(value) => {
                  if (listOptions[index].id === 'new_option') {
                    handleOptionCreate(index, value);
                  } else {
                    handleOptionChange(index, value);
                  }
                }}
                onRemoveOption={() => handleRemoveOption(index)}
              />
            );
        }
      }}
      onOrderChanged={handleOrderChanged}
    />
  );
};

const PictureGrid = styled(SortableListItems)`
  grid-template-columns: 1fr 1fr;
`;
