import { matchPath, useLocation } from 'react-router-dom';

import { TId } from '@/features/common';
import { TQuestionSource } from '@/features/questions';

export const useTestEditorQuestionId = () => {
  const location = useLocation();

  const match = matchPath<{ questionId: TId }>(location.pathname, {
    path: '/admin/:workspace/openings/:opening/test/:test/questions/:questionId(\\d+)',
  });

  return match?.params.questionId;
};

export const useTestEditorQuestionSource = () => {
  const location = useLocation();

  const match = matchPath<{ source: TQuestionSource }>(location.pathname, {
    path: '/admin/:workspace/openings/:opening/test/:test/questions/add/:source',
  });

  return match?.params.source;
};
