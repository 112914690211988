import React from 'react';

import { TQuestionType } from '@/features/questions';
import { QuestionTypeGroupTitle } from '@/features/test/components/test-editor-question-preview/ui';

export const LIBRARY_QUESTION_TYPE_OPTIONS: {
  id: TQuestionType | string;
  label: React.ReactNode;
  disabled?: boolean;
}[] = [
  {
    id: 'auto-evaluated',
    label: (
      <QuestionTypeGroupTitle>
        Auto evaluation (recommended)
      </QuestionTypeGroupTitle>
    ),
    disabled: true,
  },
  { id: 'placeholder-close-ended', label: 'Choice or numeric input' },
  {
    id: 'manually-evaluated',
    label: (
      <QuestionTypeGroupTitle>
        Requires manual evaluation
      </QuestionTypeGroupTitle>
    ),
    disabled: true,
  },
  { id: 'free-text', label: 'Free text' },
  { id: 'code-input', label: 'Coding' },
];
