import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';

import {
  BillingInfo,
  BillingInfoAttributes,
  BillingPlan,
  changeSubscriptionPlan,
  changeSubscriptionPlanPreview,
  clearBillingInfo,
  CreditsPurchaseItemCode,
  deleteSubscription,
  fetchBillingEmail,
  fetchBillingInfo,
  fetchCardInfo,
  fetchInvoices,
  fetchRecurlyHostedPage,
  fetchSubscription,
  PauseDuration,
  pauseSubscription,
  PaymentMethodToken,
  purchaseCredits,
  purchaseCreditsPreview,
  updateBillingEmail,
  updateBillingInfo,
  updateCardInfo,
} from '@/features/billing';
import { useNotifications } from '@/features/notifications';
import { WORKSPACE_STATS_QUERY_KEY } from '@/features/workspace';
import { useWorkspaceId } from '@/hooks/router';

const BILLING_KEY = ['billing'];

export const useBillingInfoQuery = (options?: UseQueryOptions) => {
  const workspaceId = useWorkspaceId();

  return useQuery(
    [...BILLING_KEY, workspaceId, 'info'],
    () => fetchBillingInfo(workspaceId),
    options as any
  );
};

export const useCardInfoQuery = (options?: UseQueryOptions) => {
  const workspaceId = useWorkspaceId();

  return useQuery(
    [...BILLING_KEY, workspaceId, 'card'],
    () => fetchCardInfo(workspaceId),
    options as any
  );
};

export const useInvoicesQuery = (
  params: { cursor: string | null },
  options?: UseQueryOptions
) => {
  const workspaceId = useWorkspaceId();

  return useQuery(
    [...BILLING_KEY, workspaceId, 'invoices', params.cursor],
    () => fetchInvoices(workspaceId, params.cursor),
    options as any
  );
};

export const useSubscriptionQuery = (options?: UseQueryOptions) => {
  const workspaceId = useWorkspaceId();

  return useQuery(
    [...BILLING_KEY, workspaceId, 'subscription'],
    () => fetchSubscription(workspaceId),
    options as any
  );
};

export const useBillingEmailQuery = (options?: UseQueryOptions) => {
  const workspaceId = useWorkspaceId();

  return useQuery(
    [...BILLING_KEY, workspaceId, 'email'],
    () => fetchBillingEmail(workspaceId),
    options as any
  );
};

export const usePurchaseCreditsPreviewQuery = (
  itemCode: CreditsPurchaseItemCode,
  options?: UseQueryOptions
) => {
  const workspaceId = useWorkspaceId();

  return useQuery(
    [...BILLING_KEY, workspaceId, 'purchase-credits-preview', itemCode],
    () => purchaseCreditsPreview(workspaceId, itemCode),
    options as any
  );
};

export const useRecurlyHostedPageQuery = (options?: UseQueryOptions) => {
  const workspaceId = useWorkspaceId();

  return useQuery(
    [
      ...BILLING_KEY,
      workspaceId,
      'purchase-credits-preview',
      'recurly-hosted-page',
    ],
    () => fetchRecurlyHostedPage(workspaceId),
    options as any
  );
};

export const useRemoveBillingInfoMutation = (options?: UseMutationOptions) => {
  const workspaceId = useWorkspaceId();
  const queryClient = useQueryClient();

  const { onSuccess, ...restOptions } = options ?? {};
  return useMutation(() => clearBillingInfo(workspaceId), {
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([...BILLING_KEY, workspaceId]);
      onSuccess?.(data, variables as any, context);
    },
    ...(restOptions as any),
  });
};

export const usePauseSubscriptionMutation = (options?: UseMutationOptions) => {
  const workspaceId = useWorkspaceId();
  const queryClient = useQueryClient();

  const { onSuccess, ...restOptions } = options ?? {};

  return useMutation(
    ({ pauseDuration }: { pauseDuration: PauseDuration }) =>
      pauseSubscription(workspaceId, pauseDuration),
    {
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries([...BILLING_KEY, workspaceId]);
        onSuccess?.(data, variables as any, context);
      },
      ...(restOptions as any),
    }
  );
};

export const useDeleteSubscriptionMutation = (options?: UseMutationOptions) => {
  const workspaceId = useWorkspaceId();
  const queryClient = useQueryClient();

  const { onSuccess, ...restOptions } = options ?? {};

  return useMutation(() => deleteSubscription(workspaceId), {
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([...BILLING_KEY, workspaceId]);
      onSuccess?.(data, variables as any, context);
    },
    ...(restOptions as any),
  });
};

export const useChangeSubscriptionPlanMutation = (
  options?: UseMutationOptions
) => {
  const workspaceId = useWorkspaceId();
  const queryClient = useQueryClient();
  const { addNotification } = useNotifications();

  const { onSuccess, ...restOptions } = options ?? {};

  return useMutation(
    ({
      plan,
      challengeToken,
      couponCode,
    }: {
      plan: BillingPlan;
      challengeToken?: string;
      couponCode?: string;
    }) => changeSubscriptionPlan(workspaceId, plan, challengeToken, couponCode),
    {
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries([...BILLING_KEY, workspaceId]);
        addNotification({ type: 'saved' });
        onSuccess?.(data, variables as any, context);
      },
      ...(restOptions as any),
    }
  );
};

export const useUpdateBillingInfoMutation = (options?: UseMutationOptions) => {
  const workspaceId = useWorkspaceId();
  const queryClient = useQueryClient();

  const { onSuccess, ...restOptions } = options ?? {};

  return useMutation(
    ({
      billingInfo,
      attributes,
    }: {
      billingInfo: BillingInfo;
      attributes: BillingInfoAttributes;
    }) => updateBillingInfo(billingInfo, attributes),
    {
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries([...BILLING_KEY, workspaceId]);
        onSuccess?.(data, variables as any, context);
      },
      ...(restOptions as any),
    }
  );
};

export const useUpdateBillingEmailMutation = (options?: UseMutationOptions) => {
  const workspaceId = useWorkspaceId();
  const queryClient = useQueryClient();

  const { onSuccess, ...restOptions } = options ?? {};

  return useMutation(
    ({ email }: { email: string }) => updateBillingEmail(workspaceId, email),
    {
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries([...BILLING_KEY, workspaceId]);
        onSuccess?.(data, variables as any, context);
      },
      ...(restOptions as any),
    }
  );
};

export const useUpdateCardInfoMutation = (options?: UseMutationOptions) => {
  const workspaceId = useWorkspaceId();
  const queryClient = useQueryClient();

  const { onSuccess, ...restOptions } = options ?? {};

  return useMutation(
    ({
      initialToken,
      challengeToken,
    }: {
      initialToken: PaymentMethodToken;
      challengeToken?: PaymentMethodToken;
    }) => updateCardInfo(workspaceId, initialToken, challengeToken),
    {
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries([...BILLING_KEY, workspaceId]);
        onSuccess?.(data, variables as any, context);
      },
      ...(restOptions as any),
    }
  );
};

export const useChangeSubscriptionPlanPreviewMutation = (
  options?: UseMutationOptions
) => {
  const workspaceId = useWorkspaceId();
  const queryClient = useQueryClient();

  const { onSuccess, ...restOptions } = options ?? {};

  return useMutation(
    ({ plan, couponCode }: { plan: BillingPlan; couponCode?: string }) => {
      return changeSubscriptionPlanPreview(workspaceId, plan, couponCode);
    },
    {
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries([...BILLING_KEY, workspaceId]);
        onSuccess?.(data, variables as any, context);
      },
      ...(restOptions as any),
    }
  );
};

export const usePurchaseCreditsMutation = (options?: UseMutationOptions) => {
  const workspaceId = useWorkspaceId();
  const queryClient = useQueryClient();

  const { onSuccess, ...restOptions } = options ?? {};

  return useMutation(
    ({
      itemCode,
      challengeToken,
    }: {
      itemCode: CreditsPurchaseItemCode;
      challengeToken?: string;
    }) => purchaseCredits(workspaceId, itemCode, challengeToken),
    {
      onSuccess: async (data, variables, context) => {
        await Promise.all([
          queryClient.invalidateQueries([...BILLING_KEY, workspaceId]),
          queryClient.invalidateQueries([
            ...WORKSPACE_STATS_QUERY_KEY,
            workspaceId,
          ]),
        ]);
        onSuccess?.(data, variables as any, context);
      },
      ...(restOptions as any),
    }
  );
};
