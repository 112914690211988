import { TApplicationQuestionType } from '@/features/job-opening';

export const APPLICATION_QUESTION_TYPES: {
  id: TApplicationQuestionType;
  label: string;
}[] = [
  {
    id: 'paragraph',
    label: 'Paragraph',
  },
  {
    id: 'short-answer',
    label: 'Short answer',
  },
  {
    id: 'single-choice',
    label: 'Single choice',
  },
  {
    id: 'multiple-choice',
    label: 'Multiple choice',
  },
];
