import styled from '@emotion/styled';

import { pluralize } from '@/features/common';

import { UnitInputWrapper } from './ui';

export const TimeInput = styled(UnitInputWrapper)<{
  duration: number;
  timeUnit: string;
}>`
  & > div:first-of-type::after {
    content: ${(props) => `"${pluralize(+props.duration, props.timeUnit)}"`};
  }
`;
