import React from 'react';
import { uuid4 } from '@sentry/utils';
import { SplitFactoryProvider } from '@splitsoftware/splitio-react';
import store from 'store';

import { TId } from '@/features/common';

import { getSplitConfig } from '../../utils';

interface AnonymousSplitProps {
  children: React.ReactNode;
}
const USER_ID_KEY = 'anonymous_user_id';

function getUserIdFromStore(): string | undefined {
  return store.get(USER_ID_KEY);
}

function setUserIdToStore(uuid: string) {
  store.set(USER_ID_KEY, uuid);
}

function getUserId(): TId {
  let userId: string | undefined = getUserIdFromStore();
  if (!userId) {
    userId = uuid4();
    setUserIdToStore(userId);
  }
  return userId;
}

export function AnonymousSplit(props: AnonymousSplitProps) {
  return (
    <SplitFactoryProvider
      config={getSplitConfig('anonymous', getUserId())}
      updateOnSdkReady
      updateOnSdkTimedout
    >
      {props.children}
    </SplitFactoryProvider>
  );
}
