import { object, string } from 'yup';

import { BillingInfo } from '@/features/billing';

export const schema = object().shape({
  firstName: string().required("This can't be blank!"),
  lastName: string().required("This can't be blank!"),
  vatNumber: string(),
  email: string().required("This can't be blank!").email('Enter a valid email'),
  address: string().required("This can't be blank!"),
  address2: string(),
  city: string().required("This can't be blank!"),
  state: string(),
  postalCode: string().required("This can't be blank!"),
  country: string().required('Select a country'),
});

export const createBillingInfoAttributes = (
  billingInfo: BillingInfo | null,
  billingEmail: string | null
) => {
  return {
    firstName: billingInfo?.firstName ?? '',
    lastName: billingInfo?.lastName ?? '',
    vatNumber: billingInfo?.vatNumber ?? '',
    address: billingInfo?.address[0] ?? '',
    address2: billingInfo?.address[1] ?? '',
    country: billingInfo?.country ?? '',
    city: billingInfo?.city ?? '',
    state: billingInfo?.state ?? '',
    postalCode: billingInfo?.postalCode ?? '',
    email: billingEmail ?? '',
  };
};

export const isValidBillingInfo = (billingInfo: BillingInfo) =>
  billingInfo.firstName &&
  billingInfo.lastName &&
  billingInfo.address.length &&
  billingInfo.city &&
  billingInfo.postalCode &&
  billingInfo.country;
