import React from 'react';

export interface IAttachmentsOptions {
  id: string;
  label: React.ReactNode;
  labelText?: string;
  disabled?: boolean;
}

export const ATTACHMENTS_OPTIONS: IAttachmentsOptions[] = [
  { id: 'optional', label: 'Optional' },
  { id: 'mandatory', label: 'Mandatory' },
];
