import React, { useEffect } from 'react';
import { DSToast } from '@hundred5/design-system';
import { useLatest } from 'react-use';

import { TNotificationContent } from '@/features/notifications';

interface NotificationProps {
  content: TNotificationContent;
  onClose: () => void;
}

export function Notification({ content, onClose }: NotificationProps) {
  const onCloseRef = useLatest(onClose);

  useEffect(() => {
    if (!!content.autoCloseTimeout) {
      const timeout = setTimeout(
        () => onCloseRef?.current(),
        content.autoCloseTimeout
      );
      return () => clearTimeout(timeout);
    }
  }, [onCloseRef, content.autoCloseTimeout]);

  return (
    <DSToast
      type={content.kind}
      title={
        typeof content.title === 'function'
          ? content.title(content.meta as any)
          : content.title
      }
      description={
        typeof content.description === 'function'
          ? content.description(content.meta as any)
          : content.description
      }
      onClose={!content.autoCloseTimeout ? () => onClose() : undefined}
      actionLabel={content.actionLabel}
      onAction={() => content.onAction?.(content.meta as any)}
    />
  );
}
