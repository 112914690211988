import api from '@/api/api';
import { TId } from '@/features/common';
import { Id } from '@/types/misc';
import { parseAPIData } from '@/utils/parsers';

import { ITemplate, ITemplateType, ITestTemplate } from '../types';

export async function fetchTemplates(params: {
  query?: string;
}): Promise<ITemplate[]> {
  const query = params.query
    ? `?query=${encodeURIComponent(params.query)}`
    : '';
  const response = await api.get(`/tests/generator/positions${query}`);

  return parseAPIData(response.data.positions ?? []);
}

export async function fetchTemplate(params: {
  id: TId;
}): Promise<ITestTemplate> {
  // query param is not needed in FE currently, so it was hardcoded
  // removing it will skip the `testPreview` field in response
  const response = await api.get(
    `/test-templates/${params.id}?include=testPreview`
  );

  return parseAPIData(response.data);
}

export async function fetchTemplateTypes(params: {
  categoryId: Id;
}): Promise<ITemplateType[]> {
  const response = await api.get(
    `/tests/generator/categories/${params.categoryId}`
  );

  return parseAPIData(response.data.types ?? []);
}
