import styled from '@emotion/styled';
import { togglTheme } from '@hundred5/design-system';

export const Container = styled.section`
  align-items: center;
  background: ${togglTheme.colors.peach[20]};
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
`;
