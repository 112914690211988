import api from '@/api/api';
import {
  ICandidateContactInfo,
  ICandidateCustomField,
} from '@/features/candidate';
import { TId } from '@/features/common';
import { parseAPIData, serialiseAPIData } from '@/utils/parsers';

export async function updateCandidateContactInfo(params: {
  candidateId: TId;
  contactInfo: Partial<ICandidateContactInfo>;
}): Promise<ICandidateContactInfo> {
  const response = await api.patch(
    `test-takers/${params.candidateId}/contact-info`,
    serialiseAPIData(params.contactInfo)
  );

  return parseAPIData(response.data);
}

export async function updateCandidateCustomFields(params: {
  candidateId: TId;
  candidateFields: ICandidateCustomField[];
}): Promise<ICandidateCustomField[]> {
  const response = await api.put(
    `test-takers/${params.candidateId}/candidate-fields`,
    serialiseAPIData(params)
  );

  return parseAPIData(response.data.candidate_fields);
}
