import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { DSButton } from '@hundred5/design-system';
import { Formik } from 'formik';
import * as yup from 'yup';

import * as authApi from '@/api/authentication';
import { checkClientError } from '@/errors';
import { FormikInputField } from '@/features/common';
import { useApiError } from '@/hooks/api';

interface ForgottenPasswordFormProps {
  onSent: () => void;
}

export function ForgottenPasswordForm({ onSent }: ForgottenPasswordFormProps) {
  const handleApiError = useApiError();

  const handleSubmit = useCallback(
    async (values, actions) => {
      try {
        await authApi.requestPasswordReset(values.email);
      } catch (error) {
        actions.setSubmitting(false);

        if (checkClientError(error, 'EmailNotFound')) {
          actions.setFieldError(
            'email',
            'Looks like that email does not exist. Try using a different email.'
          );
        } else {
          handleApiError(error);
        }

        return;
      }

      actions.setSubmitting(false);
      onSent();
    },
    [handleApiError, onSent]
  );

  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >
      {(form) => (
        <Form onSubmit={form.handleSubmit} data-recording-ignore="mask">
          <FormikInputField
            type="email"
            name="email"
            id="forgotten-password-form-email"
            label="Email"
          />

          <SubmitButton type="submit" disabled={form.isSubmitting}>
            {form.isSubmitting ? 'Sending...' : 'Send instructions'}
          </SubmitButton>
        </Form>
      )}
    </Formik>
  );
}

const schema = yup.object().shape({
  email: yup.string().required('Enter your email').email('Enter valid email'),
});

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const SubmitButton = styled(DSButton)`
  margin-top: 20px;
  width: 100%;
`;
