import React from 'react';
import styled from '@emotion/styled';
import { DSButton, DSContentBlock } from '@hundred5/design-system';
import { Form, Formik } from 'formik';

import { FormikCheckboxField, PlanTag, PromptIfDirty } from '@/features/common';
import { useNotifications } from '@/features/notifications';
import { useJobOpeningPermission } from '@/features/permissions';
import {
  IFraudDetectionSettings,
  useFraudDetectionSettingsMutation,
  useFraudDetectionSettingsQuery,
  useTestPage,
} from '@/features/test';
import { usePlanLimits } from '@/hooks/planLimits';
import { useUpsellModal } from '@/hooks/upsellModal';

import { TestSettingsSection } from '../test-settings-section';

import { useLogFraudDetectionSettings } from './skills-test-settings-anti-cheating.analytics';

export function SkillsTestSettingsAntiCheat() {
  const { addNotification } = useNotifications();
  const { openUpsellModal } = useUpsellModal();
  const logFraudDetectionSettings = useLogFraudDetectionSettings();
  const canAccessFeature = usePlanLimits();
  const canUpdate = useJobOpeningPermission()('test', 'update');
  const { test } = useTestPage();
  const fraudDetectionSettingsQuery = useFraudDetectionSettingsQuery({
    testId: test.id,
  });
  const fraudDetectionSettingsMutation = useFraudDetectionSettingsMutation();

  const initialSettings = {
    isEnabled: !!fraudDetectionSettingsQuery.data?.isEnabled,
    isFingerprintDetectionEnabled:
      !!fraudDetectionSettingsQuery.data?.isFingerprintDetectionEnabled,
    isCopyDetectionEnabled:
      !!fraudDetectionSettingsQuery.data?.isCopyDetectionEnabled,
    isPasteDetectionEnabled:
      !!fraudDetectionSettingsQuery.data?.isPasteDetectionEnabled,
    isQuickSubmitDetectionEnabled:
      !!fraudDetectionSettingsQuery.data?.isQuickSubmitDetectionEnabled,
    isSimilarEmailDetectionEnabled:
      !!fraudDetectionSettingsQuery.data?.isSimilarEmailDetectionEnabled,
    isAnswerPasteDisabled:
      !!fraudDetectionSettingsQuery.data?.isAnswerPasteDisabled,
  };

  const handleSubmit = async (values: IFraudDetectionSettings) => {
    const updatedFraudSettings = {
      isEnabled: values.isEnabled,
      isFingerprintDetectionEnabled:
        values.isEnabled && values.isFingerprintDetectionEnabled,
      isCopyDetectionEnabled: values.isEnabled && values.isCopyDetectionEnabled,
      isPasteDetectionEnabled:
        values.isEnabled && values.isPasteDetectionEnabled,
      isQuickSubmitDetectionEnabled:
        values.isEnabled && values.isQuickSubmitDetectionEnabled,
      isSimilarEmailDetectionEnabled:
        values.isEnabled && values.isSimilarEmailDetectionEnabled,
      isAnswerPasteDisabled: values.isAnswerPasteDisabled,
    };

    await fraudDetectionSettingsMutation.mutateAsync({
      testId: test.id,
      settings: updatedFraudSettings,
    });

    addNotification({ type: 'saved' });
  };

  return (
    <Formik<IFraudDetectionSettings>
      enableReinitialize
      initialValues={initialSettings}
      onSubmit={handleSubmit}
    >
      {({ values, dirty, isSubmitting, setFieldValue }) => (
        <Form>
          <PromptIfDirty />
          <DSContentBlock>
            <DSContentBlock.Title>Anti-cheating tracking</DSContentBlock.Title>

            <DSContentBlock.Content>
              <TestSettingsSection>
                <TestSettingsSection.Content>
                  <FormikCheckboxField
                    name="isEnabled"
                    label={
                      <TestSettingsSection.LabelWrapper>
                        Flag candidates by suspicious behavior
                        <StyledPlanTag />
                      </TestSettingsSection.LabelWrapper>
                    }
                    onChange={(event) => {
                      if (!canAccessFeature('suspicious_activities')) {
                        openUpsellModal('premium_feature');
                        return;
                      }

                      setFieldValue('isEnabled', event.target.checked);

                      if (event.target.checked) {
                        logFraudDetectionSettings('enable');
                      }
                    }}
                  />

                  <FraudSettingsWrapper disabled={!values.isEnabled}>
                    <FormikCheckboxField
                      disabled={!values.isEnabled}
                      name="isFingerprintDetectionEnabled"
                      label={
                        <>
                          <StrongText>Browser fingerprint:</StrongText>{' '}
                          Completing several tests from the same browser
                        </>
                      }
                      onChange={(event) => {
                        setFieldValue(
                          'isFingerprintDetectionEnabled',
                          event.target.checked
                        );

                        if (event.target.checked) {
                          logFraudDetectionSettings('browser fingerprint');
                        }
                      }}
                    />
                    <FormikCheckboxField
                      disabled={!values.isEnabled}
                      name="isCopyDetectionEnabled"
                      label={
                        <>
                          <StrongText>Copy detection:</StrongText> Copying the
                          test questions
                        </>
                      }
                      onChange={(event) => {
                        setFieldValue(
                          'isCopyDetectionEnabled',
                          event.target.checked
                        );

                        if (event.target.checked) {
                          logFraudDetectionSettings('copy detection');
                        }
                      }}
                    />
                    <FormikCheckboxField
                      disabled={!values.isEnabled}
                      name="isPasteDetectionEnabled"
                      label={
                        <>
                          <StrongText>Paste detection:</StrongText> Using the
                          paste function to answer test questions
                        </>
                      }
                      onChange={(event) => {
                        setFieldValue(
                          'isPasteDetectionEnabled',
                          event.target.checked
                        );

                        if (event.target.checked) {
                          logFraudDetectionSettings('paste detection');
                        }
                      }}
                    />
                    <FormikCheckboxField
                      disabled={!values.isEnabled}
                      name="isQuickSubmitDetectionEnabled"
                      label={
                        <>
                          <StrongText>Test completion time:</StrongText>{' '}
                          Completing the test under 5 minutes
                        </>
                      }
                      onChange={(event) => {
                        setFieldValue(
                          'isQuickSubmitDetectionEnabled',
                          event.target.checked
                        );

                        if (event.target.checked) {
                          logFraudDetectionSettings('quick submit detection');
                        }
                      }}
                    />
                    <FormikCheckboxField
                      disabled={!values.isEnabled}
                      name="isSimilarEmailDetectionEnabled"
                      label={
                        <>
                          <StrongText>Email similarity:</StrongText> Using
                          similar emails (jane@gmail.com, jane+1@gmail.com...)
                        </>
                      }
                      onChange={(event) => {
                        setFieldValue(
                          'isSimilarEmailDetectionEnabled',
                          event.target.checked
                        );

                        if (event.target.checked) {
                          logFraudDetectionSettings('similar email detection');
                        }
                      }}
                    />
                  </FraudSettingsWrapper>

                  <FormikCheckboxField
                    name="isAnswerPasteDisabled"
                    checked={!values.isAnswerPasteDisabled}
                    label={
                      <TestSettingsSection.LabelWrapper>
                        Allow pasting in answers to Free Text questions
                        <StyledPlanTag />
                      </TestSettingsSection.LabelWrapper>
                    }
                    onChange={(event) => {
                      if (!canAccessFeature('answer_paste')) {
                        openUpsellModal('premium_feature');
                        return;
                      }
                      setFieldValue(
                        'isAnswerPasteDisabled',
                        !event.target.checked
                      );
                    }}
                  />
                </TestSettingsSection.Content>
              </TestSettingsSection>
            </DSContentBlock.Content>

            <DSContentBlock.Footer>
              <DSButton
                type="submit"
                disabled={!canUpdate || !dirty || isSubmitting}
                data-rh={!canUpdate ? 'Only admins can save changes' : null}
              >
                {isSubmitting ? 'Saving...' : 'Save changes'}
              </DSButton>
            </DSContentBlock.Footer>
          </DSContentBlock>
        </Form>
      )}
    </Formik>
  );
}

const StyledPlanTag = styled(PlanTag)`
  margin: 0;
`;

const FraudSettingsWrapper = styled.div<{ disabled: boolean }>`
  display: grid;
  gap: 8px;
  width: max-content;
  padding: 8px 16px;
  border-radius: 8px;
  // purple[5] with opacity
  background-color: rgb(
    244,
    243,
    245,
    ${(props) => (props.disabled ? 0.4 : 1)}
  );

  input[type='checkbox']:disabled,
  div:has(> input[type='checkbox']:disabled) {
    display: none;
  }
`;

const StrongText = styled.span`
  font-weight: 500;
`;
