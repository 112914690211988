import { useWorkspaceTreatments } from '@/features/splits';

import {
  billingPlansDescriptions,
  billingPlansDescriptions2023V2,
  billingPlansDescriptions2024,
  billingPlansDescriptionsExp2023,
} from '..';

export const useBillingPlansConfig = () => {
  const { treatments } = useWorkspaceTreatments([
    'fe_billingPlans_exp2023',
    'fe_billingPlans_2023v2',
    'fe_billingPlans_2024',
  ]);

  return treatments.fe_billingPlans_2024.treatment === 'on'
    ? billingPlansDescriptions2024
    : treatments.fe_billingPlans_2023v2.treatment === 'on'
      ? billingPlansDescriptions2023V2
      : treatments.fe_billingPlans_exp2023.treatment === 'on'
        ? billingPlansDescriptionsExp2023
        : treatments.fe_billingPlans_2023v2.treatment === 'on'
          ? billingPlansDescriptions2023V2
          : billingPlansDescriptions;
};
