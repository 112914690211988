import styled from '@emotion/styled';

export const OptionsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 12px;
  align-items: center;

  @media (min-width: 600px) {
    grid-template-columns: 148px 1fr;
    gap: 24px;
  }
`;
