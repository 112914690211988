import React, { createContext, useCallback, useState } from 'react';

import { UpsellModalOptions, UpsellModalType } from '@/types/upsellModal';

export const UpsellModalContext = createContext<{
  isOpen: boolean;
  type: UpsellModalType | null;
  options: UpsellModalOptions | null;
  open: (type: UpsellModalType, options?: UpsellModalOptions) => void;
  close: () => void;
}>({
  isOpen: false,
  type: null,
  options: null,
  open: (type, options) => {},
  close: () => {},
});

export function UpsellModalContextProvider({ children }) {
  const [upsellModalData, setUpsellModalData] = useState<{
    isOpen: boolean;
    type?: UpsellModalType | null;
    options?: UpsellModalOptions | null;
  }>({
    isOpen: false,
    type: null,
    options: null,
  });

  const open = useCallback(
    (typeData, optionsData) => {
      setUpsellModalData({
        isOpen: true,
        type: typeData,
        options: optionsData ?? null,
      });
    },
    [setUpsellModalData]
  );
  const close = useCallback(() => {
    setUpsellModalData({
      isOpen: false,
      type: null,
      options: null,
    });
  }, [setUpsellModalData]);

  return (
    <UpsellModalContext.Provider
      value={{
        isOpen: upsellModalData.isOpen,
        type: upsellModalData.type ?? null,
        options: upsellModalData.options ?? null,
        open,
        close,
      }}
    >
      {children}
    </UpsellModalContext.Provider>
  );
}
