import styled from '@emotion/styled';

import { Loader } from '@/features/common/components/loader';

export const LibraryLoader = styled(Loader)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto auto;
`;
