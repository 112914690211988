import { object, ref, string } from 'yup';

import { IMe } from '@/features/me';

export const schema = object().shape({
  name: string().required("This can't be blank!"),
  phone: string().matches(
    /^\+\d{1,4}\s?\d{5,}$|^$/,
    'Use +333 33333333 format'
  ),
  email: string().email('Enter a valid email'),
  oldPassword: string().min(6, 'Password is less than 6 characters'),
  newPassword: string().min(6, 'Password is less than 6 characters'),
  newPasswordConfirm: string()
    .nullable()
    .oneOf([ref('newPassword'), null], 'Passwords do not match'),
});

export interface FormValues {
  name: string;
  email: string;
  oldPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
}

export const createUserAttributes = (user?: IMe): FormValues => ({
  name: user?.name ?? '',
  email: user?.email ?? '',
  oldPassword: '',
  newPassword: '',
  newPasswordConfirm: '',
});
