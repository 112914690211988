import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { BackButton, usePageLayout } from '@/features/common';

interface PageHeaderProps {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  backButton?: {
    to?: string;
    tooltip?: string;
  };
  actions?: React.ReactNode;
  children?: React.ReactNode;
  sticky?: boolean;
}

export function PageHeader({
  title,
  subtitle,
  backButton,
  actions,
  children,
  sticky,
}: PageHeaderProps) {
  const pageLayout = usePageLayout();

  return (
    <PageHeaderContainer sticky={!!sticky} top={pageLayout.offsetTop}>
      <HeaderContent>
        {backButton && (
          <BackButtonWrapper>
            <BackButton {...backButton} />
          </BackButtonWrapper>
        )}

        <Title>{title}</Title>

        {subtitle && <Subtitle>{subtitle}</Subtitle>}

        {actions && <Actions>{actions}</Actions>}
      </HeaderContent>

      {children}
    </PageHeaderContainer>
  );
}

const PageHeaderContainer = styled.header<{ sticky: boolean; top: number }>`
  ${(props) =>
    props.sticky &&
    css`
      position: sticky;
      top: ${props.top}px;
      z-index: 15;
    `}

  background: ${(props) => props.theme.colors.white};
  box-shadow: ${(props) => props.theme.shadow.card};
  padding: 12px 24px;
  display: grid;
  gap: 12px;

  &:has(nav:last-child) {
    padding-bottom: 0;
  }

  @media screen and (max-width: 596px) {
    padding: 12px 16px;
  }
`;

const HeaderContent = styled.div`
  display: grid;
  grid-template-areas: 'back title actions' '. subtitle .';
  grid-template-columns: min-content 1fr min-content;
  align-content: center;
`;

const BackButtonWrapper = styled.div`
  grid-area: back;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -7px; // yes, 7, to make sure elements are precisely aligned
`;

const Title = styled.h1`
  grid-area: title;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  align-items: center;
  gap: 8px;
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  color: ${(props) => props.theme.typography.colorPrimary};
  min-height: 36px;
`;

const Subtitle = styled.h2`
  grid-area: subtitle;
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: ${(props) => props.theme.typography.colorPrimary};
`;

const Actions = styled.div`
  grid-area: actions;
  display: flex;
  align-items: center;
  margin-left: 8px;
`;
