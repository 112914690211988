import React from 'react';
import styled from '@emotion/styled';
import { DSInlineField, DSRadio } from '@hundred5/design-system';

import { PauseDuration } from '@/features/billing';

export const PauseSubscriptionForm = ({
  pauseDuration,
  setPauseDuration,
}: {
  pauseDuration: PauseDuration;
  setPauseDuration: React.Dispatch<React.SetStateAction<PauseDuration>>;
}) => {
  return (
    <PauseFormContainer>
      <DSInlineField for="update-candidates-radio" label="1 month">
        <DSRadio
          type="radio"
          name="update-existing-candidates-hire-state"
          id="update-candidates-radio"
          checked={pauseDuration === 'pause-1-month'}
          onChange={() => setPauseDuration('pause-1-month')}
        />
      </DSInlineField>
      <DSInlineField for="update-candidates-radio2" label="3 months">
        <DSRadio
          type="radio"
          name="update-existing-candidates-hire-state"
          id="update-candidates-radio2"
          onChange={() => setPauseDuration('pause-3-months')}
        />
      </DSInlineField>
      <DSInlineField for="update-candidates-radio3" label="6 months">
        <DSRadio
          type="radio"
          name="update-existing-candidates-hire-state"
          id="update-candidates-radio3"
          onChange={() => setPauseDuration('pause-6-months')}
        />
      </DSInlineField>
    </PauseFormContainer>
  );
};

const PauseFormContainer = styled.form`
  margin: 12px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
