import React from 'react';

export const SearchResultsIcon = () => {
  return (
    <svg
      width="114"
      height="109"
      viewBox="0 0 114 109"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.189453"
        width="98.2524"
        height="20.0971"
        rx="2.43601"
        fill="#F4F3F5"
      />
      <rect
        x="15.3164"
        y="22.5332"
        width="98.2524"
        height="20.0971"
        rx="2.43601"
        fill="#F4F3F5"
      />
      <rect
        x="0.189453"
        y="45.0662"
        width="98.2524"
        height="20.0971"
        rx="2.43601"
        fill="#F4F3F5"
      />
      <path
        d="M112.359 99.5731L92.2506 79.4172C93.3765 77.8155 94.3439 76.1187 95.1368 74.3108C96.7226 70.711 97.5314 66.905 97.5631 63.0039C97.6107 54.9637 94.6135 47.431 89.1265 41.8172C83.6078 36.1717 76.1703 33.0476 68.1618 33C68.1143 33 68.0508 33 68.0033 33C64.0546 33 60.2169 33.7771 56.6012 35.2995C53.1123 36.7743 49.9724 38.8834 47.2765 41.5635C44.5806 44.2435 42.4556 47.3676 40.949 50.8564C39.3949 54.4721 38.602 58.3098 38.5703 62.2585C38.5386 66.2707 39.2998 70.156 40.8222 73.8192C42.2811 77.3556 44.4061 80.5431 47.102 83.2708C49.798 85.9984 52.9379 88.1393 56.4584 89.6299C60.09 91.1841 63.9594 91.977 67.9398 91.9928H68.0667C71.9837 91.9928 75.8214 91.2158 79.4371 89.6934C81.5145 88.8212 83.4651 87.7269 85.2729 86.4266L105.365 106.551C106.333 107.518 107.601 108.01 108.87 108.01C110.139 108.01 111.392 107.534 112.359 106.567C114.294 104.632 114.294 101.508 112.359 99.5731ZM68.0667 84.809C68.035 84.809 68.0033 84.809 67.9716 84.809C55.5862 84.7614 45.6907 74.7232 45.77 62.3061C45.8492 50.0952 55.8082 40.1997 68.0033 40.1997C68.035 40.1997 68.0826 40.1997 68.1301 40.1997C80.674 40.2631 90.4427 50.2855 90.3793 62.9722C90.3476 67.6028 88.8252 71.9162 86.2403 75.4685C85.5584 76.42 84.7972 77.3081 83.9726 78.1327L83.9567 78.1485C83.5602 78.5609 83.1321 78.9415 82.7039 79.3221C82.688 79.3221 82.688 79.3379 82.6722 79.3379C82.466 79.5282 82.244 79.7027 82.022 79.8771C82.0061 79.893 81.9903 79.893 81.9903 79.9088C81.7683 80.0833 81.5462 80.2577 81.3084 80.4321C77.5817 83.1756 72.9828 84.809 68.0667 84.809Z"
        fill="#3F2F46"
      />
      <path
        d="M112.25 89.8206C109.031 83.8103 101.403 76.1032 94.0129 78.1489C91.3962 79.3224 88.5735 82.8271 85.9093 85.079C85.2908 85.6658 83.1499 87.1089 82.7218 89.0119C82.7059 89.0436 82.7059 89.0753 82.7059 89.0911C81.9447 92.1994 85.2432 94.4671 88.0026 93.2143L88.0343 93.1667L88.0501 93.1984L88.0026 93.2302C86.7498 96.7031 89.4932 99.7321 93.2358 98.3048C91.539 101.651 94.9961 105.029 98.3105 103.379C97.3907 105.378 97.9933 107.852 100.198 108.66C102.576 109.866 104.971 106.948 106.255 105.726C111.267 100.874 116.341 97.7022 112.25 89.8206Z"
        fill="#D5D0D7"
      />
    </svg>
  );
};
