import React from 'react';

export interface TabsContext {
  activeTab: string;
  handleTabChange: (tabKey: string) => void;
}

export const TabsContext = React.createContext<TabsContext>({
  activeTab: '',
  handleTabChange: () => {},
});

export function useTabsContext() {
  const context = React.useContext(TabsContext);

  if (!context) {
    throw new Error(
      `Tabs compound components cannot be rendered outside the Tab component`
    );
  }
  return context;
}
