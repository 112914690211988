import React, { useCallback } from 'react';
import styled from '@emotion/styled';

import { TId, ConfirmModal } from '@/features/common';

interface DeleteTestModalProps {
  onClose: () => void;
  onDelete: (categoryId: TId) => void;
  open: boolean;
  testId?: TId;
}

export function DeleteTestModal({
  onClose,
  onDelete,
  open,
  testId,
}: DeleteTestModalProps) {
  const handleConfirm = useCallback(() => {
    if (!testId) {
      return;
    }

    onDelete(testId);
    onClose();
  }, [testId, onDelete, onClose]);

  return (
    <ConfirmModal
      open={open}
      action="Delete"
      layer={1000}
      confirmation="delete"
      onConfirm={handleConfirm}
      onClose={onClose}
      title="Delete test"
    >
      <SubTitle>Are you sure?</SubTitle>
      <p>
        If any candidates have completed this test, this will also delete their
        test results and you will not be able to see the scores on their
        profiles.
      </p>
    </ConfirmModal>
  );
}

const SubTitle = styled.p`
  margin: 24px 0 12px 0;
  font-weight: 700;

  @media all and (max-width: 768px) {
    margin: 16px 0 24px 0;
  }
`;
