import React, { useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  Button,
  DSButton,
  DSColumn,
  DSContentBlock,
  DSRow,
} from '@hundred5/design-system';
import { Player, Recorder } from '@togglhire/video';

import { ConfirmModal, Icon } from '@/features/common';
import { Test } from '@/types/h5test';

import {
  useCreateVideoIntroductionMutation,
  useDeleteVideoIntroductionMutation,
} from '../../queries';

export const VideoIntroduction = ({ test }: { test: Test | null }) => {
  const [recordingActive, setRecordingActive] = useState(false);
  const [blob, setBlob] = useState<Blob | null>(null);
  const [mediaUrl, setMediaUrl] = useState<string | null>(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const { isLoading, mutate: createVideoIntroduction } =
    useCreateVideoIntroductionMutation({
      onSettled: () => {
        setRecordingActive(false);
      },
    });
  const { mutate: deleteVideoIntroduction } =
    useDeleteVideoIntroductionMutation({});

  const testIntro = useMemo(() => test?.introductionVideoUrl, [test]);
  const resolution = { width: 640, height: 320 };

  return (
    <>
      <DSRow centered>
        <Column size={16}>
          <DSContentBlock>
            <DSContentBlock.Title>Introductory video</DSContentBlock.Title>
            <DSContentBlock.Content>
              <p>
                Make the test more personal by recording a 2-min video
                describing the position and your company.
                <br />
                This video will be shown for every candidate before they start
                the test.
              </p>
              <div>
                {!recordingActive && !testIntro && (
                  <VideoPlaceholder>
                    <Button
                      disabled={!test}
                      onClick={() => setRecordingActive(true)}
                    >
                      <Icon icon={solid('video')} size="sm" />
                      &nbsp;Record Video
                    </Button>
                  </VideoPlaceholder>
                )}
                {!recordingActive && testIntro && <Player src={testIntro} />}
                {recordingActive && mediaUrl && <Player src={mediaUrl} />}
                {recordingActive && !blob && (
                  <Recorder
                    resolution={resolution}
                    onFinish={(blob, mediaUrl) => {
                      setBlob(blob);
                      setMediaUrl(mediaUrl);
                    }}
                  />
                )}
              </div>
            </DSContentBlock.Content>
            <DSContentBlock.Footer>
              {recordingActive ? (
                <ButtonsContainer>
                  <DSButton
                    onClick={() => {
                      setBlob(null);
                      setMediaUrl(null);
                      setRecordingActive(false);
                    }}
                    variant="secondary"
                    disabled={isLoading}
                  >
                    Cancel
                  </DSButton>
                  {blob ? (
                    <DSRow gap="12px">
                      <DSButton
                        onClick={() => {
                          setBlob(null);
                          setMediaUrl(null);
                        }}
                        variant="secondary"
                        disabled={isLoading}
                      >
                        Retake
                      </DSButton>
                      <DSButton
                        onClick={() => {
                          if (test?.id) {
                            createVideoIntroduction({
                              testId: test?.id,
                              video: blob,
                            });
                          }
                        }}
                        disabled={isLoading}
                      >
                        {isLoading ? 'Saving...' : 'Save'}
                      </DSButton>
                    </DSRow>
                  ) : null}
                </ButtonsContainer>
              ) : testIntro ? (
                <ButtonsContainer>
                  <DSButton
                    onClick={() => {
                      setConfirmModalOpen(true);
                    }}
                    variant="secondary"
                  >
                    Delete
                  </DSButton>
                  <DSButton
                    onClick={() => {
                      setRecordingActive(true);
                    }}
                    variant="secondary"
                  >
                    Retake
                  </DSButton>
                </ButtonsContainer>
              ) : null}
            </DSContentBlock.Footer>
          </DSContentBlock>
        </Column>
      </DSRow>
      <ConfirmModal
        open={confirmModalOpen}
        action="Confirm"
        onConfirm={() => {
          if (test) {
            deleteVideoIntroduction({ testId: test?.id });
          }
          setConfirmModalOpen(false);
        }}
        onClose={() => setConfirmModalOpen(false)}
      >
        <h3>Are you sure?</h3>
        <p>
          This will delete your introduction video. This action cannot be
          undone.
        </p>
      </ConfirmModal>
    </>
  );
};

const ButtonsContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Column = styled(DSColumn)`
  @media all and (max-width: 992px) {
    min-width: 100%;
  }
`;

const VideoPlaceholder = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.colors.orange[10]};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  aspect-ratio: 16 / 9;
  border-radius: 8px;
`;
