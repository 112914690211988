import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { useCandidateSearchParams } from '@/features/candidate-search';
import { Icon } from '@/features/common';

interface FiltersButtonProps {
  isOpen: boolean;
  onClick: () => void;
}

export const FiltersButton = ({ isOpen, onClick }: FiltersButtonProps) => {
  const { hasSearchParams } = useCandidateSearchParams();

  return (
    <SelectionWrapper onClick={onClick} active={isOpen}>
      <Icon
        icon={hasSearchParams ? solid('filter') : regular('filter')}
        color={hasSearchParams ? 'orange-100' : undefined}
        size="xs"
      />
      Filters
      <ArrowIcon
        icon={regular('chevron-down')}
        size="xs"
        isOpen={isOpen}
        color={isOpen ? 'orange-100' : 'purple-60'}
      />
    </SelectionWrapper>
  );
};

//region styles
const SelectionWrapper = styled.div<{ active?: boolean; disabled?: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  height: ${36 / 14}rem;
  padding: 0 24px;
  font-size: 1rem;
  font-weight: 400;
  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.typography.colorPrimary};
  border: 1px solid ${(props) => props.theme.colors.purple[40]};
  border-radius: 8px;
  -webkit-appearance: none;
  transition:
    background 0.1s,
    box-shadow 0.1s,
    border 0.1s;

  &:hover {
    background: ${(props) => props.theme.hover.onWhite};
  }

  ${(props) =>
    props.active &&
    css`
      outline: none;
      box-shadow: ${props.theme.shadow.input};
    `}

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.4;
      cursor: default;
      pointer-events: none;
    `}
`;

const ArrowIcon = styled(Icon)<{ isOpen: boolean }>`
  transform: rotate(${(props) => (props.isOpen ? 180 : 0)}deg);
  transition: transform 100ms ease-in-out;
`;
//endregion
