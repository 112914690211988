import React from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Icon } from '../icon';

interface PlanTagProps extends React.HTMLAttributes<HTMLDivElement> {}

export const PlanTag = ({ ...rest }: PlanTagProps) => {
  return (
    <Tag {...rest}>
      <Icon icon={regular('crown')} height={8} width={10} />
    </Tag>
  );
};

const Tag = styled.div`
  background: ${(props) => props.theme.colors.orange[30]};
  height: 16px;
  margin-top: 2px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: top;
  padding: 2px 8px;
  border-radius: 8px;
  margin-left: 8px;
  position: relative;
`;
