import api from '@/api/api';
import { TId } from '@/features/common';
import { serialiseAPIData } from '@/utils/parsers';

export async function bulkUpdate(
  candidateIds: TId[],
  attributes: { categoryId: TId }
): Promise<void> {
  await api.patch(
    `/test-takers/bulk`,
    serialiseAPIData({
      ids: candidateIds,
      action: 'update',
      testTakerData: attributes,
    }),
    { captchaAction: 'bulk_update' }
  );
}
