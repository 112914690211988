import { ITest, TestType } from '@/features/test';

export function getTestTooltipContent(test: ITest) {
  let duration =
    test.durationInSeconds === 0
      ? test.approximatedDurationInSeconds / 60
      : test.durationInSeconds / 60;
  let questionCountSuffix = test.questionCount > 1 ? 's' : '';

  let testType: string = '';

  switch (test.type) {
    case TestType.Quiz:
      testType = 'Skills test';
      break;
    case TestType.Video:
      testType = 'Video intro';
      break;
    case TestType.Homework:
      testType = 'Homework';
      break;
  }

  return `${testType} (${duration}min, ${test.questionCount} question${questionCountSuffix})`;
}
