import React from 'react';

import { maxQuestionReachedMessage } from '@/features/common/utils/max-questions-reached';
import { ISkill, Skill, SkillGroup } from '@/features/skills';

interface SkillListProps {
  skills: ISkill[];
  selectedSkills: ISkill[];
  onSkillSelect: (skill: ISkill) => void;
  /** The total number of selected questions */
  totalSelectedQuestions: number;
  /** The maximum number of questions that can be selected */
  maxQuestions: number;
}

export function SkillList({
  skills,
  selectedSkills,
  onSkillSelect,
  totalSelectedQuestions,
  maxQuestions,
}: SkillListProps) {
  return (
    <SkillGroup>
      {skills.map((skill: ISkill) => {
        const selected = selectedSkills.some(
          (selectedSkill: ISkill) => selectedSkill.id === skill.id
        );
        const disabled = totalSelectedQuestions >= maxQuestions && !selected;

        return (
          <Skill
            key={skill.id}
            skill={skill}
            selected={selected}
            onClick={onSkillSelect}
            disabled={disabled}
            data-rh={disabled ? maxQuestionReachedMessage(maxQuestions) : null}
            data-rh-position="top"
          />
        );
      })}
    </SkillGroup>
  );
}
