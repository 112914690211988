import React, { useState } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import styled from '@emotion/styled';
import { DSButton } from '@hundred5/design-system';

import { useWorkspacePermission } from '@/features/permissions';

import { RequestTemplateModal } from '../request-template-modal';

import { SearchResultsIcon } from './search-results-icon';

export const EmptyState = ({
  createCustomTest,
}: {
  createCustomTest: () => void;
}) => {
  const [requestTemplateModalOpen, setRequestTemplateModalOpen] =
    useState(false);
  const canCreateJobOpening = useWorkspacePermission()(
    'job openings',
    'create'
  );

  return (
    <Container>
      <Header>
        <h3>Search results</h3>
      </Header>
      <Content>
        <SearchResultsIcon />
        <Title>No results for your search</Title>
        <Description>
          You can create a custom test using Toggl's library of over 20,000
          questions or add your own.{' '}
        </Description>
        <ButtonWrapper>
          <DSButton
            variant="secondary"
            onClick={() => {
              setRequestTemplateModalOpen(true);
              amplitude?.logEvent(
                'job templates/click no results request template'
              );
            }}
          >
            Request a template
          </DSButton>

          <DSButton
            onClick={() => {
              amplitude?.logEvent('job templates/click no results new test');
              createCustomTest();
            }}
            disabled={!canCreateJobOpening}
            data-rh={
              !canCreateJobOpening
                ? 'Access restricted. Contact workspace admin to change your user rights.'
                : null
            }
          >
            Create a custom test
          </DSButton>
        </ButtonWrapper>
      </Content>
      <RequestTemplateModal
        open={requestTemplateModalOpen}
        onClose={() => setRequestTemplateModalOpen(false)}
        createCustomTest={createCustomTest}
      />
    </Container>
  );
};

const Container = styled.section`
  overflow: hidden;
  color: ${(props) => props.theme.colors.purple[100]};
  padding: 0 36px;

  @media all and (max-width: 768px) {
    padding: 0 16px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  h3 {
    margin: 0;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 24px;
`;

const Title = styled.h1`
  color: ${(props) => props.theme.colors.purple[100]};
  font-size: 24px;
  font-weight: 700;
  margin-top: 24px;
`;

const Description = styled.div`
  color: ${(props) => props.theme.colors.purple[60]};
  font-size: 14px;
  font-weight: 400;
  margin-top: 12px;
  width: 480px;
  text-align: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 24px;
`;
