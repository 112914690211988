import { useEffect } from 'react';

import { TId } from '@/features/common';
import { usePreferenceMutation, usePreferencesQuery } from '@/features/me';

interface UpdateSelectedWorkspaceProps {
  currentWorkspaceId: TId;
}

const UpdateSelectedWorkspace = ({
  currentWorkspaceId,
}: UpdateSelectedWorkspaceProps) => {
  const { data: userPreferences } = usePreferencesQuery();
  const { mutate: updatePreferencesMutation } = usePreferenceMutation();

  const selectedWorkspaceId = userPreferences?.selectedWorkspace;

  useEffect(() => {
    if (currentWorkspaceId !== selectedWorkspaceId) {
      updatePreferencesMutation({
        name: 'selectedWorkspace',
        value: currentWorkspaceId,
      });
    }
  }, [currentWorkspaceId, selectedWorkspaceId, updatePreferencesMutation]);

  return null;
};

export default UpdateSelectedWorkspace;
