import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import styled from '@emotion/styled';

interface SortableItemProps {
  id: string;
  children: React.ReactNode;
  isOrderingDisabled?: boolean;
}

export function SortableItem({
  id,
  children,
  isOrderingDisabled,
}: SortableItemProps) {
  const {
    attributes,
    listeners,
    isDragging,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id, disabled: isOrderingDisabled });

  const draggableStyle = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <Item
      ref={setNodeRef}
      style={draggableStyle}
      dragging={isDragging}
      {...listeners}
      {...attributes}
    >
      {children}
    </Item>
  );
}

const Item = styled.div<{ dragging: boolean }>`
  visibility: ${({ dragging }) => (dragging ? 'hidden' : 'visible')};
`;
