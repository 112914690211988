import React, { useCallback, useEffect, useRef } from 'react';
import { DSButton, DSField, DSModal } from '@hundred5/design-system';
import { useMedia } from 'react-use';

import { Container, Content, Footer, Header, Input, Title } from './ui';

interface ConfirmModalProps {
  open: boolean;
  layer?: number;
  children: React.ReactNode;
  action: string;
  confirmation?: string;
  onConfirm: () => void;
  onClose: () => void;
  contentStyle?: {};
  sideContent?: React.ReactNode;
  disableActionButton?: boolean;
  title?: string;
  footerVariant?: 'primary' | 'destructive-primary' | 'destructive-secondary';
}

export function ConfirmModal({
  layer,
  action,
  confirmation,
  children,
  onClose,
  contentStyle,
  sideContent,
  disableActionButton,
  onConfirm,
  open,
  title,
  footerVariant = 'primary',
}: ConfirmModalProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [confirmInputValue, setConfirmInputValue] = React.useState('');
  const isMobile = useMedia('(max-width: 768px)');

  const canConfirm =
    confirmation == null ||
    confirmInputValue.toLowerCase().trim() === confirmation.toLowerCase();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmInputValue(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (canConfirm) {
      onConfirm();
      setConfirmInputValue('');
    }
  };

  const handleClose = useCallback(() => {
    setConfirmInputValue('');
    onClose();
  }, [setConfirmInputValue, onClose]);

  useEffect(() => {
    if (open && isMobile) {
      window.scroll({ top: window.innerHeight });
    }
  }, [open, isMobile]);

  return (
    <DSModal
      fluid
      open={open}
      layer={layer}
      onClose={handleClose}
      contentStyle={contentStyle}
    >
      <Container data-testid="confirm-modal">
        {title && (
          <Header>
            <Title>{title}</Title>
            <DSModal.Separator />
          </Header>
        )}
        {!isMobile && <DSModal.CloseButton fixed onClick={handleClose} small />}
        <form onSubmit={handleSubmit}>
          <Content>{children}</Content>
          {confirmation != null ? (
            <DSField
              for="confirm-modal-input"
              label={
                <span>
                  Type{' '}
                  <span className="notranslate">
                    {confirmation.toUpperCase()}{' '}
                  </span>
                  to confirm:
                </span>
              }
            >
              <Input
                ref={inputRef}
                id="confirm-modal-input"
                value={confirmInputValue}
                onChange={handleChange}
                autoFocus
              />{' '}
            </DSField>
          ) : null}
          <Footer float="right">
            <DSButton variant="secondary" type="button" onClick={handleClose}>
              Cancel
            </DSButton>
            <DSButton
              variant={footerVariant}
              data-testid="confirm-modal-action-button"
              type="submit"
              disabled={!canConfirm || disableActionButton}
            >
              {action}
            </DSButton>
          </Footer>
        </form>
      </Container>
      {sideContent && <DSModal.SideContent>{sideContent}</DSModal.SideContent>}
    </DSModal>
  );
}
