import { useMemo } from 'react';
import { getData, getName, getNames } from 'country-list';

const COUNTRIES = getData();
const COUNTRY_NAMES = getNames();
const EUROPEAN_UNION = { code: 'EU', name: 'European Union' };

export function useCountryList(withEU?: boolean) {
  const countries = useMemo(() => {
    const sortedCountries = [...COUNTRIES].sort((a, b) =>
      a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })
    );

    if (withEU) {
      return [EUROPEAN_UNION, ...sortedCountries];
    }

    return sortedCountries;
  }, [withEU]);

  const countryNames = useMemo(() => {
    const sortedCountyNames = [...COUNTRY_NAMES].sort((a, b) =>
      a.localeCompare(b, undefined, { sensitivity: 'base' })
    );

    if (withEU) {
      return [EUROPEAN_UNION.name, ...sortedCountyNames];
    }

    return sortedCountyNames;
  }, [withEU]);

  const getCountryName = (code: string) => {
    if (code === EUROPEAN_UNION.code) {
      return EUROPEAN_UNION.name;
    }

    return getName(code);
  };

  return { countries, countryNames, getCountryName };
}
