import api from '@/api/api';
import { TId } from '@/features/common';
import { IRecommendationSettings } from '@/features/job-opening';
import { parseAPIData, serialiseAPIData } from '@/utils/parsers';

export async function fetchRecommendationSettings(params: {
  jobOpeningId: TId;
}): Promise<IRecommendationSettings> {
  const response = await api.get(
    `/openings/${params.jobOpeningId}/recommendations/settings`
  );
  return parseAPIData(response.data);
}

export async function updateRecommendationSettings(params: {
  jobOpeningId: TId;
  settings: IRecommendationSettings;
}): Promise<IRecommendationSettings> {
  const response = await api.post(
    `/openings/${params.jobOpeningId}/recommendations/settings`,
    serialiseAPIData(params.settings)
  );
  return parseAPIData(response.data);
}
