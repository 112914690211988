import api from '@/api/api';
import { TId } from '@/features/common';
import { IEmailResponse, IInvitationEmailParams } from '@/features/email';
import { parseAPIData, serialiseAPIData } from '@/utils/parsers';

export async function sendInvitationEmail(
  emailParams: IInvitationEmailParams
): Promise<IEmailResponse> {
  const response = await api.post(
    '/emails/invite',
    serialiseAPIData(emailParams, {
      replacements: [['job_opening_slug', 'slug']],
    }),
    {
      captchaAction: 'email_invite',
    }
  );
  return parseAPIData(response.data);
}

export async function sendBulkGmailEmail(params: {
  candidateIds: TId[];
  subject: string;
  message: string;
  cc?: string[];
  bcc?: string[];
}): Promise<void> {
  await api.post(
    `/emails/bulk`,
    serialiseAPIData({
      testTakerIds: params.candidateIds,
      subject: params.subject,
      message: params.message,
      cc: formatRecipients(params.cc),
      bcc: formatRecipients(params.bcc),
    })
  );
}

const formatRecipients = (recipients?: string[]) =>
  recipients?.map((item) => ({ email: item, name: '' }));
