import { match } from 'react-router-dom';

export const getMatchParam = (match: match, param: string): string => {
  const value: string | null = match.params[param];

  if (value == null) {
    throw new Error(`Missing required match parameter: ${param}`);
  }

  return value;
};
