import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { fetchTemplateCategories } from '../api';

export const TEMPLATE_CATEGORIES_QUERY_KEY = ['template_categories'];

export const useTemplateCategoriesQuery = (options?: UseQueryOptions) => {
  return useQuery(
    [...TEMPLATE_CATEGORIES_QUERY_KEY],
    () => fetchTemplateCategories(),
    options as any
  );
};
