import React from 'react';
import styled from '@emotion/styled';
import ReactHintFactory from 'react-hint';

const ReactHint = ReactHintFactory(React);

export const SchedulingLinkTooltip = ({ link, type }) => {
  return (
    <ReactHint
      events
      persist
      delay={100}
      position="right"
      attribute="data-scheduling-link-tooltip"
      onRenderContent={() => {
        return (
          <Content>
            <Title>{type}</Title>
            <Link>{link}</Link>
          </Content>
        );
      }}
    />
  );
};

const Content = styled.div`
  padding: 8px 16px;
  font-size: 12px;
  line-height: 1.4;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  color: ${(props) => props.theme.colors.purple[100]};
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  width: 300px;
  max-width: 300px;
  z-index: 9002;
`;

const Title = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.purple[100]};
`;

const Link = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.purple[60]};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
