export const togglAccountsUrl =
  import.meta.env.VITE_TOGGL_ACCOUNTS_URL || 'https://accounts.toggl.space';

export const getTogglAccountsLoginUrl = (returnTo?: string): string => {
  const loginUrl = new URL('/hire/login', togglAccountsUrl);
  if (returnTo) {
    loginUrl.searchParams.set('returnTo', returnTo);
  }

  return loginUrl.toString();
};
