import api from './api';

export async function deleteMe(): Promise<void> {
  await api.delete('me');
}

export async function exportMe(): Promise<string> {
  const response = await api.post('/me/takeout');
  const token = response.data.token;

  return `${import.meta.env.VITE_API_URL}/api/v1/me/takeout?token=${token}`;
}
