import { useQuery } from '@tanstack/react-query';

import { useCandidateSearchParams } from '@/features/candidate-search';
import { fetchWorkspaceCandidates } from '@/features/workspace/api';
import { useWorkspaceId } from '@/hooks/router';

export const WORKSPACE_CANDIDATES_KEY = ['workspace', 'candidates'];

export const useWorkspaceCandidatesQuery = () => {
  const workspaceId = useWorkspaceId();
  const { searchParams: candidatesSearchParams } = useCandidateSearchParams();

  return useQuery(
    [...WORKSPACE_CANDIDATES_KEY, candidatesSearchParams, workspaceId],
    () => fetchWorkspaceCandidates(workspaceId, candidatesSearchParams),
    {
      enabled: !!(
        candidatesSearchParams && parseInt(candidatesSearchParams.page)
      ),
    }
  );
};
