import React from 'react';

import {
  Content,
  Header,
  LabelWrapper,
  OptionsWrapper,
  SectionWrapper,
} from './ui';

interface TestSettingsSectionProps {
  children: React.ReactNode;
}

export function TestSettingsSection({ children }: TestSettingsSectionProps) {
  return <SectionWrapper>{children}</SectionWrapper>;
}

TestSettingsSection.Header = Header;
TestSettingsSection.Content = Content;
TestSettingsSection.OptionsWrapper = OptionsWrapper;
TestSettingsSection.LabelWrapper = LabelWrapper;
