import React, { useState } from 'react';
import styled from '@emotion/styled';
import { DSModal } from '@hundred5/design-system';

import { Lightbox } from './lightbox';

export function MarkdownImage({
  src,
  alt,
  title,
}: React.ImgHTMLAttributes<HTMLImageElement>) {
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);

  return (
    <MarkdownImageContainer>
      <img
        src={src}
        alt={alt || undefined}
        title={title || undefined}
        onClick={() => setIsLightboxOpen(true)}
      />

      <DSModal open={isLightboxOpen} onClose={() => setIsLightboxOpen(false)}>
        <Lightbox imageUrl={src!} />
      </DSModal>
    </MarkdownImageContainer>
  );
}

const MarkdownImageContainer = styled.span`
  cursor: pointer;
`;
