import React, { ComponentType } from 'react';
import styled from '@emotion/styled';

export function withDisabledCopy<T extends {}>(Component: ComponentType<T>) {
  const ComponentWithDisabledCopy = (props: T) => {
    return (
      <DisabledCopyWrapper onCopy={(e) => e.preventDefault()}>
        <Component {...(props as T)} />
      </DisabledCopyWrapper>
    );
  };
  return ComponentWithDisabledCopy;
}

const DisabledCopyWrapper = styled.div`
  user-select: none;
  * {
    user-select: none;
  }
`;
