import * as React from 'react';
import styled from '@emotion/styled';
import { DSButton } from '@hundred5/design-system';

import { BillingPlan, useSubscribeModal } from '@/features/billing';
import { useHistory, useWorkspaceId } from '@/hooks/router';

interface ContentProps {
  text: string;
  requiredPlan: BillingPlan;
}

export function Content({ text, requiredPlan }: ContentProps) {
  const history = useHistory();
  const { openSubscribeModal } = useSubscribeModal();
  const workspaceId = useWorkspaceId();
  return (
    <Container>
      {text}
      <DSButton
        fullWidth
        onClick={() => {
          history.push(`/admin/${workspaceId}/settings/billing`);
          openSubscribeModal(requiredPlan);
        }}
      >
        Upgrade
      </DSButton>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 16px 17px;
  width: 309px;
  height: 123px;
  background: ${(props) => props.theme.colors.white};
  border-radius: 6px;
  box-shadow: 0 1px 40px 0 rgba(0, 0, 0, 0.25);
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
`;
