import api from '@/api/api';
import { TId } from '@/features/common';
import { parseAPIData, serialiseAPIData } from '@/utils/parsers';

import { IQuestion } from '../types';

interface IQuestionParams {
  testId: TId;
  questionId: TId;
}

export type IQuestionUpdateAPIPayload = Omit<IQuestion, 'skill'> &
  Partial<Pick<IQuestion, 'skill'>> & {
    skillId: TId;
  };

export interface IUpdateQuestionParams {
  question: IQuestionUpdateAPIPayload;
}

export async function updateQuestion({
  question,
}: IUpdateQuestionParams): Promise<IQuestion> {
  const response = await api.put(
    `/questions/${question.id}`,
    serialiseAPIData(question)
  );

  return parseAPIData(response.data);
}

export async function regenerateQuestion({
  question,
}: IUpdateQuestionParams): Promise<IQuestion> {
  const response = await api.post(
    `/questions/${question.id}/regenerate`,
    serialiseAPIData(question)
  );

  return parseAPIData(response.data);
}

export async function deleteQuestion({ testId, questionId }: IQuestionParams) {
  await api.delete(`/tests/${testId}/questions/${questionId}`);
}

export interface ICreateLibraryQuestionParams {
  testId: TId;
  question: Pick<
    IQuestion,
    'questionType' | 'difficulty' | 'isRandom' | 'points' | 'orderWeight'
  > & { skillId: TId };
}

export async function createLibraryQuestion({
  testId,
  question,
}: ICreateLibraryQuestionParams): Promise<IQuestion> {
  const response = await api.post(
    `/tests/${testId}/library/question`,
    serialiseAPIData(question)
  );

  return parseAPIData(response.data);
}

interface ICreateCustomQuestionParams {
  testId: TId;
  question: Pick<
    IQuestion,
    | 'questionType'
    | 'points'
    | 'orderWeight'
    | 'question'
    | 'answer'
    | 'options'
  >;
}

export async function createCustomQuestion({
  testId,
  question,
}: ICreateCustomQuestionParams): Promise<IQuestion> {
  const response = await api.post(
    `/tests/${testId}/custom/question`,
    serialiseAPIData(question)
  );

  return parseAPIData(response.data);
}

export interface IQuestionPropertiesUpdateParams
  extends Partial<Pick<IQuestion, 'points' | 'requireAttachment'>> {}

export async function updateQuestionProperties({
  questionId,
  params,
}: {
  questionId: TId;
  params: IQuestionPropertiesUpdateParams;
}): Promise<IQuestion> {
  const response = await api.patch(
    `/questions/${questionId}`,
    serialiseAPIData({ ...params })
  );
  return parseAPIData(response.data);
}
