import { useContext } from 'react';

import { SubscribeModalContext } from './subscribe-modal-context';

export const useSubscribeModal = () => {
  const context = useContext(SubscribeModalContext);

  if (!context) {
    throw new Error(
      'useSubscribeModal must be used within a SubscribeModalProvider'
    );
  }

  const {
    isOpen,
    plan,
    challengeToken,
    preview,
    coupon,
    challengeRequired,
    challengeFailed,
    changeSubscriptionPlanPreview,
    onCouponActivated,
    onCouponCanceled,
    open,
    close,
  } = context;

  return {
    isSubscribeModalOpen: isOpen,
    plan,
    challengeToken,
    preview,
    coupon,
    challengeRequired,
    challengeFailed,
    changeSubscriptionPlanPreview,
    onCouponActivated,
    onCouponCanceled,
    openSubscribeModal: open,
    closeSubscribeModal: close,
  };
};
