import api from '@/api/api';
import { TId } from '@/features/common';

export async function bulkSendEmail(params: {
  candidateIds: TId[];
  subject: string;
  message: string;
  sendAt?: Date;
}): Promise<void> {
  let ids: number[] = params.candidateIds.map((id) => Number(id));
  await api.patch(
    `/test-takers/bulk`,
    {
      ids: ids,
      action: 'contact',
      test_taker_email: {
        subject: params.subject,
        message: params.message,
        tags_id: [],
        send_at: params.sendAt?.toISOString(),
      },
    },
    { captchaAction: 'bulk_contact' }
  );
}
