import * as amplitude from '@amplitude/analytics-browser';

import { ClientError } from '@/errors';
import { TId } from '@/features/common';
import { IDashboardJobOpening } from '@/features/dashboard';
import { IJobOpening } from '@/features/job-opening';
import { getJobOpeningUrlForCandidate } from '@/features/job-opening/utils/job-opening-url';
import { trackSmartlookEvent } from '@/smartlook';

import { getTryTestToken } from '../api';
import { ITest } from '../types';

export async function redirectToTryTheTestYourself(
  jobOpening: IJobOpening,
  workspaceId: TId,
  test: ITest
): Promise<void>;
export async function redirectToTryTheTestYourself(
  jobOpening: IDashboardJobOpening,
  workspaceId: TId,
  test?: ITest
): Promise<void>;
export async function redirectToTryTheTestYourself(
  jobOpening: IJobOpening | IDashboardJobOpening,
  workspaceId: TId,
  test?: ITest
) {
  const isMobile = window.matchMedia('(max-width: 768px)').matches;
  const backHref = `${window.location.origin}/admin/${workspaceId}/openings${
    isMobile ? '?desktopOnly=true' : `/${jobOpening.id}`
  }`;
  let url: string | null = null;

  if (!test && !('testId' in jobOpening)) {
    throw new Error(
      'redirectToTryTheTestYourself requires IDashboardJobOpening OR (IJobOpening AND ITest) as input'
    );
  }

  try {
    const { token } = await getTryTestToken({
      // @ts-ignore `test` should always be defined here as function would throw if it isn't and TS should protect against that anyway, but type narrowing doesn't get that
      testId: 'testId' in jobOpening ? jobOpening.testId : test.id,
    });

    trackTestTry(jobOpening);

    url = getJobOpeningUrlForCandidate({
      jobOpeningSlug: jobOpening.slug,
      hashedTestId: test?.hashedTestId,
      queryParams: {
        candidateToken: token,
        skipConfirmStart: true,
        backHref,
      },
    });
  } catch (error) {
    if (error instanceof ClientError && error.type === 'ReachedNrOfTries') {
      url = getJobOpeningUrlForCandidate({
        jobOpeningSlug: jobOpening.slug,
        hashedTestId: test?.hashedTestId,
        queryParams: {
          skipConfirmStart: true,
          backHref,
        },
      });
    } else {
      throw error;
    }
  } finally {
    if (!url) {
      return;
    }

    window.location.href = url;
  }
}

function trackTestTry(jobOpening: IJobOpening | IDashboardJobOpening) {
  trackSmartlookEvent('tried_the_test_themselves');
  amplitude?.logEvent('tried the test themselves', {
    'job opening id': jobOpening.id,
    'job opening name': jobOpening.name,
  });
}
