import React from 'react';

export const useFileDownload = () => {
  let textFile = React.useRef<string | null>(null);

  return (text: string): string => {
    const data = new Blob([text], { type: 'text/plain' });

    if (textFile.current !== null) {
      window.URL.revokeObjectURL(textFile.current!);
    }

    textFile.current = window.URL.createObjectURL(data);

    return textFile.current;
  };
};
