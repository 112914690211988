import { useContext } from 'react';

import { PreviewFeatureModalContext } from '../';

export function usePreviewFeatureModal() {
  const context = useContext(PreviewFeatureModalContext);

  if (!context) {
    throw new Error(
      'usePreviewFeatureModal must be used within a ModalProvider'
    );
  }

  const { isOpen, type, open, close, options } = context;

  return {
    isOpen,
    type,
    open,
    close,
    options,
  };
}
