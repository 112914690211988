import styled from '@emotion/styled';

export const UnitInputWrapper = styled.div`
  display: flex;
  align-items: center;

  & > div:first-of-type::after {
    display: block;
    position: absolute;
    top: 8px;
    right: 12px;
    color: ${({ theme }) => theme.colors.purple[60]};
  }

  input {
    padding-right: 50px; // add space so the input does not override the time unit
  }
`;
