import React from 'react';
import { useFormikContext } from 'formik';
import { Prompt } from 'react-router-dom';

const DEFAULT_MESSAGE =
  'You have unsaved changes! Are you sure you want to leave this page?';

interface PromptIfDirtyProps {
  message?: string;
}

export function PromptIfDirty({
  message = DEFAULT_MESSAGE,
}: PromptIfDirtyProps) {
  const formik = useFormikContext();

  return (
    <Prompt when={formik.dirty && formik.submitCount === 0} message={message} />
  );
}
