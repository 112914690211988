import * as Sentry from '@sentry/react';
import { History } from 'history';

export default function configureSentry(history: History) {
  if (import.meta.env.VITE_SENTRY_DSN) {
    Sentry.init({
      dsn: import.meta.env.VITE_SENTRY_DSN,
      environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
      integrations: [
        Sentry.reactRouterV5BrowserTracingIntegration({ history }),
        Sentry.replayIntegration(),
      ],
      tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE
        ? parseFloat(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE)
        : 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      tracePropagationTargets: [import.meta.env.VITE_API_URL],
    });
  }
}
