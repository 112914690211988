import { useContext } from 'react';

import { ShareModalContext } from './components/share-modal-context';

export const useShareModal = () => {
  const context = useContext(ShareModalContext);

  if (!context) {
    throw new Error('useShareModal must be used within a ModalProvider');
  }

  const {
    isOpen,
    jobOpeningIdSelected,
    categorySelected,
    selectJobOpening,
    selectCategory,
    open,
    close,
  } = context;

  return {
    isShareModalOpen: isOpen,
    jobOpeningIdSelected,
    categorySelected,
    selectJobOpening,
    selectCategory,
    openShareModal: open,
    closeShareModal: close,
  };
};
