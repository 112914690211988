import React from 'react';

import { ConfirmModal } from '@/features/common';
import { useDeleteQuestion } from '@/features/questions/hooks';
import { useTestPage } from '@/features/test';

interface DeleteQuestionModalProps {
  open: boolean;
  onClose: () => void;
  questionId: string;
}

export const DeleteQuestionModal = ({
  open,
  onClose,
  questionId,
}: DeleteQuestionModalProps) => {
  const { workspace, jobOpening, test } = useTestPage();

  const { deleteQuestion } = useDeleteQuestion(
    workspace.id,
    jobOpening.id,
    test.id,
    questionId
  );

  return (
    <ConfirmModal
      open={open}
      action="Remove"
      onConfirm={deleteQuestion}
      onClose={onClose}
    >
      <h3>Remove question</h3>
      <p>Do you want to remove this question from the test?</p>
    </ConfirmModal>
  );
};
