import { useCallback } from 'react';
import download from 'downloadjs';

import { useLogout } from '@/features/toggl-accounts';

import * as api from '../api/me';

import { useApiError } from './api';
import { useFetchAndDispatch } from './async';

export const useExportMe = () => {
  const exportMeCallback = useCallback(async () => {
    const url = await api.exportMe();
    download(url);
  }, []);

  return useFetchAndDispatch(exportMeCallback);
};

export const useDeleteMe = () => {
  const handleApiError = useApiError();
  const logOut = useLogout();

  return useCallback(async () => {
    try {
      await api.deleteMe();
    } catch (error) {
      handleApiError(error);
      return;
    }

    logOut();
  }, [handleApiError, logOut]);
};
