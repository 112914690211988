export function isTagRestricted(tagName: string) {
  return [
    'done',
    'pending',
    'processing',
    'rejected',
    'rejected with email',
    'contacted',
    'invited',
    'not invited',
    'interested',
    'out of time',
    'in progress',
    'completed',
  ].includes(tagName?.toLowerCase());
}
