import { useCallback } from 'react';
import * as amplitude from '@amplitude/analytics-browser';

import { useTestPage } from '@/features/test';

export function useLogFraudDetectionSettings() {
  const { workspace, jobOpening, test } = useTestPage();

  return useCallback(
    (settingName: string) => {
      amplitude?.logEvent(`fraud detection settings/${settingName}`, {
        'workspace id': workspace.id,
        'job opening id': jobOpening.id,
        'test id': test.id,
        'subscription plan': workspace.subscriptionPlan,
      });
    },
    [workspace, jobOpening, test]
  );
}
