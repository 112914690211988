import { decamelizeKeys } from 'humps';
import qs from 'qs';

export const stringifyParams = <T>(searchParams: Partial<T>) =>
  qs.stringify(decamelizeKeys(searchParams), {
    encode: false,
    arrayFormat: 'comma',
    // @ts-ignore
    commaRoundTrip: true,
  });
