import api from '@/api/api';
import { TId } from '@/features/common';
import { IFraudDetectionSettings } from '@/features/test';
import { parseAPIData, serialiseAPIData } from '@/utils/parsers';

export async function fetchFraudDetectionSettings(params: {
  testId: TId;
}): Promise<IFraudDetectionSettings> {
  const response = await api.get(`/tests/${params.testId}/fraud-detection`);
  return parseAPIData(response.data);
}

export async function updateFraudDetectionSettings(params: {
  testId: TId;
  settings: IFraudDetectionSettings;
}): Promise<IFraudDetectionSettings> {
  const response = await api.put(
    `/tests/${params.testId}/fraud-detection`,
    serialiseAPIData(params.settings)
  );
  return parseAPIData(response.data);
}
