import styled from '@emotion/styled';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
`;

const QuestionName = styled.p`
  margin: 0;
  font-weight: 500;
`;

const Actions = styled.div`
  display: flex;
  gap: 8px;
`;

export const PreviewHeader = {
  Wrapper,
  QuestionName,
  Actions,
};
