import React from 'react';
import { SplitFactoryProvider } from '@splitsoftware/splitio-react';

import { useWorkspaceId } from '@/hooks/router';

import { getSplitConfig } from '../../utils';

interface WorkspaceSplitProps {
  children: React.ReactNode;
}

export function WorkspaceSplit(props: WorkspaceSplitProps) {
  const workspaceId = useWorkspaceId();

  return (
    <SplitFactoryProvider
      config={getSplitConfig('workspace', workspaceId)}
      updateOnSdkReady
      updateOnSdkTimedout
    >
      {props.children}
    </SplitFactoryProvider>
  );
}
