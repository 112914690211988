import { useCallback, useEffect, useState } from 'react';
import { useMeasure } from 'react-use';

export const useCarousel = ({ slideStep }: { slideStep: number }) => {
  const [offset, setOffset] = useState({ left: 0 });
  const [canGoPrev, setCanGoPrev] = useState(false);
  const [canGoNext, setCanGoNext] = useState(false);
  const [containerRef, { width: containerWidth }] =
    useMeasure<HTMLDivElement>();
  const [contentRef, { width: contentWidth }] = useMeasure<HTMLDivElement>();

  const getHiddenWidth = useCallback(() => {
    return contentWidth - containerWidth;
  }, [contentWidth, containerWidth]);

  useEffect(() => {
    const hiddenWidth = getHiddenWidth();
    setCanGoPrev(offset.left < 0);
    setCanGoNext(hiddenWidth > 0 && offset.left > -hiddenWidth);

    if (Math.abs(offset.left) > 0 && hiddenWidth <= 0) {
      setOffset({ left: 0 });
    }
  }, [offset.left, getHiddenWidth]);

  const goPrev = () => {
    if (!canGoPrev) {
      return;
    }

    if (offset.left + slideStep >= 0) {
      setOffset({ left: 0 });
    } else {
      setOffset({ left: offset.left + slideStep });
    }
  };
  const goNext = () => {
    if (!canGoNext) {
      return;
    }

    if (offset.left - slideStep < -getHiddenWidth()) {
      setOffset({ left: -getHiddenWidth() });
    } else {
      setOffset({ left: offset.left - slideStep });
    }
  };

  return {
    containerRef,
    contentRef,
    goNext,
    goPrev,
    canGoNext,
    canGoPrev,
    offset,
  };
};
