import api from '@/api/api';
import { TId } from '@/features/common';
import { IPipelineCategory } from '@/features/pipeline';
import { parseAPIData, serialiseAPIData } from '@/utils/parsers';

export async function fetchPipelineCategories(params: {
  jobOpeningId: TId;
}): Promise<IPipelineCategory[]> {
  const response = await api.get(`/openings/${params.jobOpeningId}/categories`);

  return parseAPIData<IPipelineCategory[]>(response.data.categories) || [];
}

export async function updatePipelineCategory(params: {
  jobOpeningId: TId;
  category: IPipelineCategory;
}): Promise<IPipelineCategory> {
  const response = await api.put(
    `/categories/${params.category.id}`,
    serialiseAPIData(params.category)
  );

  return parseAPIData(response.data);
}

export async function createPipelineCategory(params: {
  jobOpeningId: TId;
  name: string;
  orderWeight: number;
}): Promise<IPipelineCategory> {
  const response = await api.post(
    `/openings/${params.jobOpeningId}/categories`,
    serialiseAPIData({ name: params.name, orderWeight: params.orderWeight })
  );

  return parseAPIData(response.data);
}

export async function deletePipelineCategory(params: {
  jobOpeningId: TId;
  categoryId: TId;
}): Promise<void> {
  await api.delete(`/categories/${params.categoryId}`);
}
