import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { Id } from '@/types/misc';

import { fetchDashboardJobOpenings } from '../api/job-openings';

export const DASHBOARD_JOB_OPENINGS_QUERY_KEY = ['dashboard-job-openings'];

export const useDashboardJobOpeningsQuery = (
  params: {
    workspaceId: Id;
    filter?: 'open' | 'closed';
  },
  options?: UseQueryOptions
) => {
  return useQuery(
    [...DASHBOARD_JOB_OPENINGS_QUERY_KEY, params.workspaceId, params?.filter],
    () => fetchDashboardJobOpenings(params),
    {
      ...(options as any),
    }
  );
};
