import React from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Icon } from '@/features/common';

interface DragIconProps {
  sortable: boolean;
}

export const DragIcon = ({ sortable }: DragIconProps) => {
  return (
    <StyledIcon
      icon={regular('grip-dots-vertical')}
      color="purple-60"
      disabled={!sortable}
    />
  );
};

const StyledIcon = styled(Icon)<{ disabled: boolean }>`
  opacity: ${(props) => (props.disabled ? 0 : 1)};
`;
