import React from 'react';
import styled from '@emotion/styled';

import { TScreenType } from '@/features/test';

interface FormTitleProps {
  screenType: TScreenType;
}

export function FormTitle({ screenType }: FormTitleProps) {
  switch (screenType) {
    case TScreenType.Passed: {
      return <Title>Above threshold</Title>;
    }
    case TScreenType.NotPassed: {
      return <Title>Below threshold</Title>;
    }
    default:
      return null;
  }
}

const Title = styled.h2`
  font-size: 20px;
  font-weight: 700;
  color: ${(props) => props.theme.typography.colorPrimary};
  margin: 0;
`;
