import React from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Icon, SemanticButton } from '@/features/common';
import { TQuestionSource } from '@/features/questions';

import { Option, OptionIcon } from './ui';

interface TestEditorQuestionSourceProps {
  onSourceSelect: (source: TQuestionSource) => void;
  questionCount: number;
  onClose?: () => void;
}

export function TestEditorQuestionSource({
  onSourceSelect,
  questionCount,
  onClose,
}: TestEditorQuestionSourceProps) {
  return (
    <TestEditorQuestionSourceContainer>
      {onClose && questionCount > 0 ? (
        <CloseButton onClick={onClose}>
          <Icon icon={regular('xmark')} color="purple-60" />
        </CloseButton>
      ) : null}

      <Header>Add new question</Header>

      <Content>
        <Option
          icon={<OptionIcon icon={regular('book-open')} color="orange-20" />}
          title="Toggl’s library"
          description="Choose from 20,000+ questions to assess any skill, at any level"
          onSelect={() => onSourceSelect('library')}
        />

        <Option
          icon={<OptionIcon icon={regular('plus')} color="green-10" />}
          title="Create new question"
          description="Start from scratch and create something beautiful"
          onSelect={() => onSourceSelect('custom')}
        />
      </Content>
    </TestEditorQuestionSourceContainer>
  );
}

const TestEditorQuestionSourceContainer = styled.div`
  padding: 36px 36px 140px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadow.card};
  display: grid;
  gap: 52px;
  position: relative;
  min-width: min-content;
  height: 440px;
`;

const CloseButton = styled(SemanticButton)`
  position: absolute;
  top: 24px;
  right: 24px;
  width: 20px;
  height: 20px;
  line-height: 1;
`;

const Header = styled.h1`
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.typography.colorPrimary};
  text-align: center;
`;

const Content = styled.div`
  display: flex;
  gap: 24px;
  justify-content: center;
  flex-flow: wrap;
`;
