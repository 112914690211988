import { decamelizeKeys } from 'humps';
import { pick } from 'lodash';

import api from '@/api/api';
import { ClientError } from '@/errors';
import { TId } from '@/features/common';
import {
  IJobOpeningATSIntegrationSettings,
  IATSIntegration,
  TATSIntegrationType,
  TATSJobOpening,
} from '@/features/integrations';
import { parseAPIData } from '@/utils/parsers';

export async function fetchATSIntegrations(params: {
  workspaceId: TId;
}): Promise<IATSIntegration[]> {
  try {
    const response = await api.get(
      `/workspaces/${params.workspaceId}/integrations`
    );

    return parseAPIData<IATSIntegration[]>(response.data.integrations) || [];
  } catch (error) {
    if (error instanceof ClientError && error.type === 'NotFound') {
      return [];
    } else {
      throw error;
    }
  }
}

export async function addATSIntegration(params: {
  workspaceId: TId;
  atsIntegrationType: TATSIntegrationType;
  jobOpeningId?: TId | null;
}): Promise<string | null> {
  const response = await api.get(
    `/workspaces/${params.workspaceId}/integrations/${params.atsIntegrationType}/add` +
      (params.jobOpeningId ? `?opening-id=${params.jobOpeningId}` : '')
  );
  return response.data.redirect_url;
}

export async function deleteATSIntegration(params: {
  atsIntegrationId: TId;
}): Promise<void> {
  await api.delete(`/integrations/${params.atsIntegrationId}`);
}

export async function fetchJobOpeningATSIntegrationSettings(params: {
  jobOpeningId: TId;
}): Promise<IJobOpeningATSIntegrationSettings[]> {
  try {
    const response = await api.get(
      `/openings/${params.jobOpeningId}/integrations`
    );
    return (
      parseAPIData<IJobOpeningATSIntegrationSettings[]>(
        response.data.integrations
      ) || []
    );
  } catch (error) {
    if (error instanceof ClientError && error.type === 'NotFound') {
      return [];
    } else {
      throw error;
    }
  }
}

export async function createJobOpeningATSIntegrationSettings(params: {
  jobOpeningId: TId;
  integration: IJobOpeningATSIntegrationSettings;
}): Promise<IJobOpeningATSIntegrationSettings> {
  const response = await api.post(
    `/openings/${params.jobOpeningId}/integrations`,
    decamelizeKeys(params.integration)
  );
  return parseAPIData<IJobOpeningATSIntegrationSettings>(response.data);
}

export async function updateJobOpeningATSIntegrationSettings(params: {
  jobOpeningId: TId;
  integration: IJobOpeningATSIntegrationSettings;
}): Promise<IJobOpeningATSIntegrationSettings> {
  const response = await api.put(
    `/openings/${params.jobOpeningId}/integrations/${params.integration.id}`,
    decamelizeKeys(
      pick(params.integration, [
        'jobOpeningExternalId',
        'jobOpeningExternalName',
        'jobOpeningExternalSupersetId',
      ])
    )
  );
  return parseAPIData<IJobOpeningATSIntegrationSettings>(response.data);
}

export async function deleteJobOpeningATSIntegrationSettings(params: {
  jobOpeningId: TId;
  integrationId: TId;
}): Promise<void> {
  await api.delete(
    `/openings/${params.jobOpeningId}/integrations/${params.integrationId}`
  );
}

export async function fetchATSJobOpenings(params: {
  atsIntegrationId: TId;
  atsIntegrationType: TATSIntegrationType;
}): Promise<TATSJobOpening[]> {
  try {
    const response = await api.get(
      `/integrations/${params.atsIntegrationType}/${params.atsIntegrationId}/openings`
    );
    return parseAPIData<TATSJobOpening[]>(response.data.openings) || [];
  } catch (error) {
    if (error instanceof ClientError && error.type === 'NotFound') {
      return [];
    } else {
      throw error;
    }
  }
}
