import React from 'react';
import styled from '@emotion/styled';
import { DSButton } from '@hundred5/design-system';
import { Formik } from 'formik';
import * as yup from 'yup';

import * as authApi from '@/api/authentication';
import { FormikInputField } from '@/features/common';
import { useFetchAndDispatch } from '@/hooks/async';

interface ResetPasswordFormProps {
  email: string;
  token: string;
  onReset: () => void;
}

const schema = yup.object().shape({
  password: yup
    .string()
    .required('Enter your new password')
    .min(6, 'Password must have more than 6 characters'),
});

export function ResetPasswordForm(props: ResetPasswordFormProps) {
  const [resetPassword] = useFetchAndDispatch(authApi.resetPassword);

  return (
    <Formik
      initialValues={{ password: '' }}
      validationSchema={schema}
      onSubmit={async (values, actions) => {
        const [error] = await resetPassword(
          props.email,
          props.token,
          values.password
        );

        actions.setSubmitting(false);
        if (error == null) {
          props.onReset();
        }
      }}
    >
      {(form) => (
        <Form onSubmit={form.handleSubmit} data-recording-ignore="mask">
          <FormikInputField
            id="reset-password-form-password"
            name="password"
            type="password"
            label="New password"
          />

          <SubmitButton type="submit" disabled={form.isSubmitting}>
            {form.isSubmitting ? 'Saving...' : 'Save new password'}
          </SubmitButton>
        </Form>
      )}
    </Formik>
  );
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const SubmitButton = styled(DSButton)`
  width: 100%;
  margin-top: 28px;

  @media screen and (max-height: 750px) and (min-width: 490px) {
    margin-top: 17px;
  }
`;
