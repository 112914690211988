import React, { createContext, useContext, useState } from 'react';
import styled from '@emotion/styled';
import { DSButton, DSModal } from '@hundred5/design-system';

import { useFileDownload } from '@/features/common';
import { IEmailResponse } from '@/features/email';

export const EmailResultModalContext = createContext<{
  resultEmails?: IEmailResponse;
  setResultEmails: React.Dispatch<React.SetStateAction<IEmailResponse>>;
}>({
  resultEmails: {
    successfulRecipientEmailAddress: undefined,
    failedRecipientEmailAddress: undefined,
  },
  setResultEmails: () => {},
});

export function EmailResultModalContextProvider({ children }) {
  const [resultEmails, setResultEmails] = useState<IEmailResponse>();
  return (
    <EmailResultModalContext.Provider value={{ resultEmails, setResultEmails }}>
      {children}
    </EmailResultModalContext.Provider>
  );
}

export const useEmailResultModalContext = () => {
  const context = useContext(EmailResultModalContext);

  if (!context) {
    throw new Error(
      'useEmailResultModalContext must be used within a EmailResultModalContextProvider'
    );
  }

  return context;
};

export const EmailDownloadModal = () => {
  const { resultEmails, setResultEmails } = useEmailResultModalContext();
  const makeTextFile = useFileDownload();

  if (!resultEmails?.failedRecipientEmailAddress) return null;

  const filename = 'failed_emails.csv';
  const failedEmailsSum = resultEmails.failedRecipientEmailAddress?.length;
  const downloadModalUrl = makeTextFile(
    resultEmails.failedRecipientEmailAddress
      .map((o) => o.emailAddress)
      .join('\n')
  );
  const downloadUrl = (uri: string, name: string) => {
    const link = document.createElement('a');
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleClose = () => {
    setResultEmails({
      successfulRecipientEmailAddress: [],
      failedRecipientEmailAddress: [],
    });
  };
  return (
    <DSModal
      fluid
      open={resultEmails.failedRecipientEmailAddress.length > 0}
      onClose={handleClose}
    >
      <DSModal.CloseButton fixed small onClick={handleClose} />
      <Text>{'Warning'}</Text>
      <DSModal.Separator />
      <p>{`Failed to send email to ${failedEmailsSum} recipients.`}</p>

      {resultEmails.successfulRecipientEmailAddress &&
        resultEmails.successfulRecipientEmailAddress.map((email) => {
          return (
            <EmailRow key={email}>
              <div>{email}</div>
            </EmailRow>
          );
        })}

      {resultEmails.failedRecipientEmailAddress.map((item) => {
        return (
          <EmailRow key={item.emailAddress}>
            <div>{item.emailAddress}</div>
            {!!item.error && (
              <StatusWrapper>
                {item.error}
                <Status>Failed</Status>
              </StatusWrapper>
            )}
          </EmailRow>
        );
      })}

      <DSModal.Footer>
        <DSButton
          onClick={() => {
            downloadUrl(downloadModalUrl, filename);
            handleClose();
          }}
        >
          Download
        </DSButton>
      </DSModal.Footer>
    </DSModal>
  );
};

const EmailRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 14px;

  @media (min-width: 768px) {
    min-width: 450px;
  }
`;

const StatusWrapper = styled.div`
  color: ${(props) => props.theme.colors.purple[100]};
  font-weight: 600;
  font-size: 10px;
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const Status = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.purple[100]};
  background: ${(props) => props.theme.colors.red[10]};
  border-radius: 6px;
  padding-left: 6px;
  padding-right: 6px;
  height: 16px;
`;

const Text = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 16px;
`;
