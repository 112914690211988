import React, { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { format } from 'date-fns/format';
import { subDays } from 'date-fns/subDays';
import { Link } from 'react-router-dom';

import { Icon } from '@/features/common';
import { useMeQuery } from '@/features/me';
import { useWorkspacePermission } from '@/features/permissions';
import { useShareModal } from '@/features/share';
import {
  useMarkStatsAsSeenMutation,
  useWorkspaceCandidatesStatsQuery,
  useWorkspaceStatsQuery,
} from '@/features/workspace';
import { useWorkspaceId } from '@/hooks/router';

type Stats = {
  sevenDayCountSinceLastSeen: number;
  sevenDayCount: number;
  actionRequiredCount: number;
  totalCandidates: number;
};

export function PreconfiguredFilters() {
  const workspaceId = useWorkspaceId();
  const canAddCandidate = useWorkspacePermission()('candidates', 'update');
  const meQuery = useMeQuery();
  const markStatsAsSeenMutation = useMarkStatsAsSeenMutation({ workspaceId });
  const workspaceStatsQuery = useWorkspaceStatsQuery({
    workspaceId,
    onSuccess: (data) => {
      setStats(
        (prevStats) =>
          prevStats && {
            ...prevStats,
            sevenDayCount: data.sevenDayCount,
            actionRequiredCount: data.actionRequiredCount,
          }
      );

      // endpoint doesn't work in support mode
      if (meQuery.data?.id !== '0') {
        markStatsAsSeenMutation.mutate();
      }
    },
  });
  const workspaceCandidatesStatsQuery = useWorkspaceCandidatesStatsQuery({
    workspaceId,
    onSuccess: (data) => {
      setStats(
        (prevStats) =>
          prevStats && {
            ...prevStats,
            totalCandidates: data.total,
          }
      );

      // endpoint doesn't work in support mode
      if (meQuery.data?.id !== '0') {
        markStatsAsSeenMutation.mutate();
      }
    },
  });
  const [stats, setStats] = useState<Stats | undefined>(undefined);
  const { openShareModal } = useShareModal();

  const filters = useMemo(() => {
    const today = format(new Date(), 'yyyy-MM-dd');
    const weekAgo = format(subDays(new Date(), 6), 'yyyy-MM-dd');

    return [
      {
        name: 'Last 7 days',
        url: `/admin/${workspaceId}/candidates?page=1&items_per_page=10&sort_order=date&sort_direction=desc&date=${weekAgo}--${today}`,
        sinceLastSeen: stats?.sevenDayCountSinceLastSeen ?? 0,
        total: stats?.sevenDayCount ?? 0,
      },
      {
        name: 'All candidates',
        url: `/admin/${workspaceId}/candidates?page=1&items_per_page=10&sort_order=date&sort_direction=desc`,
        total: stats?.totalCandidates ?? 0,
        sinceLastSeen: 0,
      },
      {
        name: 'Action required',
        url: `/admin/${workspaceId}/candidates?page=1&items_per_page=10&sort_order=date&sort_direction=desc&action_required=yes`,
        total: stats?.actionRequiredCount ?? 0,
        sinceLastSeen: 0,
        tooltip:
          'List of candidates who have remained untouched in the Pipeline for the last 7 days or more',
      },
    ];
  }, [workspaceId, stats]);

  useEffect(() => {
    if (
      workspaceStatsQuery.isSuccess &&
      workspaceCandidatesStatsQuery.isSuccess &&
      stats === undefined
    ) {
      setStats({
        sevenDayCountSinceLastSeen:
          workspaceStatsQuery.data.sevenDayCountSinceLastSeen,
        sevenDayCount: workspaceStatsQuery.data.sevenDayCount,
        actionRequiredCount: workspaceStatsQuery.data.actionRequiredCount,
        totalCandidates: workspaceCandidatesStatsQuery.data.total,
      });
    }
  }, [
    stats,
    workspaceStatsQuery.data,
    workspaceCandidatesStatsQuery.data,
    workspaceStatsQuery.isSuccess,
    workspaceCandidatesStatsQuery.isSuccess,
  ]);

  useEffect(() => {
    const timer = setTimeout(
      () => {
        workspaceStatsQuery.refetch();
        workspaceCandidatesStatsQuery.refetch();
      },
      1000 * 5 * 60
    );

    return () => {
      clearTimeout(timer);
    };
  }, [workspaceStatsQuery, workspaceCandidatesStatsQuery]);

  return (
    <Container>
      <Header>
        <Heading>Candidates</Heading>
        {canAddCandidate && (
          <AddNew
            onClick={() => openShareModal('')}
            data-navigation-tooltip="Add new candidate"
            data-navigation-tooltip-at="left"
          >
            <Icon icon={regular('plus')} color="purple-60" fontSize="16px" />
          </AddNew>
        )}
      </Header>
      {filters.length > 0 ? (
        <>
          <List>
            {filters.map((filter) => (
              <li key={filter.url}>
                <FilterLink to={filter.url}>
                  <Icon icon={regular('user-group')} color="peach-70" />
                  <FilterName>{filter.name}</FilterName>
                  {filter.tooltip ? (
                    <TooltipIcon
                      icon={regular('info-circle')}
                      color="purple-60"
                      data-rh={filter.tooltip}
                      size="sm"
                    />
                  ) : null}
                  <Badge active={!!filter.sinceLastSeen}>
                    {filter.sinceLastSeen > 0
                      ? filter.sinceLastSeen > 99
                        ? '99+'
                        : `+${filter.sinceLastSeen}`
                      : filter.total}
                  </Badge>
                </FilterLink>
              </li>
            ))}
          </List>
        </>
      ) : (
        <Info>No candidate. Add a job to invite candidates.</Info>
      )}
    </Container>
  );
}

const Container = styled.section`
  margin: 24px 0;
`;

const Header = styled.header`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  padding-left: 16px;
  padding-right: 8px;
`;

const Heading = styled.h4`
  color: ${({ theme }) => theme.typography.colorSecondary};
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 0;
  text-transform: uppercase;
`;

const AddNew = styled.button`
  align-items: center;
  background: transparent;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  display: flex;
  height: 28px;
  justify-content: center;
  padding: 0;
  width: 28px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.purple[90]};
  }
`;

const Info = styled.p`
  color: ${({ theme }) => theme.typography.colorSecondary};
  font-size: 12px;
  padding: 0 16px;
`;

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const Badge = styled.span<{ active: boolean }>`
  align-self: center;
  background: ${({ theme, active }) =>
    active ? theme.colors.peach[70] : theme.colors.purple[90]};
  border-radius: 8px;
  color: ${({ theme, active }) =>
    active ? theme.colors.purple[70] : theme.colors.peach[70]};
  font-size: 10px;
  font-weight: 700;
  line-height: 15px;
  margin-left: auto;
  padding: 0 8px;
`;

const FilterLink = styled(Link)`
  align-items: center;
  color: ${({ theme }) => theme.colors.peach[70]};
  display: flex;
  height: 28px;
  padding: 0 16px;
  background: transparent;

  &:hover {
    background: ${({ theme }) => theme.colors.purple[90]};

    ${Badge} {
      background: ${({ theme }) => theme.colors.purple[100]};
    }
  }

  @media all and (max-width: 768px) {
    pointer-events: none;
  }
`;

const FilterName = styled.span`
  display: inline-block;
  overflow: hidden;
  padding: 0 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const TooltipIcon = styled(Icon)`
  @media all and (max-width: 786px) {
    display: none;
  }
`;
