import { sendIntercomMessage } from '@/features/intercom';

const supportEmail = import.meta.env.VITE_SUPPORT_EMAIL;

/**
 * Report an error through intercom chat (if enabled) or email
 */
export const reportError = (errorId: string) => {
  const body = `Hello, I encountered a bug with error ID: ${errorId}`;

  if (!!window.Intercom) {
    sendIntercomMessage(body);
  } else {
    const encodedSubject = encodeURIComponent('Reporting an issue');
    const encodedBody = encodeURIComponent(body);
    const mailtoLink = `mailto:${supportEmail}?subject=${encodedSubject}&body=${encodedBody}`;

    window.open(mailtoLink, '_blank');
  }
};
