import React from 'react';
import styled from '@emotion/styled';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Icon } from '../../icon';

export function TagRecommended() {
  return (
    <Tag
      data-rh="Invited through Toggl Hire recommendations"
      data-rh-at="bottom"
    >
      <Icon icon={solid('fire')} color="orange-100" fontSize={12} />
    </Tag>
  );
}

const Tag = styled.div`
  width: 25px;
  height: 24px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.purple[100]};
`;
