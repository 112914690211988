import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { TId } from '@/features/common';

interface CandidatesCountContextProps {
  totalCandidates: number;
  updateCountByCategory: (categoryId: TId, count: number) => void;
}

const CandidatesCountContext = createContext<CandidatesCountContextProps>({
  totalCandidates: 0,
  updateCountByCategory: () => {},
});

//region Provider
interface CandidatesCountProviderProps {
  children: React.ReactNode;
}

export function CandidatesCountProvider(props: CandidatesCountProviderProps) {
  const [countByCategory, setCountByCategory] = useState<{
    [key: TId]: number;
  }>({});

  const updateCountByCategory = useCallback(
    (categoryId: TId, count: number) => {
      setCountByCategory((prev) => ({ ...prev, [categoryId]: count }));
    },
    [setCountByCategory]
  );

  const contextValue = useMemo(() => {
    return {
      totalCandidates: Object.values<number>(countByCategory).reduce(
        (acc, curr) => acc + curr,
        0
      ),
      updateCountByCategory,
    };
  }, [countByCategory, updateCountByCategory]);

  return <CandidatesCountContext.Provider value={contextValue} {...props} />;
}

//endregion

//region Hooks
export function useCandidatesCount() {
  const context = useContext(CandidatesCountContext);
  if (context === undefined) {
    throw new Error(
      'useCandidatesCount must be used within a CandidatesCountProvider'
    );
  }
  return context;
}

//endregion
