import React from 'react';

export function DiamondIcon() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="47.9033" rx="23.9517" fill="#2C1138" />
      <path
        d="M17.1812 16.6419H31.1206C31.238 16.6419 31.3486 16.6969 31.4195 16.7905L34.1252 20.3612L24.3002 33.4612C24.1502 33.6612 23.8502 33.6612 23.7002 33.4612L13.8752 20.3612L16.8943 16.7754C16.9656 16.6908 17.0705 16.6419 17.1812 16.6419Z"
        fill="#FFDE91"
      />
      <rect
        x="24.0002"
        y="11.25"
        width="0.75"
        height="3.75"
        rx="0.375"
        fill="#FFDE91"
      />
      <rect
        x="10.5002"
        y="13.9821"
        width="0.75"
        height="3.81552"
        rx="0.375"
        transform="rotate(-40 10.5002 13.9821)"
        fill="#FFDE91"
      />
      <rect
        width="0.75"
        height="3.81552"
        rx="0.375"
        transform="matrix(0.766044 0.642788 0.642788 -0.766044 34.5002 16.7979)"
        fill="#FFDE91"
      />
      <path
        d="M13.8557 19.4021L13.8465 19.4134L13.8377 19.4251L13.2752 20.1751L12.3752 21.3751H13.8752H34.1252H35.5657L34.7397 20.195L32.2266 16.605C32.2266 16.605 32.2266 16.6049 32.2266 16.6049C32.0161 16.3042 31.6721 16.1251 31.305 16.1251H17.0537C16.715 16.1251 16.3942 16.2778 16.1806 16.5407L13.8557 19.4021Z"
        fill="#FF897A"
        stroke="#2C1138"
        strokeWidth="1.5"
      />
    </svg>
  );
}
