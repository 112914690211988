import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import { updateChecklist } from '../api';

export const CHECKLIST_KEY = ['checklist'];

export const useUpdateChecklistMutation = (options?: UseMutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation(updateChecklist, {
    onSuccess: (_data, { workspaceId }) => {
      queryClient.invalidateQueries([...CHECKLIST_KEY, workspaceId]);
    },
    ...(options as any),
  });
};
