import React from 'react';
import styled from '@emotion/styled';
import { DSContentBlock } from '@hundred5/design-system';

interface RedirectBlockProps {
  redirectUrl: string;
}

export function RedirectBlock({ redirectUrl }: RedirectBlockProps) {
  return (
    <Wrapper>
      <RedirectBlockContainer>
        <DSContentBlock.Title>Redirect URL</DSContentBlock.Title>
        <DSContentBlock.Content>
          Candidates are redirected to{' '}
          <Link href={redirectUrl} rel="noreferrer noopener" target="_blank">
            {redirectUrl}
          </Link>{' '}
          after submitting the test.
        </DSContentBlock.Content>
      </RedirectBlockContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.purple[10]};
  border-radius: 8px;
  display: grid;
  // 40px - modal paddings, 117px - header height, 52px - content paddings, 52px - editing toolbar height
  min-height: calc(100vh - (80px + 117px + 52px + 52px));
`;

const RedirectBlockContainer = styled(DSContentBlock)`
  width: 576px;
  margin: 36px;
  place-self: center;
`;

const Link = styled.a`
  text-decoration: underline;
  color: inherit;
`;
