import styled from '@emotion/styled';
import TextareaAutosize from 'react-textarea-autosize';

export const StyledAutosizeTextArea = styled(TextareaAutosize)`
  display: block;
  width: 100%;
  background: transparent;
  font-size: 14px;
  line-height: 21px;
  color: ${(props) => props.theme.typography.colorPrimary};
  -webkit-appearance: none;
  resize: none;
  border: none;
  padding: 0;
  margin: 0;

  &:focus {
    outline: 0;
  }

  &::placeholder {
    color: ${(props) => props.theme.typography.colorSecondary};
  }
`;
