import store from 'store';

const AUTH_TOKEN_KEY = 'hundred5_auth_token';

export function loadAuthToken(): string | null {
  return store.get(AUTH_TOKEN_KEY);
}

export function storeAuthToken(authToken: string): void {
  store.set(AUTH_TOKEN_KEY, authToken);
}

export function clearAuthToken(): void {
  store.remove(AUTH_TOKEN_KEY);
}
