import React from 'react';
import { DSTestLandingPage } from '@hundred5/design-system';

import { TJobOpeningLoginMethod } from '@/features/job-opening';

interface LoginMethodsProps {
  methods: TJobOpeningLoginMethod[] | null;
}

const order: string[] = ['linkedin', 'github', 'facebook', 'email', 'test'];

export function LoginMethods({ methods }: LoginMethodsProps) {
  if (!methods?.length) return null;

  const orderedMethods = methods.sort(
    (a, b) => order.indexOf(a) - order.indexOf(b)
  );

  return (
    <DSTestLandingPage.Login label="Log in with">
      {orderedMethods.map(
        (method, index) =>
          method !== 'test' && (
            <React.Fragment key={index}>
              {method === 'email' ? (
                <>
                  {index !== 0 && (
                    <DSTestLandingPage.LoginSeparator label="or" />
                  )}
                  <DSTestLandingPage.LoginButton
                    method="other"
                    primary={index === 0}
                    label="Log in with email"
                    onClick={() => {}}
                  />
                </>
              ) : (
                <DSTestLandingPage.LoginButton
                  method={method}
                  primary={index === 0}
                  onClick={() => {}}
                />
              )}
            </React.Fragment>
          )
      )}
    </DSTestLandingPage.Login>
  );
}
