import React from 'react';

export function FullCustomizationIllustration() {
  return (
    <svg
      width="260"
      height="392"
      viewBox="0 0 260 392"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_80_21609)">
        <path
          d="M0 8C0 3.58172 3.58172 0 8 0H253C257.418 0 261 3.58172 261 8V384C261 388.418 257.418 392 253 392H8C3.58172 392 0 388.418 0 384V8Z"
          fill="#412A4C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M175.114 197.883V254.528C175.114 255.085 175.565 255.536 176.121 255.536H224.992C225.549 255.536 226 255.085 226 254.528V213.721C226 213.165 225.549 212.714 224.992 212.714H210.096C209.539 212.714 209.088 212.263 209.088 211.706V200.654C209.088 200.098 208.637 199.647 208.081 199.647H193.322C192.766 199.647 192.315 199.196 192.315 198.639V187.106C192.315 186.55 191.864 186.099 191.307 186.099H176.121C175.565 186.099 175.114 186.55 175.114 187.106V197.883Z"
          fill="#9E69F2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M224.245 255.536L175.114 198.724L224.245 255.536Z"
          fill="#43F6B7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M112.443 254.023C112.054 254.695 112.538 255.536 113.315 255.536H173.367C174.143 255.536 174.628 254.695 174.239 254.023L144.213 202.174C143.824 201.503 142.857 201.503 142.469 202.174L112.443 254.023Z"
          fill="#FCE5DB"
        />
        <path
          d="M172.689 188.015L116.297 162.634C115.79 162.406 115.193 162.632 114.965 163.139L106.301 182.389C106.073 182.897 106.299 183.493 106.806 183.722L163.199 209.102C163.706 209.331 164.302 209.104 164.531 208.597L173.195 189.347C173.423 188.84 173.197 188.243 172.689 188.015Z"
          fill="#DD6FD1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.0075 186.099L110.299 186.099C110.856 186.099 111.307 186.55 111.307 187.106V254.549C111.307 255.106 110.856 255.557 110.299 255.557H87.3249C87.3272 255.537 87.3284 255.517 87.3284 255.497V220.271C87.3284 220.267 87.3253 220.264 87.3215 220.264C87.3179 220.264 87.3149 220.261 87.3147 220.258L87.2941 219.973C86.7842 212.925 80.6678 207.415 73.269 207.415C65.8702 207.415 59.7539 212.925 59.2439 219.973L59.2234 220.258C59.2231 220.261 59.2201 220.264 59.2165 220.264C59.2128 220.264 59.2097 220.267 59.2097 220.271V255.497C59.2097 255.517 59.2109 255.537 59.2132 255.557H36.0075C35.4511 255.557 35 255.106 35 254.549V187.106C35 186.55 35.4511 186.099 36.0075 186.099Z"
          fill="#FF9587"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M146.59 174.378C136.293 174.378 127.945 166.011 127.945 155.689C127.945 145.367 136.293 137 146.59 137C156.888 137 165.235 145.367 165.235 155.689C165.235 166.011 156.888 174.378 146.59 174.378Z"
          fill="#FFDE91"
        />
      </g>
      <defs>
        <clipPath id="clip0_80_21609">
          <rect width="260" height="392" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
