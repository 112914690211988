import React from 'react';
import { DSCheckbox, DSInlineField } from '@hundred5/design-system';
import { useField } from 'formik';
import { uniqueId } from 'lodash';

type Props = {
  name: string;
  label: React.ReactNode;
  id?: string;
  value?: string;
  checked?: boolean;
  disabled?: boolean;
  errorAbsolute?: boolean;
  hideErrorMsg?: boolean;
  onChange?: (event: React.ChangeEvent<any>) => void;
} & React.ComponentProps<typeof DSCheckbox>;

export function FormikCheckboxField({
  name,
  label,
  id = uniqueId('formik-checkbox-field-'),
  value,
  checked,
  disabled,
  errorAbsolute,
  hideErrorMsg,
  onChange,
  ...rest
}: Props) {
  const [field, meta] = useField<any>({
    name: name,
    type: 'checkbox',
    value: value,
  });
  const error = meta.touched && !!meta.error ? meta.error : null;

  return (
    <DSInlineField
      for={id}
      label={label}
      error={hideErrorMsg ? undefined : error}
      errorAbsolute={errorAbsolute}
      disabled={disabled}
    >
      <DSCheckbox
        id={id}
        error={!!error}
        {...field}
        {...rest}
        onChange={(event) => {
          if (onChange) onChange(event);
          else field.onChange(event);
        }}
        checked={checked ?? field.checked}
        disabled={disabled}
      />
    </DSInlineField>
  );
}
