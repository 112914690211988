import React from 'react';

import { NavigationMenu, PageHeader } from '@/features/common';
import { useWorkspacePermission } from '@/features/permissions';
import { useWorkspaceIdOrNull } from '@/hooks/router';
import { getActiveDashboardTab } from '@/utils/dashboard';

export function WorkspaceSettingsPageHeader() {
  const workspaceId = useWorkspaceIdOrNull();
  const permissions = useSettingsPermissions();

  return (
    <PageHeader
      title="Settings"
      backButton={{
        to: `/${getActiveDashboardTab()}`,
        tooltip: 'Go back to dashboard',
      }}
      sticky
    >
      {workspaceId && (
        <NavigationMenu>
          {permissions['user'] && (
            <NavigationMenu.Item
              label="My Profile"
              to={`/admin/${workspaceId}/settings/profile`}
            />
          )}
          {permissions['workspace'] && (
            <NavigationMenu.Item
              label="Team"
              to={`/admin/${workspaceId}/settings/team`}
            />
          )}
          {permissions['billing'] && (
            <NavigationMenu.Item
              label="Plans and Billing"
              to={`/admin/${workspaceId}/settings/billing`}
            />
          )}
          <NavigationMenu.Item
            label="Email Templates"
            to={`/admin/${workspaceId}/settings/email-templates`}
          />

          {permissions['integrations'] && (
            <NavigationMenu.Item
              label="Integrations"
              to={`/admin/${workspaceId}/settings/integrations`}
            />
          )}
        </NavigationMenu>
      )}
    </PageHeader>
  );
}

const useSettingsPermissions = () => ({
  user: useWorkspacePermission()('user settings', 'read'),
  workspace: useWorkspacePermission()('workspace settings', 'read'),
  team: useWorkspacePermission()('workspace members', 'read'),
  billing: useWorkspacePermission()('billing', 'read'),
  integrations: useWorkspacePermission()('workspace integrations', 'read'),
});
