import React from 'react';
import styled from '@emotion/styled';
import { createPortal } from 'react-dom';
import { TransitionGroup } from 'react-transition-group';

import { CollapseTransition } from '@/features/common';
import { useNotifications } from '@/features/notifications';

import { Notification } from './ui';

export function Notifications() {
  const { notifications, removeNotification } = useNotifications();

  // Placement should take into account top banner
  const inAppContainer = document.getElementById('main-content');

  const Content = (
    <NotificationsWrapper>
      <NotificationsTransitionGroup>
        {notifications.map((notification) => (
          <CollapseTransition key={`notification-${notification.id}`}>
            <NotificationItem>
              <Notification
                content={notification.content}
                onClose={() => removeNotification(notification.id)}
              />
            </NotificationItem>
          </CollapseTransition>
        ))}
      </NotificationsTransitionGroup>
    </NotificationsWrapper>
  );

  return inAppContainer ? createPortal(Content, inAppContainer) : Content;
}

const NotificationsWrapper = styled.div`
  position: fixed;
  z-index: 9999;
  pointer-events: none;
  width: 100%;
  width: -webkit-fill-available; /* Chrome, Safari, Edge (Chromium-based) */
  width: -moz-available; /* Firefox */
`;

const NotificationsTransitionGroup = styled(TransitionGroup)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NotificationItem = styled.div`
  padding: 4px 24px;
  pointer-events: all;
`;
