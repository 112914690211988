import React from 'react';

import { PageLayoutContext } from './page-layout';

export function usePageLayout() {
  const context = React.useContext(PageLayoutContext);

  if (!context) {
    throw new Error('usePageLayout must be used within a PageLayoutProvider');
  }
  return context;
}
