import React, { createContext, useContext } from 'react';

import { useStoreData } from '@/features/storage';

import { useDefaultCandidateSearchParams } from '../hooks';
import {
  ICandidateSearchBaseProps,
  TCandidateSearchStoreParams,
} from '../types';

interface CandidateSearchContextProps {
  defaultParams: ICandidateSearchBaseProps;
  storageKey: string;
}

const CandidateSearchContext = createContext<CandidateSearchContextProps>({
  defaultParams: {
    pagination: {
      page: '1',
      itemsPerPage: '10',
    },
    sort: {
      sortDirection: 'desc',
      sortOrder: 'date',
    },
  },
  storageKey: 'candidate-search-params',
});

//region Provider
interface CandidateSearchProviderProps extends ICandidateSearchBaseProps {
  storageKey: string;
  children: React.ReactNode;
}

export function CandidateSearchProvider({
  children,
  storageKey,
  ...defaultParams
}: CandidateSearchProviderProps) {
  // Load the initial search params
  const [storeParams] = useStoreData<TCandidateSearchStoreParams>(storageKey);
  useDefaultCandidateSearchParams(defaultParams, storeParams);

  return (
    <CandidateSearchContext.Provider
      value={{
        defaultParams,
        storageKey,
      }}
    >
      {children}
    </CandidateSearchContext.Provider>
  );
}

//endregion

//region Hooks
export function useCandidateSearch() {
  const context = useContext(CandidateSearchContext);
  if (context === undefined) {
    throw new Error(
      'useCandidateSearch must be used within a CandidateSearchProvider'
    );
  }
  return context;
}

//endregion
