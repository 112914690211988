import React from 'react';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSButton, DSField, DSTestResultPage } from '@hundred5/design-system';

import { Icon } from '@/features/common';
import { IJobOpeningAttachment } from '@/features/job-opening';

interface AttachmentsProps {
  attachments?: IJobOpeningAttachment[];
}

export function Attachments({ attachments }: AttachmentsProps) {
  return attachments?.length ? (
    <DSTestResultPage.DataSection
      title={
        <>
          Attachments
          <Icon
            icon={regular('info-circle')}
            color="purple-60"
            fontSize={14}
            data-rh-at="right"
            data-rh="Go to Job Settings to enable more attachments"
          />
        </>
      }
    >
      {attachments.map((attachment, index) => (
        <DSField key={index} for={attachment.name} label={attachment.name}>
          <DSButton id={attachment.name} variant="secondary" disabled>
            Upload file
          </DSButton>
        </DSField>
      ))}
    </DSTestResultPage.DataSection>
  ) : null;
}
