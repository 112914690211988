import React from 'react';

import { TestType, useTestPage } from '@/features/test';

import { HomeworkTestSettings } from './homework-test-settings';
import { SkillsTestSettings } from './skills-test-settings';
import { VideoIntroSettings } from './video-intro-settings';

export const TestSettings = () => {
  const { test } = useTestPage();
  switch (test?.type) {
    case TestType.Quiz:
      return <SkillsTestSettings />;
    case TestType.Homework:
      return <HomeworkTestSettings />;
    case TestType.Video:
      return <VideoIntroSettings />;
    default:
      return null;
  }
};
