import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { fetchCandidateHistory } from '@/features/candidate';
import { TId } from '@/features/common';

export const CANDIDATE_HISTORY_QUERY_KEY = ['candidate', 'history'];

export const useCandidateHistoryQuery = (
  params: {
    candidateId: TId;
    limit: number;
    offset: number;
  },
  options?: UseQueryOptions
) =>
  useQuery(
    [
      ...CANDIDATE_HISTORY_QUERY_KEY,
      params.candidateId,
      params.limit,
      params.offset,
    ],
    () => fetchCandidateHistory(params),
    {
      ...(options as any),
    }
  );
