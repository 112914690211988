import React, { createContext, useCallback, useState } from 'react';

import { TId } from '@/features/common';
import { IPipelineCategoryWithTest } from '@/features/pipeline';

export const ShareModalContext = createContext<{
  isOpen: boolean;
  jobOpeningIdSelected: TId | null;
  categorySelected: IPipelineCategoryWithTest | undefined;
  selectJobOpening: (jobOpeningId: TId) => void;
  selectCategory: (categorySelected: IPipelineCategoryWithTest) => void;
  open: (
    jobOpeningId: TId,
    categorySelected?: IPipelineCategoryWithTest | undefined
  ) => void;
  close: () => void;
}>({
  isOpen: false,
  jobOpeningIdSelected: '',
  categorySelected: undefined,
  selectJobOpening: (jobOpeningId) => {},
  selectCategory: (categorySelected) => {},
  open: (jobOpeningId, categorySelected) => {},
  close: () => {},
});

export function ShareModalContextProvider({ children }) {
  const [jobOpeningIdSelected, setJobOpeningIdSelected] = useState<TId | null>(
    null
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [shareModalData, setShareModalData] = useState<{
    categorySelected: IPipelineCategoryWithTest | undefined;
  }>({
    categorySelected: undefined,
  });

  const open = useCallback(
    (jobOpeningId, categorySelected) => {
      setJobOpeningIdSelected(jobOpeningId);
      setShareModalData({
        categorySelected,
      });
      setIsOpen(true);
    },
    [setShareModalData]
  );
  const selectJobOpening = useCallback(
    (jobOpeningId) => {
      setJobOpeningIdSelected(jobOpeningId);
    },
    [setJobOpeningIdSelected]
  );
  const selectCategory = useCallback(
    (categorySelected) => {
      setShareModalData({
        categorySelected,
      });
      setIsOpen(true);
    },
    [setShareModalData]
  );
  const close = useCallback(() => {
    setIsOpen(false);
    setTimeout(() => {
      setJobOpeningIdSelected('');
      setShareModalData({
        categorySelected: undefined,
      });
    }, 500);
  }, [setIsOpen, setShareModalData, setJobOpeningIdSelected]);

  return (
    <ShareModalContext.Provider
      value={{
        isOpen,
        jobOpeningIdSelected: jobOpeningIdSelected,
        categorySelected: shareModalData.categorySelected,
        selectJobOpening,
        selectCategory,
        open,
        close,
      }}
    >
      {children}
    </ShareModalContext.Provider>
  );
}
