import React from 'react';
import styled from '@emotion/styled';
import { DSInput } from '@hundred5/design-system';

import {
  IQuestion,
  IQuestionOption,
  TQuestionType,
} from '@/features/questions';
import { IValidationResult } from '@/features/test';

import { ChoiceAnswerEditor } from './choice-answer-editor';
import { ValidationAlert } from './validation-alert';

interface QuestionAnswerEditorProps
  extends Pick<IQuestion, 'questionType' | 'answer' | 'options'> {
  onNumericAnswerChange: (value: number) => void;
  onOptionsChange: (options: IQuestionOption[]) => void;
  validationResults: IValidationResult[];
  isHomework: boolean;
}

const getDefaultChoiceWarning = (
  questionType: TQuestionType
): IValidationResult => ({
  type: 'warning',
  field: 'answer',
  message: `Add your options and mark the correct answer${
    questionType === 'multiple-choice' ? '(s).' : '.'
  }`,
});

export const QuestionAnswerEditor = ({
  questionType,
  answer,
  onNumericAnswerChange,
  options,
  onOptionsChange,
  validationResults,
  isHomework,
}: QuestionAnswerEditorProps) => {
  if (isHomework) return null;

  switch (questionType) {
    case 'single-choice':
    case 'multiple-choice':
    case 'picture-question':
      return (
        <Wrapper>
          <ValidationAlert
            fields={['answer']}
            results={
              validationResults.length > 0
                ? validationResults
                : [getDefaultChoiceWarning(questionType)]
            }
          />

          <ChoiceAnswerEditor
            questionType={questionType}
            options={options}
            onChange={onOptionsChange}
          />
        </Wrapper>
      );

    case 'numeric-input':
      return (
        <Wrapper isTextAnswer={true}>
          <ValidationAlert
            fields={['answer']}
            results={
              validationResults.length > 0
                ? validationResults
                : [
                    {
                      type: 'warning',
                      field: 'answer',
                      message: `Enter a numeric value such as 491 or -32.19`,
                    },
                  ]
            }
          />

          <NoBorderInput
            type="number"
            step={0.01}
            value={answer ?? ''}
            onChange={(event) => onNumericAnswerChange(+event.target.value)}
            placeholder="Write your answer here..."
          />
        </Wrapper>
      );

    default:
      return null;
  }
};

const Wrapper = styled.div<{ isTextAnswer?: boolean }>`
  padding: ${({ isTextAnswer }) =>
    // we need to make left padding smaller because of the text input
    // it already has padding on the left
    isTextAnswer ? '16px 24px 24px 12px' : '16px 24px'};
  display: grid;
  gap: 24px;
  border-top: 1px solid ${({ theme }) => theme.colors.purple[10]};
`;

const NoBorderInput = styled(DSInput)`
  border: none;

  &::placeholder {
    color: ${({ theme }) => theme.colors.purple[60]};
  }
`;
