import React from 'react';
import styled from '@emotion/styled';
import { togglTheme } from '@hundred5/design-system';

import { Container } from './ui/container';
import { Copy } from './ui/copy';
import { Illustration } from './ui/error-illustration';
import { Header } from './ui/header';
import { InlineButton } from './ui/inline-button';

interface ErrorFallbackProps {
  eventId: string | null;
  resetError: () => void;
}

export function ErrorFallback({ eventId, resetError }: ErrorFallbackProps) {
  const supportEmail = import.meta.env.VITE_SUPPORT_EMAIL;

  return (
    <Container>
      <Illustration />
      <Header>Something went wrong</Header>
      <Copy>
        Please{' '}
        <InlineButton onClick={() => resetError()}>try again</InlineButton> or
        contact support if the problem persists.
        <br />
        <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
      </Copy>
      <EventId>Error ID: {eventId}</EventId>
    </Container>
  );
}

const EventId = styled(Copy)`
  background: ${togglTheme.colors.purple[20]};
  border-radius: 8px;
  color: ${togglTheme.colors.purple[100]};
  padding: 4px;
`;
