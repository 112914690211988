import React from 'react';
import styled from '@emotion/styled';
import { DSButton, DSModal } from '@hundred5/design-system';

import {
  ECancelSubscribeStep,
  ICancelModalStepProps,
  useBillingPlansConfig,
  useSubscribeModal,
} from '@/features/billing';
import { getPlanName, getPlanPeriod } from '@/hooks/planLimits';

import { usePauseSubscriptionModal } from '../cancel-subscription-modal.hooks';

import {
  CancelModalContentContainer,
  CancelModalFooterButtons,
  CancelModalHeader,
  PauseIcon,
} from '.';

export const InitialCancelStepModal = ({
  open,
  setCurrentStep,
}: ICancelModalStepProps) => {
  const { isPausingSubscription, currentPlan } = usePauseSubscriptionModal();
  const { openSubscribeModal } = useSubscribeModal();

  const { plans: planDescriptions } = useBillingPlansConfig();
  const currentPlanDescription =
    currentPlan &&
    planDescriptions.find((item) => item.plan === getPlanName(currentPlan));
  const offerPause = currentPlan && getPlanPeriod(currentPlan) === 'monthly';
  const offerMonthly =
    currentPlan &&
    currentPlan !== 'premium-12m-60c-2023' &&
    getPlanName(currentPlan) !== 'basic' &&
    currentPlanDescription &&
    getPlanPeriod(currentPlan) === 'annual';
  const offerBasicPlan = currentPlan === 'premium-12m-60c-2023';
  const width = offerPause ? '756px' : '640px';

  return (
    <>
      <DSModal
        fluid
        open={open}
        onClose={() => setCurrentStep(undefined)}
        contentStyle={{ display: 'flex', width: width }}
      >
        <Container>
          <CancelModalHeader>
            You’re about to cancel your subscription
            <DSModal.CloseButton
              fixed
              small
              onClick={() => setCurrentStep(undefined)}
            />
          </CancelModalHeader>
          <DSModal.Separator />
          <CancelModalContentContainer data-testid="cancel-subscription-initial-modal">
            When you cancel your paid plan:
            <InitialModalList>
              <li>All your active jobs will be automatically closed</li>
              <li>Candidates can no longer apply to current jobs</li>
              <li>You will lose access to all candidate data in all jobs</li>
            </InitialModalList>
            <strong>Are you sure?</strong>
            <DSModal.Footer>
              <CancelModalFooterButtons>
                <DSButton
                  variant={
                    offerMonthly || offerBasicPlan ? 'tertiary' : 'secondary'
                  }
                  type="button"
                  onClick={() =>
                    currentPlan &&
                    setCurrentStep(ECancelSubscribeStep.ConfirmCancel)
                  }
                >
                  Cancel subscription
                </DSButton>
                <DSButton
                  variant={
                    offerMonthly || offerBasicPlan ? 'secondary' : 'primary'
                  }
                  type="button"
                  onClick={() => setCurrentStep(undefined)}
                >
                  Stay on current plan
                </DSButton>
                {offerMonthly && (
                  <DSButton
                    onClick={() => {
                      setCurrentStep(undefined);
                      openSubscribeModal(
                        currentPlanDescription.planCode.monthly
                      );
                    }}
                  >
                    Switch to monthly
                  </DSButton>
                )}
                {offerBasicPlan && (
                  <DSButton
                    onClick={() => {
                      setCurrentStep(undefined);
                      openSubscribeModal('basic-12m-v2-2023');
                    }}
                  >
                    Switch to Basic
                  </DSButton>
                )}
              </CancelModalFooterButtons>
            </DSModal.Footer>
          </CancelModalContentContainer>
        </Container>
        {currentPlan && getPlanPeriod(currentPlan) === 'monthly' && (
          <DSModal.SideContent>
            <PauseSideContent>
              <PauseIcon />
              <span>Pause instead</span>
              Pause your subscription now to preserve all your data. When you
              are ready to hire again, just restore your subscription and
              continue where you left off.
              <DSButton
                variant="secondary"
                type="button"
                disabled={isPausingSubscription}
                onClick={() =>
                  setCurrentStep(ECancelSubscribeStep.ConfirmPause)
                }
              >
                Pause
              </DSButton>
            </PauseSideContent>
          </DSModal.SideContent>
        )}
      </DSModal>
    </>
  );
};

const Container = styled.div`
  flex-grow: 1;
`;

const PauseSideContent = styled.div`
  display: flex;
  width: 152px;
  align-items: center;
  flex-direction: column;
  font-size: 12px;
  line-height: 150%;
  padding-top: 16px;
  text-align: center;

  span {
    font-weight: 600;
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 12px;
  }

  button {
    margin-top: auto;
  }
`;

const InitialModalList = styled.ul`
  line-height: 27px;
  padding-left: 20px;
`;
