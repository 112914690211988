import React, { useMemo } from 'react';
import { DSField, DSTextArea } from '@hundred5/design-system';
import { useField } from 'formik';
import { uniqueId } from 'lodash';

type Props = {
  name: string;
  label?: React.ReactNode;
  required?: boolean;
  id?: string;
  placeholder?: string;
  readonly?: boolean;
  disabled?: boolean;
  errorAbsolute?: boolean;
  inputHeight?: string;
};

export function FormikTextAreaField(props: Props) {
  const [field, meta] = useField<any>(props.name);
  const error = meta.touched && !!meta.error ? meta.error : null;
  const id = useMemo(() => props.id || uniqueId('formik-field-'), [props.id]);

  return (
    <DSField
      for={id}
      label={props.label}
      required={props.required}
      error={error}
      errorAbsolute={props.errorAbsolute}
    >
      <DSTextArea
        id={id}
        placeholder={props.placeholder}
        error={!!error}
        {...field}
        readOnly={props.readonly}
        disabled={props.disabled}
        height={props.inputHeight}
      />
    </DSField>
  );
}
