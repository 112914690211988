/**
 * Pluralize a word based on a count
 * @param count - number, required
 * @param word - string, required
 * @param plural - string, optional
 */
export const pluralize = (
  count: number,
  word: string,
  plural: string = `${word}s`
) => {
  return count === 1 ? word : plural;
};

/**
 * Pluralize a word based on a count and include the count
 * @param count - number, required
 * @param word - string, required
 * @param plural - string, optional
 */
export const pluralizeWithCount = (
  count: number,
  word: string,
  plural: string = `${word}s`
) => {
  return `${count} ${pluralize(count, word, plural)}`;
};
