import React from 'react';
import styled from '@emotion/styled';
import { DSIconArrowLeft, DSIconArrowRight } from '@hundred5/design-system';
import { format } from 'date-fns/format';
import ReactDayPicker, {
  CaptionElementProps,
  DayPickerProps,
  NavbarElementProps,
} from 'react-day-picker';
import 'react-day-picker/lib/style.css';

export function DayPicker(props: DayPickerProps) {
  return (
    <Wrapper>
      <ReactDayPicker
        firstDayOfWeek={1}
        weekdaysShort={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
        showOutsideDays
        captionElement={CustomCaption}
        navbarElement={CustomNavigation}
        {...props}
      />
    </Wrapper>
  );
}

const CustomCaption = (props: CaptionElementProps) => {
  return (
    <Caption className="DayPicker-Caption" style={{}}>
      {format(props.date, 'MMMM')}
    </Caption>
  );
};

const CustomNavigation = (props: NavbarElementProps) => {
  return (
    <NavBar className="DayPicker-NavBar">
      <NavButton
        prev
        type="button"
        aria-label="Previous Month"
        className="DayPicker-NavButton-2"
        onClick={() => props.onPreviousClick()}
      >
        <DSIconArrowLeft />
      </NavButton>
      <NavButton
        type="button"
        aria-label="Next Month"
        className="DayPicker-NavButton-2"
        onClick={() => props.onNextClick()}
      >
        <DSIconArrowRight />
      </NavButton>
    </NavBar>
  );
};

const Wrapper = styled.div`
  .DayPicker-Weekday {
    color: ${(props) => props.theme.typography.colorSecondary};
    font-weight: 400;
    font-size: 12px;
    padding: 0;
    height: 24px;
    min-width: 36px;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }

  .DayPicker-Weekdays {
    margin-top: 12px;
  }

  .DayPicker-Day {
    color: ${(props) => props.theme.typography.colorPrimary};
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    border-radius: 8px;
    padding: 2px 1px;
    min-width: 36px;
    height: 36px;
    font-weight: 400;
    font-size: 14px;
    background-clip: content-box;
  }

  .DayPicker-Day--disabled,
  .DayPicker-Day--outside {
    color: ${(props) => props.theme.colors.purple[40]};
  }

  .DayPicker-WeekdaysRow,
  .DayPicker-Week:not(:last-of-type) {
    border-bottom: 1px solid ${(props) => props.theme.colors.purple[10]};
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(
      .DayPicker-Day--outside
    ) {
    background-color: ${(props) => props.theme.colors.orange[100]};
  }

  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(
      .DayPicker-Day--selected
    ):not(.DayPicker-Day--outside):hover {
    background-color: ${(props) => props.theme.hover.onWhite};
  }

  .DayPicker-Month {
    margin: 0;
    padding: 0;
  }

  .DayPicker-wrapper {
    padding: 0;
    margin: 12px;
  }
`;

const Caption = styled.div`
  text-align: center;
  padding: 5px 0 0;
  margin-bottom: 12px;
  height: 36px;
  font-size: 20px;
  font-weight: 500;
  color: ${(props) => props.theme.typography.colorPrimary};
`;

const NavBar = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  left: 0;
  right: 0;
`;

const NavButton = styled.button<{ prev?: boolean }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  -webkit-appearance: none;
  cursor: pointer;
  border-radius: ${(props) => (props.prev ? '8px 0 0 8px' : '0 8px 8px 0')};
  height: 36px;
  width: 36px;
  outline: none;
  color: ${(props) => props.theme.colors.purple[100]};
  background: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.purple[40]};
  transition:
    background 0.1s,
    transform 0.1s;

  &:disabled {
    opacity: 0.4;
    cursor: default;
  }

  &:active {
    transform: scale(0.96);
  }

  &:hover {
    background: ${(props) => props.theme.hover.onWhite};
  }

  svg {
    height: 10px;
    width: 10px;
  }
`;
