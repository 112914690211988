import { ImagePurpose } from '../types/image';
import { Id } from '../types/misc';

import api from './api';

const IMAGE_PURPOSES: { [K in ImagePurpose]: string | null } = {
  profile_picture: 'profile-picture',
  workspace_logo: 'workspace-logo',
  opening_background_image: 'opening-background-image',
  opening_social_meta_image: 'opening-social-meta-image',
  picture_question: 'picture-question',
};

export async function uploadImage(
  source: File,
  type: ImagePurpose | null,
  workspaceId: Id | null
): Promise<string> {
  const requestData = new FormData();
  requestData.append('upload', source);

  const url =
    workspaceId != null ? `/workspaces/${workspaceId}/images` : '/images';

  const response = await api.post(url, requestData, {
    params: { type: type ? IMAGE_PURPOSES[type] : undefined },
  });

  return response.data.url;
}
