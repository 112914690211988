import { useContext } from 'react';

import { UpsellModalContext } from '@/features/common/components/upsell-modal/upsell-modal-context';

export const useUpsellModal = () => {
  const context = useContext(UpsellModalContext);

  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }

  const { isOpen, type, options, open, close } = context;

  return { isOpen, type, options, openUpsellModal: open, close };
};
