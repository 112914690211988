import React from 'react';
import styled from '@emotion/styled';
import { DSContentBlock } from '@hundred5/design-system';
import { Link } from 'react-router-dom';

import { useTestPage } from '@/features/test';

import { Illustration } from './ui';

export function SkillsTestSettingsAuthentication() {
  const { workspace, jobOpening } = useTestPage();

  return (
    <DSContentBlock>
      <DSContentBlock.Title>
        Authentication method for candidates
      </DSContentBlock.Title>
      <DSContentBlock.Content>
        <ContentGrid>
          <Info>
            You can choose the authentication method for this test, including
            email address or social login options.
          </Info>
          <Actions>
            <SettingsLink
              to={`/admin/${workspace.id}/openings/${jobOpening.id}/job-settings`}
            >
              Go to job settings
            </SettingsLink>
          </Actions>
          <Illustration />
        </ContentGrid>
      </DSContentBlock.Content>
    </DSContentBlock>
  );
}

const ContentGrid = styled.div`
  display: grid;
  grid-template-areas:
    'info'
    'actions'
    'illustration';
  grid-template-columns: 1fr;
  gap: 24px;

  @media (min-width: 769px) {
    grid-template-areas: 'info illustration' 'actions illustration';
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
  }
`;

const Info = styled.div`
  grid-area: info;
`;

const Actions = styled.div`
  grid-area: actions;
`;

const SettingsLink = styled(Link)`
  text-decoration: underline;
  color: ${(props) => props.theme.typography.colorPrimary};
  font-size: 14px;
  font-weight: 400;
`;
