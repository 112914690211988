import { decamelizeKeys } from 'humps';

import { LogInCredentials, SignUpAttributes } from '../types/authentication';

import api from './api';

export async function authenticate({
  email,
  password,
}: LogInCredentials): Promise<string> {
  const response = await api.post(
    '/auth/authenticate',
    {
      email,
      password,
    },
    { captchaAction: 'authenticate' }
  );

  return response.data.access_token;
}

export async function signup(attributes: SignUpAttributes): Promise<void> {
  await api.post('/auth/signup', decamelizeKeys(attributes), {
    captchaAction: 'authenticate',
  });
}

export async function updatePassword(oldPassword: string, newPassword: string) {
  await api.put(
    '/auth/password/update',
    decamelizeKeys({ oldPassword, newPassword }),
    { captchaAction: 'update_password' }
  );
}

export async function requestPasswordReset(email: string) {
  await api.post(
    '/auth/password/forgot',
    { email },
    { captchaAction: 'password_forgot' }
  );
}

export async function resetPassword(
  email: string,
  token: string,
  password: string
) {
  await api.post(
    '/auth/password/reset',
    { email, token, password },
    { captchaAction: 'reset_forgot' }
  );
}

export async function sendVerificationEmail(email: string) {
  await api.post(
    '/auth/email/verify/send-email',
    { email },
    { captchaAction: 'verify_send_email' }
  );
}

export async function verifyEmail(token: string) {
  await api.post(
    '/auth/email/verify',
    { token },
    { captchaAction: 'verify_email' }
  );
}
