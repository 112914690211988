import React, { useEffect, useState } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import styled from '@emotion/styled';
import { object } from 'yup';

import { TId } from '@/features/common';
import {
  convertVariablesToCamelCase,
  EmailDownloadModal,
  EmailList,
  emailMessageValidationSchema,
  emailSubjectValidationSchema,
  FormikEmailBody,
  FormikEmailForm,
  FormikInputMultipleEmailsField,
  IEmailFormValuesBase,
  TEmailTemplateType,
  useEmailModalContext,
  useEmailResultModalContext,
  useEmailTemplateVariables,
  useOnSend,
} from '@/features/email';
import { trackIntercomEvent } from '@/features/intercom';
import { useJobOpeningIdOrNull, useWorkspaceId } from '@/hooks/router';

import { SubmitButtons } from './ui/submit-buttons';

interface SendEmailFormProps {
  testId?: TId;
  interviewId?: TId | null;
  emailTemplatesType?: TEmailTemplateType;
  onSubmit?: () => void;
  emails?: string[];
  initialSubject?: string;
  initialMessage?: string;
  showUpsell?: boolean;
  actionButton?: React.ReactNode;
}

export interface IEmailModalFormValues extends IEmailFormValuesBase {
  sendAt?: Date;
  emails: string[];
  action: 'reject-without-email' | 'send';
  cc?: string[];
  bcc?: string[];
}

export function SendEmailForm({
  emails = [],
  onSubmit,
  emailTemplatesType,
  testId,
  interviewId,
  initialSubject,
  initialMessage,
  showUpsell,
  actionButton,
}: SendEmailFormProps) {
  const workspaceId = useWorkspaceId();
  const jobOpeningId = useJobOpeningIdOrNull();
  const { emailTemplateVariablesUsedAreValid } = useEmailTemplateVariables();
  const { emailModal } = useEmailModalContext();
  const emailModalType = emailModal?.type;

  const onSend = useOnSend();
  const { resultEmails } = useEmailResultModalContext();
  const [sending, setSending] = useState<boolean | undefined>(undefined);

  const schema = object().shape({
    subject: emailSubjectValidationSchema,
    message: emailMessageValidationSchema,
  });

  const handleSubmit = async (values: IEmailModalFormValues) => {
    if (showUpsell) {
      return;
    }

    const subject = convertVariablesToCamelCase(values.subject);

    if (
      !emailTemplateVariablesUsedAreValid(subject) ||
      !emailTemplateVariablesUsedAreValid(values.message)
    ) {
      return;
    }

    setSending(true);

    await onSend(
      values.emails,
      subject,
      values.message,
      values.sendAt,
      values.action,
      values.cc,
      values.bcc
    );

    setSending(false);

    amplitude?.logEvent('emails/send emails', {
      'workspace id': workspaceId,
      type: emailTemplatesType || '',
      'email count': values.emails.length,
    });

    if (emailTemplatesType) {
      trackIntercomEvent(
        emailTemplatesType === 'invitation'
          ? 'invited_candidates'
          : emailTemplatesType === 'rejection'
            ? 'rejected_candidate'
            : ''
      );
    }
  };

  useEffect(() => {
    if (sending === false) {
      if (!resultEmails?.failedRecipientEmailAddress?.length && onSubmit) {
        onSubmit();
      }
      setSending(undefined);
    }
  }, [onSubmit, resultEmails, sending]);

  return (
    <>
      <FormikEmailForm<IEmailModalFormValues>
        initialValues={{
          emails: emails,
          message: initialMessage || '<p></p>',
          cc: [],
          bcc: [],
          subject: initialSubject || '',
          action: 'send',
        }}
        onSubmit={handleSubmit}
        validationSchema={schema}
      >
        {({ values, isValid, errors }) => (
          <>
            <Wrapper>
              {emails?.length > 0 ? (
                <EmailList
                  emails={emails}
                  cc={values.cc}
                  bcc={values.bcc}
                  addCCToEmails={
                    emailModalType &&
                    [
                      'gmail-candidate',
                      'gmail-bulk-contact-candidate',
                    ].includes(emailModalType)
                  }
                />
              ) : (
                <FormikInputMultipleEmailsField
                  name="emails"
                  label="Email"
                  placeholder="Add one or more emails"
                  required
                  disableFileInput={true}
                />
              )}
              <FormikEmailBody
                testId={testId || undefined}
                interviewId={interviewId || null}
                jobOpeningId={jobOpeningId}
                emailType={
                  (interviewId ? 'interview-invitation' : emailTemplatesType) ||
                  'invitation'
                }
              />
            </Wrapper>
            <Footer>
              <ActionButtonWrapper>{actionButton}</ActionButtonWrapper>
              <SubmitButtons
                sending={!!sending}
                disabled={
                  !isValid ||
                  !!sending ||
                  (values.emails.length === 0 && emails.length === 0)
                }
                {...((errors.subject || errors.message) && {
                  'data-rh': 'An e-mail must have subject and body.',
                  'data-rh-at': 'top',
                })}
              />
            </Footer>
          </>
        )}
      </FormikEmailForm>
      <EmailDownloadModal />
    </>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ActionButtonWrapper = styled.div`
  margin-right: auto;
`;

const Footer = styled.div`
  padding-top: 36px;
  display: flex;
  justify-content: space-between;
`;
